import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import WhiteCompany from "../../assets/White-sidebar-icon/Superadmin-Company.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BarChart, Bar, XAxis, ResponsiveContainer } from "recharts";
import {
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
function WorkerGraphs() {
  const ComparisonGraphh = ({ data, poll1, poll2 }) => {
    return (
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={Object.keys(data).map((month) => ({
            month,
            "Active Plans": data[month].activePlans,
            "Inactive Plans": data[month].inactivePlans,
          }))}
          barGap={-5}
        >
          <XAxis
            dataKey="month"
            axisLine={{ stroke: "transparent" }}
            tickLine={{ stroke: "transparent" }}
            tick={{ fontFamily: "Roboto", fontSize: 14 }}
          />
          <Bar
            dataKey="Active Plans"
            fill={poll1}
            shape={({ x, y, width, height }) => {
              const radius = 12;
              return (
                <g>
                  <rect
                    x={x}
                    y={y}
                    width={width * 0.8}
                    height={height}
                    fill={poll1}
                    rx={radius}
                    ry={radius}
                  />
                  <rect
                    x={x}
                    y={y + height - radius}
                    width={width * 0.8}
                    height={radius}
                    fill={poll1}
                  />
                </g>
              );
            }}
          />
          {/* <Bar
                dataKey="Inactive Plans"
                fill={poll2}
                shape={({ x, y, width, height }) => {
                  const radius = 12;
                  return (
                    <g>
                      <rect
                        x={x}
                        y={y}
                        width={width * 0.8}
                        height={height}
                        fill={poll2}
                        rx={radius}
                        ry={radius}
                      />
                      <rect
                        x={x}
                        y={y + height - radius}
                        width={width * 0.8}
                        height={radius}
                        fill={poll2}
                      />
                    </g>
                  );
                }}
              /> */}
        </BarChart>
      </ResponsiveContainer>
    );
  };
  const ComparisonChartContainerr = ({ poll1 }) => {
    const data = {
      Jan: { activePlans: 50, inactivePlans: 30 },
      Feb: { activePlans: 60, inactivePlans: 25 },
      Mar: { activePlans: 70, inactivePlans: 35 },
      Apr: { activePlans: 55, inactivePlans: 40 },
      May: { activePlans: 45, inactivePlans: 20 },
      Jun: { activePlans: 65, inactivePlans: 30 },
      Jul: { activePlans: 75, inactivePlans: 35 },
      Aug: { activePlans: 80, inactivePlans: 30 },
      Sep: { activePlans: 70, inactivePlans: 25 },
      Oct: { activePlans: 60, inactivePlans: 35 },
      Nov: { activePlans: 55, inactivePlans: 45 },
      Dec: { activePlans: 45, inactivePlans: 20 },
    };
    return <ComparisonGraphh data={data} poll1={poll1} />;
  };
  function CircleProgressBarr({ value }) {
    return (
      <div
        className="superadminimage"
        style={{ width: "70px", height: "70px" }}
      >
        <CircularProgressbar
          value={value}
          text={value}
          strokeWidth={6}
          styles={buildStyles({
            strokeLinecap: "round",
            textSize: "40px",
            pathColor: `#fff`,
            textColor: "#fff",
            trailColor: "rgba(141, 183, 223, 1)",
          })}
        />
      </div>
    );
  }

  const ComparisonGraph = ({ data, poll1, poll2 }) => {
    return (
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={Object.keys(data).map((month) => ({
            month,
            "Active Plans": data[month].activePlans,
            "Inactive Plans": data[month].inactivePlans,
          }))}
          barGap={-5}
        >
          <XAxis
            dataKey="month"
            axisLine={{ stroke: "transparent" }}
            tickLine={{ stroke: "transparent" }}
            tick={{ fontFamily: "Roboto", fontSize: 14 }}
          />
          <Bar
            dataKey="Active Plans"
            fill={poll1}
            shape={({ x, y, width, height }) => {
              const radius = 12;
              return (
                <g>
                  <rect
                    x={x}
                    y={y}
                    width={width * 0.8}
                    height={height}
                    fill={poll1}
                    rx={radius}
                    ry={radius}
                  />
                  <rect
                    x={x}
                    y={y + height - radius}
                    width={width * 0.8}
                    height={radius}
                    fill={poll1}
                  />
                </g>
              );
            }}
          />
          <Bar
            dataKey="Inactive Plans"
            fill={poll2}
            shape={({ x, y, width, height }) => {
              const radius = 12;
              return (
                <g>
                  <rect
                    x={x}
                    y={y}
                    width={width * 0.8}
                    height={height}
                    fill={poll2}
                    rx={radius}
                    ry={radius}
                  />
                  <rect
                    x={x}
                    y={y + height - radius}
                    width={width * 0.8}
                    height={radius}
                    fill={poll2}
                  />
                </g>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };
  const ComparisonChartContainer = ({ poll1, poll2 }) => {
    const data = {
      Jan: { activePlans: 50, inactivePlans: 30 },
      Feb: { activePlans: 60, inactivePlans: 25 },
      Mar: { activePlans: 70, inactivePlans: 35 },
      Apr: { activePlans: 55, inactivePlans: 40 },
      May: { activePlans: 45, inactivePlans: 20 },
      Jun: { activePlans: 65, inactivePlans: 30 },
      Jul: { activePlans: 75, inactivePlans: 35 },
      Aug: { activePlans: 80, inactivePlans: 30 },
      Sep: { activePlans: 70, inactivePlans: 25 },
      Oct: { activePlans: 60, inactivePlans: 35 },
      Nov: { activePlans: 55, inactivePlans: 45 },
      Dec: { activePlans: 45, inactivePlans: 20 },
    };
    return <ComparisonGraph data={data} poll1={poll1} poll2={poll2} />;
  };
  function CircleProgressBar({ value }) {
    return (
      <div
        className="superadminimage"
        style={{ width: "70px", height: "70px" }}
      >
        <CircularProgressbar
          value={value}
          text={value}
          strokeWidth={6}
          styles={buildStyles({
            strokeLinecap: "round",
            textSize: "40px",
            pathColor: `#fff`,
            textColor: "#fff",
            trailColor: "rgba(141, 183, 223, 1)",
          })}
        />
      </div>
    );
  }

  const [selectedYearPlan, setSelectedYearPlan] = useState("This Year");
  const [selectedYearAdmin, setSelectedYearAdmin] = useState("This Year");
  const [dropdownOpenPlan, setdropdownOpenPlan] = useState(false);
  const [dropdownOpenAdmin, setdropdownOpenAdmin] = useState(false);
  const togglePlan = () => setdropdownOpenPlan((prevState) => !prevState);
  const toggleAdmin = () => {
    setdropdownOpenAdmin((prevState) => !prevState);
  };
  const handleChangePlan = (year) => {
    setSelectedYearPlan(year);
  };
  const handleChangeAdmin = (year) => {
    setSelectedYearAdmin(year);
  };
  return (
    <>
      <div className="w-100 d-flex gap-2 mb-5 plan-company-graph">
        {/* <Col xs={12} lg={6} sm={12} md={12} className="pt-3">
          <Card
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
              border: "0.3px solid rgba(82, 84, 89, 0.5)",
              borderRadius: "12px",
            }}
            // className="ml-4"
          >
            <Row className="w-100 px-3 my-3">
              <Col
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                }}
                className="pl-4 text-blue-color"
              >
                Plans
              </Col>
              <Col className="d-flex justify-content-end">
                <Dropdown isOpen={dropdownOpenPlan} toggle={togglePlan}>
                  <DropdownToggle
                    className="bg-blue-color"
                    caret
                    style={{
                      color: "#fff",
                    }}
                  >
                    {selectedYearPlan ? selectedYearPlan : "Select Year"}
                  </DropdownToggle>
                  <DropdownMenu className="text-blue-color">
                    <DropdownItem onClick={() => handleChangePlan("This Year")}>
                      This Year
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handleChangePlan("Previous Year")}
                    >
                      Previous Year
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            <Row className="w-100 py-3 px-5">
              <ComparisonChartContainerr
                poll1={"#063164"}
                poll2={"rgba(6, 49, 100, 1)"}
              />
            </Row>
            <Row className="w-100 px-3 my-1">
              <Col
                lg={12}
                className="d-flex justify-content-center align-items-center"
              >
                <span
                  style={{ fontFamily: "Poppins", fontSize: "15px" }}
                  className="px-3"
                >
                  <i
                    class="fa-solid fa-circle px-1 text-blue-color"
                    // style={{ color: "#063164" }}
                  ></i>
                  Purchase Plans
                </span>
              </Col>
            </Row>
          </Card>
        </Col> */}
        <Col xs={12} lg={6} sm={12} md={12} className="pt-3">
          <Card
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
              border: "0.3px solid rgba(82, 84, 89, 0.5)",
              borderRadius: "12px",
            }}
            // className="ml-4"
          >
            <Row className="w-100 px-3 my-3">
              {/* <Col
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                }}
                className="pl-4 text-orange-color"
              >
                Company
              </Col> */}
              <Col className="d-flex justify-content-end">
                <Dropdown isOpen={dropdownOpenAdmin} toggle={toggleAdmin}>
                  <DropdownToggle
                    className="bg-orange-color"
                    caret
                    style={{
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    {selectedYearAdmin ? selectedYearAdmin : "Select Year"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => handleChangeAdmin("This Year")}
                    >
                      This Year
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handleChangeAdmin("Previous Year")}
                    >
                      Previous Year
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            <Row className="w-100 py-3 px-5">
              <ComparisonChartContainer poll1={"#E88C44"} poll2={"#063164"} />
            </Row>
            <Row className="w-100 px-3 my-1">
              <Col
                lg={12}
                className="d-flex justify-content-center align-items-center
                active_deactive-graph"
              >
                <span
                  style={{ fontFamily: "Poppins", fontSize: "15px" }}
                  className="px-3"
                >
                  <i class="fa-solid fa-circle px-1 text-orange-color"></i>
                  Quotes
                </span>
                <span
                  style={{ fontFamily: "Poppins", fontSize: "15px" }}
                  className="px-3 inactive-para"
                >
                  <i class="fa-solid fa-circle px-1 text-blue-color"></i>
                  Contract
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} lg={6} sm={12} md={12} className="pt-3">
          <Card
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
              border: "0.3px solid rgba(82, 84, 89, 0.5)",
              borderRadius: "12px",
            }}
            // className="ml-4"
          >
            <Row className="w-100 px-3 my-3">
              {/* <Col
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                }}
                className="pl-4 text-orange-color"
              >
                Company
              </Col> */}
              <Col className="d-flex justify-content-end">
                <Dropdown isOpen={dropdownOpenAdmin} toggle={toggleAdmin}>
                  <DropdownToggle
                    className="bg-orange-color"
                    caret
                    style={{
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    {selectedYearAdmin ? selectedYearAdmin : "Select Year"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => handleChangeAdmin("This Year")}
                    >
                      This Year
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handleChangeAdmin("Previous Year")}
                    >
                      Previous Year
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            <Row className="w-100 py-3 px-5">
              <ComparisonChartContainer poll1={"#E88C44"} poll2={"#063164"} />
            </Row>
            <Row className="w-100 px-3 my-1">
              <Col
                lg={12}
                className="d-flex justify-content-center align-items-center
                active_deactive-graph"
              >
                <span
                  style={{ fontFamily: "Poppins", fontSize: "15px" }}
                  className="px-3"
                >
                  <i class="fa-solid fa-circle px-1 text-orange-color"></i>
                  Invoices
                </span>
                <span
                  style={{ fontFamily: "Poppins", fontSize: "15px" }}
                  className="px-3 inactive-para"
                >
                  <i class="fa-solid fa-circle px-1 text-blue-color"></i>
                  Appointment
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
      </div>
    </>
  );
}

export default WorkerGraphs;
