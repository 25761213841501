import { Dialog, DialogContent, DialogTitle, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Input, CardHeader, FormGroup, Label } from "reactstrap";
import {
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import pdficon from "../../../assets/image/icons/pdficon.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { handleAuth } from "../../../components/Login/Auth";
import { postFile } from "../../../components/Files/Functions";
import AxiosInstance from "../../AxiosInstance";
import sendToast from "../../../components/Toast/sendToast";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import showToast from "../../../components/Toast/Toster";
import { Typography } from "@mui/material";
import WhiteButton from "../../../components/Button/WhiteButton";
import BlueButton from "../../../components/Button/BlueButton";
import { LoaderComponent } from "../../../components/Icon/Index";

const InvoiceMail = ({
  modal,
  setModal,
  customerData,
  invoiceData,
  Total,
  handleSubmit,
  InvoiceNumber,
  Attachment,
  DueDate,
  Subject,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const cdn_url = process.env.REACT_APP_CDN_API;
  const [inputValue, setInputValue] = useState(
    customerData?.EmailAddress || ""
  );

  useEffect(() => {
    setInputValue(customerData?.EmailAddress);
  }, [customerData?.EmailAddress]);

  const todayDate =
    DueDate &&
    new Date(DueDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

  const [files, setFiles] = useState([]);
  const [sendToMail, setSendToMail] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
    } else if (e.type === "dragleave") {
    }
  };

  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setData(res?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const renderFilePreview = (file) => {
    if (file?.type === "application/pdf") {
      return (
        <Grid
          key={file?.name}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <img
            src={pdficon}
            alt="PDF Icon"
            style={{ width: "25px", height: "25px", marginRight: "10px" }}
          />
          <Typography>{file?.name}</Typography>
        </Grid>
      );
    } else if (file?.type?.startsWith("image/")) {
      return (
        <Grid
          key={file?.name}
          style={{
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={URL.createObjectURL(file)}
            alt={`file-preview-${file?.name}`}
            style={{
              width: "25px",
              height: "25px",
              objectFit: "cover",
              marginRight: "10px",
            }}
          />
          <Typography style={{ fontSize: "14px" }}>{file?.name}</Typography>
        </Grid>
      );
    } else {
      return (
        <Grid key={file?.name} style={{ marginBottom: "10px" }}>
          <Typography>{file?.name}</Typography>
        </Grid>
      );
    }
  };

  const [subject, setSubject] = useState(
    `Invoice from ${data?.full_name} - For Services Rendered`
  );

  useEffect(() => {
    setSubject(`Invoice from ${data?.full_name} - For Services Rendered`);
  }, [data]);

  const [content, setContent] = useState(
    `Dear ${customerData?.FirstName} ${customerData?.LastName},\n\n` +
      `Thank you for your recent business with us.\n\n` +
      `We are writing to inform you that the invoice with a total amount of $${Total} with all discount & tax is paid by ${todayDate}.\n\n` +
      `If you have any questions or require any clarification regarding this invoice, please feel free to contact us at ${data?.primaryEmailAddress}.\n\n` +
      `We appreciate your prompt attention to this matter.\n\n` +
      `Sincerely,\n${data?.full_name}`
  );

  useEffect(() => {
    const initialContent = `
    <Grid style="font-family: Arial, sans-serif; color: #333; max-width: 600px; margin: 0 auto; border-radius: 10px; overflow: hidden; border: 1px solid #dedede; box-shadow: none;">
      <!-- Header -->
      <Grid style="text-align: center; padding: 10px;    width: 50%;">
         <img src="https://app.cloudjobmanager.com/cdn/upload/20241002123818_site-logo1.png" alt="Company Logo" style="width: 50%; height: auto;">
      </Grid>

      <!-- Body -->
      <Grid style="padding: 20px;">
          <h2 style="font-size: 22px; color: #003366; text-align: center; margin-bottom: 20px;">Your Custom Invoice is Ready!</h2>
          <Typography style="font-size: 16px; color: #555;">Dear <strong style="color: #003366;">${
            customerData?.FirstName
          } ${customerData?.LastName}</strong></strong>,</Typography>
          <Typography>Thank you for your recent business with us.</Typography>
          <Typography>We are writing to inform you that the invoice of <strong>${
            invoiceData?.Subject || Subject
          }</strong> with a total amount of <strong> $${Total}</strong>  with all discount & tax is paid by ${new Date().toLocaleDateString()}</Typography>
          <Grid style="background-color: #f9f9f9; border: 1px solid #e0e0e0; border-radius: 8px; padding: 15px; text-align: center; margin: 20px 0;">
              <Typography style="font-size: 24px; color: #e88c44; margin: 0;">Total Amount: <strong>$${Total}</strong></Typography>
              <Typography style="font-size: 16px; color: #718096; margin: 0;">Quote Date: <strong>${new Date().toLocaleDateString()}</strong></Typography>
          </Grid>
          <Typography style="font-size: 16px; color: #555;">For any questions or to proceed with this invoice, feel free to reach out at <a href="mailto:${
            data?.primaryEmailAddress
          }" style="color: #003366; text-decoration: none;"><strong>${
      data?.primaryEmailAddress
    }</strong></a>. We're happy to assist you!</Typography>
          <Typography style="font-size: 16px; color: #555;">We look forward to working with you!</Typography>
          <Grid style="text-align: right; margin-top: 20px;">
            <Typography style="font-size: 16px; color: #555; margin: 0;">Best regards,<br />
              <strong style="color: #003366;">${data?.full_name}</strong><br />
              <Typography style="font-size: 14px; color: #718096;">${
                data?.primaryEmailAddress
              }</Typography>
            </Typography>
          </Grid>
      </Grid>

      <!-- Footer -->
      <Grid style="background-color: #f4f4f4; color: #777; text-align: center; padding: 15px 0; border-top: 1px solid #dedede;">
          <Typography style="margin: 0; font-size: 12px;">&copy; ${new Date().getFullYear()} cloudjobrental. All rights reserved.</Typography>
      </Grid>
    </Grid>
    `;

    setContent(initialContent);
  }, [data, customerData, Total, Subject]);

  const handleChange = (value) => {
    setContent(value);
  };
  const [loader, setLoader] = useState(false);
  const handleSendMail = async () => {
    try {
      setLoader(true);
      const fileUrls = [];
      if (selectedFiles?.length > 0) {
        for (const file of selectedFiles) {
          if (typeof file === "string") {
            fileUrls?.push(file);
          } else {
            const url = await postFile(file);
            fileUrls?.push(url);
          }
        }
      }
      const url = `/invoice/send_mail/${data?.companyId}`;
      const object = {
        to: inputValue,
        subject,
        content,
        sendToMe: sendToMail ? data?.primaryEmailAddress : false,
        selectedFiles: fileUrls,
      };

      const response = await AxiosInstance.post(url, object);
      if (response?.data?.statusCode === 200) {
        showToast.success(response?.data?.message);
        setModal(false);
        if (handleSubmit) {
          handleSubmit();
        }
      } else {
        sendToast(response?.data?.message);
      }
    } catch (error) {
      console.error(error?.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={() => setModal(!modal)}
        style={{ height: "100%" }}
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            width: "60%",
            height: "70%",
            maxWidth: "none",
            maxHeight: "none",
            marginTop: "10px",
          },
        }}
        className="email-quote email_sendModel"
      >
        <DialogTitle className="d-flex justify-content-between">
          <Grid className="Title" style={{ margin: "0px", padding: "0px" }}>
            <Typography
              className="text-blue-color email_quote_nam e heading-four"
              style={{ fontWeight: "600", fontSize: "34px" }}
            >
              Email invoice #{InvoiceNumber} to {customerData?.FirstName}{" "}
              {customerData?.LastName}
            </Typography>
          </Grid>
          <Typography
            onClick={() => setModal(false)}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </Typography>
        </DialogTitle>
        <DialogContent className="w-100 row mt-3">
          <Col className="col-12 col-lg-8 order-1 order-lg-1" xl={8} lg={8}>
            <Grid className="justify-content-between d-flex">
              <Grid
                className="input-email d-flex align-items-center border-blue-color"
                style={{
                  border: "solid 0.5px",
                  borderRadius: "10px",
                  padding: "5px",
                  width: "100%",
                  height: "66px",
                }}
              >
                <Grid
                  className="text-blue-color"
                  style={{
                    marginLeft: "10px",

                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  To
                </Grid>
                <Grid
                  className="email-input-field mailSendToBtn"
                  style={{ width: "50%" }}
                >
                  <Grid
                    className="d-flex align-items-center"
                    style={{
                      border: "1px solid rgba(6, 49, 100, 0.2)",
                      borderRadius: "10px",
                      marginLeft: "5%",
                      width: "100%",
                    }}
                  >
                    <Input
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "20px",
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: "400",
                        boxShadow: "none",
                      }}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <Typography style={{ marginRight: "3%" }}>
                      <CloseIcon
                        style={{
                          color: "rgba(6, 49, 100, 0.4)",
                          cursor: "pointer",
                          height: "14px",
                          width: "14px",
                        }}
                        onClick={() => setInputValue("")}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid
                className="card"
                style={{
                  marginTop: "20px",
                  height: "325px",
                  borderRadius: "10px",
                  border: "0.5px solid rgba(6, 49, 100, 1)",
                  overflowY: "scroll",
                  flexDirection: "column",
                }}
              >
                <CardHeader
                  className="text-blue-color"
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#fff",
                    padding: "6px",
                    borderRadius: "12px",
                    border: "none",
                  }}
                >
                  <Grid className="d-flex mt-3 p-0">
                    <Grid
                      className="text-blue-color"
                      style={{
                        marginLeft: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        marginTop: "0.5%",
                      }}
                    >
                      Subject
                    </Grid>
                    <Grid style={{ width: "100%", marginLeft: "5%" }}>
                      <Input
                        id={`Subject`}
                        name={`Subject`}
                        style={{
                          border: "1px solid rgba(6, 49, 100, 0.2)",
                          fontSize: "14px",
                          paddingLeft: "15px",
                          width: "100%",
                          height: "31px",
                          fontWeight: "400",
                        }}
                        className="text-blue-color"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardHeader>
                <hr
                  style={{ color: "rgba(6, 49, 100, 1)", fontWeight: "500" }}
                />
                <ReactQuill
                  value={content}
                  onChange={handleChange}
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "list",
                    "bullet",
                    "link",
                    "image",
                  ]}
                />
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <FormGroup
                check
                style={{ alignItems: "center", display: "flex" }}
              >
                <Input
                  type="checkbox"
                  id="send_copy"
                  name="send_copy"
                  style={{ width: "17px", height: "17px" }}
                  value={sendToMail}
                  className="text-blue-color"
                  onChange={(e) => setSendToMail(e.target.checked)}
                />
                <Label
                  style={{
                    color: "rgba(6, 49, 100, 1)",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginLeft: "10px",
                    marginTop: "8px",
                  }}
                  check
                >
                  Send me a copy
                </Label>
              </FormGroup>
            </Grid>
          </Col>
          <Col className="col-12 col-lg-4 order-2 order-lg-2" xl={4} lg={8}>
            <Typography
              style={{
                color: "rgba(6, 49, 100, 1)",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Attachments
            </Typography>
            <Grid
              className="file-upload fileUploadInmaimBox"
              style={{
                padding: "5px",
                fontSize: "14px",
                // height: "53px",

                paddingTop: "4%",
                alignItems: "center",
                marginTop: "20px",
                border: "2px dashed rgba(6, 49, 100, 0.8)",
              }}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="invoice-Attachment"
                name="Attachment"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <label
                className="d-flex justify-content-between emailDragFileCopy"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                Drag your files here or{" "}
                <Typography
                  style={{
                    color: "rgba(6, 49, 100, 1)",
                    fontSize: "14px",
                    marginTop: "-5px",
                    border: "0.5px solid rgba(6, 49, 100, 0.8)",
                    borderRadius: "8px",
                    padding: "4px 8px",
                    cursor: "pointer",
                    className: "drafFileBoxMail",
                  }}
                  onClick={() =>
                    document.getElementById("invoice-Attachment").click()
                  }
                >
                  Select a File
                </Typography>
              </label>
            </Grid>
            <Grid
              style={{
                maxHeight: "130px",
                overflowY: "auto",
                marginTop: "20px",
                scrollbarWidth: "thin",
              }}
            >
              <Grid
                className="align-items-center mt-3"
                style={{ overflowY: "auto", overflowX: "hidden" }}
              >
                {files.map((file, index) => (
                  <FormGroup
                    check
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Input
                      type="checkbox"
                      id={`send_copy_${index}`}
                      name="send_copy"
                      className="text-blue-color"
                      style={{
                        marginTop: "6px",
                        width: "17px",
                        height: "17px",
                      }}
                      onChange={(e) => {
                        const value = e.target.checked;
                        if (value) {
                          setSelectedFiles([...selectedFiles, file]);
                        } else {
                          setSelectedFiles(() =>
                            selectedFiles?.filter((files) => files !== file)
                          );
                        }
                      }}
                    />
                    <Label
                      for={`send_copy_${index}`}
                      className="text-blue-color"
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid style={{ marginLeft: "10px", height: "20px" }}>
                        {renderFilePreview(file, index)}
                      </Grid>
                    </Label>
                  </FormGroup>
                ))}
              </Grid>
            </Grid>
            <hr />
            <Grid className="align-items-center mt-0">
              <UncontrolledAccordion>
                <AccordionItem style={{ border: "none" }}>
                  <AccordionHeader
                    targetId="1"
                    style={{ padding: "0px" }}
                    className="accordingHeaderPadd"
                  >
                    <Grid
                      style={{ fontSize: "10px", padding: "0px" }}
                      className="d-flex justify-content-between "
                    >
                      <Typography
                        style={{
                          color: "rgb(42, 79, 97)",
                          fontSize: "15px",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Invoice Attachments
                      </Typography>
                      <Typography
                        style={{
                          width: "24px",
                          height: "24px",
                          border: "1px solid rgba(6, 49, 100, 1)",
                          borderRadius: "50px",
                          background: "rgba(6, 49, 100, 0.2)",
                          fontSize: "18px",
                          marginLeft: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          display: "flex",
                          padding: "1px 1px 0px 0px",
                        }}
                      >
                        <span> {Attachment?.length || 0} </span>
                      </Typography>
                    </Grid>
                  </AccordionHeader>
                  <AccordionBody
                    accordionId="1"
                    style={{ paddingLeft: "0px", marginTop: "-10px" }}
                  >
                    <Grid style={{ marginBottom: "0" }}>
                      {Attachment ? (
                        <Grid
                          style={{
                            maxHeight: "100px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {Attachment.map((file, index) => (
                            <FormGroup
                              check
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Input
                                type="checkbox"
                                id={`send_copy_${index}`}
                                name="send_copy"
                                className="text-blue-color"
                                style={{
                                  marginTop: "6px",
                                  width: "17px",
                                  height: "17px",
                                }}
                                onChange={(e) => {
                                  const value = e.target.checked;
                                  if (value) {
                                    setSelectedFiles([...selectedFiles, file]);
                                  } else {
                                    setSelectedFiles(() =>
                                      selectedFiles?.filter(
                                        (files) => files !== file
                                      )
                                    );
                                  }
                                }}
                              />
                              <Label
                                className="text-blue-color"
                                for={`send_copy_${index}`}
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "13px",
                                }}
                              >
                                <Grid
                                  style={{
                                    paddingTop: "10px",
                                    fontSize: "12px",
                                    color: "rgb(42, 79, 97)",
                                  }}
                                >
                                  {typeof file === "string" ? file : file?.name}
                                </Grid>
                              </Label>
                            </FormGroup>
                          ))}
                        </Grid>
                      ) : (
                        <Typography
                          style={{
                            fontSize: "12px",
                            lineHeight: "24px",
                            fontStyle: "italic",
                            color: "rgba(6, 49, 100, 0.7)",
                          }}
                        >
                          No attachments were found. <br /> Any attachments on
                          invoice notes will appear here.
                        </Typography>
                      )}
                    </Grid>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </Grid>
            <hr />
          </Col>
          <Grid className="order-3 order-lf-3">
            <hr />
            {loader ? (
              <Grid className="d-flex justify-content-end">
                <LoaderComponent
                  height="20"
                  width="20"
                  padding="20"
                  loader={loader}
                />
              </Grid>
            ) : (
              <Grid className="d-flex  justify-content-between QUoteSendCancelBtn ">
                <Grid
                  className="d-flex justify-content-between button-responsive"
                  style={{ marginTop: "0px" }}
                >
                  <Grid>
                    <WhiteButton
                      onClick={() => {
                        setModal(false);
                        setFiles([]);
                      }}
                      label="Cancel"
                    />
                  </Grid>
                </Grid>
                <Grid
                  className="d-flex  button-responsive"
                  style={{ marginTop: "0px" }}
                >
                  <Grid>
                    <BlueButton onClick={handleSendMail} label="Send email" />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InvoiceMail;
