import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { Circles, RotatingLines } from "react-loader-spinner";
import * as Yup from "yup";
import axios from "axios";
import AxiosInstance from "../../../AxiosInstance.js";
import IconButton from "@mui/material/IconButton";
import Previous from "../../../../assets/image/icons/Previous.png";
import toast, { Toaster } from "react-hot-toast";
import Address from "../../../../components/Address/index";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  Card,
  Dropdown,
  DropdownItem,
  Navbar,
  DropdownMenu,
  DropdownToggle,
  Input,
  FormGroup,
  Label,
  Table,
} from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SettingSidebar from "../../../../components/Setting/SettingSidebar.js";
import { Divider } from "@mui/material";
import { Country } from "country-state-city";
import MenuIcon from "@mui/icons-material/Menu";
import Permissions from "../../../../components/Permissions/index.js";
import profileIcon from "../../../../assets/image/icons/file.logo.svg";
import InputText from "../../../../components/InputFields/InputText.js";
import moment from "moment";

const AddUser = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const cdnUrl = process.env.REACT_APP_CDN_API;

  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();

  const [selectedRole, setSelectedRole] = useState(undefined);
  const [loader, setLoader] = useState(true);
  const getData = async () => {
    try {
      if (location.state.id) {
        const response = await AxiosInstance.get(
          `${baseUrl}/worker/get/${location.state.id}`
        );
        formik.setValues(response.data.data);
        const data = response.data.data.permissions;
        setSelectedRole(data);
        setTimes(JSON.parse(response.data.data.ScheduleTime));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const countriesData = Country.getAllCountries();
    const uniqueCountries = countriesData.map((country) => country?.name);
    setCountries(uniqueCountries);
  }, []);

  const [isUploading, setIsUploading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(profileIcon);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const toggle = () => setIsOpenDropDown(!isOpenDropDown);

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      companyId: "",
      FullName: "",
      City: "",
      State: "",
      Zip: "",
      Country: "",
      PhoneNumber: "",
      EmailAddress: "",
      Address: "",
      LaborCost: "",
      Password: "",
      Title: "",
      Description: "",
      Schedule: "",
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("Required"),
      EmailAddress: Yup.string()
        .email("Invalid email")
        .required("Email is required")
        .matches(/^[^@]+@[^@]+\.[^@]+$/, "Email must contain '@' and '.'"),
      // PhoneNumber: Yup.string().required("Required"),
      PhoneNumber: Yup.string()
        // .required("Phone number is required")
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        ),
      // Address: Yup.string().required("Required"),
      // Country: Yup.string().required("Required"),
      // State: Yup.string().required("Required"),
      // City: Yup.string().required("Required"),
      // Zip: Yup.string().required("Required"),
      // LaborCost: Yup.string().required("Required"),
    }),
    validateOnChange: false, // Only validate on form submit or blur
    validateOnBlur: false, // Disable validation on blur if it's too slow
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const object = {
          ...values,
          companyId: localStorage.getItem("CompanyId"),
          WorkerPermission: selectedRole,
          // ProfileImage: profilePhoto,
          ScheduleTime: JSON.stringify(times),
        };

        let response;
        if (location.state.id) {
          response = await AxiosInstance.put(
            `${baseUrl}/worker/${location.state.id}`,
            object
          );
        } else {
          response = await AxiosInstance.post(`${baseUrl}/worker`, object);
        }
        if (response.data.statusCode === 200) {
          setTimeout(() => {
          toast.success(response.data.message);
        }, 500);
          navigate(-1);
        } else if (response.data.statusCode === 203) {
          setTimeout(() => {
          toast.error(response.data.message);
        }, 500);
        } else if (response.data.statusCode === 202) {
          setTimeout(() => {
          toast.error(response.data.message);
        }, 500);
        } else {
          toast.error("", response.data.message, "error");
        }
      } catch (error) {
        setTimeout(() => {

        toast.error("", error.message, "error");
      }, 500);
        console.error("There was an error submitting the form!", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  //  Phone number formatting function
  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10); // Limit to 10 digits
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/); // Matches (xxx) xxx-xxxx

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  // Handle phone number change and format it
  const handlePhoneChange = (e) => {
    if (formik.values.PhoneNumber?.length > e.target.value?.length) {
      formik.setFieldValue("PhoneNumber", e.target.value);
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      formik.setFieldValue("PhoneNumber", formattedValue);
    }
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  useEffect(() => {
    setCountries(Country.getAllCountries());
    if (formik.values.Country) {
      setSelectedCountry(() => {
        const country = Country.getAllCountries().find(
          (item) => item.name === formik.values.Country
        );
        return country;
      });
    }
  }, [formik]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
  };
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };


  const [SundayStartTime, setSundayStartTime] = useState(
    dayjs("2024-01-01 09:00")
  );
  const [SundayEndTime, setSundayEndTime] = useState(dayjs("2024-01-01 17:00"));

  const handleSundayStartTimeChange = (newValue) => {
    setSundayStartTime(newValue);
  };

  const handleSundayEndTimeChange = (newValue) => {
    setSundayEndTime(newValue);
  };
  // monfay
  const [isMondayChecked, setIsMondayChecked] = useState(true);
  const [MondayStartTime, setMondayStartTime] = useState(
    dayjs("2024-01-01 09:00")
  );
  const [MondayEndTime, setMondayEndTime] = useState(dayjs("2024-01-01 17:00"));

  const handleMondayStartTimeChange = (newValue) => {
    setMondayStartTime(newValue);
  };

  const handleMondayEndTimeChange = (newValue) => {
    setMondayEndTime(newValue);
  };
  // tuesday
  const [isTuesdayChecked, setIsTuesdayChecked] = useState(true);
  const [TuesdayStartTime, setTuesdayStartTime] = useState(
    dayjs("2024-01-01 09:00")
  );
  const [TuesdayEndTime, setTuesdayEndTime] = useState(
    dayjs("2024-01-01 17:00")
  );

  const handleTuesdayCheckboxChange = (e) => {
    setIsTuesdayChecked(e.target.checked);
  };

  const handleTuesdayStartTimeChange = (newValue) => {
    setTuesdayStartTime(newValue);
  };

  const handleTuesdayEndTimeChange = (newValue) => {
    setTuesdayEndTime(newValue);
  };
  // Wednesday
  const [isWednesdayChecked, setIsWednesdayChecked] = useState(true);
  const [WednesdayStartTime, setWednesdayStartTime] = useState(
    dayjs("2024-01-01 09:00")
  );
  const [WednesdayEndTime, setWednesdayEndTime] = useState(
    dayjs("2024-01-01 17:00")
  );

  const handleWednesdayCheckboxChange = (e) => {
    setIsWednesdayChecked(e.target.checked);
  };

  const handleWednesdayStartTimeChange = (newValue) => {
    setWednesdayStartTime(newValue);
  };

  const handleWednesdayEndTimeChange = (newValue) => {
    setWednesdayEndTime(newValue);
  };
  // thursday
  const [isThursdayChecked, setIsThursdayChecked] = useState(true);
  const [ThursdayStartTime, setThursdayStartTime] = useState(
    dayjs("2024-01-01 09:00")
  );
  const [ThursdayEndTime, setThursdayEndTime] = useState(
    dayjs("2024-01-01 17:00")
  );

  const handleThursdayCheckboxChange = (e) => {
    setIsThursdayChecked(e.target.checked);
  };

  const handleThursdayStartTimeChange = (newValue) => {
    setThursdayStartTime(newValue);
  };

  const handleThursdayEndTimeChange = (newValue) => {
    setThursdayEndTime(newValue);
  };
  // friday
  const [isFridayChecked, setIsFridayChecked] = useState(true);
  const [FridayStartTime, setFridayStartTime] = useState(
    dayjs("2024-01-01 09:00")
  );
  const [FridayEndTime, setFridayEndTime] = useState(dayjs("2024-01-01 17:00"));

  const handleFridayCheckboxChange = (e) => {
    setIsFridayChecked(e.target.checked);
  };

  const handleFridayStartTimeChange = (newValue) => {
    setFridayStartTime(newValue);
  };

  const handleFridayEndTimeChange = (newValue) => {
    setFridayEndTime(newValue);
  };
  // saturday
  const [isSaturdayChecked, setIsSaturdayChecked] = useState(false);
  const [SaturdayStartTime, setSaturdayStartTime] = useState(
    dayjs("2024-01-01 09:00")
  );
  const [SaturdayEndTime, setSaturdayEndTime] = useState(
    dayjs("2024-01-01 17:00")
  );

  const handleSaturdayCheckboxChange = (e) => {
    setIsSaturdayChecked(e.target.checked);
  };

  const handleSaturdayStartTimeChange = (newValue) => {
    setSaturdayStartTime(newValue);
  };

  const handleSaturdayEndTimeChange = (newValue) => {
    setSaturdayEndTime(newValue);
  };
  const initialTimes = {
    Sunday: {
      start: null,
      end: null,
    },
    Monday: {
      start: moment("09:00 AM", "hh:mm A"),
      end: moment("05:00 PM", "hh:mm A"),
    },
    Tuesday: {
      start: moment("09:00 AM", "hh:mm A"),
      end: moment("05:00 PM", "hh:mm A"),
    },
    Wednesday: {
      start: moment("09:00 AM", "hh:mm A"),
      end: moment("05:00 PM", "hh:mm A"),
    },
    Thursday: {
      start: moment("09:00 AM", "hh:mm A"),
      end: moment("05:00 PM", "hh:mm A"),
    },
    Friday: {
      start: moment("09:00 AM", "hh:mm A"),
      end: moment("05:00 PM", "hh:mm A"),
    },
    Saturday: {
      start: null,
      end: null,
    },
  };

  const [times, setTimes] = useState(initialTimes);
  const [isChecked, setIsChecked] = useState({
    Sunday: false,
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: false,
  });

  // const handleCheckboxChange = (day) => {
  //   setIsChecked((prev) => ({
  //     ...prev,
  //     [day]: !prev[day],
  //   }));
  // };

  // const handleTimeChange = (day, type) => (newValue) => {
  //   setTimes((prev) => ({
  //     ...prev,
  //     [day]: {
  //       ...prev[day],
  //       [type]: newValue,
  //     },
  //   }));
  // };

  const handleCheckboxChange = (day) => {
    setIsChecked((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const handleTimeChange = (day, type) => (newValue) => {
    const updatedTimes = { ...times };
    updatedTimes[day] = {
      ...updatedTimes[day],
      [type]: newValue ? newValue.format("MM-DD-YYYY HH:mm:ss") : null,
    };
    setTimes(updatedTimes);
    setTimes(updatedTimes);
  };

  const handleSave = async () => {
    const dataToPost = Object.keys(times).map((day) => ({
      day,
      defaultStart: initialTimes[day].start
        ? initialTimes[day].start.format("hh:mm A")
        : null,
      defaultEnd: initialTimes[day].end
        ? initialTimes[day].end.format("hh:mm A")
        : null,
      selectedStart: times[day].start
        ? moment(times[day].start).format("hh:mm A")
        : null,
      selectedEnd: times[day].end
        ? moment(times[day].end).format("hh:mm A")
        : null,
    }));

    try {
      const response = await axios.post("/api/save-times", dataToPost);
    } catch (error) {
      console.error("Error saving times:", error);
    }
  };

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "90vh" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            visible={loader}
          />
        </div>
      ) : (
        <div className="manage-team" style={{ display: "flex" }}>
          <div className="col-2 h-100 hiren">
            <SettingSidebar />
          </div>
          {/* <Divider
            className="divider-manageteam"
            orientation="vertical"
            flexItem
            style={{
              marginLeft: "-30px",
              marginRight: "2%",
              marginTop: "-30px",
              borderRight: "2px solid #063164",
              height: "auto",
              width: "10px",
            }}
          /> */}
          <Navbar
            className="navbar-setting"
            style={{
              zIndex: "9",
              borderRadius: "5px",
              display: "block",
            }}
          >
            <Dropdown
              className="dropdown menus"
              isOpen={isOpenDropDown}
              toggle={toggle}
              style={{ width: "100%" }}
            >
              <DropdownToggle
                style={{
                  background: "#E88C44",
                  border: "none",
                  color: "#FFFF",
                }}
              >
                <IconButton>
                  <MenuIcon style={{ color: "#FFFF" }} />
                </IconButton>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <h6 style={{ fontWeight: 600, marginBottom: "10px" }}>
                    BUSINESS <br /> MANAGEMENT
                  </h6>
                </DropdownItem>
                <DropdownItem>
                  <div className="d-flex flex-column">
                    <div
                      className="sidebar-link-setting"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/${companyName}/product&service`, {
                          state: { navigats: ["/index", "/product&service"] },
                        });
                      }}
                    >
                      Products & Services
                    </div>
                    <div
                      className="sidebar-link-setting"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/${companyName}/profile`, {
                          state: { navigats: ["/index", "/profile"] },
                        });
                      }}
                    >
                      Manage Team
                    </div>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Navbar>

          <div
            className="col-10"
            style={{
              borderLeft: "0.5px solid rgba(6, 49, 100, 30%)",
              marginTop: "-30px",
              paddingLeft: "20px",
            }}
          >
            <Button
              className="bg-button-blue-color"
              style={{
                marginRight: "10px",
                width: "30px",
                height: "35px",
                marginBottom: "10px",
                padding: "0px 0px",
                borderRadius: "4px",
                marginTop: "5%",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={Previous} style={{ width: "20px", height: "20px" }} />
            </Button>
            <Card
              style={{
                padding: "40px",
                marginTop: "10px",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div className="row">
                <div className="">
                  <div className=" " style={{}}>
                    <p
                      className="text-blue-color person-info"
                      style={{
                        fontSize: "30px",
                        fontWeight: "600",
                      }}
                    >
                      Personal Information
                    </p>
                  </div>

                  <div className="d-flex">
                    <div
                      className="text-center"
                      style={{ marginTop: "0px", marginBottom: "30px" }}
                    >
                      <label htmlFor="upload-button">
                        <input
                          type="file"
                          id="upload-button"
                          style={{ display: "none" }}
                        />
                        <div
                          className="d-flex justify-content-center align-items-center bg-button-blue-color"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            boxShadow: "0 4px 5px rgba(0, 0, 0, 0.4)",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            document.getElementById("upload-button").click()
                          }
                        >
                          {isUploading ? (
                            <div
                              style={{
                                width: "58%",
                                height: "58%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="40"
                                visible={true}
                              />
                            </div>
                          ) : (
                            <img
                              src={profilePhoto}
                              alt="Profile"
                              style={{
                                width: "58%",
                                height: "58%",
                              }}
                            />
                          )}
                        </div>
                      </label>
                    </div>

                    <div>
                      <label htmlFor="upload-button">
                        <Button
                          component="span"
                          className="btn-upload text-blue-color"
                          style={{
                            textTransform: "none",
                            marginTop: "5px",
                            marginBottom: "15px",
                            fontSize: "12px",
                            cursor: "pointer",
                            padding: "8px 16px",
                            borderRadius: "4px",
                          }}
                        >
                          Upload image here...
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="responsive-container gap-3">
                  <div className="w-50 sub-div">
                    <InputText
                      value={formik.values?.FullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.FullName &&
                        Boolean(formik.errors.FullName)
                      }
                      helperText={
                        formik.touched.FullName && formik.errors.FullName
                      }
                      name="FullName"
                      label="FullName"
                      type="text"
                      className="text-blue-color w-100 m-0 mb-4"
                      fieldHeight="56px"
                    />
                    <InputText
                      value={formik.values?.EmailAddress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.EmailAddress &&
                        Boolean(formik.errors.EmailAddress)
                      }
                      helperText={
                        formik.touched.EmailAddress &&
                        formik.errors.EmailAddress
                      }
                      name="EmailAddress"
                      label="EmailAddress"
                      type="text"
                      className="text-blue-color w-100 m-0 mb-4"
                      fieldHeight="56px"
                    />
                    <InputText
                      value={formik.values?.PhoneNumber}
                      onChange={handlePhoneChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.PhoneNumber &&
                        Boolean(formik.errors.PhoneNumber)
                      }
                      helperText={
                        formik.touched.PhoneNumber && formik.errors.PhoneNumber
                      }
                      name="PhoneNumber"
                      label="PhoneNumber"
                      type="text"
                      className="text-blue-color w-100 m-0 mb-3 phoneBtn"
                      fieldHeight="56px"
                    />
                  </div>
                  <div className="w-50 sub-div labor-top">
                    <Address
                      setSelectedCountry={setSelectedCountry}
                      selectedCountry={selectedCountry}
                      countries={countries}
                      handleChange={handleChange}
                      formik={formik}
                    />
                  </div>
                </div>

                <div className="d-flex gap-3 responsive-container ">
                  <div className="w-50 sub-div">
                    <p
                      className="text-blue-color labor labor-top"
                      style={{ fontWeight: 700, fontSize: "18px" }}
                    >
                      Labor Cost
                    </p>
                    <InputText
                      value={formik.values?.LaborCost}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.LaborCost &&
                        Boolean(formik.errors.LaborCost)
                      }
                      helperText={
                        formik.touched.LaborCost && formik.errors.LaborCost
                      }
                      name="LaborCost"
                      label="LaborCost"
                      type="text"
                      placeholder="$0.00"
                      className="text-blue-color w-100 m-0 mb-3 "
                      fieldHeight="56px"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          className="text-blue-color"
                        >
                          <span style={{ fontSize: "12px" }}>per hour</span>
                        </InputAdornment>
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="sub-div">
                  <p
                    className="text-blue-color labor labor-top"
                    style={{ fontWeight: 700, fontSize: "18px" }}
                  >
                    Schedule
                  </p>

                  <p
                    className="text-blue-color labor labor-top"
                    style={{ fontWeight: 600, fontSize: "16px" }}
                  >
                    Working hours
                  </p>
                  <p className="text-blue-color labor labor-top">
                    Availability is only applied to online booking, currently.
                    Set team member's availability so that they are only
                    bookable when they are available.
                  </p>
                </div>
                <div className="mt-4 d-flex justify-content-between align-items-start">
                  <Table className="w-50">
                    <tbody>
                      {Object.keys(times).map((day) => (
                        <tr key={day}>
                          <td
                            style={{ fontWeight: 700 }}
                            className="text-blue-color"
                          >
                            {day}
                          </td>
                          <td className="text-blue-color">
                            {times[day].start && times[day].end
                              ? `${moment(times[day].start).format(
                                  "hh:mm A"
                                )} – ${moment(times[day].end).format(
                                  "hh:mm A"
                                )}`
                              : "Unavailable"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Button>
                    <span
                      style={{ borderBottom: "2px solid" }}
                      className="border-blue-color"
                      onClick={handleOpenDialog}
                    >
                      Edit
                    </span>
                  </Button>
                </div>
              </div>
            </Card>

            {/* Permission */}
            <Permissions data={selectedRole} setData={setSelectedRole} />

            {/* Communications */}
            <Card
              style={{
                padding: "40px",
                marginTop: "10px",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <h3 style={{ fontWeight: 700 }} className="text-blue-color">
                Communications
              </h3>
              <p
                className="text-blue-color"
                style={{
                  fontSize: "18px",
                  marginBottom: 0,
                  fontWeight: 600,
                }}
              >
                Email Subscriptions
              </p>
              <div className="d-flex  gap-2">
                <Input
                  type="checkbox"
                  className="border-blue-color"
                  style={{
                    height: "15px",
                    width: "15px",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  defaultChecked={true}
                />
                <Label check className="ml-2">
                  <span
                    className="text-blue-color"
                    style={{ fontSize: "14px" }}
                  >
                    Surveys
                  </span>{" "}
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(6, 49, 100, 50%)",
                    }}
                  >
                    Occasionally participate in surveys to provide feedback on
                    our performance
                  </p>
                </Label>
              </div>
              <p
                className="text-blue-color mb-2"
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Invitation Language
              </p>
              <p style={{ fontSize: "10px", color: "rgba(6, 49, 100, 50%)" }}>
                The selected language for the invitation is fixed and cannot be
                altered once it has been sent.
              </p>
              <FormGroup check>
                <Input
                  type="radio"
                  className="border-blue-color"
                  onChange={() => {}}
                  style={{
                    cursor: "pointer",
                  }}
                  name="invitationLanguage"
                  value="English"
                  checked
                />
                <Label check className="text-blue-color">
                  <span style={{ fontSize: "14px" }}>English</span>
                </Label>
              </FormGroup>

              <div className="d-flex gap-2">
                <Input
                  type="radio"
                  className="border-blue-color"
                  style={{
                    height: "15px",
                    width: "15px",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  onChange={() => {}}
                  name="invitationLanguage"
                  value="Spanish"
                />
                <Label check className="ml-2">
                  <span
                    className="text-blue-color"
                    style={{ fontSize: "14px" }}
                  >
                    Spanish
                  </span>{" "}
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(6, 49, 100, 50%)",
                    }}
                  >
                    The mobile app is accessible in Spanish exclusively for
                    non-administrative users who have their phone language
                    configured to Spanish.
                  </p>
                </Label>
              </div>
            </Card>

            <Box>
              {isSubmitting ? (
                <Box display="flex" justifyContent="right" color="#063164">
                  <Circles
                    height="20"
                    width="20"
                    color="#063164"
                    ariaLabel="circles-loading"
                    visible={isSubmitting}
                  />
                </Box>
              ) : (
                <div className="mb-3 my-5 d-flex align-items-center gap-2 justify-content-end">
                  <Button
                    className="border-blue-color text-blue-color outline-button-blue-color"
                    style={{
                      fontSize: "14px",
                      border: "1px solid",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <div className="bg-button-blue-color">
                    <Button
                      className="worker-button text-white-color"
                      onClick={formik.handleSubmit}
                      style={{
                        fontSize: "16px",
                        textTransform: "none",
                        fontWeight: 500,
                        borderRadius: "20px",
                        cursor: "pointer",
                      }}
                    >
                      Save Worker
                    </Button>
                  </div>
                  {/* )} */}
                </div>
              )}
            </Box>
          </div>
        </div>
      )}

      <Dialog open={open} onClose={handleCloseDialog} className="client">
        <DialogTitle>
          <div className="w-100 d-flex justify-content-start align-items-center">
            <h4
              className="text-blue-color text-property"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "42px",
                margin: "0 10px",
              }}
            >
              Edit Schedule
            </h4>
          </div>
        </DialogTitle>
        <Divider
          style={{ height: "1px", backgroundColor: "rgba(42, 79, 97, 0.8)" }}
        />
        <DialogContent>
          <form>
            {Object.keys(times).map((day) => (
              <div className="d-flex align-items-center my-3" key={day}>
                <div className="col-4 d-flex">
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(day)}
                      checked={isChecked[day]}
                    />
                    <Label check>{day}</Label>
                  </FormGroup>
                </div>
                <div className="col-8 d-flex gap-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Start Time"
                      value={isChecked[day] ? dayjs(times[day].start) : null}
                      onChange={handleTimeChange(day, "start")}
                      disabled={!isChecked[day]}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <TimePicker
                      label="End Time"
                      value={isChecked[day] ? dayjs(times[day].end) : null}
                      onChange={handleTimeChange(day, "end")}
                      disabled={!isChecked[day]}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            ))}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(6, 49, 100, 0.8)",
              color: "rgba(6, 49, 100, 1)",
            }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            className="bg-button-blue-color createButton"
            style={{ color: "#fff" }}
            onClick={() => {
              handleSave();
              handleCloseDialog();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Toaster />
    </>
  );
};

export default AddUser;
