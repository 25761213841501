// import React, { useState, useEffect } from "react";
// import {
//   Autocomplete,
//   Button,
//   Checkbox,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   FormControlLabel,
//   FormGroup,
//   TextField,
// } from "@mui/material";
// import {
//   JobberTable,
//   JobberSearch,
//   JobberPagination,
//   NavigatorNav,
// } from "../../../../components/MuiTable/index.js";
// import { Formik, Form } from "formik";
// import * as Yup from "yup";
// import IconButton from "@mui/material/IconButton";
// import Edit from "../../../../assets/image/icons/edit.svg";
// import Delete from "../../../../assets/image/icons/delete.svg";
// import swal from "sweetalert";
// import "./style.css";
// import moment from "moment";
// import {
//   Card,
//   CardBody,
//   CardFooter,
//   CardHeader,
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
//   Input,
//   Label,
//   ListInlineItem,
//   Navbar,
// } from "reactstrap";
// import { useNavigate } from "react-router-dom";
// import { Circles } from "react-loader-spinner";
// import SettingSidebar from "../../../../components/Setting/SettingSidebar.js";
// import { Link, useParams } from "react-router-dom";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
// import Paper from "@mui/material/Paper";
// import MenuList from "@mui/material/MenuList";
// import List from "@mui/material/MenuItem";
// import SwipeableDrawer from "./Drawer.js";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Gridider } from "@mui/material";
// import AxiosInstance from "../../../AxiosInstance.jsx";
// import CombinedComponent from "./Drawer.js";
// import SettingDropdown from "./SettingComponent.js";
// import InputText from "../../../../components/InputFields/InputText.js";
// import { SingleFileUpload } from "../../../../components/Files/index.js";
// import { DisplayImage } from "../../../../components/Files/DisplayFiles.js";
// import InputDropdown from "../../../../components/InputFields/InputDropdown.js";
// import { postFile } from "../../../../components/Files/Functions.js";
// import AddItems from "./AddItems.js";
// import sendSwal from "../../../../components/Swal/sendSwal.js";
// import {
//   DeleteIcone,
//   EditIcon,
//   LoaderComponent,
// } from "../../../../components/Icon/Index.js";
// import { Grid } from "@mui/material";
// import { Row, Col } from "react-bootstrap"; // React Bootstrap components
// import { Typography } from "@mui/material";

// import BlueButton from "../../../../components/Button/BlueButton.jsx";
// import showToast from "../../../../components/Toast/Toster.js";

// const ProductService = () => {
//   const [modelOpen, setModelOpen] = useState(false);
//   const [search, setSearch] = useState("");
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [selectedAdminId, setSelectedAdminId] = useState("");
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [productAndService, setProductAndService] = useState([]);
//   const [loader, setLoader] = useState(true);
//   const [countData, setCountData] = useState(0);
//   const CompanyId = localStorage.getItem("CompanyId");
//   const [showSquaresSection, setShowSquaresSection] = useState(false);

//   const getData = async () => {
//     try {
//       const res = await AxiosInstance.get(`/product/get/${CompanyId}`, {
//         params: {
//           pageSize: rowsPerPage,
//           pageNumber: page,
//           search: search || "",
//         },
//       });
//       setProductAndService(res.data.data);
//       setCountData(res.data.count);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoader(false);
//     }
//   };

//   useEffect(() => {
//     getData();
//   }, [rowsPerPage, page, search]);

//   const allowedFileTypes = [
//     "image/jpeg",
//     "image/png",
//     "application/pdf",
//     "application/msword",
//     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//     "application/vnd.ms-excel",
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   ];

//   const cdnUrl = process.env.REACT_APP_CDN_API;
//   const cdnShowUrl = "https://app.cloudjobmanager.com/cdn/upload";

//   const uploadImage = async (file) => {
//     setImageLoader(true);
//     try {
//       const formData = new FormData();
//       formData.append("files", file);

//       const response = await AxiosInstance.post(`${cdnUrl}/upload`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       if (response.data.files.length > 0) {
//         const imagePath = response.data.files[0].filename;
//         setFile(imagePath);
//         setError("");
//       } else {
//         throw new Error("No file returned from CDN.");
//       }
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       setError("There was an issue uploading the file. Please try again.");
//       setFile(null);
//     } finally {
//       setImageLoader(false);
//     }
//   };
//   const uploadFile = async (file) => {
//     const formData = new FormData();
//     formData.append("file", file);
//     const response = await AxiosInstance.post(`${cdnUrl}/upload`, formData, {
//       headers: { "Content-Type": "multipart/form-data" },
//     });
//     return response.data.filePath;
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     const droppedFile = event.dataTransfer.files[0];
//     if (droppedFile) {
//       if (allowedFileTypes.includes(droppedFile.type)) {
//         uploadImage(droppedFile);
//       } else {
//         setError(
//           "Unsupported file type. Please upload a PDF, JPEG, or PNG file."
//         );
//         setFile(null);
//         setFile("");
//       }
//     }
//   };
//   const handleRemoveFile = () => {
//     setFile(null);
//     setFile("");
//     setError("");
//   };
//   const [previewModalOpen, setPreviewModalOpen] = useState(false);
//   const [previewFile, setPreviewFile] = useState(null);
//   const [file, setFile] = useState();
//   const [error, setError] = useState("");
//   const [imageLoader, setImageLoader] = useState(false);

//   const handleFilePreview = (filePath) => {
//     if (!filePath) {
//       console.error("No file to preview");
//       return;
//     }
//     const fullPath = `${cdnShowUrl}/${filePath}`;
//     setPreviewFile(fullPath);
//     setPreviewModalOpen(true);
//   };

//   const closePreviewModal = () => {
//     setPreviewFile(null);
//     setPreviewModalOpen(false);
//   };

//   // const handleFileChange = (event) => {
//   //   const selectedFile = event.target.files[0];
//   //   if (selectedFile) {
//   //     if (allowedFileTypes.includes(selectedFile.type)) {
//   //       uploadImage(selectedFile);
//   //     } else {
//   //       setError(
//   //         "Unsupported file type. Please upload a JPEG, PNG, or PDF file."
//   //       );
//   //       setFile(null);
//   //     }
//   //   }
//   // };
//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     const allowedTypes = [
//       "application/pdf",
//       "image/jpeg",
//       "image/jpg",
//       "image/png",
//       "image/webp",
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
//       "application/vnd.ms-excel", // .xls
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
//       "application/msword", // .doc
//     ];

//     if (file && allowedTypes.includes(file.type)) {
//       setFile(file);
//       setError(null); // Clear error message if valid file
//     } else {
//       setError(
//         "Invalid file type. Please upload a PDF, image, Excel, or Word file."
//       );
//       event.target.value = ""; // Clear the input
//     }
//   };

//   const handleEditClick = (item) => {
//     setSelectedProduct(item);
//     setFile(item.Attachment);
//     setSelectedAdminId(item.ProductId);
//     setSelectedProductAndService({ Type: item.Type });
//     setSelectedUnitsAndHours({
//       Type:
//         item.Unit && item.CostPerUnit
//           ? "Unit"
//           : item.Square && item.CostPerSquare
//           ? "Sq. Ft."
//           : item.Hour && item.CostPerHour
//           ? "Hour"
//           : "",
//     });
//     if (item.Unit && item.CostPerUnit) {
//       setShowUnitsSection(true);
//       setShowSquaresSection(false);
//       setShowHoursSection(false);
//     }
//     if (item.Hour && item.CostPerHour) {
//       setShowHoursSection(true);
//       setShowUnitsSection(false);
//       setShowSquaresSection(false);
//     }
//     if (item.Square && item.CostPerSquare) {
//       setShowSquaresSection(true);
//       setShowHoursSection(false);
//       setShowUnitsSection(false);
//     }
//     setModelOpen(true);
//   };

//   const handleDelete = (id) => {
//     sendSwal().then(async (deleteReason) => {
//       if (deleteReason) {
//         try {
//           const response = await AxiosInstance.delete(`/product/${id}`, {
//             data: { DeleteReason: deleteReason },
//           });
//           if (response.data.statusCode === 200) {
//             setTimeout(() => {
//               showToast.success(response.data.message);
//             }, 500);
//             getData();
//           } else {
//             setTimeout(() => {
//               showToast.warning("", response.data.message, "error");
//             }, 500);
//           }
//         } catch (error) {
//           console.error("Error:", error);
//           showToast.error(error);
//         }
//       } else {
//         setTimeout(() => {
//           showToast.success("Products & Services is safe!", {
//             position: "top-center",
//             autoClose: 1000,
//           });
//         }, 500);
//       }
//     });
//     setTimeout(() => {
//       const deleteButton = document.querySelector(".swal-button--confirm");
//       if (deleteButton) {
//         deleteButton.disabled = true;
//       }
//     }, 0);
//   };

//   const CollapseData = ({ data }) => {
//     return (
//       <Grid className="d-flex gap-4 mt-3 mb-3 w-100">
//         <Col className="card col-8 productDetaillTable">
//           <Grid
//             className="card-body w-100"
//             style={{ backgroundColor: "#D8E7EE" }}
//           >
//             <Grid className="d-flex w-100 flex-row justify-content-between gap-2">
//               <Typography className="text-blue-color">Description: </Typography>
//               <Typography
//                 style={{
//                   backgroundColor: "white",
//                   padding: "0.2rem 0.5rem",
//                   borderRadius: "0.25rem",
//                   width: "100%",
//                   marginBottom: "7px",
//                 }}
//                 className="text-blue-color"
//               >
//                 {data.Description}
//               </Typography>
//             </Grid>
//           </Grid>
//         </Col>
//       </Grid>
//     );
//   };

//   const cellData = productAndService?.map((item, index) => {
//     // Determine which field and value to display with a label
//     let displayValue = "-";
//     if (item.Hour) {
//       displayValue = `Hour : ${item.Hour}`;
//     } else if (item.Unit) {
//       displayValue = `Unit : ${item.Unit}`;
//     } else if (item.Square) {
//       displayValue = `Sq. Ft. : ${item.Square}`;
//     }

//     return {
//       key: item.ProductId,
//       value: [
//         page * rowsPerPage + index + 1,
//         item.Type ? item.Type : "-",
//         item.Name ? item.Name : "-",
//         displayValue,
//         item.CostPerHour || item.CostPerUnit || item.CostPerSquare,
//         moment(item.updatedAt).format("MM-DD-YYYY"),
//         <>
//           <EditIcon
//             onClick={(e) => {
//               e.stopPropagation();
//               handleEditClick(item);
//             }}
//           />
//           <DeleteIcone
//             className="mx-1 customerEditImgToEdit"
//             onClick={(e) => {
//               e.stopPropagation();
//               handleDelete(item.ProductId);
//             }}
//           />
//         </>,
//       ],
//       component: item,
//     };
//   });

//   const [showHoursSection, setShowHoursSection] = useState(false);
//   const [showUnitsSection, setShowUnitsSection] = useState(false);

//   const productsAndService = [{ Type: "Product" }, { Type: "Service" }];
//   const UnitsAndHours = [
//     { Type: "Unit" },
//     { Type: "Hour" },
//     { Type: "Sq. Ft." },
//   ];

//   const [selectedUnitsAndHours, setSelectedUnitsAndHours] = useState(null);
//   const [selectedProductAndService, setSelectedProductAndService] =
//     useState(null);
//   const { companyName } = useParams();
//   const [isOpenDropDown, setIsOpenDropDown] = useState(false);
//   const toggle = () => setIsOpenDropDown(!isOpenDropDown);

//   return (
//     <>
//       <Grid className="d-flex">
//         <Col className=" col-2 h-100 hiren" xl={2}>
//           <SettingSidebar />
//         </Col>
//         <Col
//           className=" product-service-table col-10  addProductServiceSideLine"
//           style={{
//             borderLeft: "0.5px solid rgba(6, 49, 100, 30%)",
//             paddingLeft: "20px",
//             marginTop: "-30px",
//           }}
//           xl={10}
//         >
//           <Typography
//             className="heading-two text-blue-color productServiceHeadFont"
//             style={{ fontWeight: 700, marginTop: "5%" }}
//           >
//             Products & Services
//           </Typography>
//           <Typography className="text-blue-color" style={{ marginTop: "5px" }}>
//             By keeping your product and service information updated, you can
//             more easily create accurate quotes, jobs, and invoices.
//           </Typography>

//           <Grid className=" justify-content-end align-items-center">
//             <Grid className="d-flex justify-content-end  align-items-center gap-2 settingSettingAddproSer BlueAndWhiteBtmFlex">
//               <Grid className="d-flex  align-items-center gap-2 settingProductServiceGaps">
//                 {/* <Grid
//                   className="setting-dropdown settingsidebardrop mb-2"
//                   style={{ borderRadius: "5px " }}
//                 >
//                   <button
//                     className="btn bg-blue-color"
//                     onClick={toggle}
//                     state
//                     style={{ color: "#fff" }}
//                   >
//                     Settings
//                   </button>
//                   {isOpenDropDown && (
//                     <SettingDropdown
//                       isOpenDropDown={isOpenDropDown}
//                       toggle={toggle}
//                       companyName={companyName}
//                     />
//                   )}
//                 </Grid> */}

//                 <SettingDropdown
//                   isOpenDropDown={isOpenDropDown}
//                   toggle={toggle}
//                   companyName={companyName}
//                 />
//               </Grid>
//               <Grid
//                 className="d-flex justify-content-end align-items-end mb-2 mr-1  addproduCtBtnHere "
//                 style={{ marginTop: "15px" }}
//               >
//                 <BlueButton
//                   onClick={() => {
//                     setModelOpen(true);
//                     setSelectedProduct(null);
//                     setSelectedProductAndService(null);
//                   }}
//                   label="Add Products & Service"
//                 />
//               </Grid>
//             </Grid>

//             <Card
//               style={{
//                 borderRadius: "20px",
//                 border: "2px solid",
//                 padding: 0,
//               }}
//               className="border-blue-color"
//             >
//               <CardHeader
//                 className="d-flex justify-content-between align-items-center table-header bg-blue-color  customersAddCustomers"
//                 style={{
//                   borderBottom: "2px solid ",
//                   borderTopLeftRadius: "15px",
//                   borderTopRightRadius: "15px",
//                 }}
//               >
//                 <Typography className="text-light text-size settingProductService heading-five tableNameHead">
//                   Product & Service
//                 </Typography>
//                 <Grid className="d-flex settingSearch searchBarOfTable">
//                   <JobberSearch
//                     className=""
//                     search={search}
//                     setSearch={setSearch}
//                     style={{ background: "transparant", color: "white" }}
//                   />
//                 </Grid>
//               </CardHeader>
//               {loader ? (
//                 <Grid className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
//                   <LoaderComponent loader={loader} height="50" width="50" />
//                 </Grid>
//               ) : (
//                 <CardBody style={{ padding: "10px 0px" }}>
//                   <JobberTable
//                     headerData={[
//                       "Sr no",
//                       "Type",
//                       "Name",
//                       "Measurement",
//                       "Cost",
//                       "Date",
//                       "Action",
//                     ]}
//                     cellData={cellData}
//                     CollapseComponent={(data) => CollapseData(data)}
//                     isCollapse={true}
//                     page={page}
//                     isNavigate={false}
//                   />
//                 </CardBody>
//               )}
//               <CardFooter
//                 className="bg-orange-color border-blue-color"
//                 style={{
//                   borderTop: "2px solid",
//                   borderBottomLeftRadius: "20px",
//                   borderBottomRightRadius: "20px",
//                 }}
//               >
//                 <JobberPagination
//                   totalData={countData}
//                   currentData={rowsPerPage}
//                   dataPerPage={rowsPerPage}
//                   pageItems={[10, 25, 50]}
//                   page={page}
//                   setPage={setPage}
//                   setRowsPerPage={setRowsPerPage}
//                 />
//               </CardFooter>
//             </Card>
//           </Grid>
//         </Col>
//       </Grid>
//       <AddItems
//         modelOpen={modelOpen}
//         setModelOpen={setModelOpen}
//         setSelectedProductAndService={setSelectedProductAndService}
//         selectedProductAndService={selectedProductAndService}
//         productsAndService={productsAndService}
//         setShowUnitsSection={setShowUnitsSection}
//         setShowHoursSection={setShowHoursSection}
//         setSelectedUnitsAndHours={setSelectedUnitsAndHours}
//         showHoursSection={showHoursSection}
//         showUnitsSection={showUnitsSection}
//         selectedProduct={selectedProduct}
//         selectedUnitsAndHours={selectedUnitsAndHours}
//         UnitsAndHours={UnitsAndHours}
//         showSquaresSection={showSquaresSection}
//         setShowSquaresSection={setShowSquaresSection}
//         selectedAdminId={selectedAdminId}
//         CompanyId={CompanyId}
//         getData={getData}
//         handleFileChange={handleFileChange}
//         uploadFile={uploadFile}
//         file={file}
//         error={error}
//         closePreviewModal={closePreviewModal}
//         previewFile={previewFile}
//         handleRemoveFile={handleRemoveFile}
//         handleDragOver={handleDragOver}
//         handleDrop={handleDrop}
//         previewModalOpen={previewModalOpen}
//         handleFilePreview={handleFilePreview}
//       />
//     </>
//   );
// };

// export default ProductService;
import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
  NavigatorNav,
} from "../../../../components/MuiTable/index.jsx";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import Edit from "../../../../assets/image/icons/edit.svg";
import Delete from "../../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import "./style.css";
import moment from "moment";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListInlineItem,
  Navbar,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import SettingSidebar from "../../../../components/Setting/SettingSidebar.jsx";
import { Link, useParams } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import List from "@mui/material/MenuItem";
import SwipeableDrawer from "./Drawer.jsx";
import MenuIcon from "@mui/icons-material/Menu";
import { Gridider } from "@mui/material";
import AxiosInstance from "../../../AxiosInstance.jsx";
import CombinedComponent from "./Drawer.jsx";
import SettingDropdown from "./SettingComponent.jsx";
import InputText from "../../../../components/InputFields/InputText.jsx";
import { SingleFileUpload } from "../../../../components/Files/index.jsx";
import { DisplayImage } from "../../../../components/Files/DisplayFiles.jsx";
import InputDropdown from "../../../../components/InputFields/InputDropdown.jsx";
import { postFile } from "../../../../components/Files/Functions.jsx";
import AddItems from "./AddItems.jsx";
import sendSwal from "../../../../components/Swal/sendSwal.jsx";
import {
  DeleteIcone,
  EditIcon,
  LoaderComponent,
} from "../../../../components/Icon/Index.jsx";
import { Grid } from "@mui/material";
import { Row, Col } from "react-bootstrap"; // React Bootstrap components
import { Typography } from "@mui/material";

import BlueButton from "../../../../components/Button/BlueButton.jsx";
import showToast from "../../../../components/Toast/Toster.jsx";

const ProductService = () => {
  const [modelOpen, setModelOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [productAndService, setProductAndService] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const CompanyId = localStorage.getItem("CompanyId");
  const [showSquaresSection, setShowSquaresSection] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [file, setFile] = useState();
  const [error, setError] = useState("");
  const [imageLoader, setImageLoader] = useState(false);

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`/product/get/${CompanyId}`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
        },
      });
      setProductAndService(res?.data?.data);
      setCountData(res?.data?.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);

  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const cdnUrl = process.env.REACT_APP_CDN_API;
  const cdnShowUrl = "https://app.cloudjobmanager.com/cdn/upload";

  const uploadImage = async (file) => {
    setImageLoader(true);
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await AxiosInstance.post(`${cdnUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data?.files?.length > 0) {
        const imagePath = response?.data?.files[0]?.filename;
        setFile(imagePath);
        setError("");
      } else {
        throw new Error("No file returned from CDN.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("There was an issue uploading the file. Please try again.");
      setFile(null);
    } finally {
      setImageLoader(false);
    }
  };
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await AxiosInstance.post(`${cdnUrl}/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response?.data?.filePath;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event?.preventDefault();
    const droppedFile = event?.dataTransfer?.files[0];
    if (droppedFile) {
      if (allowedFileTypes?.includes(droppedFile?.type)) {
        uploadImage(droppedFile);
      } else {
        setError(
          "Unsupported file type. Please upload a PDF, JPEG, or PNG file."
        );
        setFile(null);
        setFile("");
      }
    }
  };
  const handleRemoveFile = () => {
    setFile(null);
    setFile("");
    setError("");
  };


  const handleFilePreview = (filePath) => {
    if (!filePath) {
      console.error("No file to preview");
      return;
    }
    const fullPath = `${cdnShowUrl}/${filePath}`;
    setPreviewFile(fullPath);
    setPreviewModalOpen(true);
  };

  const closePreviewModal = () => {
    setPreviewFile(null);
    setPreviewModalOpen(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event?.target?.files[0];
    if (selectedFile) {
      if (allowedFileTypes?.includes(selectedFile?.type)) {
        uploadImage(selectedFile);
      } else {
        setError(
          "Unsupported file type. Please upload a JPEG, PNG, or PDF file."
        );
        setFile(null);
      }
    }
  };

  const handleEditClick = (item) => {
    setSelectedProduct(item);
    setFile(item?.Attachment);
    setSelectedAdminId(item?.ProductId);
    setSelectedProductAndService({ Type: item?.Type });
    setSelectedUnitsAndHours({
      Type:
        item?.Unit && item?.CostPerUnit
          ? "Unit"
          : item?.Square && item?.CostPerSquare
          ? "Sq. Ft."
          : item?.Hour && item?.CostPerHour
          ? "Hour"
          : "",
    });
    if (item?.Unit && item?.CostPerUnit) {
      setShowUnitsSection(true);
      setShowSquaresSection(false);
      setShowHoursSection(false);
    }
    if (item?.Hour && item?.CostPerHour) {
      setShowHoursSection(true);
      setShowUnitsSection(false);
      setShowSquaresSection(false);
    }
    if (item?.Square && item?.CostPerSquare) {
      setShowSquaresSection(true);
      setShowHoursSection(false);
      setShowUnitsSection(false);
    }
    setModelOpen(true);
  };

  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`/product/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (response?.data?.statusCode === 200) {
            setTimeout(() => {
              showToast.success(response?.data?.message);
            }, 500);
            getData();
          } else {
            setTimeout(() => {
              showToast.error("", response?.data?.message, "error");
            }, 500);
          }
        } catch (error) {
          console.error("Error:", error);
          showToast.error(error);
        }
      } else {
        setTimeout(() => {
          showToast.success("Products & Services is safe!", {
            position: "top-center",
            autoClose: 1000,
          });
        }, 500);
      }
    });
    setTimeout(() => {
      const deleteButton = document?.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const CollapseData = ({ data }) => {
    return (
      <Grid className="d-flex gap-4 mt-3 mb-3 w-100">
        <Col className="card col-8 productDetaillTable">
          <Grid
            className="card-body w-100"
            style={{ backgroundColor: "#D8E7EE" }}
          >
            <Grid className="d-flex w-100 flex-row justify-content-between gap-2">
              <Typography className="text-blue-color">Description: </Typography>
              <Typography
                style={{
                  backgroundColor: "white",
                  padding: "0.2rem 0.5rem",
                  borderRadius: "0.25rem",
                  width: "100%",
                  marginBottom: "7px",
                }}
                className="text-blue-color"
              >
                {data?.Description}
              </Typography>
            </Grid>
          </Grid>
        </Col>
      </Grid>
    );
  };

  const cellData = productAndService?.map((item, index) => {
    // Determine which field and value to display with a label
    let displayValue = "-";
    if (item?.Hour) {
      displayValue = `Hour : ${item?.Hour}`;
    } else if (item?.Unit) {
      displayValue = `Unit : ${item?.Unit}`;
    } else if (item?.Square) {
      displayValue = `Sq. Ft. : ${item?.Square}`;
    }

    return {
      key: item.ProductId,
      value: [
        page * rowsPerPage + index + 1,
        item.Type ? item.Type : "-",
        item.Name ? item.Name : "-",
        displayValue,
        [
          `$${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(
            item.CostPerHour || item.CostPerUnit || item.CostPerSquare || 0.0
          )}`,
        ],

        moment(item.updatedAt).format("MM-DD-YYYY"),
        <>
          <EditIcon
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item);
            }}
          />
          <DeleteIcone
            className="mx-1 customerEditImgToEdit"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item?.ProductId);
            }}
          />
        </>,
      ],
      component: item,
    };
  });

  const [showHoursSection, setShowHoursSection] = useState(false);
  const [showUnitsSection, setShowUnitsSection] = useState(false);

  const productsAndService = [{ Type: "Product" }, { Type: "Service" }];
  const UnitsAndHours = [
    { Type: "Unit" },
    { Type: "Hour" },
    { Type: "Sq. Ft." },
  ];

  const [selectedUnitsAndHours, setSelectedUnitsAndHours] = useState(null);
  const [selectedProductAndService, setSelectedProductAndService] =
    useState(null);
  const { companyName } = useParams();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const toggle = () => setIsOpenDropDown(!isOpenDropDown);

  return (
    <>
      <Grid className="d-flex">
        <Col className=" col-2 h-100 hiren" xl={2}>
          <SettingSidebar />
        </Col>
        <Col
          className=" product-service-table col-10  addProductServiceSideLine"
          style={{
            borderLeft: "0.5px solid rgba(6, 49, 100, 30%)",
            paddingLeft: "20px",
            marginTop: "-30px",
          }}
          xl={10}
        >
          <Typography
            className="heading-two text-blue-color productServiceHeadFont"
            style={{ fontWeight: 700, marginTop: "5%" }}
          >
            Products & Services
          </Typography>
          <Typography className="text-blue-color" style={{ marginTop: "5px" }}>
            By keeping your product and service information updated, you can
            more easily create accurate quotes, jobs, and invoices.
          </Typography>

          <Grid className=" justify-content-end align-items-center">
            <Grid className="d-flex justify-content-end  align-items-center gap-2 settingSettingAddproSer BlueAndWhiteBtmFlex">
              <Grid className="d-flex  align-items-center gap-2 settingProductServiceGaps">
                {/* <Grid
                  className="setting-dropdown settingsidebardrop mb-2"
                  style={{ borderRadius: "5px " }}
                >
                  <button
                    className="btn bg-blue-color"
                    onClick={toggle}
                    state
                    style={{ color: "#fff" }}
                  >
                    Settings
                  </button>
                  {isOpenDropDown && (
                    <SettingDropdown
                      isOpenDropDown={isOpenDropDown}
                      toggle={toggle}
                      companyName={companyName}
                    />
                  )}
                </Grid> */}

                <SettingDropdown
                  isOpenDropDown={isOpenDropDown}
                  toggle={toggle}
                  companyName={companyName}
                />
              </Grid>
              <Grid
                className="d-flex justify-content-end align-items-end mb-2 mr-1  addproduCtBtnHere "
                style={{ marginTop: "15px" }}
              >
                <BlueButton
                  onClick={() => {
                    setModelOpen(true);
                    setSelectedProduct(null);
                    setSelectedProductAndService(null);
                  }}
                  label="Add Products & Service"
                />
              </Grid>
            </Grid>

            <Card
              style={{
                borderRadius: "20px",
                border: "2px solid",
                padding: 0,
              }}
              className="border-blue-color"
            >
              <CardHeader
                className="d-flex justify-content-between align-items-center table-header bg-blue-color  customersAddCustomers"
                style={{
                  borderBottom: "2px solid ",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <Typography className="text-light text-size settingProductService heading-five tableNameHead">
                  Product & Service
                </Typography>
                <Grid className="d-flex settingSearch searchBarOfTable">
                  <JobberSearch
                    className=""
                    search={search}
                    setSearch={setSearch}
                    style={{ background: "transparant", color: "white" }}
                  />
                </Grid>
              </CardHeader>
              {loader ? (
                <Grid className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <LoaderComponent loader={loader} height="50" width="50" />
                </Grid>
              ) : (
                <CardBody style={{ padding: "10px 0px" }}>
                  <JobberTable
                    headerData={[
                      "Sr no",
                      "Type",
                      "Name",
                      "Measurement",
                      "Cost",
                      "Date",
                      "Action",
                    ]}
                    cellData={cellData}
                    CollapseComponent={(data) => CollapseData(data)}
                    isCollapse={true}
                    page={page}
                    isNavigate={false}
                  />
                </CardBody>
              )}
              <CardFooter
                className="bg-orange-color border-blue-color"
                style={{
                  borderTop: "2px solid",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <JobberPagination
                  totalData={countData}
                  currentData={rowsPerPage}
                  dataPerPage={rowsPerPage}
                  pageItems={[10, 25, 50]}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </CardFooter>
            </Card>
          </Grid>
        </Col>
      </Grid>
      <AddItems
        modelOpen={modelOpen}
        setModelOpen={setModelOpen}
        setSelectedProductAndService={setSelectedProductAndService}
        selectedProductAndService={selectedProductAndService}
        productsAndService={productsAndService}
        setShowUnitsSection={setShowUnitsSection}
        setShowHoursSection={setShowHoursSection}
        setSelectedUnitsAndHours={setSelectedUnitsAndHours}
        showHoursSection={showHoursSection}
        showUnitsSection={showUnitsSection}
        selectedProduct={selectedProduct}
        selectedUnitsAndHours={selectedUnitsAndHours}
        UnitsAndHours={UnitsAndHours}
        showSquaresSection={showSquaresSection}
        setShowSquaresSection={setShowSquaresSection}
        selectedAdminId={selectedAdminId}
        CompanyId={CompanyId}
        getData={getData}
        handleFileChange={handleFileChange}
        uploadFile={uploadFile}
        file={file}
        error={error}
        closePreviewModal={closePreviewModal}
        previewFile={previewFile}
        handleRemoveFile={handleRemoveFile}
        handleDragOver={handleDragOver}
        handleDrop={handleDrop}
        previewModalOpen={previewModalOpen}
        handleFilePreview={handleFilePreview}
      />
    </>
  );
};

export default ProductService;
