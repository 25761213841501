import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  FormGroup,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import "./style.css";
import { Card, CardBody, CardHeader, CardTitle, Col, Label } from "reactstrap";
import { Circles, RotatingLines } from "react-loader-spinner";
import { handleAuth } from "../../../components/Login/Auth.js";
import Edit from "../../../assets/svg/edit-white.svg";
import Delete from "../../../assets/svg/delete-white.svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import InputText from "../../../components/InputFields/InputText.js";
import AxiosInstance from "../../AxiosInstance.js";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputDropdown from "../../../components/InputFields/InputDropdown.js";
import sendSwal from "../../../components/Swal/sendSwal";

const MailService = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [toMail, setToMail] = useState();
  useState(() => {
    const fetchTokenData = async () => {
      try {
        const data = await handleAuth(navigate, location);
        if (data.data) {
          setToMail(data.data.primaryEmailAddress);
        }
      } catch (error) {
        console.error("Error: ", error.message);
      }
    };
    fetchTokenData();
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittings, setIsSubmittings] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    mailFormik.resetForm();
    setOpen(false);
  };

  const mailFormik = useFormik({
    initialValues: {
      Secure: false,
      ToMail: "",
      Host: "",
      Port: "",
      User: "",
      Password: "",
      Mail: "",
      Verify: false,
    },
    validationSchema: Yup.object({
      Host: Yup.string().required("Host is required."),
      Port: Yup.string().required("Port is required."),
      User: Yup.string().required("User is required."),
      Password: Yup.string().required("Pass is required."),
      Mail: Yup.string().required("Mail is required."),
      Verify: Yup.boolean()
        .oneOf([true], "Mail service is not verified.")
        .required("Mail service is not verified."),
    }),
    // onSubmit: async (values) => {
    //   try {
    //     values["Secure"] = values.Secure === "True";
    //     if (response.data.statusCode === 201) {
    //       handleClose();
    //       toast.success(response.data.message);
    //     } else {
    //       toast.error(response.data.message);
    //     }
    //   } catch (error) {
    //     console.error("Error: ", error.message);
    //   }
    // },
    onSubmit: async (values) => {
      try {
        const requestData = { ...values, Secure: values.Secure === "true" };

        if (values.id) {
          const response = await AxiosInstance.put(
            `/mailconfiguration/${values.id}`,
            requestData
          );

          if (response.data.statusCode === 200) {
            handleClose();
            setTimeout(() => {
            toast.success(response.data.message);
          }, 500);
          } else {
            setTimeout(() => {
            toast.error(response.data.message);
          }, 500);
          }
        } else {
          const response = await AxiosInstance.post(
            `/mailconfiguration`,
            requestData
          );

          if (response.data.statusCode === 201) {
            setTimeout(() => {
            toast.success(response.data.message);
          }, 500);
            handleClose();
            fetchMailConfigurations();
          } else {
            setTimeout(() => {
            toast.error(response.data.message);
          }, 500);
          }
        }
      } catch (error) {
        console.error("Error: ", error.message);
        setTimeout(() => {
        toast.error("An error occurred while processing your request.");
      }, 500);
      }
    },
  });
  const [showPassword, setShowPassword] = useState(false);

  const verifyEmail = async () => {
    setIsSubmitting(true); // Start loader
    try {
      mailFormik.values["ToMail"] = toMail;
      const response = await AxiosInstance.post(
        `/mailconfiguration/test_mail`,
        { ...mailFormik.values, Secure: mailFormik.values.Secure === "true" }
      );
      if (response.data.statusCode === 200 && response.data.info.verified) {
        mailFormik.setFieldValue("Verify", true);
        setTimeout(() => {
        toast.success(response.data.info.message);
      }, 500);
      } else {
        setTimeout(() => {
        toast.error(response.data.info.message);
      }, 500);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setIsSubmitting(false); // Stop loader
    }
  };

  const [mailConfigs, setMailConfigs] = useState([]);

  const fetchMailConfigurations = async () => {
    try {
      const response = await AxiosInstance.get("/mailconfiguration");
      if (response.data.statusCode === 200) {
        setMailConfigs(response.data.result);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchMailConfigurations();
  }, []);

  // const handleEditConfig = (id) => {
  //   const config = mailConfigs.find((config) => config.id === id);
  //   if (config) {
  //     mailFormik.setValues(config);
  //     setOpen(true);
  //   }
  // };

  const handleEditConfig = async (id) => {
    try {
      // Fetch the specific configuration using the MailConfigurationId (id)
      const response = await AxiosInstance.get(`/mailconfiguration/${id}`);

      if (response.data.statusCode === 200) {
        // If data is fetched successfully, populate the form with the data
        const config = response.data.result;
        mailFormik.setValues({
          Host: config.Host,
          Port: config.Port,
          User: config.User,
          Password: config.Password,
          Mail: config.Mail,
          Secure: config.Secure.toString(),
          Verify: config.Verify,
          id: config.MailConfigurationId,
        });

        setOpen(true);
      } else {
        console.error("Failed to fetch mail configuration details.");
      }
    } catch (error) {
      console.error("Error fetching mail configuration details:", error);
    }
  };

  // const handleDelete = async (id) => {
  //   try {
  //     const response = await AxiosInstance.delete(
  //       `/delete_company_mail_details/${id}`
  //     );
  //     if (response.status === 200) {
  //       // Handle successful deletion, e.g., update state or show a notification
  //       // Optionally, you can remove the item from the UI
  //     }
  //   } catch (error) {
  //     console.error("Error deleting data:", error);
  //     // Handle error, e.g., show an error message
  //   }
  // };

  //delete
  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(
            `/mailconfiguration/${id}`
          );
          if (response.data.statusCode === 200) {
            setMailConfigs((prevData) =>
              prevData.filter((item) => item.MailConfigurationId !== id)
            );
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            // handleEditConfig();
          } else {
            setTimeout(() => {
            toast.error("", response.data.message, "error");
          }, 500);
          }
        } catch (error) {
          console.error("Error:", error);
          setTimeout(() => {
          toast.error(error);
        }, 500);
        }
      }
    });
  };

  return (
    <div className="mail">
      <div className="justify-content-center align-items-center mb-3 ">
        <div className="d-flex justify-content-between mb-2 align-items-center"></div>

        <Card
          className="border-blue-color"
          style={{
            borderRadius: "20px",
            border: "2px solid ",
            padding: 0,
            marginBottom: "20px",
            height: "100%",
          }}
        >
          <CardHeader
            className="d-flex border-blue-color justify-content-between align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid ",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="custe text-light p-4">Mail Service </h5>{" "}
          </CardHeader>
          <CardBody style={{ padding: "10px 0px" }}>
            <p
              className="text-blue-color"
              style={{
                fontWeight: 600,
                fontSize: "18px",
                paddingLeft: "40px",
                paddingTop: "30px",
              }}
            >
              Set your SMTP Mail Configuration settings from here
            </p>
            <div>
              <div style={{ paddingLeft: "40px", paddingTop: "20px" }}>
                <Button
                  className="bg-blue-color text-white-color"
                  style={{ textTransform: "capitalize" }}
                  onClick={handleClickOpen}
                >
                  Add New Configuration
                </Button>
              </div>
              <div
                style={{
                  paddingLeft: "40px",
                  paddingTop: "20px",
                  paddingRight: "40px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {mailConfigs &&
                  mailConfigs.length > 0 &&
                  mailConfigs.map((config, index) => (
                    <Card
                      className="my-2 mail-card"
                      style={{
                        flex: "0 0 calc(50% - 10px)",
                      }}
                      key={index}
                    >
                      <CardHeader className="d-flex justify-content-between align-items-center bg-blue-color mail-header">
                        <p
                          className="mb-0 text-white-color"
                          style={{ fontSize: "18px", fontWeight: 600 }}
                        >
                          Configuration Details
                        </p>
                        <div className="">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              background: "rgba(255, 255, 255, 0.2)",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="mx-3"
                              style={{
                                cursor: "pointer",
                                color: "#FFFF",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() =>
                                handleEditConfig(config.MailConfigurationId)
                              }
                            >
                              <img src={Edit} alt="Edit Icon" />
                            </div>

                            <Divider
                              orientation="vertical"
                              flexItem
                              style={{
                                height: "20px",
                                border: "2px solid #FFFF",
                                alignItems: "center",
                                margin: "5px",
                                marginTop: "8px",
                              }}
                            />
                            <div
                              className="mx-3"
                              style={{
                                cursor: "pointer",
                                color: "#FFFF",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() =>
                                handleDelete(config.MailConfigurationId)
                              } // Attach the click handler here
                            >
                              <img src={Delete} alt="Delete Icon" />
                            </div>
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <CardTitle tag="h5" className="text-blue-color">
                            Host :
                          </CardTitle>
                          <p
                            className="mb-3 text-blue-color mail-section"
                            style={{ opacity: 0.5, fontWeight: 500 }}
                          >
                            {config.Host}
                          </p>
                        </div>
                        <div>
                          <CardTitle tag="h5" className="text-blue-color">
                            Port :
                          </CardTitle>
                          <p
                            className="text-blue-color"
                            style={{ opacity: 0.5, fontWeight: 500 }}
                          >
                            {config.Port}
                          </p>
                        </div>
                        <div>
                          <CardTitle tag="h5" className="text-blue-color">
                            User :
                          </CardTitle>
                          <p
                            className="mb-3 text-blue-color"
                            style={{ opacity: 0.5, fontWeight: 500 }}
                          >
                            {config.User}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  ))}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: "18px" }}
          className="text-blue-color"
          id="customized-dialog-title"
        >
          Add New Configuration
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <CardTitle tag="h6" className="text-blue-color">
            Set your SMTP Mail Configuration settings from here
          </CardTitle>
          <div className="my-4 d-flex gap-3 mail-modal-main">
            <div className="col-6">
              <FormGroup
                row
                className="gap-2 align-items-center mb-3 formgroups"
              >
                <Label for="Host" className="mb-0" style={{ fontSize: "16px" }}>
                  Host* :
                </Label>
                <Col className="inputs-maill" style={{ marginLeft: "8px" }}>
                  <InputText
                    name="Host"
                    label="Host"
                    fieldHeight="54px"
                    id="Host"
                    type="text"
                    className="text-blue-color w-100"
                    value={mailFormik.values?.Host}
                    onChange={mailFormik.handleChange}
                    onBlur={mailFormik.handleBlur}
                    error={
                      mailFormik.touched.Host && Boolean(mailFormik.errors.Host)
                    }
                    helperText={
                      mailFormik.touched.Host && mailFormik.errors.Host
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup
                row
                className="gap-2 align-items-center mb-3 formgroups"
              >
                <Label for="User" className="mb-0" style={{ fontSize: "16px" }}>
                  User* :
                </Label>
                <Col className="inputs-maill" style={{ marginLeft: "8px" }}>
                  <InputText
                    name="User"
                    label="User"
                    fieldHeight="54px"
                    id="User"
                    type="text"
                    className="text-blue-color w-100"
                    value={mailFormik.values?.User}
                    onChange={mailFormik.handleChange}
                    onBlur={mailFormik.handleBlur}
                    error={
                      mailFormik.touched.User && Boolean(mailFormik.errors.User)
                    }
                    helperText={
                      mailFormik.touched.User && mailFormik.errors.User
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup
                row
                className="gap-2 align-items-center mb-3 formgroups"
              >
                <Label
                  for="Secure"
                  className="mb-0"
                  style={{ fontSize: "16px" }}
                >
                  Secure :
                </Label>
                <Col className="inputs-mail">
                  <InputDropdown
                    options={["true", "false"]}
                    value={mailFormik.values?.Secure || null}
                    inputValue={mailFormik.values?.Secure || null}
                    onChange={(e, value) =>
                      mailFormik.setFieldValue("Secure", value)
                    }
                    label="Secure"
                    name="Secure"
                    type="text"
                    getOptionLabel={(option) => option || ""}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option
                          ?.toLowerCase()
                          ?.includes(state?.inputValue?.toLowerCase())
                      );
                    }}
                    error={
                      mailFormik.touched.Secure &&
                      Boolean(mailFormik.errors.Secure)
                    }
                    helperText={
                      mailFormik.touched.Secure && mailFormik.errors.Secure
                    }
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup
                row
                className="gap-2 align-items-center mb-3 formgroups"
                style={{ marginRight: "12px" }}
              >
                <Label for="Port" className="mb-0" style={{ fontSize: "16px" }}>
                  Port* :
                </Label>
                <Col style={{ marginLeft: "52px" }}>
                  <InputText
                    name="Port"
                    label="Port"
                    fieldHeight="54px"
                    id="Port"
                    type="text"
                    className="text-blue-color w-100"
                    value={mailFormik.values?.Port}
                    // onChange={mailFormik.handleChange}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numeric values including decimals
                      if (/^\d*\.?\d*$/.test(value)) {
                        // Use your original handleChange logic
                        mailFormik.handleChange({
                          target: {
                            name: "Port",
                            value: Math.max(0, Number(value)),
                          },
                        });
                      }
                    }}
                    onBlur={mailFormik.handleBlur}
                    error={
                      mailFormik.touched.Port && Boolean(mailFormik.errors.Port)
                    }
                    helperText={
                      mailFormik.touched.Port && mailFormik.errors.Port
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup
                row
                className="gap-2 align-items-center mb-3 formgroups"
                style={{ marginRight: "12px" }}
              >
                <Label for="Pass" className="mb-0" style={{ fontSize: "16px" }}>
                  Password* :
                </Label>
                <Col style={{ marginLeft: "8px" }}>
                  <InputText
                    value={mailFormik.values?.Password}
                    onChange={mailFormik.handleChange}
                    onBlur={mailFormik.handleBlur}
                    error={
                      mailFormik.touched.Password &&
                      Boolean(mailFormik.errors.Password)
                    }
                    helperText={
                      mailFormik.touched.Password && mailFormik.errors.Password
                    }
                    name="Password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    className="text-blue-color w-100 m-0 mb-3"
                    fieldHeight="56px"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup
                row
                className="gap-2 align-items-center mb-3 formgroups"
                style={{ marginRight: "12px" }}
              >
                <Label for="Mail" className="mb-0" style={{ fontSize: "16px" }}>
                  Mail* :
                </Label>
                <Col style={{ marginLeft: "52px" }}>
                  <InputText
                    name="Mail"
                    label="Mail"
                    fieldHeight="54px"
                    id="Mail"
                    type="text"
                    className="text-blue-color w-100"
                    value={mailFormik.values?.Mail}
                    onChange={mailFormik.handleChange}
                    onBlur={mailFormik.handleBlur}
                    error={
                      mailFormik.touched.Mail && Boolean(mailFormik.errors.Mail)
                    }
                    helperText={
                      mailFormik.touched.Mail && mailFormik.errors.Mail
                    }
                  />
                </Col>
              </FormGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Box>
            {isSubmitting ? (
              <Box display="flex" justifyContent="right" color="#063164">
                <Circles
                  height="20"
                  width="20"
                  color="#063164"
                  ariaLabel="circles-loading"
                  visible={isSubmitting}
                />
              </Box>
            ) : (
              <div>
                {mailFormik.touched.Verify && mailFormik.errors.Verify && (
                  <div style={{ color: "red" }}>{mailFormik.errors.Verify}</div>
                )}
                <Button
                  autoFocus
                  className="bg-blue-color text-white-color"
                  type="button"
                  onClick={verifyEmail}
                >
                  Verify Email
                </Button>
              </div>
            )}
          </Box>

          <div className="gap-2 d-flex">
            <Button
              className="border-blue-color text-blue-color"
              style={{
                textTransform: "capitalize",
                border: "1px solid",
                borderRadius: "8px",
              }}
              outline
              onClick={handleClose}
              type="button"
            >
              Cancel
            </Button>
            <Box>
              {isSubmittings ? (
                <Box display="flex" justifyContent="right" color="#063164">
                  <Circles
                    height="20"
                    width="20"
                    color="#063164"
                    ariaLabel="circles-loading"
                    visible={isSubmittings}
                  />
                </Box>
              ) : (
                <Button
                  className="bg-blue-color text-white-color"
                  style={{ textTransform: "capitalize" }}
                  type="button"
                  onClick={mailFormik.handleSubmit}
                >
                  Add Email
                </Button>
              )}
            </Box>
          </div>
        </DialogActions>
      </Dialog>
      <Toaster />
    </div>
  );
};

export default MailService;
