import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import { generateInvoicePDF } from "../../../components/Files/InvoicePdf";
import AxiosInstance from "../../AxiosInstance";
import InvoiceDetail from "../../Admin/Invoice/InvoiceDetails";
import toast, { Toaster } from "react-hot-toast";
import sendToast from "../../../components/Toast/sendToast";

const options = { year: "numeric", month: "short", day: "numeric" };

function WorkerInvoiceDetail() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [invoicedata, setInvoicedata] = useState({});
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [mail, setMail] = useState(false);

  let fetchData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/invoice/invoice_detail/${location.state.id}`
      );
      setInvoicedata(res.data.data);
    } catch (error) {
      console.error("Error: ", error.messae);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleCopy = () => {
    const fullName = `${invoicedata?.customer?.FirstName?.charAt(
      0
    )?.toUpperCase()}${invoicedata?.customer?.FirstName?.slice(
      1
    )} ${invoicedata?.customer?.LastName?.charAt(
      0
    )?.toUpperCase()}${invoicedata?.customer?.LastName?.slice(1)}`;
    navigator.clipboard.writeText(fullName);
  };

  const handleEditClick = () => {
    navigate(`/${companyName}/addinvoice`, {
      state: {
        invoiceId: location.state.id,
        navigats: [...location.state.navigats, "/addinvoice"],
      },
    });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const sendMail = async () => {
    try {
      const mailRes = await AxiosInstance.post(
        `/invoice-payment/send_mail/${location.state.id}`
      );
      if (mailRes.data.statusCode === 200) {
        toast.success(mailRes.data.message);
      } else {
        sendToast(mailRes.data.message);
      }
    } catch (error) {
      sendToast(error.response.data.error);
    }
  };

  const downloadPdf = async () => {
    try {
      const res = await AxiosInstance.post(
        `/invoice/invoicepdf/${location.state.id}`
      );
      if (res.data.statusCode === 200) {
        const url = `https://app.cloudjobmanager.com/cdn/upload/${res.data.fileName}`;
        fetch(url)
          .then((response) => {
            if (!response.ok) throw new Error("Network response was not ok");
            return response.blob(); 
          })
          .then((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob); 
            link.download = "invoice_document.pdf"; 
            document.body.appendChild(link);
            link.click(); 
            document.body.removeChild(link); 
          })
          .catch((error) =>
            console.error(
              "There was a problem with the fetch operation:",
              error
            )
          );
      }
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  return (
    <>
      <InvoiceDetail
        loader={loader}
        companyName={companyName}
        location={location}
        invoicedata={invoicedata}
        setMail={setMail}
        handleEditClick={handleEditClick}
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        generateInvoicePDF={generateInvoicePDF}
        downloadPdf={downloadPdf}
        handleCopy={handleCopy}
        open={open}
        setOpen={setOpen}
        file={file}
        mail={mail}
        options={options}
        navigate={navigate}
        sendMail={sendMail}
      />
      <Toaster />
    </>
  );
}

export default WorkerInvoiceDetail;
