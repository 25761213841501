import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import AxiosInstance from "../../Views/AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import backimg from "../../assets/image/icons/back.png";
import locke from "../../assets/image/icons/Rectangle 20.png";
import InputText from "../InputFields/InputText";
import AppLogo from "../../assets/image/CMS_LOGO.svg";
import toast, { Toaster } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";

const ResetPassword = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [showCPassword, setShowCPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    setIsLoading(true); // Start loading state

    AxiosInstance.get(`/resetpassword/check_token_status/${token}`)
      .then((response) => {
        const data = response.data;
        setIsLoading(false);
        if (data.expired) {
          setTokenExpired(true);
        } else {
          setEmail(token);
        }
      })
      .catch((error) => {
        console.error("Error checking token status:", error);
        setIsLoading(false);
      });
  }, [location.search]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    validate: (values) => {
      const errors = {};
      if (values.password !== values.confirmpassword) {
        errors.confirmpassword = "Passwords do not match";
      }
      if (!isStrongPassword(values.password)) {
        errors.password =
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const response = await AxiosInstance.put(
          `/resetpassword/reset_passwords/${email}`,
          {
            Password: values.password,
            role: "company",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          toast.success("Password Changed Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          setTimeout(() => {
            navigate(response.data.url);
          }, 1000);
        } else {
          setError(response.data.message);
          toast.error("Failed To Change Password", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        setError("An error occurred while changing the password");
      } finally {
        setIsLoading(false);
      }
    },
  });

  const isStrongPassword = (password) => {
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  if (isLoading) {
    return (
      <ColorRing
        height="30"
        width="30"
        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    );
  }

  if (tokenExpired) {
    return <div>Token has expired. Please request a new reset link.</div>;
  }

  return (
    <div className="loginnn">
      <Grid container spacing={2} className="vh-100">
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex flex-direction-column align-items-center pt-3"
        >
          <div
            style={{
              width: "80%",
              height: "90%",
              paddingTop: "40px",
              marginLeft: "20px",
            }}
            className="d-flex flex-direction-column align-items-center px-3 maon-forget-password"
          >
            <Typography
              className="resetPassword-logo"
              style={{
                marginRight: "160px",
              }}
            >
              <img
                src={AppLogo}
                alt="logo"
                style={{ width: "250px", height: "60px" }}
              ></img>
            </Typography>
            <form
              style={{ width: "85%", height: "90%", marginTop: "70px" }}
              onSubmit={formik.handleSubmit}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "21px",
                  textAlign: "left",
                  color: "black",
                  marginTop: "0px",
                  paddingTop: "0px",
                  display: "flex",
                  marginBottom: "15px",
                }}
              >
                <img src={backimg} width={20} height={20} alt="back" />
                <span className="mx-2">Back to login </span>
              </Typography>
              <Typography
                className="text"
                style={{ fontSize: "35px", margintop: "20px" }}
              >
                Forgot your password?
              </Typography>
              <Typography
                className="text2"
                style={{ color: "#606060", margintop: "20px" }}
              >
                Don't worry, happens to all of us. Enter your email below to
                recover your password
              </Typography>
              <FormGroup style={{ width: "100%", marginTop: "24px" }}>
                <InputText
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  name="password"
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  className="text-blue-color w-100 m-0 "
                  fieldHeight="56px"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormGroup>
              <FormGroup style={{ width: "100%", marginTop: "24px" }}>
                <InputText
                  value={formik.values.confirmpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmpassword &&
                    Boolean(formik.errors.confirmpassword)
                  }
                  helperText={
                    formik.touched.confirmpassword &&
                    formik.errors.confirmpassword
                  }
                  name="confirmpassword"
                  label="Re-type New Password"
                  type={showCPassword ? "text" : "password"}
                  className="text-blue-color w-100 m-0 mb-3"
                  fieldHeight="56px"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCPassword(!showCPassword)}
                        edge="end"
                      >
                        {showCPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormGroup>
              <Button
                style={{
                  backgroundColor: "rgba(51, 53, 71, 1)",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "21px",
                  borderRadius: "4px",
                  gap: "4px",
                  height: "48px",
                  textTransform: "none",
                  background: "rgb(39 84 110)",
                  // marginTop: "20px",
                }}
                className="resetPassword-button"
                variant="contained"
                type="submit"
                fullWidth
              >
                Change Password
              </Button>
              <Typography
                className="text-danger"
                style={{ textAlign: "left", marginTop: "20px" }}
              >
                {error}
              </Typography>
            </form>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center img reset-image"
        >
          <img
            src={locke}
            alt="logo"
            style={{ width: "70%", height: "90%", marginTop: "20px" }}
          ></img>
        </Grid>
      </Grid>
      <Toaster />
    </div>
  );
};

export default ResetPassword;
