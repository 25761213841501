import React from "react";
import ContractMail from "../ContractMail";
import CustomerModal from "../../Quotes/CustomerModal";
import { FormGroup } from "@mui/material";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import { Circles, ColorRing } from "react-loader-spinner";
import {
  CalendarJOB,
  InternalNotes,
  OneOffContract,
  RecurringContract,
  Team,
} from "../../../../components/Contract Component/Index";
import GetProductsForContract from "../../../../components/Products/GetProductsForContract";
import InputText from "../../../../components/InputFields/InputText";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Previous from "../../../../assets/image/icons/Previous.png";
import GetProducts from "../../../../components/Products/GetProducts";
import CloseIcon from "@mui/icons-material/Close";

const AddContract = ({
  lineItems,
  isProperty,
  setIsProperty,
  setIsCustomer,
  isCustomer,
  setPropertyData,
  setCustomersData,
  mail,
  setMail,
  customersData,
  contractData,
  propertyData,
  formik,
  formikTeam,
  handleSaveQuote,
  toggle,
  // InternalNotes,
  dropdownOpen,
  setLoading,
  loading,
  Total,
  taxAmount,
  showTax,
  setShowTax,
  showDiscount,
  setShowDiscount,
  discountAmount,
  deleteLineItem,
  subTotal,
  addLineItem,
  showCosts,
  setShowCosts,
  setMenuIsOpen,
  menuIsOpen,
  handleSelectChange,
  setLineItems,
  activeTab,
  setActiveTab,
  handleCahngeIds,
  selectedTeams,
  setSelectedTeams,
  isCalendarVisible,
  setIsCalendarVisible,
  isNumChange,
  setIsNumChange,
  handleContractNumberChange,
  toggleDropdown,
  isDropdownOpen,
  handleOutsideClick,
  teamData,
  setTeamData,
  checkedState,
  setCheckedState,
  handleRemoveTeam,
  handleTeamSelect,
  isAddTeam,
  toggleModal,
  isModalOpen,
  ids,
  setIds,
  setIsAddTeam,
  handlePhoneChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Button
        style={{
          marginRight: "10px",
          width: "50px",
          height: "40px",
          padding: "0px 0px",
          borderRadius: "4px",
        }}
        onClick={() => {
          navigate(-1);
        }}
        className="text-capitalize bg-button-blue-color back-button text-white-color "
      >
        <img src={Previous} style={{ width: "20px", height: "20px" }} />
      </Button>
      <div className="justify-content-center align-items-center mb-3 mt-3 quotes job">
        <Card
          className="my-2 col-12 p-4 border-blue-color"
          style={{ borderRadius: "20px" }}
        >
          <CardTitle
            className="text-blue-color contract-for"
            style={{
              fontSize: "27px",
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
            }}
          >
            Contract for
            <span
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIsCustomer(true);
              }}
            >
              <u className="text-blue-color" style={{ paddingLeft: "5px" }}>
                {customersData?.FirstName
                  ? `${customersData?.FirstName} ${customersData?.LastName}`
                  : "Customer Name"}
              </u>
              {!customersData?.FirstName && (
                <Button
                  className="mx-3 bg-button-blue-color text-white-color "
                  style={{
                    height: "20px",
                    width: "30px",
                    minWidth: "30px",
                    padding: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  +
                </Button>
              )}
            </span>
          </CardTitle>
          <div className="d-lg-flex d-md-block my-3 gap-4">
            <div className="col-lg-6 col-md-12 col-sm-12 d-block">
              {" "}
              <div>
                <Label
                  className="text-blue-color"
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                  for="exampleEmail"
                >
                  Contract title
                </Label>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <InputText
                    value={formik.values?.Title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="Title"
                    id="exampleEmail"
                    placeholder="Enter Title here..."
                    label="Title"
                    type="Title"
                    className="text-blue-color w-100"
                    fieldHeight="56px"
                  />
                  

                  <InputText
                    value={formik.values?.Description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="Description"
                    id="title"
                    placeholder="Enter Description here..."
                    label="Description"
                    type="text"
                    className="text-blue-color w-100"
                    fieldHeight="56px"
                  />
                </div> */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <InputText
                    value={formik.values?.Title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.Title && Boolean(formik.errors.Title)}
                    helperText={formik.touched.Title && formik.errors.Title}
                    name="Title"
                    id="exampleEmail"
                    placeholder="Enter Title here..."
                    label="Title"
                    type="text"
                    className="text-blue-color w-100"
                    fieldHeight="56px"
                  />

                  <InputText
                    value={formik.values?.Description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.Description &&
                      Boolean(formik.errors.Description)
                    }
                    helperText={
                      formik.touched.Description && formik.errors.Description
                    }
                    name="Description"
                    id="description"
                    placeholder="Enter Description here..."
                    label="Description"
                    type="text"
                    className="text-blue-color w-100"
                    fieldHeight="56px"
                  />
                </div>
              </div>
              {/* {customersData?.FirstName && propertyData?.City && (
              <div
                  className="d-flex mt-5 gap-3"
                  style={{ color: "rgba(6, 49, 100, 1)" }}
                >
                  <div className="col-6 text-left ">
                    <h6>
                      <b>Property address</b>
                    </h6>
                    <p>
                      {propertyData?.Address || "-"} <br />
                      {propertyData?.City || "-"}, {propertyData?.State || "-"},{" "}
                      {propertyData?.Zip || "-"}
                      <br />
                      {propertyData?.Country || "-"} <br />
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCustomer(true);
                        }}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {" "}
                        Change
                      </a>
                    </p>
                  </div>
                  <div className="col-6 text-left">
                    <h6>Contact details</h6>
                    <p>
                      {customersData?.PhoneNumber || "-"}
                      <br />
                      {customersData?.EmailAddress || "-"}
                    </p>
                  </div>
                </div>
              )} */}
              {customersData?.FirstName && propertyData?.City && (
                <div
                  className="d-flex mt-5 gap-3"
                  style={{ color: "rgba(6, 49, 100, 1)" }}
                >
                  <div className="col-6 text-left">
                    <h6>
                      <strong>Property address</strong>
                    </h6>
                    <p>
                      {propertyData?.Address || "-"} <br />
                      {propertyData?.City || "-"}, {propertyData?.State || "-"},{" "}
                      {propertyData?.Zip || "-"}
                      <br />
                      {propertyData?.Country || "-"} <br />
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCustomer(true);
                        }}
                        style={{ color: "green", cursor: "pointer" }}
                        href="#customer-section"
                        className="text-blue-color"
                      >
                        Change
                      </a>
                    </p>
                  </div>
                  <div className="col-6 text-left">
                    <h6>Contact details</h6>
                    <p>
                      {customersData?.PhoneNumber || "-"}
                      <br />
                      {customersData?.EmailAddress || "-"}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="col-6 d-flex order-2 changesinput">
              <div className="col-6 my-4 mx-3 text-left">
                <h5
                  className="text-blue-color"
                  style={{
                    fontWeight: 600,
                    marginBlock: 0,
                    fontSize: "15px",
                  }}
                >
                  Contract details
                </h5>
                {!isNumChange ? (
                  <p style={{ fontSize: "13px" }} className="text-blue-color">
                    Contract number #{formik.values.ContractNumber}
                  </p>
                ) : (
                  <input
                    id="ContractNumber"
                    name="ContractNumber"
                    type="text"
                    value={formik.values.ContractNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )}
              </div>
              <div className="col-3 my-4 text-center changeText">
                {!isNumChange ? (
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsNumChange(!isNumChange)}
                  >
                    Change
                  </u>
                ) : (
                  <Button onClick={() => setIsNumChange(!isNumChange)}>
                    Cancel
                  </Button>
                )}
              </div>
            </div> */}

            <div className="col-6 d-flex order-2 changesinput">
              <div className="col-6 my-3 mx-3 text-left">
                {!isNumChange ? (
                  <p
                    style={{ fontSize: "13px", marginTop: "20px" }}
                    className="text-blue-color"
                  >
                    Conract number #{formik.values.ContractNumber}
                  </p>
                ) : (
                  // <input
                  //   id="ContractNumber"
                  //   name="ContractNumber"
                  //   type="text"
                  //   value={formik.values.ContractNumber}
                  //   onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  // />
                  <InputText
                    value={formik.values.ContractNumber}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 0) {
                        e.target.value = 0;
                      }
                      formik.handleChange(e, "ContractNumber");
                    }}
                    onBlur={formik.handleBlur}
                    name="ContractNumber"
                    id="ContractNumber"
                    label="Contract Number"
                    type="number"
                    className="text-blue-color w-100 my-3"
                    fieldHeight="53px"
                  />
                )}
              </div>
              <div className="col-3 my-4 text-center changeText">
                {!isNumChange ? (
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsNumChange(true)}
                    className="text-blue-color"
                  >
                    Change
                  </u>
                ) : (
                  <Button
                    onClick={handleContractNumberChange}
                    className="buttons outline-button-blue-color outline selectclientaddquote bg-blue-color"
                  >
                    Done
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex col-12 schedule-section-main">
            <div className="col-6 ">
              <h5
                className="text-blue-color"
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                Type <HelpOutlineOutlinedIcon className="mx-1" />
              </h5>
              <div className="">
                <div className="toggle-button" style={{ width: "fit-content" }}>
                  <div className="" style={{ width: "auto" }}>
                    <Nav
                      className="toggle-button-nav text-blue-color border-blue-color"
                      tabs
                      style={{
                        borderRadius: "15px",
                        border: "1px solid ",
                        marginBottom: "15px",
                      }}
                    >
                      <NavItem className="on-off-contract outline">
                        <NavLink
                          className={`${
                            activeTab === 1 ? "active" : ""
                          } outline contract`}
                          onClick={() => setActiveTab(1)}
                          style={{
                            backgroundColor:
                              activeTab === 1 ? "red" : "transparent",
                            color: activeTab === 2 ? "#063164" : "black",
                            borderTopLeftRadius: "11px",
                            borderBottomLeftRadius: "11px",
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                            marginBottom: 0,
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          One-off Contract
                        </NavLink>
                      </NavItem>
                      <NavItem className="recurring">
                        <NavLink
                          className={`${
                            activeTab === 2 ? "active" : ""
                          } outline contract`}
                          onClick={() => setActiveTab(2)}
                          style={{
                            backgroundColor:
                              activeTab === 2 ? "red" : "transparent",
                            color: activeTab === 1 ? "#063164" : "black",
                            borderTopRightRadius: "11px",
                            borderBottomRightRadius: "11px",
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          Recurring Contract
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
                {activeTab === 1 && (
                  <OneOffContract
                    formik={formik}
                    isCalendarVisible={isCalendarVisible}
                    setIsCalendarVisible={setIsCalendarVisible}
                  />
                )}
                {activeTab === 2 && (
                  <RecurringContract
                    formik={formik}
                    isCalendarVisible={isCalendarVisible}
                    setIsCalendarVisible={setIsCalendarVisible}
                  />
                )}
              </div>
            </div>
            <div className="col-6 mx-2 my-3 mb-0 first-tab">
              {isCalendarVisible && (
                <CalendarJOB
                  isCalendarVisible={isCalendarVisible}
                  setIsCalendarVisible={setIsCalendarVisible}
                  selectedTeams={selectedTeams}
                  setSelectedTeams={setSelectedTeams}
                />
              )}
              {!isCalendarVisible && (
                <div style={{ marginTop: "19.8%" }}>
                  <Team
                    setIds={handleCahngeIds}
                    _ids={formik.values.selectedTeams}
                  />
                </div>
              )}
            </div>
          </div>
          {isCalendarVisible && (
            <div className="col-6">
              {/* <Team /> */}
              <div className="jobs">
                <div className="team-card" style={{ width: "100%" }}>
                  <Card className="" style={{ height: "140px" }}>
                    <CardHeader
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "none",
                        background: "none",
                      }}
                      className="team-header"
                    >
                      <h3
                        className="text-blue-color"
                        style={{ fontWeight: 600 }}
                      >
                        Team
                      </h3>
                      <div className="assignbtn">
                        <Button
                          className="bg-blue-color text-white-color "
                          outline
                          style={{
                            padding: "0 14px 0 14px",
                            fontSize: "12px",
                            marginTop: 0,
                            height: "32px",
                          }}
                          onClick={toggleDropdown}
                        >
                          + Assign
                        </Button>
                        {isDropdownOpen && (
                          <div
                            className="assigndrop"
                            style={{
                              position: "absolute",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              borderRadius: "4px",
                              marginTop: "10px",
                              padding: "10px",
                              zIndex: 1000,
                              width: "250px",
                              right: 0,
                            }}
                          >
                            <Card>
                              <CardHeader
                                className="text-white-color bg-blue-color"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                Select team
                                <CloseIcon
                                  onClick={toggleDropdown}
                                  style={{ cursor: "pointer" }}
                                />
                              </CardHeader>
                              <CardBody 
                                style={{
                                  // maxHeight: "250px",
                                  // overflowY: "auto",
                                  // scrollbarWidth: "thin",
                                  overflowY:"auto",
                                  height:"100px"
                                }}
                              >
                                <div onClick={handleOutsideClick}>
                                  {teamData && teamData.length > 0 ? (
                                    teamData.map((person) => (
                                      <FormGroup
                                        check
                                        className="my-3 mb-0"
                                        key={person._id}
                                      >
                                        <Input
                                          type="checkbox"
                                          checked={
                                            checkedState &&
                                            !!checkedState[person?.FullName]
                                          }
                                          onChange={(e) =>
                                            handleTeamSelect(e, person)
                                          }
                                        />
                                        <Label
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(6,49,100,0.7)",
                                            fontWeight: "400",
                                            marginBottom: 0,
                                          }}
                                        >
                                          {person.FullName}
                                        </Label>
                                      </FormGroup>
                                    ))
                                  ) : (
                                    <p>No team members found.</p>
                                  )}
                                </div>

                                {/* <React.Fragment> */}
                                <hr />

                                {/* </React.Fragment> */}
                              </CardBody>
                              <CardFooter>
                                <Button
                                  className="text-blue-color text-white-color text-white-color bg-button-blue-color"
                                  style={{
                                    border: "none",
                                    fontWeight: "400",
                                    padding: "8px",
                                    fontSize: "12px",
                                  }}
                                  onClick={toggleModal}
                                >
                                  + Create User
                                </Button>
                              </CardFooter>
                            </Card>
                            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                              <ModalHeader
                                toggle={toggleModal}
                                className="text-blue-color"
                              >
                                Add new user
                              </ModalHeader>
                              <ModalBody
                                className="nozindex"
                                style={{ borderBottom: "none" }}
                              >
                                <FormGroup>
                                  <Input
                                    name="FullName"
                                    placeholder="Full Name"
                                    type="text"
                                    className="text-blue-color w-100 mb-3  border-blue-color"
                                    value={formikTeam.values.FullName}
                                    onChange={formikTeam.handleChange}
                                    onBlur={formikTeam.handleBlur}
                                    invalid={
                                      formikTeam.touched.FullName &&
                                      Boolean(formikTeam.errors.FullName)
                                    }
                                  />
                                  {formikTeam.touched.FullName &&
                                  formikTeam.errors.FullName ? (
                                    <div className="text-danger">
                                      {formikTeam.errors.FullName}
                                    </div>
                                  ) : null}
                                </FormGroup>
                                <FormGroup>
                                  <Input
                                    name="EmailAddress"
                                    placeholder="Email"
                                    type="text"
                                    className="text-blue-color w-100 mb-3 border-blue-color"
                                    value={formikTeam.values.EmailAddress}
                                    onChange={formikTeam.handleChange}
                                    onBlur={formikTeam.handleBlur}
                                    invalid={
                                      formikTeam.touched.EmailAddress &&
                                      Boolean(formikTeam.errors.EmailAddress)
                                    }
                                  />
                                  {formikTeam.touched.EmailAddress &&
                                  formikTeam.errors.EmailAddress ? (
                                    <div className="text-danger">
                                      {formikTeam.errors.EmailAddress}
                                    </div>
                                  ) : null}
                                </FormGroup>
                                <FormGroup>
                                  <Input
                                    name="MobileNumber"
                                    placeholder="Mobile Number"
                                    type="text"
                                    className="text-blue-color w-100 mb-3 border-blue-color"
                                    value={formikTeam.values.MobileNumber}
                                    onChange={handlePhoneChange}
                                    onBlur={formikTeam.handleBlur}
                                    invalid={
                                      formikTeam.touched.MobileNumber &&
                                      Boolean(formikTeam.errors.MobileNumber)
                                    }
                                  />
                                  {formikTeam.touched.MobileNumber &&
                                  formikTeam.errors.MobileNumber ? (
                                    <div className="text-danger">
                                      {formikTeam.errors.MobileNumber}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="secondary"
                                  // onClick={toggleModal}
                                  onClick={() => {
                                    formikTeam.resetForm();
                                    toggleModal();
                                  }}
                                  className="text-blue-color outline-button-blue-color "
                                  style={{
                                    background: "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                                {loading ? (
                                  <div className="d-flex justify-content-center">
                                    <Circles
                                      height="20"
                                      width="20"
                                      color="#063164"
                                      ariaLabel="circles-loading"
                                      visible={loading}
                                    />
                                  </div>
                                ) : (
                                  <Button
                                    color="primary"
                                    onClick={formikTeam.handleSubmit}
                                    className="bg-blue-color text-white-color outline-button-blue-color"
                                  >
                                    Save User
                                  </Button>
                                )}
                              </ModalFooter>
                            </Modal>
                          </div>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div style={{ marginTop: "-10px", height: "18px" }}>
                        {selectedTeams.map((team, index) => (
                          <div
                            key={index}
                            className="tag"
                            style={{ marginTop: "0px", marginLeft: "10px" }}
                          >
                            <span
                              className="tag-text"
                              style={{ fontSize: "16px" }}
                            >
                              {team}
                            </span>
                            <button
                              className="tag-close"
                              onClick={() => handleRemoveTeam(team)}
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          )}

          {activeTab === 2 ? (
            <Card
              className="p-3 my-3 border-blue-color"
              style={{
                paddingRight: "30px",
                border: "1px solid ",
                marginBottom: "15px",
              }}
            >
              <h4 className="text-blue-color" style={{ fontWeight: 600 }}>
                Invoicing
              </h4>
              <div className="d-flex row">
                <div className="col-lg-5">
                  <div>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                      className="text-blue-color "
                    >
                      How do you want to invoice?
                    </p>
                    <div className="d-flex gap-4">
                      <div
                        className="text-blue-color"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          alignItems: "center",
                        }}
                      >
                        <Input type="radio" name="radio" value="yes" /> Per
                        Visit
                      </div>
                      <div
                        className="text-blue-color"
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          alignItems: "center",
                        }}
                      >
                        <Input type="radio" name="radio" value="no" /> Fixed
                        Price
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                      className="text-blue-color "
                    >
                      When do you want to invoice?
                    </p>
                    <div className="mt-1">
                      <Input
                        type="select text-blue-color"
                        style={{
                          fontSize: "14px",
                          border: "1px solid rgba(6, 49, 100, 30%)",
                          borderRadius: "10px",
                          fontSize: "12px",
                          width: "70%",
                        }}
                      >
                        <option value="">
                          Monthly on the last day of mont
                        </option>
                      </Input>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Label
                      className="my-2 text-blue-color"
                      for="exampleEmail"
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      Visits
                    </Label>
                    <div className="d-flex">
                      <div
                        className="col-3 text-blue-color"
                        style={{
                          borderRight: "0.5px solid rgba(6,49,100, 0.8)",
                        }}
                      >
                        <p
                          className="mb-0 text-blue-color"
                          style={{ fontSize: "12px", marginRight: "0px" }}
                        >
                          First
                        </p>
                        <p
                          className="mb-0 text-blue-color"
                          style={{ fontSize: "12px" }}
                        >
                          May 21, 2024
                        </p>
                      </div>
                      <div
                        className="col-3 mx-0"
                        style={{
                          borderRight: "0.5px solid rgba(6, 49, 100, 30%)",
                        }}
                      >
                        <p
                          className="mb-0 text-blue-color"
                          style={{ fontSize: "12px", marginLeft: "5px" }}
                        >
                          Last
                        </p>
                        <p
                          className="mb-0 text-blue-color"
                          style={{ fontSize: "12px", marginLeft: "5px" }}
                        >
                          May 21, 2024
                        </p>
                      </div>
                      <div className="col-3 mx-1">
                        <p
                          className="mb-0 text-blue-color"
                          style={{ fontSize: "12px" }}
                        >
                          Total
                        </p>
                        <p
                          className="mb-0 text-blue-color"
                          style={{ fontSize: "12px" }}
                        >
                          27
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div style={{ width: "100%" }}>
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginBottom: "0px",
                        }}
                        className="text-blue-color "
                      >
                        Automatically pay invoices
                      </p>
                      <div style={{ marginRight: "18%" }}>
                        <FormGroup switch className="my-3">
                          <Input type="switch" style={{ cursor: "pointer" }} />
                        </FormGroup>
                      </div>
                    </div>
                    <div>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          marginTop: "5px",
                        }}
                        className="text-blue-color "
                      >
                        Automatic payments will be enabled as soon as your
                        Customer adds a payment method. Learn more in{" "}
                        <span
                          style={{
                            fontSize: "16px",
                            textDecoration: "underline",
                          }}
                          className="text-blue-color "
                        >
                          Help Center
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "60%" }}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "0px",
                      }}
                      className="text-blue-color "
                    >
                      No payment methods on file
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        marginTop: "5px",
                      }}
                      className="text-blue-color "
                    >
                      Your customer can save a payment method when they make
                      their first payment.
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          ) : null}
          <Card
            className="p-3 my-4 border-blue-color"
            style={{
              paddingRight: "30px",
              border: "1px solid ",
              marginBottom: "15px",
            }}
          >
            <h4 className="text-blue-color" style={{ fontWeight: 600 }}>
              Line Items
            </h4>

            {lineItems.map((item, index) => (
              <React.Fragment key={index}>
                <GetProducts
                  item={item}
                  index={index}
                  handleSelectChange={handleSelectChange}
                  lineItems={lineItems}
                  setLineItems={setLineItems}
                  showCosts={showCosts}
                  setShowCosts={setShowCosts}
                  menuIsOpen={menuIsOpen}
                  setMenuIsOpen={setMenuIsOpen}
                  deleteLineItem={deleteLineItem}
                  // isError={isError}
                  // setIsError={setIsError}
                  // productRef={productRef}
                />
              </React.Fragment>
            ))}

            <div
              className="d-flex justify-content-between align-items-center mb-0 pb-0 newlineitem"
              style={{ marginTop: "20px", background: "none", border: "none" }}
            >
              <Button
                className="bg-button-blue-color addnewline text-white-color"
                outline
                style={{
                  padding: "0 14px 0 14px",
                  fontSize: "12px",
                  marginTop: 0,
                  height: "32px",
                  fontWeight: "400",
                }}
                onClick={addLineItem}
              >
                New Line Item
              </Button>
              <div>
                <div className="d-flex align-items-center  line-items-total ">
                  <p style={{ fontWeight: 600 }} className=" text-blue-color">
                    Total price
                  </p>
                  <p
                    className="mx-2 text-blue-color"
                    style={{ fontWeight: 600 }}
                  >
                    ${subTotal}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="col-7 mt-3  order-sm-2 ">
                <Table>
                  <tbody>
                    <tr>
                      <td
                        className="text-blue-color  "
                        style={{ height: "50px" }}
                      >
                        Subtotal
                      </td>
                      <td className="text-end text-blue-color">
                        ${subTotal?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-blue-color"
                        style={{ height: "50px" }}
                      >
                        Discount
                      </td>
                      <td
                        className={`d-flex ${
                          !showDiscount
                            ? "justify-content-end"
                            : "justify-content-between"
                        }`}
                      >
                        {showDiscount && (
                          <span
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <Input
                              id="Discount"
                              name="Discount"
                              placeholder="0.00"
                              className="text-blue-color"
                              style={{
                                fontSize: "14px",
                                paddingLeft: "15px",
                              }}
                              value={formik.values.Discount}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                              className="text-blue-color"
                            >
                              %
                            </span>
                          </span>
                        )}
                        <u
                          className="text-blue-color"
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            textAlign: "end",
                            marginBottom: "9px",
                          }}
                          onClick={() => setShowDiscount(!showDiscount)}
                        >
                          {discountAmount > 0
                            ? `$${discountAmount?.toFixed(2)}`
                            : "Add Discount"}
                        </u>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-blue-color"
                        style={{ height: "50px" }}
                      >
                        Tax
                      </td>
                      <td
                        className={`d-flex ${
                          !showTax
                            ? "justify-content-end"
                            : "justify-content-between"
                        }`}
                      >
                        {showTax && (
                          <span
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <Input
                              id="Tax"
                              name="Tax"
                              placeholder="0.00"
                              className="text-blue-color"
                              style={{
                                fontSize: "14px",
                                paddingLeft: "15px",
                              }}
                              value={formik.values.Tax}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <span
                              className="text-blue-color"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                            >
                              %
                            </span>
                          </span>
                        )}
                        <u
                          className="text-blue-color"
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            marginBottom: "9px",
                          }}
                          onClick={() => setShowTax(!showTax)}
                        >
                          {taxAmount > 0
                            ? `$${taxAmount?.toFixed(2)}`
                            : "Add Tax"}
                        </u>
                      </td>
                    </tr>
                    <tr className="last-row border-0">
                      <td className="border-0 text-blue-color">
                        <b>Total</b>
                      </td>
                      <td className="text-end border-0 text-blue-color">
                        <b>${Total}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Card>

          {/* <Team /> */}

          <InternalNotes
            Total={0}
            notes={formik.values.Notes}
            setNotes={(value) => formik.setFieldValue("Notes", value)}
            attachments={formik.values.Attachment}
            setAttachments={(value) =>
              formik.setFieldValue("Attachment", value)
            }
          />
          <div
            className="d-flex justify-content-between button-responsive"
            style={{ marginTop: "80px", gap: "10px" }}
          >
            <Button
              outline
              className="footer-buttons"
              style={{ fontSize: "16px" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            {/* <div>
              {customersData && Object.keys(customersData).length > 0 ? (
                //5206 upper code end: error shown
                loading ? (
                  <div className="d-flex justify-content-center text-blue-color ">
                    <Circles
                      height="20"
                      width="20"
                      color="#063164"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      visible={loading}
                    />
                  </div>
                ) : (
                  <div className="gap-3 d-flex sec-button-section">
                    <Button
                      outline
                      className="buttons outline-button-blue-color outline text-blue-color"
                      style={{ fontSize: "16px", height: "38px" }}
                      onClick={() => {
                        setLoading(true);
                        handleSaveQuote(false);
                      }}
                      //5206 code start:" change button at edit time"
                    >
                      {location.state?.id
                        ? " Update Contract"
                        : "Save Contract"}
                    </Button>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle className="bg-blue-color" caret>
                        Save &{" "}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => setMail(true)}>
                          Save & Send Mail
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )
              ) : (
                <Button
                  outline
                  className="buttons outline-button-blue-color outline selectclientaddquote bg-blue-color text-white-color 10"
                  style={{
                    fontSize: "16px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsCustomer(true);
                  }}
                >
                  Select Customer
                </Button>
              )}
            </div> */}
            <div>
              {customersData && Object.keys(customersData).length > 0 ? (
                loading ? (
                  <div className="d-flex justify-content-center text-blue-color">
                    <Circles
                      height="20"
                      width="20"
                      color="#063164"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      visible={loading}
                    />
                  </div>
                ) : (
                  <div className="gap-3 d-flex sec-button-section">
                    {/* <Button
                      outline
                      className="buttons outline-button-blue-color outline text-blue-color"
                      style={{ fontSize: "16px", height: "38px" }}
                      onClick={async (e) => {
                        e.preventDefault();
                        await formik.handleSubmit(); 

                        if (
                          Object.keys(formik.errors).length === 0 &&
                          formik.values.Title &&
                          formik.values.Description
                        ) {
                          setLoading(true);
                          await handleSaveQuote(false);
                        } else {
                    
                          toast.error("Please Fill Required Fields");
                        }
                      }}


                    >
                      {location.state?.id ? "Update Contract" : "Save Contract"}
                    </Button> */}
                    <Button
                      outline
                      className="buttons outline-button-blue-color outline text-blue-color"
                      style={{ fontSize: "16px", height: "38px" }}
                      onClick={async (e) => {
                        e.preventDefault();

                        const isValid = await formik.validateForm();
                        formik.setTouched({
                          Title: true,
                          Description: true,
                        });
                        if (
                          Object.keys(isValid).length === 0 &&
                          formik.values.Title) {
                          setLoading(true);
                          await handleSaveQuote(false);
                        } else {
                          toast.error("Please Fill Required Fields");
                        }
                      }}
                    >
                      {location.state?.id ? "Update Contract" : "Save Contract"}
                    </Button>

                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle className="bg-blue-color" caret>
                        Save &{" "}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => setMail(true)}>
                          Save & Send Mail
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )
              ) : (
                <Button
                  outline
                  className="buttons outline-button-blue-color outline selectclientaddquote bg-blue-color text-white-color 10"
                  style={{
                    fontSize: "16px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsCustomer(true);
                  }}
                >
                  Select Customer
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
      <CustomerModal
        isCustomer={isCustomer}
        setIsCustomer={setIsCustomer}
        isProperty={isProperty}
        setIsProperty={setIsProperty}
        setFieldValue={formik.setFieldValue}
        values={formik.values}
        lineItems={lineItems}
        propertyData={propertyData}
        setPropertyData={setPropertyData}
        customersData={customersData}
        setCustomersData={setCustomersData}
        formik={formik}
      />
      <ContractMail
        modal={mail}
        setModal={setMail}
        contractData={contractData}
        customerData={customersData}
        propertyData={propertyData}
        Attachment={formik?.values?.Attachment}
        Title={formik?.values?.Title}
        Total={Total}
        handleSubmit={handleSaveQuote}
      />
      <Toaster />
    </>
  );
};

export default AddContract;
