import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { Button } from "reactstrap";
import "./style.css";

const Summary = ({ formik, plan, loading }) => {
  function getPlanFrequencyDisplay(monthFrequency) {
    if (monthFrequency === "12 (Annually)") {
      return "year";
    } else if (monthFrequency === "1") {
      return `month`;
    } else {
      return `${monthFrequency?.split(" ")[0]} month`;
    }
  }

  function formatDate(date) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div className="mx-5 h-auto second-card">
      <Box
        sx={{ border: "1px solid ", borderRadius: "4px" }}
        className="space p-3 h-100 border-blue-color"
      >
        <div className="d-flex justify-content-between summary-main side">
          <h4 style={{ fontSize: "26px", fontWeight: 600 }}>Summary</h4>
          <h4 style={{ fontSize: "30px", fontWeight: 600 }}>
            {plan?.PlanName}
          </h4>
        </div>
        <hr
          style={{ background: "#2A4F61", color: "#2A4F61", height: "1px" }}
          className="mt-1"
        />
        <div className="">
          <div className="bill d-flex justify-content-between">
            <h4 style={{ fontSize: "17px", fontWeight: 600 }}>Billed</h4>
            <h4 style={{ fontSize: "17px", fontWeight: 600 }}>
              {formatDate(new Date())}
            </h4>
          </div>
          <div className="bill d-flex justify-content-between">
            <h4 style={{ fontSize: "17px", fontWeight: 600 }}>Price</h4>
            <h4 style={{ fontSize: "17px", fontWeight: 600 }}>
              ${Number(plan?.PlanPrice).toFixed(2)}/
              {getPlanFrequencyDisplay(plan?.MonthFrequency)}
            </h4>
          </div>
          <div className="d-flex justify-content-end">
            <h4
              style={{ fontSize: "15px", fontWeight: 400, color: "#06316499" }}
            >
              All prices are in $USD
            </h4>
          </div>
        </div>
        <hr
          style={{ background: "#2A4F61", color: "#2A4F61", height: "1px" }}
          className="mt-1"
        />
        <div className="d-flex justify-content-start note">
          <h4 style={{ fontSize: "13px", fontWeight: 400, color: "#06316499" }}>
            Payments are non-refundable and will automatically renew unless
            canceled prior to the end of the current billing cycle via your
            Account and Billing page.
          </h4>
        </div>
        <div className="d-flex justify-content-center mt-md-4">
          <Button
            className="bg-blue-color btn"
            color="white"
            style={{ fontSize: "18px", color: "white", marginBottom: "10px" }}
            onClick={formik.handleSubmit}
            disabled={loading} // Disable button while loading
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" /> // Loader
            ) : (
              "Add Subscription" // Button text
            )}
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default Summary;
