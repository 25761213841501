import React, { useEffect, useState } from "react";
import ReactSelect, { components } from "react-select";
import {
  Button,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { File, SingleFileUpload } from "../Files";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AxiosInstance from "../../Views/AxiosInstance";
import { Formik } from "formik";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import toast, { Toaster } from "react-hot-toast";
import CancelIcon from "@mui/icons-material/Close";
import { handleAuth } from "../Login/Auth";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InputText from "../InputFields/InputText";
import InputDropdown from "../InputFields/InputDropdown";
import { DisplayImage } from "../Files/DisplayFiles";
import AddItems from "../../Views/Admin/Setting/Product&service/AddItems";
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    padding: 0,
    margin: 0,
  }),
  control: (provided) => ({
    ...provided,
    height: "100%",
    border: "0",
    boxShadow: "none",
    padding: 0,
    margin: 0,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    padding: 0,
    margin: 0,
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "rgba(6, 49, 100, 0.2)" : "white",
    color: "black",
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: 0,
    margin: 0,
  }),
};

const CustomOption = ({ data, innerRef, innerProps, selectOption }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleSelectOption = (option) => {
    selectOption(option);
  };

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: isHovered ? "rgba(6, 49, 100, 0.2)" : "white",
        padding: "5px",
        zIndex: "9999",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        handleSelectOption(data);
      }}
    >
      <div style={{ fontWeight: "bold", paddingLeft: "10px" }}>{data.Name}</div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <div style={{ width: "90%" }}>{data.Description}</div>
        <div style={{ width: "10%" }}>
          {data.CostPerUnit || data.CostPerHour || data.CostPerSquare}
        </div>
      </div>
    </div>
  );
};

const GetProducts = ({
  item,
  index,
  handleSelectChange,
  lineItems,
  setLineItems,
  menuIsOpen,
  setMenuIsOpen,
  deleteLineItem,
  isError,
  productRef,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [tokenDecode, setTokenDecode] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setTokenDecode(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const getProducts = async () => {
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/product/get_products/${
          localStorage.getItem("CompanyId") || tokenDecode?.companyId
        }`
      );
      if (res.data.statusCode === 200) {
        const products = res.data.data;
        setGroupedOptions(products);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  useEffect(() => {
    getProducts();
  }, [tokenDecode]);

  const handleInputChange = (e, key) => {
    const newLineItems = [...lineItems];
    newLineItems[index][key] = e.target.value;
    if (key === "Unit" || key === "CostPerUnit") {
      newLineItems[index].Total =
        Number(newLineItems[index].Unit || 0) *
        Number(newLineItems[index].CostPerUnit || 0);
    } else if (key === "Hour" || key === "CostPerHour") {
      newLineItems[index].Total =
        Number(newLineItems[index].Hour || 0) *
        Number(newLineItems[index].CostPerHour || 0);
    } else if (
      key === "Square" ||
      key === "CostPerSquare" ||
      key === "Sq. Ft."
    ) {
      newLineItems[index].Total =
        Number(newLineItems[index].Square || 0) *
        Number(newLineItems[index].CostPerSquare || 0);
    }
    setLineItems(newLineItems);
  };

  const handleSearchInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = groupedOptions.filter((group) =>
    group.options.some(
      (option) =>
        option.Name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.Description.toLowerCase().includes(inputValue.toLowerCase())
    )
  );
  const [modelOpen, setModelOpen] = useState(false);
  const productsAndService = [{ Type: "Service" }, { Type: "Product" }];
  const [selectedProductAndService, setSelectedProductAndService] =
    useState(null);

  const UnitsAndHours = [
    { Type: "Unit" },
    { Type: "Hour" },
    { Type: "Sq. Ft." },
  ];
  const [selectedUnitsAndHours, setSelectedUnitsAndHours] = useState(null);
  const [showHoursSection, setShowHoursSection] = useState(false);
  const [showSquaresSection, setShowSquaresSection] = useState(false);
  const [showUnitsSection, setShowUnitsSection] = useState(false);
  useState(null);

  const [unitType, setUnitType] = useState("Hour");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [currentUnitType, setCurrentUnitType] = useState("");

  useEffect(() => {
    if (item.CostPerHour !== null && item.CostPerHour !== null) {
      setCurrentUnitType("Hour");
    } else if (item.CostPerUnit !== null && item.CostPerUnit !== null) {
      setCurrentUnitType("Unit");
    } else if (item.CostPerSquare !== null && item.CostPerSquare !== null) {
      setCurrentUnitType("Sq. Ft.");
    } else {
      setCurrentUnitType(unitType);
    }
  }, [item, unitType]);

  const handleDropdownToggle = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    if (value === "Square") {
      const newLineItems = [...lineItems];
      newLineItems[index].Hour = null;
      newLineItems[index].Unit = null;
      newLineItems[index].Square = 1;
    }
    if (value === "Hour") {
      const newLineItems = [...lineItems];
      newLineItems[index].Square = null;
      newLineItems[index].Unit = null;
      newLineItems[index].Hour = 1;
    }
    if (value === "Unit") {
      const newLineItems = [...lineItems];
      newLineItems[index].Square = null;
      newLineItems[index].Hour = null;
      newLineItems[index].Unit = 1;
    }
    setCurrentUnitType(value);
    setOpenDropdown(false);
  };

  return (
    <div
      className="row getproduct"
      style={{ paddingRight: "7px", paddingLeft: "7px" }}
    >
      <h6 style={{ fontSize: "16px", color: "#063164" }}>Product/ Service</h6>
      <div
        className="col-lg-5 col-md-12 my-3 "
        style={{ paddingRight: "3px", paddingLeft: "3px" }}
      >
        <div className="card border-blue-color" style={{ border: "none" }}>
          <CardHeader
            className="border-blue-color text-blue-color"
            style={{
              fontSize: "14px",
              // color: "#063164",
              backgroundColor: "#fff",
              padding: "6px",
              border: "1px solid",
              borderRadius: "10px",
            }}
          >
            <ReactSelect
              className="basic-single text-blue-color"
              classNamePrefix="select"
              ref={productRef}
              placeholder="Product/ Service"
              options={filteredOptions}
              components={{
                Option: (props) => (
                  <CustomOption
                    {...props}
                    selectOption={(option) => handleSelectChange(index, option)}
                  />
                ),
                NoOptionsMessage: () => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <span>No Data Found</span>
                    <Button
                      className="text-blue-color border-blue-color"
                      style={{
                        // color: "#063164",
                        backgroundColor: "#fff",
                        border: "1px solid ",
                        borderRadius: "10px",
                        fontSize: "12px",
                      }}
                      onClick={(e) => {
                        setModelOpen(true);
                      }}
                    >
                      Create New Product
                    </Button>
                  </div>
                ),
              }}
              styles={customStyles}
              value={groupedOptions
                .flatMap((group) => group.options)
                .find((option) => {
                  return option.Name === item?.Name;
                })}
              menuIsOpen={menuIsOpen[index]}
              onMenuOpen={() => {
                const newMenuState = [...menuIsOpen];
                newMenuState[index] = true;
                setMenuIsOpen(newMenuState);
              }}
              onMenuClose={() => {
                const newMenuState = [...menuIsOpen];
                newMenuState[index] = false;
                setMenuIsOpen(newMenuState);
              }}
              onInputChange={handleSearchInputChange}
            />
          </CardHeader>
          {isError && <div style={{ color: "red" }}> product is required!</div>}
          <TextField
            value={item.Description}
            onChange={(e) => handleInputChange(e, "Description")}
            name={`Description[${index}]`}
            label="Description"
            placeholder="Enter Description here..."
            type="text"
            className="text-blue-color w-100 mt-3"
            multiline
            rows={5}
            InputLabelProps={{
              shrink: Boolean(item.Description),
            }}
          />
        </div>
      </div>
      <div className="col-lg-3 col-md-4 my-3 qty">
        <FormGroup>
          <InputText
            value={
              currentUnitType === "Square" || currentUnitType === "Sq. Ft."
                ? 1
                : item[currentUnitType] || ""
            }
            onChange={(e) => handleInputChange(e, currentUnitType)}
            onInput={(e) => {
              if (e.target.value < 0) {
                e.target.value = 0;
                handleInputChange(e, currentUnitType);
              }
            }}
            label={currentUnitType}
            placeholder={`Enter ${currentUnitType} here...`}
            id={`qty_${index}`}
            name={`${currentUnitType}[${index}]`}
            type="number"
            className="text-blue-color w-100 mb-3"
            fieldHeight="56px"
            min="0"
            disable={
              currentUnitType === "Square" || currentUnitType === "Sq. Ft."
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle unit dropdown"
                  onClick={handleDropdownToggle}
                  edge="end"
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {openDropdown && (
            <InputText
              select
              value={currentUnitType}
              onChange={handleDropdownChange}
              className="text-blue-color w-100"
              fieldHeight="56px"
            >
              <MenuItem value="Unit">Unit</MenuItem>
              <MenuItem value="Hour" className="hour_dropdown">
                Hour
              </MenuItem>
              <MenuItem value="Sq. Ft.">Sq. Ft.</MenuItem>
            </InputText>
          )}
        </FormGroup>

        <FormGroup className={`mt-3 ${openDropdown && "pt-3"}`}>
          <File
            key={`file_${index}`}
            file={item.Attachment}
            index={index}
            setFile={(index, value) => {
              const newLineItems = [...lineItems];
              if (value === "") {
                newLineItems[index].Attachment = "";
              } else {
                newLineItems[index].Attachment = value;
              }
              setLineItems(newLineItems);
            }}
          />
        </FormGroup>
      </div>

      <div className="col-lg-2 col-md-4 my-1 px-1">
        <FormGroup
          style={{
            padding: "13px",
            borderRadius: "8px",
          }}
        >
          {currentUnitType === "Unit" ? (
            <FormGroup>
              <InputText
                label="Price"
                className="mb-0 border-blue-color totalNumber"
                id={`CostPerUnit${index}`}
                name={`CostPerUnit[${index}]`}
                placeholder="Cost"
                type="number"
                value={item.CostPerUnit || ""}
                fieldHeight="56px"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 0) {
                    e.target.value = 0;
                  }
                  handleInputChange(e, "CostPerUnit");
                }}
                min="0"
              />
            </FormGroup>
          ) : currentUnitType === "Square" ? (
            <FormGroup>
              <InputText
                label="Price"
                className="mb-0 border-blue-color totalNumber"
                id={`CostPerSquare${index}`}
                name={`CostPerSquare[${index}]`}
                placeholder="Cost"
                type="number"
                value={item.CostPerSquare || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 0) {
                    e.target.value = 0;
                  }
                  handleInputChange(e, "CostPerSquare");
                }}
                min="0"
              />
            </FormGroup>
          ) : currentUnitType === "Sq. Ft." ? (
            <FormGroup>
              <InputText
                label="Price"
                className="mb-0 border-blue-color totalNumber"
                id={`CostPerSquare${index}`}
                name={`CostPerSquare[${index}]`}
                placeholder="Cost"
                type="number"
                value={item.CostPerSquare || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 0) {
                    e.target.value = 0;
                  }
                  handleInputChange(e, "CostPerSquare");
                }}
                min="0"
              />
            </FormGroup>
          ) : (
            <FormGroup>
              <InputText
                label="Price"
                className="mb-0 border-blue-color totalNumber"
                id={`CostPerHour${index}`}
                name={`CostPerHour[${index}]`}
                placeholder="Cost"
                type="number"
                value={item.CostPerHour || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value < 0) {
                    e.target.value = 0;
                  }
                  handleInputChange(e, "CostPerHour");
                }}
                min="0"
              />
            </FormGroup>
          )}
        </FormGroup>
      </div>
      <div className="col-lg-2 col-md-4 my-3 px-1">
        <FormGroup>
          <InputText
            label="Total"
            className="mb-0 border-blue-color totalNumber"
            id={`total_${index}`}
            name={`Total[${index}]`}
            placeholder="Total"
            type="number"
            value={item.Total || ""}
            disabled={true} 
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#063164", 
                  borderRadius: "8px",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#063164", 
                },
              },
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                height: "100%",
                textAlign: "center",
                padding: "0 14px",
                boxSizing: "border-box",
              },
              "& .MuiInputLabel-root": {
                color: "#063164",
                fontSize: "15px",
              },
              "& .MuiOutlinedInput-root.Mui-disabled": {
                "& fieldset": {
                  borderColor: "#063164", 
                },
              },
            }}
          />
        </FormGroup>
        {lineItems.length > 1 && (
          <button
            className="deleteIcon"
            onClick={() => deleteLineItem(index)}
            style={{ color: "red", border: "none", background: "none" }}
          >
            <DeleteOutlineOutlinedIcon />
          </button>
        )}
      </div>
      <AddItems
        modelOpen={modelOpen}
        setModelOpen={setModelOpen}
        setSelectedProductAndService={setSelectedProductAndService}
        selectedProductAndService={selectedProductAndService}
        productsAndService={productsAndService}
        setShowUnitsSection={setShowUnitsSection}
        setShowHoursSection={setShowHoursSection}
        setSelectedUnitsAndHours={setSelectedUnitsAndHours}
        showHoursSection={showHoursSection}
        showUnitsSection={showUnitsSection}
        selectedUnitsAndHours={selectedUnitsAndHours}
        UnitsAndHours={UnitsAndHours}
        CompanyId={localStorage.getItem("CompanyId") || tokenDecode?.companyId}
        getData={getProducts}
        showSquaresSection={showSquaresSection}
        setShowSquaresSection={setShowSquaresSection}
        inputValue={inputValue}
        handleSelectChange={handleSelectChange}
        index={index}
        setNew={true}
      />
    </div>
  );
};

export default GetProducts;
