import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, CardText } from "reactstrap";
import "./style.css";
import { Circles } from "react-loader-spinner";
import AxiosInstance from "../../AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function Index() {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();

  const [plans, setPlans] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await AxiosInstance.get(`${baseUrl}/plan`);

        if (response.status === 200 && response.data.data) {
          const mostPopularItem = response.data.data.find(
            (item) => item.IsMostpopular
          );
          const otherItems = response.data.data.filter(
            (item) => !item.IsMostpopular
          );
          const middleIndex = Math.floor(otherItems.length / 2);
          const sortedItems = [
            ...otherItems.slice(0, middleIndex),
            mostPopularItem,
            ...otherItems.slice(middleIndex),
          ];
          setPlans(sortedItems.filter(plan => plan)); // Filter out undefined plans
        } else {
          console.error("Failed to fetch data", response);
        }
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      } finally {
        setLoader(false);
      }
    };

    fetchPlans();
  }, []);

  const mostPopularIndex = plans.findIndex((plan) => plan?.IsMostpopular);
  let reorderedPlans = [...plans];
  if (mostPopularIndex !== -1) {
    const [mostPopularPlan] = reorderedPlans.splice(mostPopularIndex, 1);
    reorderedPlans.splice(1, 0, mostPopularPlan);
  }

  function getPlanFrequencyDisplay(monthFrequency) {
    if (monthFrequency === "12 (Annually)") {
      return "year";
    } else if (monthFrequency === "1") {
      return `month`;
    } else {
      return `${monthFrequency?.split(" ")[0]} month`;
    }
  }

  return (
    <div className="plan d-flex flex-column justify-content-center py-lg-5">
      <div className="d-flex justify-content-center Title">
        <h3
          className="text-blue-color"
          style={{ fontWeight: 700, fontSize: "38px" }}
        >
          Select a plan
        </h3>
      </div>
      <div className="d-flex justify-content-center">
        <p
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "28.8px",
            textAlign: "center",
          }}
          className="my-3 mb-3 Heading text-blue-color"
        >
          Compare the plans to determine which one is most suitable for your
          business.
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <p
          className="my-3 text-blue-color"
          style={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}
        >
          Unlock significant savings with an annual subscription.
        </p>
      </div>
      <div
        className="card-plan d-flex flex-wrap justify-content-center"
        style={{
          marginTop: "30px",
          width: "100%",
          gap: "1%",
        }}
      >
        {plans.length === 0 ? (
          <div
            className="d-flex flex-direction-row justify-content-center align-items-center p-5 "
            style={{ marginTop: "8%" }}
          >
            <Circles
              height="50"
              width="50"
              color="#063164"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loader}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            {reorderedPlans.map((plan, index) =>
              plan ? ( 
                <div
                  key={plan?._id}
                  className={plan?.IsMostpopular ? "mostpopularcard" : ""}
                >
                  <Card
                    className={` ${index === 0 ? "first-card" : "first-card"}`}
                    style={{
                      width: "18rem",
                      borderRadius: "50px",
                      ...(plan?.IsMostpopular ? {} : {}),
                      marginTop: plan?.IsMostpopular ? "0px" : "50px",
                      height: plan?.IsMostpopular ? "auto" : "",
                    }}
                  >
                    <CardHeader
                      className={`text-center ${
                        plan?.IsMostpopular ? "bg-blue-color" : "text-blue-color"
                      }`}
                      style={{
                        fontWeight: 700,
                        fontSize: "24px",
                        background: "none",
                        border: "none",
                        borderTopLeftRadius: plan?.IsMostpopular ? "50px" : "",
                        borderTopRightRadius: plan?.IsMostpopular ? "50px" : "",
                      }}
                    >
                      {plan?.IsMostpopular && (
                        <p className="mb-0 my-0">
                          <i
                            className="text-white-color"
                            style={{
                              fontStyle: "Sarabun italic",
                              fontWeight: "400",
                              fontSize: "24px",
                            }}
                          >
                            Most Popular
                          </i>
                        </p>
                      )}
                      <p
                        className={`mb-0 ${
                          plan?.IsMostpopular
                            ? "text-white-color"
                            : "text-blue-color"
                        }`}
                        style={{
                          color: plan?.IsMostpopular ? "#063164" : "",
                          fontWeight: plan?.IsMostpopular ? 700 : "",
                          fontSize: "24px",
                        }}
                      >
                        {plan?.PlanName}
                      </p>
                    </CardHeader>
                    <CardBody className="text-center">
                      <CardText
                        className="text-blue-color"
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",

                        fontFamily: "Poppins",
                        lineHeight: "24px",
                      }}
                    >
                      {plan?.PlanDetail}
                    </CardText>
                    <hr />
                    <CardText
                      className="text-blue-color"
                      style={{
                        fontWeight: 600,
                        fontSize: "24px",
                        fontFamily: "Poppins",
                        lineHeight: "24px",
                      }}
                    >
                      ${Number(plan?.PlanPrice).toFixed(2)}/
                      {getPlanFrequencyDisplay(plan?.MonthFrequency)}
                      <br />
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: 300,
                          marginTop: "5px",
                        }}
                      >
                        $
                        {Number(
                          Number(plan?.PlanPrice) /
                            Number(plan?.MonthFrequency?.split(" ")[0])
                        ).toFixed(2)}
                        /month
                      </span>
                    </CardText>
                    <Button
                      className="text-blue-color outline"
                      outline
                      style={{
                        marginTop: "35px",
                        marginBottom: "20px",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                        padding: "3px 10px",
                        fontWeight: "500",
                        fontSize: "20px",
                        // backgroundColor: plan?.IsMostpopular
                        //   ? "#063164"
                        //   : "none",
                        color: plan?.IsMostpopular ? "#fff" : "",
                      }}
                      onClick={() => {
                        if (!location.state?.plan) {
                          if (companyName) {
                            navigate(`/${companyName}/plan-purchase`, {
                              state: {
                                navigats: [
                                  ...location?.state?.navigats,
                                  "/plan-purchase",
                                ],
                                plan,
                                card: location.state?.card || "",
                              },
                            });
                          } else {
                            navigate("/plan-purchase", {
                              state: {
                                plan,
                              },
                            });
                          }
                        }
                      }}
                    >
                      {location?.state?.plan ? "Upgrade Plan" : "Select Plan"}
                    </Button>
                    <div style={{ display: plan?.IsMostpopular ? "" : "none" }}>
                      <p className="my-2 choose_connect">
                        <i
                          className="text-blue-color"
                          style={{ fontSize: "12px" }}
                        >
                          <b>40% of Security and Alarm businesses</b>
                          <br />
                          <span className="text-blue-color">
                            choose Connect
                          </span>
                        </i>
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ): null
          )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
