import React, { useEffect, useState, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Box,
  Popover,
} from "@mui/material";
import AxiosInstance from "../../../AxiosInstance";
import { Watch } from "react-loader-spinner";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Customer from "../../../../assets/Blue-sidebar-icon/Customer.svg";
import Quote from "../../../../assets/Blue-sidebar-icon/Quote.svg";
import Contract from "../../../../assets/Blue-sidebar-icon/Contract.svg";
import Invoice from "../../../../assets/Blue-sidebar-icon/Invoice.svg";
import Appointment from "../../../../assets/Blue-sidebar-icon/Appoinment-blue.svg";

const localizer = momentLocalizer(moment);

function Schedual() {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const calendarRef = useRef(null);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [clickedDate, setClickedDate] = useState(null);

  const fetchSheduleData = async () => {
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/quote/shedule/${localStorage.getItem("CompanyId")}`
      );
      const scheduleData = res.data.data;
      const mappedEvents = scheduleData.map((item) => ({
        id: item._id,
        QuoteId: item.QuoteId,
        Title: item.Title,
        QuoteNumber: item.QuoteNumber,
        FirstName: item.FirstName,
        LastName: item.LastName,
        Address: item.Address,
        City: item.City,
        State: item.State,
        Country: item.Country,
        Zip: item.Zip,
        start: new Date(item.sheduleDate),
        end: new Date(item.sheduleDate),
        allDay: true,
      }));
      return mappedEvents;
    } catch (error) {
      console.error("Error:", error.message);
      return [];
    }
  };

  const fetchContractScheduleData = async () => {
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/contract/contract-schedule/${localStorage.getItem(
          "CompanyId"
        )}`
      );
      const contractData = res.data.data;
      const mappedContractEvents = contractData.map((contract) => ({
        ContractId: contract.ContractId,
        Title: contract.Title,
        ContractNumber: contract.ContractNumber,
        FirstName: contract.FirstName,
        LastName: contract.LastName,
        Address: contract.Address,
        City: contract.City,
        State: contract.State,
        Country: contract.Country,
        Zip: contract.Zip,
        Status: contract.Status,
        start: new Date(),
        end: new Date(),
      }));

      return mappedContractEvents;
    } catch (error) {
      console.error("Error fetching contract schedule:", error.message);
      return [];
    }
  };

  const fetchVisitScheduleData = async () => {
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/visits/visit-schedule/${localStorage.getItem("CompanyId")}`
      );
      const visitData = res.data.data;
      const mappedVisitEvents = visitData.map((visit) => ({
        VisitId: visit.VisitId,
        VisitContractId: visit.ContractId,
        Title: visit.ItemName,
        Note: visit.Note,
        FirstName: visit.FirstName,
        LastName: visit.LastName,
        Address: visit.Address,
        City: visit.City,
        State: visit.State,
        Zip: visit.Zip,
        Country: visit.Country,
        start: new Date(visit.StartDate),
        end: new Date(visit.EndDate),
        IsConfirm: visit.IsConfirm,
        IsConfirmByWorker: visit.IsConfirmByWorker,
        IsDelete: visit.IsDelete,
      }));

      return mappedVisitEvents;
    } catch (error) {
      console.error("Error fetching contract schedule:", error.message);
      return [];
    }
  };
  const fetchInvoiceScheduleData = async () => {
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/invoice/invoiceShedule/${localStorage.getItem("CompanyId")}`
      );
      const invoiceData = res.data.data;
      const mappedInvoiceEvents = invoiceData.map((item) => ({
        id: item._id,
        InvoiceId: item.InvoiceId,
        FirstName: item.FirstName,
        LastName: item.LastName,
        Address: item.Address,
        City: item.City,
        State: item.State,
        Zip: item.Zip,
        Country: item.Country,
        Title: item.Title,
        InvoiceNumber: item.InvoiceNumber,
        start: new Date(item.sheduleDate),
        end: new Date(item.sheduleDate),
        allDay: true,
      }));
      return mappedInvoiceEvents;
    } catch (error) {
      console.error("Error fetching invoice schedule:", error.message);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const [scheduleEvents, contractEvents, InvoiceEvents, visitEvents] =
        await Promise.all([
          fetchSheduleData(),
          fetchContractScheduleData(),
          fetchInvoiceScheduleData(),
          fetchVisitScheduleData(),
        ]);
      setEvents([
        ...scheduleEvents,
        ...contractEvents,
        ...InvoiceEvents,
        ...visitEvents,
      ]);
      setLoading(false);
    };
    fetchData();
  }, []);

  const eventPropGetter = (event) => ({
    style: {
      border: "none",
      color: "black",
      display: "block",
    },
  });
  const CustomEvent = ({ event }) => (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px 12px",
        border: "1px solid #e0e0e0",
        borderRadius: "4px",
        backgroundColor: "#f9f9f9",
        transition: "background-color 0.2s",
        cursor: "pointer",
        fontSize: "10px",
        margin: "4px 0",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleEventClick(event);
      }}
    >
      {event.QuoteId && (
        <img
          src={Quote}
          alt="Quote"
          style={{
            width: 16,
            height: 16,
            marginRight: 8,
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        />
      )}
      {event.ContractId && (
        <img
          src={Contract}
          alt="Contract"
          style={{
            width: 16,
            height: 16,
            marginRight: 8,
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        />
      )}
      {event.InvoiceId && (
        <img
          src={Invoice}
          alt="Invoice"
          style={{
            width: 16,
            height: 16,
            fontSize: "10px",
            marginRight: 8,
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        />
      )}
      {event.VisitId && (
        <img
          src={Appointment}
          alt="Appointment"
          style={{
            width: 16,
            height: 16,
            marginRight: 8,
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        />
      )}
      <strong
        style={{
          fontWeight: "bold",
          color: "#333",
        }}
      >
        {event.QuoteNumber && `(Quote #${event.QuoteNumber}) `}
        {event.ContractNumber && `(Contract #${event.ContractNumber}) `}
        {event.InvoiceNumber && `(Invoice #${event.InvoiceNumber}) `}
        {event.FirstName} {event.LastName} - {event.Title}
      </strong>
    </span>
  );

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setSelectedEvent(null);
    setAnchorPosition(null);
  };
  const handleSlotClick = ({ start }) => {
    const hasEvent = events.some((event) =>
      moment(event.start).isSame(start, "day")
    );

    if (!hasEvent) {
      setClickedDate(start);
      const { x, y } = mousePosition;
      setAnchorPosition({ top: y, left: x });
    }
  };

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = (event) => {
    const { clientX, clientY } = event;
    setMousePosition({ x: clientX, y: clientY });
  };

  const handlePopoverClose = () => {
    setAnchorPosition(null);
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "1500px",
        margin: "0 auto",
        padding: "20px",
        backgroundColor: "#f8f9fa",
        borderRadius: "12px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ position: "relative" }} ref={calendarRef}>
        <div
          style={{
            position: "relative",
            filter: loading ? "blur(2px)" : "none",
            opacity: loading ? 0.5 : 1,
            width: "100%",
          }}
          onMouseDown={handleMouseDown}
        >
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "600px", width: "100%", overflowY: "auto" }}
            selectable
            views={["month"]}
            components={{
              event: CustomEvent,
            }}
            eventPropGetter={eventPropGetter}
            onSelectEvent={handleEventClick}
            onSelectSlot={handleSlotClick}
            popup={true}
          />
        </div>
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Watch
              visible={true}
              height="50"
              width="50"
              radius="48"
              color="#063164"
              ariaLabel="watch-loading"
            />
          </div>
        )}
      </div>

      <Popover
        open={Boolean(anchorPosition)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handlePopoverClose}
        PaperProps={{
          style: {
            position: "absolute",
            border: "1px solid #ccc",
            borderRadius: "8px",
            background: "linear-gradient(135deg, #f0f4f8, #ffffff)",
            boxShadow: "0 6px 12px rgba(0,0,0,0.2)",
          },
        }}
      >
        <Box p={2} style={{ width: "270px" }}>
          <Typography
            variant="h7"
            style={{ fontWeight: "bold", color: "#333", textAlign: "center" }}
          >{`${moment(clickedDate).format("MMMM Do YYYY")}`}</Typography>

          <Divider
            style={{
              margin: "12px 0",
              backgroundColor: "#333",
              height: "1px",
              width: "100%",
            }}
          />

          <Typography
            variant="body1"
            style={{
              cursor: "pointer",
              marginTop: "12px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              padding: "4px",
            }}
            className="text-blue-color"
            onClick={() => {
              navigate(`/${companyName}/add-customer`, {
                state: {
                  navigats: [...location.state.navigats, "/add-customer"],
                },
              });
            }}
          >
            <span style={{ marginRight: "8px" }}>
              <img src={Customer} />
            </span>
            New Customer
          </Typography>

          <Typography
            variant="body1"
            style={{
              cursor: "pointer",
              marginTop: "12px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              padding: "4px",
            }}
            className="text-blue-color"
            onClick={() => {
              navigate(`/${companyName}/add-quotes`, {
                state: {
                  navigats: [...location.state.navigats, "/add-quotes"],
                },
              });
            }}
          >
            <span style={{ marginRight: "8px" }}>
              <img src={Quote} />
            </span>
            New Quotes
          </Typography>

          <Typography
            variant="body1"
            style={{
              cursor: "pointer",
              marginTop: "12px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              padding: "4px",
            }}
            className="text-blue-color"
            onClick={() => {
              navigate(`/${companyName}/add-contract`, {
                state: {
                  navigats: [...location.state.navigats, "/add-contract"],
                  startDate: moment(clickedDate).format("MM-DD-YYYY"),
                },
              });
            }}
          >
            <span style={{ marginRight: "8px" }}>
              <img src={Contract} />
            </span>
            New Contract
          </Typography>

          <Typography
            variant="body1"
            style={{
              cursor: "pointer",
              marginTop: "12px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              padding: "4px",
            }}
            className="text-blue-color"
            onClick={() => {
              navigate(`/${companyName}/invoice`, {
                state: {
                  navigats: [...location.state.navigats, "/invoice"],
                },
              });
            }}
          >
            <span style={{ marginRight: "8px" }}>
              <img src={Invoice} />
            </span>
            New Invoice
          </Typography>
        </Box>
      </Popover>

      {selectedEvent && (
        <Dialog
          open={!!selectedEvent}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: { width: "90%", maxWidth: "600px", borderRadius: "12px" },
          }}
        >
          <DialogContent dividers className="bg-orange-color">
            <Box>
              <Typography variant="h6" style={{ color: "#fff" }}>
                {moment(selectedEvent.start).isSame(moment(), "day")
                  ? "Today"
                  : moment(selectedEvent.start).isAfter(moment())
                  ? "Upcoming Reminder"
                  : "Past Reminder"}
              </Typography>
              <Typography variant="subtitle1" style={{ color: "#fff" }}>
                {moment(selectedEvent.start).format("MMMM Do YYYY, h:mm A")}
              </Typography>
            </Box>
          </DialogContent>
          <DialogContent dividers>
            <FormControlLabel control={<Checkbox />} label="Completed" />
            <Divider />
            <Box my={2}>
              <Typography variant="h6">Details</Typography>
              <Typography>
                {selectedEvent.QuoteId
                  ? `${selectedEvent.FirstName} ${selectedEvent.LastName} - Quote #${selectedEvent.QuoteNumber}`
                  : selectedEvent.ContractId
                  ? `${selectedEvent.FirstName} ${selectedEvent.LastName} - Contract #${selectedEvent.ContractNumber}`
                  : selectedEvent.VisitId
                  ? `${selectedEvent.FirstName} ${selectedEvent.LastName} - ${selectedEvent.Title}`
                  : `${selectedEvent.FirstName} ${selectedEvent.LastName} - Invoice #${selectedEvent.InvoiceNumber}`}
              </Typography>
              <Typography>
                {selectedEvent.QuoteId
                  ? `Quote was sent on ${moment(selectedEvent.start).format(
                      "MMMM Do YYYY"
                    )} but no job has been generated yet`
                  : selectedEvent.ContractId
                  ? `Contract was created on ${moment(
                      selectedEvent.start
                    ).format("MMMM Do YYYY")}`
                  : selectedEvent.VisitId
                  ? `Visit was created on ${moment(selectedEvent.start).format(
                      "MMMM Do YYYY"
                    )}`
                  : `Invoice was issued on ${moment(selectedEvent.start).format(
                      "MMMM Do YYYY"
                    )}`}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="h6">Location</Typography>
              <Typography>
                {selectedEvent.Address ? `${selectedEvent.Address}, ` : null}
                {selectedEvent.City}, {selectedEvent.State} {selectedEvent.Zip}{" "}
                - {selectedEvent.Country}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            {!selectedEvent.VisitId && (
              <Button
                onClick={() => {
                  const id =
                    selectedEvent.QuoteId ||
                    selectedEvent.ContractId ||
                    selectedEvent.InvoiceId;
                  const navigateTo = selectedEvent.QuoteId
                    ? `/${companyName}/add-quotes`
                    : selectedEvent.ContractId
                    ? `/${companyName}/add-contract`
                    : `/${companyName}/addinvoice`;

                  navigate(navigateTo, {
                    state: {
                      ...(selectedEvent.InvoiceId ? {} : { id: id }),
                      invoiceId: selectedEvent.InvoiceId,
                      navigats: [
                        ...(location.state?.navigats || []),
                        navigateTo,
                      ],
                    },
                  });
                }}
              >
                Edit
              </Button>
            )}

            <Button
              onClick={() => {
                const id =
                  selectedEvent.VisitContractId ||
                  selectedEvent.QuoteId ||
                  selectedEvent.ContractId ||
                  selectedEvent.InvoiceId;

                const navigateTo = selectedEvent.VisitContractId
                  ? `/${companyName}/contractdetails`
                  : selectedEvent.QuoteId
                  ? `/${companyName}/quotes-detail`
                  : selectedEvent.ContractId
                  ? `/${companyName}/contractdetails`
                  : `/${companyName}/invoice-details`;

                navigate(navigateTo, {
                  state: {
                    id: id,
                    navigats: [...(location.state?.navigats || []), navigateTo],
                  },
                });
              }}
              className="bg-blue-color text-white-color"
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
              }}
            >
              View Details
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
export default Schedual;
