import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
} from "../../../components/MuiTable/index.js";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import "../style.css";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import AxiosInstance from "../../AxiosInstance.js";

const WorkerCustomer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customerData, setCustomerData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const [companyId, setCompanyId] = useState("");

  const fetchTokenData = async () => {
    if (!companyId) {
      try {
        const baseUrl = process.env.REACT_APP_BASE_API;
        const token =
          localStorage.getItem("adminToken") ||
          localStorage.getItem("workerToken");

        if (!token) {
          console.error("Token not found in localStorage");
          return;
        }
        const res = await AxiosInstance.post(`${baseUrl}/company/token_data`, {
          token,
        });
        if (res.data) {
          setCompanyId(res.data.data.companyId);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchTokenData();
  }, []);

  const [staffData, setStaffData] = useState(undefined);
  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        const response = await AxiosInstance.get(
          `${baseUrl}/worker/get/${localStorage.getItem("worker_id")}`
        );

        setStaffData(response.data.data.permissions);
      } catch (error) {}
    };
    if (location.pathname.includes("/staff-member")) {
      fetchStaffData();
    }
  }, []);

  const getData = async () => {
    if (companyId) {
      try {
        const res = await AxiosInstance.get(`/customer/get/${companyId}`, {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        });
        setCustomerData(res.data.data);
        setCountData(res.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search, companyId]);

  const handleEditClick = (id) => {
    if (id) {
      navigate("/staff-member/add-customer", {
        state: { id, navigats: [...location.state.navigats, "/add-customer"] },
      });
    }
  };

  // Delete
  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(`/customer/${id}`);
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            toast.error("", response.data.message, "error");
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
        }
      }
    });
  };

  const cellData = customerData?.map((item, index) => {
    const properties = item?.location || [];

    let propertyDisplay;
    if (properties.length === 1) {
      const property = properties[0];
      propertyDisplay = `${property.Address || ""}`;
    } else {
      propertyDisplay = `${properties.length} ${
        properties.length > 1 ? "Properties" : "Property"
      }`;
    }

    return staffData?.CustomersProperties
      ?.ViewAndEditFullCustomerAndPropertyInfo ||
      staffData?.CustomersProperties
        ?.ViewEditAndDeleteFullCustomerAndPropertyInfo
      ? {
          key: item?.CustomerId,
          value: [
            page * rowsPerPage + index + 1,
            `${item?.FirstName} ${item?.LastName}`,
            // item.companyName,
            item.EmailAddress,
            properties.length === 1 ? (
              propertyDisplay
            ) : (
              <span>{propertyDisplay}</span>
            ),
            <IconButton>
              <img
                src={Edit}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditClick(item.CustomerId);
                }}
              />
              {staffData?.CustomersProperties
                ?.ViewEditAndDeleteFullCustomerAndPropertyInfo && (
                <img
                  className="mx-1"
                  src={Delete}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(item.CustomerId);
                  }}
                />
              )}
            </IconButton>,
          ],
        }
      : {
          key: item?.CustomerId,
          value: [
            page * rowsPerPage + index + 1,
            `${item?.FirstName} ${item?.LastName}`,
            // item.companyName,
            item.EmailAddress,
            properties.length === 1 ? (
              propertyDisplay
            ) : (
              <span>{propertyDisplay}</span>
            ),
          ],
        };
  });

  return (
    <>
      <div className="justify-content-center align-items-center mb-3">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h3 className="text-blue-color" style={{ fontWeight: 700 }}>
            Customer
          </h3>
          {(staffData?.CustomersProperties
            ?.ViewAndEditFullCustomerAndPropertyInfo ||
            staffData?.CustomersProperties
              ?.ViewEditAndDeleteFullCustomerAndPropertyInfo) && (
            <Button
              onClick={() => {
                navigate(`/staff-member/add-customer`, {
                  state: {
                    navigats: [...location.state.navigats, "/add-customer"],
                  },
                });
              }}
              className="text-capitalize bg-button-blue-color text-white-color"
            >
              Add Customer
            </Button>
          )}
        </div>
        <Card
        className="border-blue-color"
          style={{
            borderRadius: "20px",
            border: "2px solid ",
            padding: 0,
          }}
        >
          <CardHeader
            className="d-flex justify-content-between border-blue-color align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid ",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="custe text-light">Customer</h5>
            <div className="customer d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparant", color: "white" }}
              />
            </div>
          </CardHeader>
          {loader ? (
            <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
              <Circles
                height="50"
                width="50"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
                color="#063164"
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={
                  staffData?.CustomersProperties
                    ?.ViewAndEditFullCustomerAndPropertyInfo ||
                  staffData?.CustomersProperties
                    ?.ViewEditAndDeleteFullCustomerAndPropertyInfo
                    ? [
                        "Sr No.",
                        "Full Name ",
                        // "Company Name",
                        "Email",
                        "Address",
                        "Action",
                      ]
                    : [
                        "Sr No.",
                        "Full Name ",
                        // "Company Name",
                        "Email",
                        "Address",
                      ]
                }
                cellData={cellData}
                isCollapse={false}
                page={page}
                isNavigate={true}
                navigatePath={`/staff-member/WorkerCustomerDetail`}
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color border-blue-color"
            style={{
              borderTop: "2px solid ",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
      </div>
      <Toaster />
    </>
  );
};

export default WorkerCustomer;
