import React, { useEffect, useState } from "react";
import "../Client/style.css";
import { handleAuth } from "../../../components/Login/Auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postFile } from "../../../components/Files/Functions";
import AxiosInstance from "../../AxiosInstance";
import toast from "react-hot-toast";
import AddContractView from "./views/AddContract";

function AddContract() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const { companyName } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const [isProperty, setIsProperty] = useState(false);
  const [isNumChange, setIsNumChange] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = React.useState(true);
  const [propertyData, setPropertyData] = useState({});
  const [contractData, setContractData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [isCustomer, setIsCustomer] = useState(false);
  const [customersData, setCustomersData] = useState({});
  const [lineItems, setLineItems] = useState([
    {
      Description: "",
      Name: "",
      Attachment: "",
      Type: "",
      Unit: "",
      CostPerUnit: "",
      Hour: "",
      CostPerHour: "",
      Square: "",
      CostPerSquare: "",
      Total: "",
      isNew: true,
    },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { startDate } = location.state || {};
  const formik = useFormik({
    initialValues: {
      Title: "",
      QuoteId: "",
      Description: "",
      ContractNumber: 1,
      CustomerId: "",
      CompanyId: localStorage.getItem("CompanyId"),
      LocationId: "",
      contract_disclaimer:
        "Contract/ Disclaimer\nThis quote is valid for the next 30 days, after which values may be subject to change.",
      Notes: "",
      Attachment: [],
      OneoffJob: {
        StartDate: startDate || "",
        EndDate: "",
        StartTime: "",
        EndTime: "",
        ScheduleLetter: false,
        Repeats: "Weekly",
      },
      RecuringJob: {
        StartDate: "",
        StartTime: "",
        EndTime: "",
        Repeats: "",
        Duration: "",
        Frequency: "",
      },
      selectedTeams: [],
      subTotal: "",
    },
    validateOnChange: false, // Only validate on form submit or blur
    validateOnBlur: false, // Disable validation on blur if it's too slow
    validationSchema: Yup.object({
      Title: Yup.string().required("Required"),
      // Description: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const attachmentURLs = [];
        for (let file of formik.values.Attachment) {
          if (typeof file !== "string") {
            const uploadedFilePath = await postFile(file);
            attachmentURLs.push(uploadedFilePath);
          } else {
            attachmentURLs.push(file);
          }
        }

        for (let item of lineItems) {
          if (typeof item?.Attachment !== "string") {
            const string = await postFile(item.Attachment);
            item.Attachment = string;
          }
        }
        const object = {
          ...values,
          Attachment: attachmentURLs,
          products: lineItems,
          selectedTeams: selectedTeams,
          IsOneoffJob: activeTab === 1 ? true : false,
          IsRecuringJob: activeTab === 2 ? true : false,
          Total: Total,
          subTotal: subTotal,
          WorkerId: workerId,
          Frequency: values?.RecuringJob?.Frequency,
          Duration: values?.RecuringJob?.Duration,
        };

        if (activeTab === 1) {
          delete object.IsRecuringJob;
        } else if (activeTab === 2) {
          delete object.OneoffJob;
        }

        let response;
        if (!location.state?.id) {
          response = await AxiosInstance.post(`/contract`, object);
        } else {
          response = await AxiosInstance.put(
            `/contract/${location.state?.id}`,
            object
          );
        }
        if (response.data.statusCode === 200) {
          setTimeout(() => {
          toast.success(response.data.message);
        }, 500);
          navigate(`/${companyName}/contract`, {
            state: {
              navigats: ["/index", "/contract"],
            },
          });
        }
        return response.data;
      } catch (error) {
        console.error("Error: ", error);
      } finally {
        setLoading(false);
      }
    },
  });


  // const formik = useFormik({
  //   initialValues: {
  //     Title: "",
  //     QuoteId: "",
  //     Description: "",
  //     ContractNumber: 1,
  //     CustomerId: "",
  //     CompanyId: localStorage.getItem("CompanyId"),
  //     LocationId: "",
  //     contract_disclaimer:
  //       "Contract/ Disclaimer\nThis quote is valid for the next 30 days, after which values may be subject to change.",
  //     Notes: "",
  //     Attachment: [],
  //     OneoffJob: {
  //       StartDate: startDate || "",
  //       EndDate: "",
  //       StartTime: "",
  //       EndTime: "",
  //       ScheduleLetter: false,
  //       Repeats: "Weekly",
  //     },
  //     RecuringJob: {
  //       StartDate: "",
  //       StartTime: "",
  //       EndTime: "",
  //       Repeats: "",
  //       Duration: "",
  //       Frequency: "",
  //     },
  //     selectedTeams: [],
  //     subTotal: "",
  //   },
  //   validationSchema: Yup.object({
  //     Title: Yup.string(), // Made optional
  //     Description: Yup.string(), // Made optional
  //   }),

  //   onSubmit: async (values) => {
  //     try {
  //       setLoading(true);

  //       const attachmentURLs = [];
  //       for (let file of formik.values.Attachment) {
  //         if (typeof file !== "string") {
  //           const uploadedFilePath = await postFile(file);
  //           attachmentURLs.push(uploadedFilePath);
  //         } else {
  //           attachmentURLs.push(file);
  //         }
  //       }

  //       for (let item of lineItems) {
  //         if (typeof item?.Attachment !== "string") {
  //           const string = await postFile(item.Attachment);
  //           item.Attachment = string;
  //         }
  //       }

  //       const object = {
  //         ...values,
  //         Attachment: attachmentURLs,
  //         products: lineItems,
  //         selectedTeams: selectedTeams,
  //         IsOneoffJob: activeTab === 1,
  //         IsRecuringJob: activeTab === 2,
  //         Total: Total,
  //         subTotal: subTotal,
  //         AssignPersonId: assignPersonId,
  //         Frequency: values.RecuringJob.Frequency,
  //         Duration: values.RecuringJob.Duration,
  //       };

  //       if (activeTab === 1) {
  //         delete object.IsRecuringJob;
  //       } else if (activeTab === 2) {
  //         delete object.OneoffJob;
  //       }

  //       let response;
  //       if (!location.state?.id) {
  //         response = await AxiosInstance.post(`/contract`, object);
  //       } else {
  //         response = await AxiosInstance.put(
  //           `/contract/${location.state?.id}`,
  //           object
  //         );
  //       }

  //       if (response.data.statusCode === 200) {
  //         toast.success(response.data.message);
  //         navigate(`/${companyName}/contract`, {
  //           state: {
  //             navigats: ["/index", "/contract"],
  //           },
  //         });
  //       }

  //       return response.data;
  //     } catch (error) {
  //       console.error("Error: ", error);
  //     } finally {
  //  
  //       setLoading(false);
  //     }
  //   },
  // });
  // const  formik = useFormik({
  //   initialValues: {
  //     Title: "",
  //     QuoteId: "",
  //     Description: "",
  //     ContractNumber: 1,
  //     CustomerId: "",
  //     CompanyId: localStorage.getItem("CompanyId"),
  //     LocationId: "",
  //     contract_disclaimer:
  //       "Contract/ Disclaimer\nThis quote is valid for the next 30 days, after which values may be subject to change.",
  //     Notes: "",
  //     Attachment: [],
  //     OneoffJob: {
  //       StartDate: startDate || "",
  //       EndDate: "",
  //       StartTime: "",
  //       EndTime: "",
  //       ScheduleLetter: false,
  //       Repeats: "Weekly",
  //     },
  //     RecuringJob: {
  //       StartDate: "",
  //       StartTime: "",
  //       EndTime: "",
  //       Repeats: "",
  //       Duration: "",
  //       Frequency: "",
  //     },
  //     selectedTeams: [],
  //     subTotal: "",
  //   },
  //   validationSchema: Yup.object({
  //     Title: Yup.string().required('Title is required if provided.'),
  //     Description: Yup.string().required('Description is required if provided.'),
  //   }),
  //   onSubmit: async (values) => {
  //     if (!values.Title && !values.Description) {
  //       formik.setFieldError('Title', 'Title is required if provided.');
  //       formik.setFieldError('Description', 'Description is required if provided.');
  //       return;
  //     }

  //     try {
  //       setLoading(true);

  //       const attachmentURLs = [];
  //       for (let file of formik.values.Attachment) {
  //         if (typeof file !== "string") {
  //           const uploadedFilePath = await postFile(file);
  //           attachmentURLs.push(uploadedFilePath);
  //         } else {
  //           attachmentURLs.push(file);
  //         }
  //       }

  //       for (let item of lineItems) {
  //         if (typeof item?.Attachment !== "string") {
  //           const string = await postFile(item.Attachment);
  //           item.Attachment = string;
  //         }
  //       }

  //       const object = {
  //         ...values,
  //         Attachment: attachmentURLs,
  //         products: lineItems,
  //         selectedTeams: selectedTeams,
  //         IsOneoffJob: activeTab === 1,
  //         IsRecuringJob: activeTab === 2,
  //         Total: Total,
  //         subTotal: subTotal,
  //         AssignPersonId: assignPersonId,
  //         Frequency: values.RecuringJob.Frequency,
  //         Duration: values.RecuringJob.Duration,
  //       };

  //       if (activeTab === 1) {
  //         delete object.IsRecuringJob;
  //       } else if (activeTab === 2) {
  //         delete object.OneoffJob;
  //       }

  //       let response;
  //       if (!location.state?.id) {
  //         response = await AxiosInstance.post(`/contract`, object);
  //       } else {
  //         response = await AxiosInstance.put(
  //           `/contract/${location.state?.id}`,
  //           object
  //         );
  //       }

  //       if (response.data.statusCode === 200) {
  //         toast.success(response.data.message);
  //         navigate(`/${companyName}/contract`, {
  //           state: {
  //             navigats: ["/index", "/contract"],
  //           },
  //         });
  //       }

  //       return response.data;
  //     } catch (error) {
  //       console.error("Error: ", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  // });


  useEffect(() => {
    if (location.state && location.state.id) {
      if (location.state.id === "someValueForOneOff") {
        setActiveTab(1);
      } else if (location.state.id === "someValueForRecurring") {
        setActiveTab(2);
      }
    }
  }, [location.state]);
  const [showCosts, setShowCosts] = useState(
    new Array(lineItems.length).fill(false)
  );
  const [menuIsOpen, setMenuIsOpen] = useState(
    new Array(lineItems.length).fill(false)
  );
  const handleSelectChange = (index, selectedOption) => {
    const newLineItems = [...lineItems];

    newLineItems[index] = {
      ...newLineItems[index],
      ProductId: selectedOption.ProductId,
      Description: selectedOption.Description,
      Name: selectedOption.Name,
      Type: selectedOption.Type,
      Unit: selectedOption.Unit,
      Attachment: selectedOption.Attachment,
      CostPerUnit: selectedOption.CostPerUnit,
      Hour: selectedOption.Hour,
      CostPerHour: selectedOption.CostPerHour,
      Square: selectedOption.Square,
      CostPerSquare: selectedOption.CostPerSquare,
      isNew: true,
      Total: selectedOption?.Unit
        ? Number(selectedOption?.Unit || 0) *
          Number(selectedOption?.CostPerUnit || 0)
        : selectedOption?.Square
        ? Number(selectedOption?.Square || 0) *
          Number(selectedOption?.CostPerSquare || 0)
        : Number(selectedOption?.Hour || 0) *
          Number(selectedOption?.CostPerHour || 0),
    };

    setLineItems(newLineItems);
    setMenuIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const addLineItem = () => {
    setLineItems([...lineItems, {}]);
  };
  const deleteLineItem = (index) => {
    const newLineItems = lineItems.filter((_, i) => i !== index);
    setLineItems(newLineItems);
  };

  const [selectedTeams, setSelectedTeams] = useState([]);

  const handleSaveQuote = async () => {
    try {
      await formik.submitForm();
    } catch (error) {
      console.error("Error saving quote: ", error);
    }
  };

  const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const fetchData = async () => {
    if (location.state?.id) {
      try {
        const res = await AxiosInstance.get(
          `/contract/contract_details/${location.state.id}`
        );
        if (res.data?.statusCode === 200) {
          setLoading(true);
          const data = res.data?.data;
          formik.setValues({
            Title: data?.Title,
            Firstname: data?.customer?.Firstname, 
            LastName: data?.customer?.LastName,
            ContractNumber: data?.ContractNumber,
            CompanyId: data.CompanyId,
            CustomerId: data?.CustomerId,
            LocationId: data?.LocationId,
            CustomerMessage: data?.CustomerMessage,
            ContractDisclaimer: data?.ContractDisclaimer,
            Notes: data?.Notes,
            Attachment: data?.Attachment,
            Discount: data?.Discount,
            Tax: data?.Tax,
            subTotal: data?.subTotal,
            Description: data?.Description,
            OneoffJob: data?.OneoffJob,
            RecuringJob: data?.RecuringJob,
            IsOneoffJob: data?.IsOneoffJob,
            IsRecuringJob: data?.IsRecuringJob,
            WorkerId: data?.WorkerId[0],
          });
          setContractData(data);
          setActiveTab(data?.IsOneoffJob ? 1 : 2);

          const member = teamData.find(
            (item) => item.WorkerId === data.WorkerId[0]
          );
          if (member) {
            setSelectedTeams([member.FullName]);
            setCheckedState({
              [member.FullName]: true,
            });
            setAssignPersonId(data.WorkerId[0]);
          }
          setLineItems(
            data?.Items || [
              {
                Description: "",
                Name: "",
                Type: "",
                Unit: "",
                Attachment: "",
                CostPerUnit: "",
                Hour: "",
                CostPerHour: "",
                Square: "",
                CostPerSquare: "",
                Total: "",
                isNew: true,
              },
            ]
          );
        }
      } catch (error) {
        console.error("Error: ", error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [teamData]);

  useEffect(() => {
    // Contract Number
    const getNumber = async () => {
      try {
        if (!location.state?.id) {
          const res = await AxiosInstance.get(
            `/contract/get_number/${localStorage.getItem("CompanyId")}`
          );
          if (res.data?.statusCode === 200) {
            const nextContractNumber = res.data?.contractNumber + 1;
            formik.setValues((values) => {
              return {
                ...values,
                ContractNumber: nextContractNumber,
              };
            });
          }
        }
      } catch (error) {
        console.error("Error fetching quote number: ", error);
      }
    };

    const initialize = async () => {
      if (location.state?.previewData) {
        formik.setValues(location.state.previewData?.values);
        setLineItems(
          location.state.previewData?.lineItems || [
            {
              Description: "",
              Name: "",
              Type: "",
              Unit: "",
              Attachment: "",
              CostPerUnit: "",
              Hour: "",
              CostPerHour: "",
              Square: "",
              CostPerSquare: "",
              Total: "",
              isNew: true,
              subTotal: "",
            },
          ]
        );
        formik.setFieldValue("CustomerId", location.state.CustomerId);
        formik.setFieldValue("LocationId", location.state.LocationId);
        window.history.replaceState(
          {
            id: location.state?.previewData?.id
              ? location.state?.previewData?.id
              : null,
          },
          ""
        );
      } else if (location.state?.id) {
        await fetchData();
      } else if (location?.state?.formData) {
        formik.setValues(location?.state?.formData);
        setLineItems(location?.state?.products);
      }
      await getNumber();
    };

    initialize();
    fetchData();
    return () => {
      formik.resetForm();
      setLineItems([
        {
          FirstName: "",
          LastName: "",
          Description: "",
          Name: "",
          Type: "",
          Unit: "",
          Attachment: "",
          CostPerUnit: "",
          Hour: "",
          CostPerHour: "",
          Square: "",
          CostPerSquare: "",
          Total: "",
          isNew: true,
          subTotal: "",
        },
      ]);
    };
  }, []);

  const calculateSubTotal = () => {
    const Total = lineItems?.reduce(
      (sum, item) => sum + Number(item.Total || 0),
      0
    );
    return Total;
  };

  const subTotal = calculateSubTotal();
  const handleCahngeIds = (value) => {};
  const [mail, setMail] = useState(false);

  const handleContractNumberChange = async () => {
    const enteredContractNumber = Number(formik.values.ContractNumber);
    const companyId = localStorage.getItem("CompanyId");

    try {
      const res = await AxiosInstance.post(
        `/contract/check_number/${companyId}`,
        {
          ContractNumber: enteredContractNumber,
        }
      );
      if (res.data?.statusCode === 200) {
        setTimeout(() => {
        toast.success("Contract number is valid and added successfully.");
      }, 500);
        setIsNumChange(false);
      } else if (res.data?.statusCode === 203) {
        setTimeout(() => {
        toast.error(
          "Contract number already exists. Please choose a different number."
        );
      }, 500);
        formik.setFieldValue("ContractNumber", "");
      } else {
        setTimeout(() => {

        toast.error("Failed to check Contract number. Please try again.");
      }, 500);
      }
    } catch (error) {
      console.error(
        "Error checking Contract number: ",
        error.response || error.message
      );
      setTimeout(() => {
      toast.error("An error occurred while checking the Contract number.");
    }, 500);
    }
  };

  const [showDiscount, setShowDiscount] = useState(false);

  const [showTax, setShowTax] = useState(false);

  const discountAmount = formik.values.Discount
    ? (Number(formik.values.Discount) * subTotal) / 100
    : 0;
  const discountedTotal = subTotal - discountAmount;
  const taxAmount = formik.values.Tax
    ? (Number(formik.values.Tax) * discountedTotal) / 100
    : 0;
  const Total = (discountedTotal + taxAmount)?.toFixed(2);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(false);
  const [workerId, setAssignPersonId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ids, setIds] = useState([]);
  const [isAddTeam, setIsAddTeam] = useState(false);
  const [tokenDecode, setTokenDecode] = useState({});


  const baseUrl = process.env.REACT_APP_BASE_API;

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOutsideClick = () => {
    toggleDropdown();
  };

  const handleRemoveTeam = (teamName) => {
    setSelectedTeams(selectedTeams.filter((name) => name !== teamName));
    setCheckedState((prevState) => ({
      ...prevState,
      [teamName]: false,
    }));
  };

  const handleTeamSelect = (event, team) => {
    if (event.target.checked) {
      setSelectedTeams([...selectedTeams, team?.FullName]);
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: true,
      }));
      setIds(() => [...ids, team.WorkerId]);
      setAssignPersonId(team.WorkerId);
    } else {
      setSelectedTeams(selectedTeams.filter((name) => name !== team?.FullName));
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: false,
      }));
      setIds(() => ids.filter((name) => name !== team?.WorkerId));
      setAssignPersonId(null);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const fetchTeamData = async () => {
    setLoading(true);
    try {
      const companyId =
        localStorage.getItem("CompanyId") || tokenDecode?.companyId;

      if (!companyId) {
        console.error("CompanyId is not found in localStorage or tokenDecode.");
        return;
      }

      const response = await AxiosInstance.get(`/worker/${companyId}`);

      if (response.status === 200) {
        setTeamData(response.data.data);
      } else {
        console.error("Error fetching team data:", response);
      }
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, [tokenDecode]);

  const formikTeam = useFormik({
    initialValues: {
      FullName: "",
      EmailAddress: "",
      MobileNumber: "",
      WorkerId: "",
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("Full Name is required"),
      EmailAddress: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // MobileNumber: Yup.string().required("Mobile Number is required"),
      MobileNumber: Yup.string()
      .required("Phone number is required")
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Phone number must be in the format (xxx) xxx-xxxx"
      ),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const companyId =
          localStorage.getItem("CompanyId") || tokenDecode?.companyId;

        if (!companyId) {
          console.error("CompanyId is not found in localStorage");
          return;
        }
        const object = {
          ...values,
          companyId: localStorage.getItem("CompanyId"),
          WorkerId: values.WorkerId,
          FullName: values.FullName,
          EmailAddress: values.EmailAddress,
          PhoneNumber: values.MobileNumber,
        };

        const response = await AxiosInstance.post(`${baseUrl}/worker`, object);
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          toggleModal();
          fetchTeamData();
          formikTeam.resetForm();
        } else if (response.data.statusCode === 202) {
          toast.error(response.data.message);
        }
      } catch (error) {
        if (error.response) {
          console.error("Server responded with an error:", error.response.data);
          setTimeout(() => {

          toast.error(error.response.data.message || "Something went wrong!");
        }, 500);
        } else if (error.request) {
          console.error("No response received:", error.request);
          setTimeout(() => {
          toast.error("No response from the server, please try again later.");
        }, 500);
        } else {
          console.error("Error during request setup:", error.message);
          setTimeout(() => {
          toast.error("Error occurred while sending request.");
        }, 500);
        }
      }finally{
        setLoading(false);
      }
    },
  });

   //  Phone number formatting function
  const formatPhoneNumber = (value) => {
    const MobileNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = MobileNumber.slice(0, 10); // Limit to 10 digits
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/); // Matches (xxx) xxx-xxxx

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  // Handle phone number change and format it
  const handlePhoneChange = (e) => {
    if (formikTeam.values.MobileNumber?.length > e.target.value?.length) {
      formikTeam.setFieldValue("MobileNumber", e.target.value);
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      formikTeam.setFieldValue("MobileNumber", formattedValue);
    }
  };

  const fetchDatas = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchDatas();
  }, []);

  return (
    <>
      <AddContractView
        lineItems={lineItems}
        isProperty={isProperty}
        setIsProperty={setIsProperty}
        setIsCustomer={setIsCustomer}
        isCustomer={isCustomer}
        setPropertyData={setPropertyData}
        mail={mail}
        startDate={startDate}
        setMail={setMail}
        customersData={customersData}
        propertyData={propertyData}
        formik={formik}
        handleSaveQuote={handleSaveQuote}
        toggle={toggle}
        dropdownOpen={dropdownOpen}
        setLoading={setLoading}
        loading={loading}
        Total={Total}
        taxAmount={taxAmount}
        showTax={showTax}
        setShowTax={setShowTax}
        showDiscount={showDiscount}
        contractData={contractData}
        setShowDiscount={setShowDiscount}
        discountAmount={discountAmount}
        deleteLineItem={deleteLineItem}
        subTotal={subTotal}
        addLineItem={addLineItem}
        showCosts={showCosts}
        setShowCosts={setShowCosts}
        setMenuIsOpen={setMenuIsOpen}
        menuIsOpen={menuIsOpen}
        handleSelectChange={handleSelectChange}
        setLineItems={setLineItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleCahngeIds={handleCahngeIds}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        isCalendarVisible={isCalendarVisible}
        setIsCalendarVisible={setIsCalendarVisible}
        isNumChange={isNumChange}
        setIsNumChange={setIsNumChange}
        handleContractNumberChange={handleContractNumberChange}
        setCustomersData={setCustomersData}
        toggleDropdown={toggleDropdown}
        isDropdownOpen={isDropdownOpen}
        handleOutsideClick={handleOutsideClick}
        teamData={teamData}
        setTeamData={setTeamData}
        checkedState={checkedState}
        setCheckedState={setCheckedState}
        handleRemoveTeam={handleRemoveTeam}
        handleTeamSelect={handleTeamSelect}
        isAddTeam={isAddTeam}
        toggleModal={toggleModal}
        isModalOpen={isModalOpen}
        ids={ids}
        setIds={setIds}
        setIsAddTeam={setIsAddTeam}
        formikTeam={formikTeam}
        handlePhoneChange={handlePhoneChange}
        // workerId={workerId}
      />
    </>
  );
}

export default AddContract;
