// import React, { useEffect, useState } from "react";
// import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
// import { Circles } from "react-loader-spinner";
// import { useLocation, useNavigate } from "react-router-dom";
// import {
//   JobberPagination,
//   JobberSearch,
//   JobberTable,
// } from "../../../components/MuiTable/index";
// import AxiosInstance from "../../AxiosInstance";
// import { handleAuth } from "../../../components/Login/Auth";
// import moment from "moment";
// import { Toaster, toast } from "react-hot-toast";

// function WorkerAppointments() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loader, setLoader] = useState(true);
//   const [workerVisitsData, setWorkerVisitsData] = useState([]);
//   const [tokenDecode, setTokenDecode] = useState({});
//   const [search, setSearch] = useState("");
//   const [page, setPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [totalCount, setTotalVisits] = useState(0);

//   const fetchTokenDecode = async () => {
//     setLoader(true);
//     try {
//       const res = await handleAuth(navigate, location);
//       setTokenDecode(res.data);
//     } catch (error) {
//       console.error("Error fetching token decode data:", error);
//       toast.error("Failed to fetch authentication data.");
//     } finally {
//       setLoader(false);
//     }
//   };

//   const fetchWorkerVisits = async () => {
//     const { companyId, WorkerId } = tokenDecode;
//     if (companyId && WorkerId) {
//       setLoader(true);
//       try {
//         const response = await AxiosInstance.get(
//           `/visits/worker_visits/${companyId}/${WorkerId}`,
//           {
//             params: {
//               page: page,
//               limit: rowsPerPage,
//               search: search || "",
//             },
//           }
//         );
//         if (response.data.statusCode === 200) {
//           setWorkerVisitsData(response.data.data);
//           setTotalVisits(response.data.totalCount);
//         } else {
//           toast.error("Failed to fetch worker visits data.");
//         }
//       } catch (error) {
//         console.error("API error:", error);
//         toast.error("An error occurred while fetching data.");
//       } finally {
//         setLoader(false);
//       }
//     }
//   };

//   useEffect(() => {
//     fetchTokenDecode();
//   }, []);

//   useEffect(() => {
//     if (Object.keys(tokenDecode).length) {
//       fetchWorkerVisits();
//     }
//   }, [tokenDecode, page, rowsPerPage, search]);

//   // const cellData = workerVisitsData.map((visit, index) => ({
//   //   srNo: (page - 1) * rowsPerPage + index + 1,
//   //   appointmentDate: moment(visit.StartDate).format("DD/MM/YYYY"),
//   //   location: `${visit.Location?.Address}, ${visit.Location?.City}, ${visit.Location?.State}, ${visit.Location?.Country}`,
//   //   isConfirm: visit.IsConfirm ? "Confirmed" : "Pending",
//   //   // component: visit,
//   // }));
//   const cellData = workerVisitsData?.map((item, index) => {
//     return {
//       key: item.VisitId,
//       value: [
//         item.EndDate,
//         // item.PlanPrice,
//         // item.MonthFrequency,
//         // item.createdAt,
//         // <>
//         //   <img
//         //     src={Edit}
//         //     alt="edit"
//         //     onClick={(e) => {
//         //       e.stopPropagation();
//         //       handleEditClick(item);
//         //     }}
//         //   />

//         //   <img
//         //     className="mx-1"
//         //     alt="delete"
//         //     src={Delete}
//         //     onClick={(e) => {
//         //       e.stopPropagation();
//         //       handleDelete(item.PlanId);
//         //     }}
//         //   />
//         // </>,
//       ],
//       component: item,
//     };
//   });
//   return (
//     <>
//       <div className="justify-content-center align-items-center mb-3">
//         <div className="d-flex justify-content-between mb-2 align-items-center">
//           <h3 className="text-blue-color" style={{ fontWeight: 700 }}>
//             Appointments
//           </h3>
//         </div>
//         <Card
//           style={{
//             borderRadius: "20px",
//             border: "1px solid #063164",
//             padding: 0,
//           }}
//         >
//           <CardHeader
//             className="d-flex justify-content-between align-items-center table-header border-blue-color bg-blue-color"
//             style={{
//               borderBottom: "1px solid #063164",
//               borderTopLeftRadius: "15px",
//               borderTopRightRadius: "15px",
//             }}
//           >
//             <h5 className="quot text-light">Appointments List</h5>
//             <div className="quotesearch d-flex">
//               <JobberSearch
//                 search={search}
//                 setSearch={setSearch}
//                 style={{ background: "transparent", color: "white" }}
//               />
//             </div>
//           </CardHeader>
//           {loader ? (
//             <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
//               <Circles
//                 height="50"
//                 width="50"
//                 color="#063164"
//                 ariaLabel="circles-loading"
//                 wrapperStyle={{}}
//                 wrapperClass=""
//                 visible={loader}
//               />
//             </div>
//           ) : (
//             <CardBody style={{ padding: "10px 0px" }}>
//               <JobberTable
//                 headerData={[
//                   "Sr No.",
//                   "Appointment Date",
//                   "Location",
//                   "Status",
//                   "Actions",
//                 ]}
//                 CellData={cellData}
//                 page={page}
//               />
//             </CardBody>
//           )}

//           <CardFooter
//             className="bg-orange-color border-blue-color"
//             style={{
//               borderTop: "1px solid #063164",
//               borderBottomLeftRadius: "20px",
//               borderBottomRightRadius: "20px",
//             }}
//           >
//             <JobberPagination
//               totalData={totalCount}
//               currentData={rowsPerPage}
//               dataPerPage={rowsPerPage}
//               pageItems={[10, 25, 50]}
//               page={page}
//               setPage={setPage}
//               setRowsPerPage={setRowsPerPage}
//             />
//           </CardFooter>
//         </Card>
//       </div>
//       <Toaster />
//     </>
//   );
// }

// export default WorkerAppointments;

import React, { useState, useEffect } from "react";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
} from "../../../components/MuiTable/index.js";
import toast, { Toaster } from "react-hot-toast";
import "./style.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Circles } from "react-loader-spinner";
import { handleAuth } from "../../../components/Login/Auth.js";
import AxiosInstance from "../../AxiosInstance.js";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const WorkerAppointments = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleAuth(navigate, location);
  }, [navigate, location]);

  const baseUrl = process.env.REACT_APP_BASE_API;
  const [modelOpen, setModelOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teamSize, setTeamSize] = useState([]);
  const [totalCount, setCountData] = useState(0);
  const [loader, setLoader] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tokenDecode, setTokenDecode] = useState({});

  const fetchTokenDecode = async () => {
    setLoader(true);
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching token decode data:", error);
      toast.error("Failed to fetch authentication data.");
    } finally {
      setLoader(false);
    }
  };

  const getData = async () => {
    const { companyId, WorkerId } = tokenDecode;

    setLoader(true);
    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/visits/worker_visits/${companyId}/${WorkerId}`,
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        }
      );
      setTeamSize(res.data.data);
      setCountData(res.data.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchTokenDecode();
  }, []); // Fetch token decode on mount

  useEffect(() => {
    if (tokenDecode.companyId && tokenDecode.WorkerId) {
      getData();
    }
  }, [rowsPerPage, page, search, tokenDecode]);

  const handleRowClick = (item) => {
    // Check if item is defined before accessing properties
    if (item && item.VisitId) {
      setSelectedRowData(item); // Store the selected row data
      navigate(`/staff-member/appointment-confirm?visitId=${item.VisitId}`);
    } else {
      console.error("Item or VisitId is undefined:", item);
      toast.error("Failed to navigate to appointment confirmation.");
    }
  };
  const statusStyles = {
    confirmed: {
      color: "#089F57",
      fontWeight: "bold",
    },
    pending: {
      color: "#E88C44",
      fontWeight: "bold",
    },
  };
  const cellData = teamSize?.map((item, index) => {
    return {
      key: item.VisitId,
      value: [
        page * rowsPerPage + index + 1,
        item.ItemName || "N/A", // Default to 'N/A' if undefined
        //  || "N/A",
        // Default to 'N/A' if undefined
        moment(item.StartDate).format("MM-DD-YYYY"),
        `${item.Location?.Address || ""}, ${item.Location?.City || ""}, ${item.Location?.State || ""
        }, ${item.Location?.Country || ""}`,
        // item.IsConfirmByWorker ? "Confirmed" : "Pending",
        <span
          style={
            item.IsConfirmByWorker
              ? statusStyles.confirmed
              : statusStyles.pending
          }
        >
          {item.IsConfirmByWorker ? "Confirmed" : "Pending"}
        </span>,
      ],
      component: item,
      onClick: () => handleRowClick(item), // Pass the entire item for better context
    };
  });

  return (
    <>
      <div className="justify-content-center align-items-center mb-3 mt-5">
        <div className="d-flex justify-content-start mb-2">
          <h4 className="text-blue-color" style={{ fontWeight: 700 }}>
            Appointment
          </h4>
        </div>
        <Card style={{ borderRadius: "20px", border: "2px solid #063164" }}>
          <CardHeader
            className="d-flex justify-content-between align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid #063164",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="plan text-light">Appointment List</h5>
            <div className="plansearch d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparent", color: "#fff" }}
              />
            </div>
          </CardHeader>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <Circles
                height="50"
                width="50"
                color="#063164"
                ariaLabel="circles-loading"
                visible={loader}
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={[
                  "Sr No",
                  "Item Name",
                  "Appointment Date",
                  "Location",
                  "Status",
                ]}
                cellData={cellData}
                page={page}
                isNavigate={true}
                navigatePath={"/staff-member/appointment-confirm"}
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color"
            style={{
              borderTop: "2px solid #063164",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={totalCount}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50, 100]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage} 
            />
          </CardFooter>
        </Card>
      </div>
      <Toaster />
    </>
  );
};

export default WorkerAppointments;
