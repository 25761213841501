import React, { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import "./QuoteNotification.css";
import { handleAuth } from "../../../src/components/Login/Auth";
import AxiosInstance from "../../Views/AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import quote from "../../assets/Blue-sidebar-icon/Quote.svg";
import contract from "../../assets/Blue-sidebar-icon/Contract.svg";
import Appointment from "../../assets/Blue-sidebar-icon/Appoinment-blue.svg";
import customerIcon from "../../assets/Blue-sidebar-icon/Customer.svg";
import InvoiceIcon from "../../assets/Blue-sidebar-icon/Invoice.svg";
import { differenceInSeconds } from "date-fns";

const QuoteNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.QuoteId, notification?.NotificationId, "quote")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          {notification?.Company?.ownerName} created a quote - $
          {notification?.Quote?.Total}
        </span>
        <div className="description">
          <span
            className="quoteNumber"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Quote #{notification?.Quote?.QuoteNumber} -{" "}
            {notification?.Quote?.Title}
          </span>
          <span className="additionalInfo">
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const ContractNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      style={{ cursor: "pointer" }}
      onClick={() =>
        onClick(notification?.ContractId, notification?.NotificationId, "contract")
      }
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={contract} alt="Contract Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          {notification?.Company?.ownerName} created a contract - $
          {notification?.Contract?.Total}
        </span>
        <div className="description">
          <span
            className="quoteNumber"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Contract #{notification?.Contract?.ContractNumber} -{" "}
            {notification?.Contract?.Title}
          </span>
          <span className="additionalInfo">
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};
const AppointmentConfirmNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(
          notification?.ContractId,
          notification?.NotificationId,
          "contract"
        )
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={Appointment} alt="Contract Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          Appointment {notification?.Visit?.StartDate} Confirmed By Customer -
        </span>
        <div className="description">
          <span
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};
const QuoteConfirmNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.QuoteId, notification?.NotificationId, "quote")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          Appointment {notification?.Visit?.StartDate} Confirmed by Worker -{" "}
        </span>
        <div className="description">
          <span
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {notification?.Visit?.WorkerFullName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const WorkerCreateNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.WorkerId, notification?.NotificationId, "worker")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
        {notification?.Company?.ownerName} Created A Worker - 
        </span>
        <div className="description">
          <span
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {notification?.Worker?.FullName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const ProductAndServiceCreateNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.ProductId, notification?.NotificationId, "productandservice")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
        {notification?.Company?.ownerName} Created A {notification?.ProductAndService?.Type} -
        
        </span>
        <div className="description">
          <span
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
          {notification?.ProductAndService?.Name}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const CustomerNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(
          notification?.CustomerId,
          notification?.NotificationId,
          "customer"
        )
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={customerIcon} alt="Customer Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          {notification?.Company?.ownerName} added a new customer:{" "}
        </span>
        <div className="description">
          <span
            className="quoteNumber"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};
const InvoiceNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(
          notification?.InvoiceId,
          notification?.NotificationId,
          "invoice"
        )
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={InvoiceIcon} alt="Invoice Icon" />
      </div>
      <div className="notificationContent" style={{ cursor: "pointer" }}>
        <span className="title">
          {notification?.Company?.ownerName} created a invoice - $
          {notification?.Invoice?.Total}
        </span>
        <div className="description">
          <span
            className="quoteNumber"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Invoice #{notification?.Invoice?.InvoiceNumber} -{" "}
            {notification?.Invoice?.Subject}
          </span>
          <span className="additionalInfo">
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const QuoteChangeRequestNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.QuoteId, notification?.NotificationId, "Chnge")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer">
        <img src={quote} alt="Quote Change Request Icon" />
      </div>
      <div className="notificationContent">
        <span className="title">
          {notification?.Customer?.FirstName} {notification?.Customer?.LastName}
          {""} Requested a change to quote - ${notification?.Quote?.Total}
        </span>
        <div className="description">
          <span
            className="requestquote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Quote #{notification?.Quote?.QuoteNumber} - {""}
            {notification?.Quote?.Title}
          </span>
          <span className="additionalInfo">
            Request Message:{" "}
            {notification?.Request?.RequestMessage || "No message"}{" "}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const QuoteApproveNotification = ({ onClick, notification }) => {
  const createdAt = notification?.createdAt
    ? new Date(notification.createdAt)
    : null;
  let formattedTime = "";

  if (createdAt) {
    const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

    if (timeDiffInSeconds < 60) {
      formattedTime = "just now";
    } else {
      formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
    }
  }

  return (
    <div
      className="notificationCard"
      onClick={() =>
        onClick(notification?.QuoteId, notification?.NotificationId, "approve")
      }
      style={{ cursor: "pointer" }}
    >
      <div className="iconContainer">
        <img src={quote} alt="Quote Approve Icon" />
      </div>
      <div className="notificationContent">
        <span className="title">
          {notification?.Customer?.FirstName} {notification?.Customer?.LastName}{" "}
          Approved quote - ${notification?.Quote?.Total}
        </span>
        <div className="description">
          <span
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Quote #{notification?.Quote?.QuoteNumber} -{" "}
            {notification?.Quote?.Title}
          </span>
        </div>
        <span className="time">{formattedTime}</span>
      </div>
    </div>
  );
};

const NotificationsPage = ({ handleCloseNotifications }) => {
  const { companyName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [tokenDecode, setTokenDecode] = useState({});
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setTokenDecode(res.data.companyId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tokenDecode) {
      fetchNotifications();
    }
  }, [tokenDecode]);
  const fetchNotifications = async () => {
    try {
      const response = await AxiosInstance.get(`/notifications/${tokenDecode}`);
      if (response.data.statusCode === 200) {
        const filteredNotifications = response.data.notifications.filter(
          (notification) =>
            (notification?.Quote && notification?.Quote?.QuoteNumber) ||
            (notification?.Contract &&
              notification?.Contract?.ContractNumber) ||
            (notification?.Customer && notification?.Customer?.FirstName) ||
            (notification?.Invoice && notification?.Invoice?.InvoiceNumber) ||
            notification?.QuoteChangeRequest ||
            notification?.QuoteApprove || notification.WorkerId || notification.ProductId
        );
        setNotifications(filteredNotifications || []);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        console.error("Company id is not found!");
      } else {
        console.error("Error fetching notifications:", error.message);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 10000);
    return () => clearInterval(interval);
  }, []);
  const handleNotificationClick = async (id, notificationId, type) => {
    try {
      await AxiosInstance.put(`/notifications/${notificationId}`);
      handleCloseNotifications();
      await fetchNotifications();

      const route =
        type === "quote"
          ? "quotes-detail"
          : type === "contract"
          ? "contractdetails"
          : type === "customer"
          ? "customerdetails"
          : type === "invoice"
          ? "invoice-details"
          : type === "Chnge"
          ? "quotes-detail"
          : type === "approve"
          ? "quotes-detail"
          : type === "worker"
          ? "add-user"
          : type === "productandservice"
          ? "product&service"
          : null;
      if (route) {
        navigate(`/${companyName}/${route}`, {
          state: {
            id: id,
            navigats: ["/index", `/${route}`],
          },
          replace: true,
        });
      }
    } catch (error) {
      console.error("Error marking notification as read:", error.message);
    }
  };
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "75%", width: "100%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            visible={loader}
          />
        </div>
      ) : (
        <div className="notificationsContainer quotes-notification">
          {notifications.length > 0 ? (
            notifications.map((notification) => {
              if (notification.CreatedBy === "WorkerRole") {
                return (
                  <WorkerCreateNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }
              else if (notification?.CreatedBy?.trim().toLowerCase() === "confirm") {
                return (
                  <AppointmentConfirmNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              } else if (notification.CreatedBy === "customer") {
                return (
                  <QuoteChangeRequestNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              } else if (notification.CreatedBy === "ProductAndServices") {
                return (
                  <ProductAndServiceCreateNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              } else if (notification.CreatedBy === "Worker") {
                return (
                  <QuoteConfirmNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              } else if (notification?.Quote) {
                if (notification.CreatedBy === "Customer") {
                  return (
                    <QuoteApproveNotification
                      key={notification._id}
                      notification={notification}
                      onClick={handleNotificationClick}
                    />
                  );
                } else {
                  return (
                    <QuoteNotification
                      key={notification._id}
                      notification={notification}
                      onClick={handleNotificationClick}
                    />
                  );
                }
              } else if (notification?.Contract) {
                return (
                  <ContractNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              } else if (notification?.Customer) {
                return (
                  <CustomerNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              } else if (notification?.Invoice) {
                return (
                  <InvoiceNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              } else if (notification?.Worker) {
                return (
                  <WorkerCreateNotification
                    key={notification._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }

              return null;
            })
          ) : (
            <div className="mt-4 text-center" style={{ color: "#063164" }}>
              {/* No notifications available. */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NotificationsPage;
