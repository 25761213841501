import React, { useState, useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import WhiteCompany from "../../assets/White-sidebar-icon/Superadmin-Company.svg";
import SuperadminGraphs from "./SuperadminGraphs";
import "./style.css";
import { Circles } from "react-loader-spinner";

const BASE_URL = process.env.REACT_APP_BASE_API;

const PlanCard = () => {
  const [data, setData] = useState({
    activeCompanies: 0,
    inactiveCompanies: 0,
    totalCompanies: 0,
    totalPlans: 0,
  });
  const [loader, setloader] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch(`${BASE_URL}/superadmin/counts`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        if (result.statusCode === 200) {
          setData(result.data);
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setError("Failed to fetch data from server: " + error.message);
      } finally {
        setloader(false);
      }
    };

    fetchCounts();
  }, []);

  return (
    <div>
      {loader ? (
        <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="d-flex gap-3 plan-company">
          <Paper
            elevation={3}
            sx={{
              width: "30%",
              height: "195px",
              borderRadius: "12px",
              // backgroundColor: "#063164",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            className="plan_company bg-blue-color"
          >
            <Typography
              variant="h6"
              sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "bold" }}
              className="company_box"
            >
              Plan
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <Typography
                variant="body1"
                className="text-blue-color"
                sx={{
                  // color: "#063164",
                  fontSize: "18px",
                  background: "#fff",
                  borderRadius: "10px",
                  padding: "5px 8px",
                }}
              >
                Total : {data.totalPlans}
              </Typography>
              <DescriptionIcon sx={{ color: "#ffffff", fontSize: "60px" }} />
            </Box>
          </Paper>
          <Paper
            className="bg-orange-color active_deactive"
            elevation={3}
            sx={{
              width: "30%",
              height: "195px",
              borderRadius: "12px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography
                variant="h6"
                sx={{ color: "#ffffff", fontSize: "24px", fontWeight: "bold" }}
              >
                Company
              </Typography>
              <p className="text-white-color mb-0" style={{ opacity: "70%" }}>
                Active Company : {data.activeCompanies}
              </p>
              <p className="text-white-color mb-0" style={{ opacity: "70%" }}>
                Inactive Company : {data.inactiveCompanies}
              </p>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  marginBottom: "20px",
                }}
                className="active_box"
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "18px",
                    background: "#fff",
                    borderRadius: "10px",
                    padding: "5px 8px",
                    marginBottom: "1px",
                  }}
                  className="active_box text-blue-color"
                >
                  Total : {data.totalCompanies}
                </Typography>
                <img
                  alt="img"
                  src={WhiteCompany}
                  sx={{ color: "#ffffff", fontSize: "60px" }}
                />
              </Box>
            </div>
          </Paper>
        </div>
      )}
      <SuperadminGraphs />
    </div>
  );
};

export default PlanCard;
