import React, { useState } from "react";
import { Circles } from "react-loader-spinner";
import {
  Button,
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
} from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Previous from "../../../../assets/image/icons/Previous.png";
import Payment from "../../../../assets/image/dashboard/payment.svg";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import edit from "../../../../assets/image/icons/edit.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InvoiceImage from "../../../../assets/Blue-sidebar-icon/Invoice.svg";
import Copy from "../../../../assets/image/icons/copy.svg";
import { TableHead } from "@mui/material";
import {
  DisplayImage,
  FileModal,
} from "../../../../components/Files/DisplayFiles";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InvoiceMail from "../InvoiceMail";
import InvoiceDetails from "../InvoiceDetails";
import AxiosInstance from "../../../AxiosInstance";

const InvoiceDetail = ({
  loader,
  companyName,
  location,
  invoicedata,
  setMail,
  handleEditClick,
  dropdownOpen,
  toggle,
  downloadPdf,
  handleCopy,
  open,
  setOpen,
  file,
  mail,
  data,
  options,
  navigate,
  sendMail,
  handleCancelInvoice,
  invoiceStatus,
  cdnUrl,
  setFile,
}) => {
  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="Quote-details">
          <div>
            <div className="d-flex justify-content-between buttonGroup">
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    marginRight: "10px",
                    width: "50px",
                    height: "40px",
                    marginBottom: "0px",
                    padding: "0px 0px",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-capitalize bg-button-blue-color text-white-color"
                >
                  <img
                    src={Previous}
                    alt=""
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
              </div>
              <div className="d-flex justify-content-end gap-2 buttonGroupthree">
                {companyName && (
                  <Button
                    className="bg-button-blue-color"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleCancelInvoice}
                    disabled={invoiceStatus === "Canceled"}
                  >
                    <img src={Payment} alt="Payment Icon" />
                    &nbsp;
                    {invoiceStatus === "Canceled"
                      ? "Invoice Cancelled"
                      : "Cancel Invoice"}
                  </Button>
                )}
                {/* <Button
                  className="bg-button-blue-color"
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    navigate(`/${companyName}/invoice-payment`, {
                      state: {
                        navigats: [
                          ...location.state.navigats,
                          "/invoice-payment",
                        ],
                        id: invoicedata.InvoiceId,
                      },
                    })
                  }
                >
                  <img src={Payment} />
                  &nbsp; Collect Payment
                </Button> */}
                {companyName && (
                  <Button
                    className="bg-button-blue-color"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      navigate(`/${companyName}/invoice-payment`, {
                        state: {
                          navigats: [
                            ...location.state.navigats,
                            "/invoice-payment",
                          ],
                          id: invoicedata.InvoiceId,
                        },
                      })
                    }
                    disabled={invoiceStatus === "Canceled"}
                  >
                    <img src={Payment} />
                    {/* &nbsp; Collect Payment */}
                    &nbsp;{" "}
                    {invoiceStatus === "Canceled"
                      ? "Collect Payment"
                      : "Collect Payment"}
                  </Button>
                )}
                <Button
                  className="bg-button-blue-color"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setMail(true);
                  }}
                >
                  <MailOutlineOutlinedIcon style={{ marginRight: "8px" }} />
                  Send Email
                </Button>

                {companyName && (
                  <Button
                    className="outline-button-blue-color tect-blue-color"
                    outline
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick();
                    }}
                    disabled={invoiceStatus === "Canceled"}
                  >
                    <img src={edit} />
                    &nbsp; {invoiceStatus === "Canceled" ? "Edit" : "Edit"}
                  </Button>
                )}
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  style={{ zIndex: 1 }}
                >
                  <DropdownToggle
                    className="text-blue-color outline border-blue-color"
                    style={{
                      background: "none",
                      border: "1px solid ",
                    }}
                  >
                    {" "}
                    <MoreHorizIcon /> More Actions
                  </DropdownToggle>
                  <DropdownMenu
                    className="border-blue-color"
                    style={{
                      borderRadius: "16px",
                      border: "1px solid ",
                    }}
                  >
                    <DropdownItem className="text-blue-color" onClick={toggle}>
                      Collect Signature
                    </DropdownItem>
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        downloadPdf();
                      }}
                    >
                      Download PDF
                    </DropdownItem>
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        sendMail();
                      }}
                    >
                      Send reciept to mail
                    </DropdownItem>
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        // generateInvoicePDF(invoicedata, "print");
                        downloadPdf(invoicedata, "print");
                      }}
                    >
                      Print
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div>
              <Card
                className="p-3 my-4 border-blue-color"
                style={{ border: "1px solid", borderRadius: "12px" }}
              >
                <div className="justify-content-between d-flex align-items-center">
                  {/* <div className="d-flex align-items-center">
                    <img src={InvoiceImage} />
                    <p
                      className="mb-0 mx-2 text-blue-color"
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        padding: "7px 12px 7px",
                        borderRadius: "25px",
                        backgroundColor:
                          invoicedata?.Status === "Paid"
                            ? "#AEF6D3"
                            : invoicedata?.Status === "Unpaid"
                            ? "#FFC6C6"
                            : invoicedata?.Status === "Canceled"
                            ? "#FFE9BC"
                            : "",
                      }}
                    >
                      <p
                        style={{
                          backgroundColor:
                            invoicedata?.Status === "Paid"
                              ? "#089F57"
                              : invoicedata?.Status === "Unpaid"
                              ? "#FF0000"
                              : invoicedata?.Status === "Canceled"
                              ? "#FFAF0B"
                              : "",
                          borderRadius: "50%",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: 0,
                        }}
                      ></p>
                      {invoicedata?.Status}
                    </p>
                  </div> */}
                  <div className="d-flex align-items-center">
                    <img src={InvoiceImage} alt="Invoice" />
                    <p
                      className="mb-0 mx-2 text-blue-color"
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        padding: "7px 12px 7px",
                        borderRadius: "25px",
                        backgroundColor:
                          invoiceStatus === "Paid"
                            ? "#AEF6D3"
                            : invoiceStatus === "Unpaid"
                            ? "#FFC6C6"
                            : invoiceStatus === "Canceled"
                            ? "#FFE9BC"
                            : "",
                      }}
                    >
                      <p
                        style={{
                          backgroundColor:
                            invoiceStatus === "Paid"
                              ? "#089F57"
                              : invoiceStatus === "Unpaid"
                              ? "#FF0000"
                              : invoiceStatus === "Canceled"
                              ? "#FFAF0B"
                              : "",
                          borderRadius: "50%",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: 0,
                        }}
                      ></p>
                      {/* Displaying invoice status */}
                      {invoiceStatus}
                    </p>
                  </div>
                  <p
                    className="text-blue-color"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Invoice :#{invoicedata?.InvoiceNumber}
                  </p>
                </div>
                <div className="d-flex col-12">
                  <div className="col-9">
                    <h3
                      className="my-4 mb-0 text-blue-color"
                      style={{ fontWeight: 700 }}
                    >
                      {invoicedata?.customer?.FirstName || ""}{" "}
                      {invoicedata?.customer?.LastName || ""}
                      <img
                        src={Copy}
                        style={{ cursor: "pointer" }}
                        onClick={handleCopy}
                        alt="Copy Icon"
                        className="mx-2"
                      />
                    </h3>
                    <p className="text-blue-color" style={{ fontSize: "14px" }}>
                      {invoicedata?.Subject}
                    </p>
                    <div className="d-flex">
                      <div className="col-3">
                        <p
                          className="mb-0 Sub-title text-blue-color"
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          Property Address
                        </p>
                        <p
                          className="mb-0 text-data text-blue-color"
                          style={{ fontSize: "14px", width: "80%" }}
                        >
                          {invoicedata?.location?.Address || ""}{" "}
                          {invoicedata?.location?.City || ""}{" "}
                          {invoicedata?.location?.State || ""}{" "}
                          {invoicedata?.location?.Country || ""}{" "}
                          {invoicedata?.location?.Zip || ""}
                        </p>
                      </div>
                      <div className="col-3 ">
                        <p
                          className="mb-0 Sub-title text-blue-color"
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          Service Address
                        </p>
                        <p
                          className="mb-0 text-data text-blue-color"
                          style={{ fontSize: "14px", width: "80%" }}
                        >
                          {invoicedata?.location?.Address || ""}{" "}
                          {invoicedata?.location?.City || ""}{" "}
                          {invoicedata?.location?.State || ""}{" "}
                          {invoicedata?.location?.Country || ""}{" "}
                          {invoicedata?.location?.Zip || ""}
                        </p>
                      </div>
                      <div className="col-3 ">
                        <p
                          className="mb-0 Sub-title text-blue-color"
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          Contact Details
                        </p>
                        <p
                          className="mb-0 text-data text-blue-color"
                          style={{ fontSize: "14px", width: "80%" }}
                        >
                          {invoicedata?.customer?.EmailAddress || ""} <br />
                          {invoicedata?.customer?.PhoneNumber || ""}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <Table borderless>
                      <TableHead>
                        <tr>
                          <th>Invoice Details</th>
                        </tr>
                      </TableHead>
                      <tbody>
                        <tr>
                          <td
                            scotbodype="row"
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            Issued Date
                          </td>
                          <td scope="row">
                            {new Date(invoicedata.IssueDate).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </td>
                        </tr>
                        {/* <tr>
                          <td
                            scope="row"
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            Due
                          </td>
                          <td scope="row">{invoicedata.DueDate}</td>
                        </tr> */}
                        {invoicedata.DueDate && (
                          <tr>
                            <td
                              scope="row"
                              style={{
                                fontSize: "14px",
                                fontWeight: 600,
                                
                              }}
                            >
                              Due
                              {new Date(invoicedata.DueDate).toLocaleDateString(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="d-flex property-address"></div>
                <hr />
                {invoicedata?.Items?.length > 0 ? (
                  invoicedata?.Items?.map((item, index) => (
                    <div className="d-flex" key={index}>
                      <div className="col-4">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            Product/ Service
                          </p>
                        )}
                        <div className="d-flex justify-content-between products mb-3">
                          <div>
                            <p
                              className="mb-0 Sub-title text-blue-color"
                              style={{
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >
                              {item?.Name}
                            </p>
                            <p
                              className="mb-0 text-data text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {item?.Description}
                            </p>
                          </div>
                          <FileModal
                            open={open}
                            setOpen={setOpen}
                            file={file}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Product/Service Image
                          </p>
                        )}
                        <p style={{ textAlign: "center" }}>
                          {item.Attachment ? (
                            <img
                              style={{
                                height: "40px",
                                marginBottom: "30px",
                                cursor: "pointer",
                              }}
                              src={`${cdnUrl}/upload/${item.Attachment}`}
                              onClick={() => {
                                setFile(item.Attachment);
                                setOpen(true);
                              }}
                              alt="Thumbnail"
                            />
                          ) : (
                            "-"
                          )}
                        </p>
                      </div>
                      <div className="col-2">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Unit/Hour
                          </p>
                        )}
                        <p
                          className="mb-0 text-data text-blue-color unit"
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          {item?.Unit
                            ? item?.Unit
                            : item?.Square
                            ? item?.Square
                            : item?.Hour}
                        </p>
                      </div>
                      <div className="col-2">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              textAlign: "end",
                            }}
                          >
                            Unit Cost/Hour Cost
                          </p>
                        )}
                        <p
                          className="mb-0 text-data text-blue-color per-unit"
                          style={{
                            fontSize: "14px",
                            textAlign: "end",
                          }}
                        >
                          $
                          {item?.CostPerHour
                            ? Number(item.CostPerHour).toFixed(2)
                            : item?.CostPerSquare
                            ? Number(item.CostPerSquare).toFixed(2)
                            : Number(item?.CostPerUnit).toFixed(2)}
                        </p>
                      </div>
                      <div className="col-2 text-end">
                        {index === 0 && (
                          <p
                            className="mb-3 Sub-heading text-blue-color totalInput"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            Total
                          </p>
                        )}
                        <p
                          className="mb-0 text-data text-blue-color total-pro"
                          style={{ fontSize: "14px" }}
                        >
                          $
                          {Number(item?.Total)
                            ? Number(item.Total).toFixed(2)
                            : ""}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-blue-color">
                    <p
                      className="mb-0"
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      Product/Service not available
                    </p>
                  </div>
                )}

                <hr />
                <div className="d-flex products-colum">
                  <div className="col-8 contc">
                    <p
                      className="mb-2 text-blue-color"
                      style={{ fontSize: "14px" }}
                    >
                      {invoicedata?.CustomerMessage}
                    </p>
                    <p
                      className="text-data text-blue-color"
                      style={{ fontSize: "14px", width: "70%" }}
                    >
                      {invoicedata?.ContractDisclaimer}
                    </p>
                  </div>
                  <div className="col-4 contc">
                    <div className="d-flex justify-content-between">
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        Subtotal
                      </p>
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        ${invoicedata?.subTotal}
                      </p>
                    </div>

                    {invoicedata?.Discount ? (
                      <div className="d-flex justify-content-between">
                        <p
                          className=" text-blue-color"
                          style={{ fontSize: "14px" }}
                        >
                          Discount
                        </p>
                        <p
                          style={{ fontSize: "14px" }}
                          className="text-blue-color"
                        >
                          {invoicedata?.Discount} %
                        </p>
                      </div>
                    ) : null}
                    {invoicedata?.Tax ? (
                      <div className="d-flex justify-content-between">
                        <p
                          className="text-blue-color"
                          style={{ fontSize: "14px" }}
                        >
                          Tax
                        </p>
                        <p
                          style={{ fontSize: "14px" }}
                          className="text-blue-color"
                        >
                          {invoicedata?.Tax} %
                        </p>
                      </div>
                    ) : null}
                    <hr className="my-0 mb-2" />
                    <div className="d-flex justify-content-between">
                      <p
                        className="mb-2 text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        <b>Total</b>
                      </p>
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        <b>${invoicedata?.Total}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
              <Card
                className="p-3 my-4 text-blue-color"
                style={{ border: "1px solid", borderRadius: "12px" }}
              >
                <p className="text-blue-color" style={{ fontWeight: 600 }}>
                  Internal notes and attachments <HelpOutlineOutlinedIcon />
                </p>
                <div
                  className=""
                  style={{
                    border: "0.5px solid rgba(6, 49, 100, 80%)",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <p>{invoicedata?.Notes || "No Notes Available"}</p>
                </div>
                <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {invoicedata?.Attachment && (
                    <DisplayImage
                      files={invoicedata?.Attachment}
                      IsDeleted={false}
                    />
                  )}
                </div>
              </Card>

              <Card
                className="p-3 my-4 text-blue-color"
                style={{ border: "1px solid ", borderRadius: "12px" }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <p
                      className="bg-blue-color text-white-color"
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {invoicedata?.customer?.FirstName?.charAt(
                        0
                      )?.toUpperCase()}
                      {invoicedata?.customer?.LastName?.charAt(
                        0
                      )?.toUpperCase()}
                    </p>
                    <div className="mx-2">
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        <b>
                          {invoicedata?.customer?.FirstName || ""}{" "}
                          {invoicedata?.customer?.LastName || ""}
                        </b>
                      </p>
                      <p style={{ fontSize: "12px" }} className="">
                        Created :{" "}
                        {new Date(invoicedata?.updatedAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      <InvoiceMail
        modal={mail}
        setModal={setMail}
        customerData={invoicedata?.customer}
        Total={invoicedata?.Total}
        invoiceData={invoicedata}
        data={data}
        QuoteNumber={invoicedata?.QuoteNumber}
        Attachment={invoicedata?.Attachment}
        DueDate={invoicedata?.DueDate}
      />
    </>
  );
};

export default InvoiceDetail;
