// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 1680 */
@media (max-width: 1700px) {}

/* 1500 */
@media (max-width: 1680px) {}

/* 1440 */
@media (max-width: 1449px) {}

/* 1280 */
@media (max-width: 1439px) {}

/* 992 */
@media (max-width: 1279px) {
.active_deactive.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.bg-orange-color.active_deactive.css-16wdwr3-MuiPaper-root{
    width: 100% !important;
}
.plan_company.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.css-1i0icgc-MuiPaper-root{
    width: 100% !important;
}
}
@media (max-width: 789px) {
 .active_box{
    margin-right: 0px !important;
 }   
}
/* 768 */
@media (max-width: 991px) {}

/* 576 */
@media (max-width: 767px) {
    .plan-company-graph{
        margin-left: 0.5% !important;
    }
}

/* 525 */
@media (max-width: 575px) {
    .plan-company,.active_deactive-graph{
        flex-direction: column;
    }
    .inactive-para{
        margin-left: 3% !important;
    }
    
}

/* 480 */
@media (max-width: 524px) {}

/* 425 */
@media (max-width: 479px) {}

/* 375 */
@media (max-width: 424px) {}

/* 320 */
@media (max-width: 374px) {}

/* 280 */
@media (max-width: 319px) {}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/style.css"],"names":[],"mappings":"AAAA,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,QAAQ;AACR;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,sBAAsB;AAC1B;AACA;AACA;CACC;IACG,4BAA4B;CAC/B;AACD;AACA,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR;IACI;QACI,4BAA4B;IAChC;AACJ;;AAEA,QAAQ;AACR;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,0BAA0B;IAC9B;;AAEJ;;AAEA,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B","sourcesContent":["/* 1680 */\n@media (max-width: 1700px) {}\n\n/* 1500 */\n@media (max-width: 1680px) {}\n\n/* 1440 */\n@media (max-width: 1449px) {}\n\n/* 1280 */\n@media (max-width: 1439px) {}\n\n/* 992 */\n@media (max-width: 1279px) {\n.active_deactive.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.bg-orange-color.active_deactive.css-16wdwr3-MuiPaper-root{\n    width: 100% !important;\n}\n.plan_company.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.css-1i0icgc-MuiPaper-root{\n    width: 100% !important;\n}\n}\n@media (max-width: 789px) {\n .active_box{\n    margin-right: 0px !important;\n }   \n}\n/* 768 */\n@media (max-width: 991px) {}\n\n/* 576 */\n@media (max-width: 767px) {\n    .plan-company-graph{\n        margin-left: 0.5% !important;\n    }\n}\n\n/* 525 */\n@media (max-width: 575px) {\n    .plan-company,.active_deactive-graph{\n        flex-direction: column;\n    }\n    .inactive-para{\n        margin-left: 3% !important;\n    }\n    \n}\n\n/* 480 */\n@media (max-width: 524px) {}\n\n/* 425 */\n@media (max-width: 479px) {}\n\n/* 375 */\n@media (max-width: 424px) {}\n\n/* 320 */\n@media (max-width: 374px) {}\n\n/* 280 */\n@media (max-width: 319px) {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
