import React, { createContext, useState, useContext, useEffect } from "react";
import AxiosInstance from "../../Views/AxiosInstance";

const StaffContext = createContext();

export const useStaffContext = () => {
  return useContext(StaffContext);
};

export const StaffProvider = ({ children }) => {
  const [staffData, setStaffData] = useState(undefined);

  const fetchStaffData = async () => {
    try {
      if (staffData) return;
      const workerId = localStorage.getItem("worker_id") || "";
      const response = await AxiosInstance.get(
        `${process.env.REACT_APP_BASE_API}/worker/get/${workerId}`
      );
      setStaffData(response?.data?.data?.permissions);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  useEffect(() => {
    fetchStaffData();
  }, []);

  return (
    <StaffContext.Provider value={{ staffData, setStaffData, fetchStaffData }}>
      {children}
    </StaffContext.Provider>
  );
};
