import React from "react";

import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import { Circles } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

import client from "../../../../assets/White-sidebar-icon/Customer.svg"
import clientcontact from "../../../../assets/White-sidebar-icon/Home.svg"

import Address from "../../../../components/Address";
import InputText from "../../../../components/InputFields/InputText";

const AddCustomer = ({
  formik,
  handleChange,
  loader,
  countries,
  selectedCountry,
  setSelectedCountry,
  handlePhoneChange
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="justify-content-center align-items-center mb-3 mt-5 client">
      <div className="d-flex align-items-center text-white-color">
        <Button
          style={{
            marginRight: "10px",
            width: "50px",
            height: "40px",
            marginBottom: "10px",
            padding: "0px 0px",
            borderRadius: "4px",
          }}
          onClick={() => {
            navigate(-1);
          }}
          className="text-capitalize bg-button-blue-color"
        >
          <ArrowBackOutlinedIcon />
        </Button>
        <h2 className="text-blue-color mb-2" style={{ fontWeight: 700 ,fontSize:"30px"}}>
          {!location.state.id ? " New Customer" : " Edit Customer"}
        </h2>
      </div>
      <Card
        className="my-2 col-12 p-4 border-blue-color"
        style={{ borderRadius: "20px" }}
      >
        <div style={{ display: "flex", gap: "15px" }} className="client-main">
          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{ paddingRight: "20px" }}
          >
            <CardTitle
              tag="h5"
              className="text-blue-color"
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="bg-blue-color"
                style={{
                  borderRadius: "50%",
                  marginRight: "10px",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <img src={client} alt="Client Details" />
              </div>
              Customer details
            </CardTitle>
            <div className="my-4 mb-0 ">
              <InputText
                value={formik.values?.FirstName}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.FirstName && Boolean(formik.errors.FirstName)
                }
                helperText={formik.touched.FirstName && formik.errors.FirstName}
                name="FirstName"
                placeholder="Enter First Name here..."
                label="First Name"
                type="text"
                className="text-blue-color w-100 mb-3 "
                fieldHeight="56px"
              />
            </div>
            <div className="my-2 mb-0 lastnametxt lastnamemb">
              <InputText
                value={formik.values?.LastName}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.LastName && Boolean(formik.errors.LastName)
                }
                helperText={formik.touched.LastName && formik.errors.LastName}
                name="LastName"
                placeholder="Enter Last Name here..."
                label="Last Name"
                type="text"
                className="text-blue-color w-100"
                fieldHeight="56px"
              />
            </div>
            <div className="my-2 phonei">
              <h5
                className="mb-3 text-blue-color phone-number mt-4"
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                Contact details
              </h5>
            </div>
            <div className="my-4 mb-0">
              <InputText
                value={formik.values?.PhoneNumber}
                onChange={handlePhoneChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.PhoneNumber &&
                  Boolean(formik.errors.PhoneNumber)
                }
                helperText={
                  formik.touched.PhoneNumber && formik.errors.PhoneNumber
                }
                name="PhoneNumber"
                id="PhoneNumber"
                placeholder="Enter Phone Number here..."
                label="PhoneNumber"
                type="text"
                className="text-blue-color w-100 mb-3"
                fieldHeight="56px"
              />
            </div>
            <div className="my-2 mb-0 lastnametxt">
              <InputText
                value={formik.values.EmailAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.EmailAddress &&
                  Boolean(formik.errors.EmailAddress)
                }
                helperText={
                  formik.touched.EmailAddress && formik.errors.EmailAddress
                }
                name="EmailAddress"
                id="EmailAddress"
                placeholder="Enter Email Address here..."
                label="Email Address"
                type="email"
                className="text-blue-color w-100"
                fieldHeight="56px"
              />
            </div>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 Property-details"
            style={{ paddingRight: "12px" }}
          >
            <CardTitle
              tag="h5"
              className="text-blue-color"
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="bg-blue-color"
                style={{
                  borderRadius: "50%",
                  marginRight: "10px",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <img src={clientcontact} alt="Property Details" />
              </div>
              Property details
            </CardTitle>
            {!location?.state?.id ||
            (location?.state?.id && formik.values?.location?.length <= 1) ? (
              <div className="my-4 mb-0 px-0">
                <Address
                  setSelectedCountry={setSelectedCountry}
                  selectedCountry={selectedCountry}
                  countries={countries}
                  handleChange={handleChange}
                  formik={formik}
                />
              </div>
            ) : (
              <div className="my-4 mb-0 px-0" style={{ width: "98%" }}>
                <Card
                  style={{ backgroundColor: "rgb(216, 231, 238)" }}
                  className="w-100 d-flex flex-row justify-content-center align-items-start py-3 px-0 mx-0"
                >
                  <div
                    style={{ width: "15%", minHeight: "100%" }}
                    className="d-flex align-items-start justify-content-center"
                  >
                    <LightbulbOutlinedIcon
                      style={{ color: "rgb(42, 79, 97)", height: "50px" }}
                    />
                  </div>
                  <div
                    style={{
                      borderLeft: "1px solid rgb(42, 79, 97)",
                    }}
                  >
                    <CardHeader
                      className="border-0 d-flex align-items-center"
                      style={{
                        backgroundColor: "rgb(216, 231, 238)",
                      }}
                    >
                      This Customer has multiple properties
                    </CardHeader>
                    <CardBody>
                      Multiple properties can only edited individually. To edit
                      a property, select if from the Customer's list of
                      properties.
                    </CardBody>
                  </div>
                </Card>
                <FormGroup className="py-3" check inline>
                  <Input
                    type="checkbox"
                    name="billing_same_property"
                    checked={formik.values?.billing_same_property}
                    onChange={handleChange}
                  />
                  <Label
                    check
                    style={{
                      color: "rgba(6, 49, 100, 70%)",
                      fontSize: "12px",
                    }}
                  >
                    Billing address is the same as property address
                  </Label>
                </FormGroup>
              </div>
            )}
          </div>
        </div>
        <div
          className="d-flex justify-content-between button-responsive"
          style={{ marginTop: "70px" }}
        >
          <div>
            <Button
              className="footer-buttons  outline-button-blue-color"
              style={{ fontSize: "16px" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
          {loader ? (
            <div className="d-flex justify-content-center text-blue-color ">
              <Circles
                height="20"
                width="20"
                color="#063164"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                visible={loader}
              />
            </div>
          ) : (
            <div className="gap-3 d-flex sec-button-section">
              <Button
                className="save-client-button bg-blue-color"
                style={{ fontSize: "16px" }}
                onClick={formik.handleSubmit}
              >
                {location.state.id ? "Update Customer" : "Save Customer"}
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default AddCustomer;
