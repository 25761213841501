import {
  Box,
  FormGroup,
  Popover,
  Table,
  TextField,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import AxiosInstance from "../../Views/AxiosInstance";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../components/Login/Auth";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  TabPane,
} from "reactstrap";
import InputText from "../InputFields/InputText";
import { DisplayImage } from "../Files/DisplayFiles";
import { Files } from "../Files";
import toast from "react-hot-toast";
import { Watch } from "react-loader-spinner";
// 5150 start
const InternalNotes = ({
  notes,
  setNotes,
  attachments,
  setAttachments,
  noBorder,
}) => {
  const handleNoteChange = (event) => {
    setNotes(event.target.value);
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setAttachments((prev) => [...prev, ...files]);
  };
  return (
    <>
      <Card
        style={{
          border: noBorder ? "none" : "1px solid black",
          padding: "15px",
          borderRadius: "10px",
          borderColor: "rgba(6, 49, 100, 30%)",
        }}
      >
        <p className="text-blue-color mx-3" style={{ fontWeight: 600 }}>
          Internal notes and attachments <HelpOutlineOutlinedIcon />
        </p>
        <div
          class=""
          style={{
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {/* <TextField
            className="note-details text-blue-color border-blue-color"
            id="note"
            name="note"
            label="Note details"
            value={notes}
            onChange={handleNoteChange}
            defaultValue=""
            placeholder="Enter notes here..."
            type="text"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#063164",
                },
                "&:hover fieldset": {
                  borderColor: "#063164",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#063164",
                },
                "& input": {
                  height: "70px",
                  alignItems: "start",
                },
              },
            }}
            InputProps={{
              readOnly: false,
            }}
            style={{ width: "100%", paddingTop: "0px" }}
            InputLabelProps={{
              style: { fontSize: "15px" },
            }}

          /> */}
          <TextField
            className="note-details text-blue-color border-blue-color"
            // id="note"
            name="note"
            label="Note details"
            value={notes}
            onChange={handleNoteChange}
            defaultValue=""
            type="text"
            id="outlined-multiline-static"
            placeholder="Enter A  Notes here..."
            multiline
            rows={3}
          />
          <Files
            files={attachments}
            setFiles={(value) => setAttachments(value)}
          />
          <DisplayImage
            files={attachments}
            setFiles={(value) => setAttachments(value)}
            IsDeleted={true}
          />
          <hr />
          <div>
            <p style={{ fontSize: "14px" }} className="text-blue-color">
              Link note to related
            </p>
            <FormGroup check>
              <div className="d-flex gap-2 align-items-center">
                <Input
                  type="checkbox"
                  className="text-blue-color"
                  style={{ marginTop: 0 }}
                />{" "}
                <Label
                  className="text-blue-color"
                  style={{ fontSize: "15px", marginBottom: 0 }}
                  check
                >
                  Invoices
                </Label>
              </div>
            </FormGroup>
          </div>
        </div>
      </Card>
    </>
  );
};
// 5150 end

const OneOffContract = ({
  formik,
  isCalendarVisible,
  setIsCalendarVisible,
}) => {
  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };

  const handleStartDateChange = (e) => {
    const startDate = e.target.value;
    formik.setFieldValue("OneoffJob.StartDate", startDate);

    if (
      formik.values.OneoffJob?.EndDate &&
      startDate > formik.values.OneoffJob.EndDate
    ) {
      formik.setFieldValue("OneoffJob.EndDate", startDate);
    }
  };

  const handleEndDateChange = (e) => {
    const endDate = e.target.value;
    if (
      formik.values.OneoffJob?.StartDate &&
      endDate >= formik.values.OneoffJob.StartDate
    ) {
      formik.setFieldValue("OneoffJob.EndDate", endDate);
    } else if (
      formik.values.OneoffJob?.StartDate &&
      endDate < formik.values.OneoffJob.StartDate
    ) {
      formik.setFieldValue(
        "OneoffJob.EndDate",
        formik.values.OneoffJob.StartDate
      );
    }
  };

  const handleStartTimeChange = (e) => {
    const startTime = e.target.value;
    formik.setFieldValue("OneoffJob.StartTime", startTime);

    if (
      formik.values.OneoffJob?.EndTime &&
      startTime > formik.values.OneoffJob.EndTime
    ) {
      formik.setFieldValue("OneoffJob.EndTime", startTime);
    }
  };

  const handleEndTimeChange = (e) => {
    const endTime = e.target.value;
    if (
      formik.values.OneoffJob?.StartTime &&
      endTime >= formik.values.OneoffJob.StartTime
    ) {
      formik.setFieldValue("OneoffJob.EndTime", endTime);
    } else if (
      formik.values.OneoffJob?.StartTime &&
      endTime < formik.values.OneoffJob.StartTime
    ) {
      formik.setFieldValue(
        "OneoffJob.EndTime",
        formik.values.OneoffJob.StartTime
      );
    }
  };

  return (
    <div className="col-md-4 col-lg-12 col-sm-12 first-tab jobs">
      <TabContent
        activeTab={1}
        className="text-start my-3 mx-0"
        style={{ padding: "2px" }}
      >
        <TabPane tabId={1}>
          <Col sm="12" className="d-flex">
            <Card className="mx-0" style={{ width: "100%" }}>
              <CardHeader
                className="d-flex justify-content-between"
                style={{
                  width: "100%",
                  alignItems: "center",
                  padding: "0 10px 0 10px",
                  borderBottom: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  className="my-3 text-blue-color"
                >
                  One off Contract Schedule
                </p>
                <Button
                  className="bg-button-blue-color hide-calendar text-white-color"
                  style={{
                    padding: "0 14px 0 14px",
                    fontSize: "12px",
                    marginTop: 0,
                    height: "32px",
                    borderRadius: "2px",
                  }}
                  onClick={toggleCalendarVisibility}
                >
                  {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
                </Button>
              </CardHeader>
              <CardBody>
                <div className="d-flex row start-end-date mb-2">
                  <FormGroup className="col-6 start-date">
                    <Label
                      for="startDate"
                      style={
                        {
                          // color: "#000000",
                          // fontWeight: 600,
                          // fontSize: "12px",
                        }
                      }
                      className="mt-2 text-blue-color"
                    >
                      Start Date
                    </Label>
                    <Input
                      id="startDate"
                      placeholder="with a placeholder"
                      type="date"
                      name="OneoffJob.StartDate"
                      onChange={formik.handleChange}
                      value={formik?.values?.OneoffJob?.StartDate}
                      style={{ fontSize: "12px", height: "40px" }}
                      className="text-blue-color border-blue-color"
                    />
                  </FormGroup>
                  <FormGroup className="col-6 end-date">
                    <Label
                      htmlFor="endDate"
                      style={
                        {
                          // color: "#000000",
                          // fontWeight: 600,
                          // fontSize: "12px",
                        }
                      }
                      className="mt-2"
                    >
                      End Date (optional)
                    </Label>
                    <Input
                      id="endDate"
                      placeholder="with a placeholder"
                      type="date"
                      name="OneoffJob.EndDate"
                      onChange={handleEndDateChange}
                      value={formik?.values?.OneoffJob?.EndDate}
                      style={{ fontSize: "12px", height: "40px" }}
                      className="text-blue-color border-blue-color"
                    />
                  </FormGroup>
                </div>

                <Label
                  htmlFor="startTime"
                  style={
                    {
                      // color: "#000000",
                      // fontWeight: 600,
                      // fontSize: "12px",
                    }
                  }
                  className="mt-2"
                >
                  Start Time & End Time
                </Label>

                <div className="d-flex time-section mb-2">
                  <Col
                    className="col-6 border-blue-color"
                    style={{
                      borderBottomLeftRadius: "10px",
                      border: "1px solid rgba(6, 49, 100, 30%)",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    <Input
                      id="startTime"
                      placeholder="Start Time"
                      type="time"
                      name="OneoffJob.StartTime"
                      className="text-blue-color border-blue-color"
                      onChange={handleStartTimeChange}
                      value={formik?.values?.OneoffJob?.StartTime}
                      style={{
                        fontSize: "14px",
                        border: "none",
                        borderBottomLeftRadius: "10px",
                        borderTopLeftRadius: "10px",
                        height: "40px",
                      }}
                    />
                  </Col>
                  <Col
                    className="col-6 border-blue-color"
                    style={{
                      borderBottomRightRadius: "10px",
                      borderTopRightRadius: "10px",
                      border: "1px solid rgba(6, 49, 100, 30%)",
                      borderLeft: "none",
                    }}
                  >
                    <Input
                      id="EndTime"
                      placeholder="Start Time"
                      type="time"
                      name="OneoffJob.EndTime"
                      onChange={handleEndTimeChange}
                      value={formik?.values?.OneoffJob?.EndTime}
                      style={{
                        fontSize: "14px",
                        border: "none",
                        borderBottomRightRadius: "14px",
                        borderTopRightRadius: "14px",
                        height: "40px",
                      }}
                    />
                  </Col>
                </div>
                <Label htmlFor="Repeats" className="mt-2">
                  Repeats
                </Label>
                <Input
                  type="select"
                  name="OneoffJob.Repeats"
                  className="text-blue-color border-blue-color"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  value={formik.values?.OneoffJob?.Repeats}
                  style={{
                    fontSize: "14px",
                    border: "1px solid rgba(6, 49, 100, 30%)",
                    borderRadius: "10px",
                    fontSize: "12px",
                    height: "40px",
                  }}
                >
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </Input>

                <div>
                  <FormGroup className="my-3 mb-0 d-flex">
                    <Input
                      type="checkbox"
                      name="OneoffJob.ScheduleLetter"
                      onChange={formik.handleChange}
                      checked={formik.values?.OneoffJob?.ScheduleLetter}
                    />{" "}
                    <Label
                      className="text-blue-color"
                      style={{
                        fontSize: "12px",
                        color: "rgba(6,49,100,0.7)",
                        fontWeight: "400",
                        marginTop: "5px",
                      }}
                    >
                      Schedule letter
                    </Label>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Col>
        </TabPane>
      </TabContent>
    </div>
  );
};
const RecurringContract = ({
  formik,
  isCalendarVisible,
  setIsCalendarVisible,
}) => {
  const [duration, setDuration] = useState("");
  const [value, setValue] = useState(6);
  const [lastDate, setLastDate] = useState("");
  const [isCardVisible, setIsCardVisible] = useState(true);
  useEffect(() => {
    if (lastDate && formik.values?.RecuringJob?.StartDate) {
      calculateTotalVisits(
        formik.values?.RecuringJob?.StartDate,
        lastDate,
        formik.values?.RecuringJob?.Repeats
      );
    }
  }, [
    lastDate,
    formik.values?.RecuringJob?.StartDate,
    formik.values?.RecuringJob?.Repeats,
  ]);

  const handleStartTimeChange = (e) => {
    const StartTime = e.target.value;
    formik.setFieldValue("RecuringJob.StartTime", StartTime);

    if (
      formik.values.RecuringJob?.EndTime &&
      StartTime > formik.values.RecuringJob.EndTime
    ) {
      formik.setFieldValue("RecuringJob.EndTime", StartTime);
    }
  };
  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
    setIsCardVisible(!isCardVisible);
  };

  const handleEndTimeChange = (e) => {
    const EndTime = e.target.value;
    formik.setFieldValue("RecuringJob.EndTime", EndTime);

    if (
      formik.values.RecuringJob?.StartTime &&
      EndTime < formik.values.RecuringJob.StartTime
    ) {
      formik.setFieldValue("RecuringJob.StartTime", EndTime);
    }
  };

  const handleDurationChange = (e) => {
    const newDuration = e.target.value;
    setDuration(newDuration);
    calculateFutureDate(
      value,
      newDuration,
      formik.values?.RecuringJob?.StartDate
    );
    calculateTotalVisits(
      formik.values?.RecuringJob?.StartDate,
      lastDate,
      formik.values?.RecuringJob?.Repeats
    );
    formik.setFieldValue("RecuringJob.Duration", newDuration);
  };

  const handleValueChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    formik.setFieldValue("RecuringJob.Frequency", newValue);
  };

  const calculateFutureDate = (num, dur, startDate) => {
    if (num && dur && startDate) {
      const durationNumber = parseInt(num, 10);
      let futureDate;

      switch (dur) {
        case "day(s)":
          futureDate = moment(startDate).add(durationNumber, "days");
          break;
        case "week(s)":
          futureDate = moment(startDate).add(durationNumber, "weeks");
          futureDate.subtract(1, "days");
          break;
        case "month(s)":
          futureDate = moment(startDate).add(durationNumber, "months");
          futureDate.subtract(1, "days");
          break;
        case "year(s)":
          futureDate = moment(startDate).add(durationNumber, "years");
          futureDate.subtract(1, "days");
          break;
        default:
          futureDate = moment(startDate);
      }

      setLastDate(futureDate.format("MMMM DD, YYYY"));
    } else {
      setLastDate("");
    }
  };

  // const calculateTotalVisits = (startDate, lastDate, Repeats) => {
  //   if (
  //     !startDate ||
  //     !lastDate ||
  //     !moment(startDate).isValid() ||
  //     !moment(lastDate).isValid()
  //   ) {
  //     return 0;
  //   }

  //   const start = moment(startDate);
  //   const end = moment(lastDate);

  //   let totalVisits = 0;
  //   switch (Repeats) {
  //     case "Weekly on":
  //       totalVisits = Math.floor(end.diff(start, "weeks", true)) + 1;
  //       break;
  //     case "Every 2 Weeks on":
  //       totalVisits = Math.floor(end.diff(start, "weeks", true) / 2) + 1;
  //       break;
  //     case "Monthly on the date":
  //       totalVisits = Math.floor(end.diff(start, "months", true)) + 1;
  //       break;
  //     default:
  //       totalVisits = 0;
  //   }

  //   return totalVisits < 1 ? 1 : totalVisits;
  // };
  useEffect(() => {
    if (formik.values?.RecuringJob?.StartDate && duration && value) {
      calculateFutureDate(
        value,
        duration,
        formik.values?.RecuringJob?.StartDate
      );
    }
  }, [value, duration, formik.values?.RecuringJob?.StartDate]);

  useEffect(() => {
    if (lastDate && formik.values?.RecuringJob?.StartDate) {
      const total = calculateTotalVisits(
        formik.values?.RecuringJob?.StartDate,
        lastDate,
        formik.values?.RecuringJob?.Repeats
      );
      // Optionally, you could store this in the state if you need to
    }
  }, [
    lastDate,
    formik.values?.RecuringJob?.StartDate,
    formik.values?.RecuringJob?.Repeats,
  ]);

  const calculateTotalVisits = (startDate, lastDate, Repeats) => {
    if (!startDate || !lastDate) {
      return "-";
    }
    const start = moment(startDate);
    const end = moment(lastDate);

    let totalVisits = 0;
    switch (Repeats) {
      case "Weekly on":
        totalVisits = Math.floor(end.diff(start, "weeks", true)) + 1;
        break;
      case "Every 2 Weeks on":
        totalVisits = Math.floor(end.diff(start, "weeks", true) / 2) + 1;
        break;
      case "Monthly on the date":
        totalVisits = Math.floor(end.diff(start, "months", true)) + 1;
        break;
      default:
        totalVisits = 0;
    }

    return totalVisits > 0 ? totalVisits : "-";
  };

  const [Repeats, setRepeats] = useState(
    formik?.values?.RecuringJob?.Repeats || ""
  );

  const handleRepeatChange = (e) => {
    const selectedValue = e.target.value;
    setRepeats(selectedValue);
    formik.setFieldValue("RecuringJob.Repeats", selectedValue);
  };

  const totalVisits = calculateTotalVisits(
    formik?.values?.RecuringJob?.StartDate,
    lastDate,
    Repeats
  );
  useEffect(() => {
    const durationValue = formik.values?.RecuringJob?.Duration;

    if (durationValue && formik.values?.RecuringJob?.StartDate) {
      setDuration(durationValue);
      calculateFutureDate(
        value,
        durationValue,
        formik.values?.RecuringJob?.StartDate
      );
    }
  }, [
    formik.values?.RecuringJob?.Duration,
    formik.values?.RecuringJob?.StartDate,
  ]);

  useEffect(() => {
    if (lastDate && formik.values?.RecuringJob?.StartDate) {
      calculateTotalVisits(
        formik.values?.RecuringJob?.StartDate,
        lastDate,
        formik.values?.RecuringJob?.Repeats
      );
    }
  }, [lastDate, formik.values?.RecuringJob?.StartDate]);

  useEffect(() => {
    const frequency = formik.values?.RecuringJob?.Frequency;
    if (frequency) {
      setValue(frequency);
    } else {
      setValue(0);
    }
  }, [formik.values?.RecuringJob?.Frequency]);

  return (
    <div className="col-md-4 col-lg-12 col-sm-12 first-tab mb-2">
      <Card className="p-0">
        <CardHeader
          className="d-flex justify-content-between"
          style={{
            width: "100%",
            alignItems: "center",
            padding: "0 10px 0 10px",
            borderBottom: "none",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              fontWeight: 600,
            }}
            className="my-3 text-blue-color"
          >
            Recurring Schedule
          </p>
          <Button
            className="bg-button-blue-color text-white-color"
            style={{
              padding: "0 14px 0 14px",
              fontSize: "12px",
              marginTop: 0,
              height: "32px",
              borderRadius: "2px",
            }}
            onClick={toggleCalendarVisibility}
          >
            {isCalendarVisible ? "Hide Calendar" : "Show Calendar"}
          </Button>
        </CardHeader>

        <CardBody>
          <FormGroup>
            <Label htmlFor="startDate">Start Date</Label>
            <Input
              id="startDate"
              type="date"
              name="RecuringJob.StartDate"
              onChange={formik.handleChange}
              className="text-blue-color border-blue-color"
              value={formik.values?.RecuringJob?.StartDate}
            />
          </FormGroup>

          <Label htmlFor="timeRange" className="mt-2">
            Start Time & End Time
          </Label>
          <div className="d-flex gap-1">
            <Col className="col-6">
              <Input
                id="startTime"
                type="time"
                name="RecuringJob.StartTime"
                className="text-blue-color border-blue-color"
                onChange={handleStartTimeChange}
                value={formik?.values?.RecuringJob?.StartTime}
              />
            </Col>
            <Col className="col-6">
              <Input
                id="endTime"
                type="time"
                name="RecuringJob.EndTime"
                className="text-blue-color border-blue-color"
                onChange={handleEndTimeChange}
                value={formik.values?.RecuringJob?.EndTime}
              />
            </Col>
          </div>
          <FormGroup>
            <Label htmlFor="Repeats" className="mt-2">
              Repeats
            </Label>
            <Input
              type="select"
              className="text-blue-color border-blue-color"
              name="RecuringJob.Repeats"
              onChange={handleRepeatChange}
              value={Repeats} // Use local state
            >
              <option value="">As needed - we won't prompt you</option>
              <option value={`Weekly on`}>
                Weekly on{" "}
                {moment(formik.values?.RecuringJob?.StartDate).format("dddd")}
              </option>
              <option value={`Every 2 Weeks on`}>
                Every 2 Weeks on{" "}
                {moment(formik.values?.RecuringJob?.StartDate).format("dddd")}
              </option>
              <option value={`Monthly on the date`}>
                Monthly on the{" "}
                {moment(formik.values?.RecuringJob?.StartDate).format("Do")} of
                the month
              </option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="duration" className="mt-2">
              Duration
            </Label>
            <Box
              display="flex"
              className="text-blue-color border-blue-color"
              flexDirection="row"
              alignItems="center"
            >
              <TextField
                label=""
                type="number"
                value={value}
                onChange={handleValueChange}
                style={{ width: "80px", marginRight: "10px" }}
                InputProps={{
                  inputProps: { min: 1 },
                }}
                className="text-blue-color border-blue-color"
              />
              <Select
                value={duration}
                onChange={handleDurationChange}
                style={{ width: "100%", borderRadius: "10px" }}
                className="text-blue-color border-blue-color"
              >
                <MenuItem className="text-blue-color " value="day(s)">
                  day(s)
                </MenuItem>
                <MenuItem className="text-blue-color " value="week(s)">
                  week(s)
                </MenuItem>
                <MenuItem className="text-blue-color " value="month(s)">
                  month(s)
                </MenuItem>
                <MenuItem className="text-blue-color " value="year(s)">
                  year(s)
                </MenuItem>
              </Select>
            </Box>
          </FormGroup>
          <div className="d-flex mt-2">
            <div
              className="col-4"
              style={{
                borderRight: "0.5px solid rgba(6,49,100, 0.8)",
              }}
            >
              <p
                className="mb-0 text-blue-color"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                First
              </p>
              <p
                className="mb-0 text-blue-color"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                {formik.values?.RecuringJob?.StartDate
                  ? moment(formik.values?.RecuringJob?.StartDate).format(
                      "MMMM DD, YYYY"
                    )
                  : "-"}
              </p>
            </div>
            <div
              className="col-4 mx-1"
              style={{
                borderRight: "0.5px solid rgba(6, 49, 100, 0.8)",
              }}
            >
              <p
                className="mb-0 text-blue-color"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                Last
              </p>
              <p
                className="mb-0 text-blue-color"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                {lastDate || "-"}
              </p>
            </div>
            <div className="col-4 mx-1">
              <p
                className="mb-0 text-blue-color"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                Total
              </p>
              <p
                className="mb-0 text-blue-color"
                color="rgba(6, 49, 100, 30%)"
                style={{ fontSize: "12px" }}
              >
                {totalVisits || "-"}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const CalendarJOB = ({ isCalendarVisible, setIsCalendarVisible }) => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const location = useLocation();

  const CustomToolbar = ({ label, onNavigate }) => {
    const goToBack = () => {
      onNavigate("PREV");
    };

    const goToNext = () => {
      onNavigate("NEXT");
    };

    return (
      <div className="rbc-toolbar">
        <button type="button" onClick={goToBack}>
          Back
        </button>
        <span className="rbc-toolbar-label">{label}</span>

        <button type="button" onClick={goToNext}>
          Next
        </button>
      </div>
    );
  };

  const toggleCalendarVisibility = () => {
    setIsCalendarVisible(!isCalendarVisible);
    setIsCardVisible(!isCardVisible);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const fetchSheduleData = async () => {
    setloading(true);
    try {
      const res = await AxiosInstance.get(
        `/visits/schedule/${localStorage.getItem("CompanyId")}`
      );
      const scheduleData = res.data.data;
      const mappedEvents = scheduleData.map((item) => ({
        id: item._id,
        VisitId: item.VisitId,
        ItemName: item.ItemName,
        FirstName: item.FirstName,
        LastName: item.LastName,
        start: new Date(item.StartDate),
        end: new Date(item.EndDate),
        allDay: true,
      }));
      setEvents(mappedEvents);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setloading(false); // Set loading to false after the fetch completes
    }
  };
  useEffect(() => {
    fetchSheduleData();
  }, []);
  const calendarRef = useRef(null);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [clickedDate, setClickedDate] = useState(null);
  const navigate = useNavigate();
  const { companyName } = useParams();

  const eventPropGetter = (event) => ({
    style: {
      border: "none",
      color: "black",
      display: "block",
    },
  });

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };
  const handleSlotClick = ({ start }, e) => {
    if (e && e.clientY && e.clientX) {
      const calendarRect = calendarRef.current.getBoundingClientRect();
      const top = e.clientY - calendarRect.top + window.scrollY;
      const left = e.clientX - calendarRect.left + window.scrollX;
      setAnchorPosition({ top, left });
      setClickedDate(start);
    }
  };
  const handlePopoverClose = () => {
    setAnchorPosition(null);
  };
  const handleClose = () => {
    setSelectedEvent(null);
    setAnchorPosition(null);
  };
  const CustomEvent = ({ event }) => (
    <span>
      <strong>{event.ItemName}</strong>
      <br />
    </span>
  );
  return (
    <div className="col-12 mx-2 my-3 mb-0 first-tab">
      <div>
        {isCalendarVisible && (
          <div
            style={{
              position: "relative",
            }}
          >
            {loading && (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <Watch
                  visible={true}
                  height="50"
                  width="50"
                  radius="48"
                  color="#063164"
                  ariaLabel="watch-loading"
                />
              </div>
            )}
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              selectable
              views={["month"]}
              components={{
                event: CustomEvent,
              }}
              eventPropGetter={eventPropGetter}
              onSelectEvent={handleEventClick}
              onSelectSlot={handleSlotClick}
              popup={true}
              className="text-blue-color border-blue-color"
            />
          </div>
        )}
      </div>
      <Popover
        open={Boolean(anchorPosition)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handlePopoverClose}
        PaperProps={{
          style: {
            top: anchorPosition ? `${anchorPosition.top}px` : undefined,
            left: anchorPosition ? `${anchorPosition.left}px` : undefined,
            position: "absolute",
          },
        }}
      >
        <Box p={2}>
          <Typography variant="h6">No Event</Typography>
          <Typography>{`You clicked on ${moment(clickedDate).format(
            "MMMM Do YYYY"
          )}`}</Typography>
        </Box>
      </Popover>
    </div>
  );
};
const ClientMessage = ({ formik }) => {
  return (
    <div className="col-5 order-sm-1 messageinput">
      <FormGroup>
        <InputText
          value={formik.values?.CustomerMessage}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.CustomerMessage &&
            Boolean(formik.errors.CustomerMessage)
          }
          helperText={
            formik.touched.CustomerMessage && formik.errors.CustomerMessage
          }
          name="CustomerMessage"
          label="CustomerMessage"
          type="text"
          className="text-blue-color w-100 m-0 mb-3"
          fieldHeight="56px"
        />
      </FormGroup>
      <FormGroup>
        <InputText
          value={formik.values?.ContractDisclaimer}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.ContractDisclaimer &&
            Boolean(formik.errors.ContractDisclaimer)
          }
          helperText={
            formik.touched.ContractDisclaimer &&
            formik.errors.ContractDisclaimer
          }
          name="ContractDisclaimer"
          label="ContractDisclaimer"
          type="text"
          className="text-blue-color w-100 m-0 mb-3"
          fieldHeight="56px"
        />
      </FormGroup>
    </div>
  );
};

const AddQuoteDiscount = ({ lineItems, formik }) => {
  const [showDiscount, setShowDiscount] = useState(false);
  const [showTax, setShowTax] = useState(false);

  const calculateSubTotal = () => {
    const total = lineItems?.reduce(
      (sum, item) => sum + Number(item.Total || 0),
      0
    );
    return total;
  };

  const subTotal = calculateSubTotal();
  const discountAmount = formik.values.Discount
    ? (Number(formik.values.Discount) * subTotal) / 100
    : 0;
  const discountedTotal = subTotal - discountAmount;
  const taxAmount = formik.values.Tax
    ? (Number(formik.values.Tax) * discountedTotal) / 100
    : 0;
  const Total = (discountedTotal + taxAmount)?.toFixed(2);

  return (
    <div className="col-7 order-sm-2 totalinput">
      <table>
        <tbody>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Subtotal
            </td>
            <td className="text-end text-blue-color">
              ${subTotal?.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Discount
            </td>
            <td
              className={`d-flex ${
                !showDiscount
                  ? "justify-content-end"
                  : "justify-content-between"
              }`}
            >
              {showDiscount && (
                <span style={{ position: "relative", display: "inline-block" }}>
                  <InputText
                    value={formik.values?.Discount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.Discount && Boolean(formik.errors.Discount)
                    }
                    helperText={
                      formik.touched.Discount && formik.errors.Discount
                    }
                    name="Discount"
                    label="Discount"
                    type="text"
                    className="text-blue-color w-100 m-0 mb-3"
                    fieldHeight="56px"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                    className="text-blue-color"
                  >
                    %
                  </span>
                </span>
              )}
              <u
                className="text-blue-color"
                style={{
                  fontWeight: 600,
                  cursor: "pointer",
                  textAlign: "end",
                  marginBottom: "9px",
                }}
                onClick={() => setShowDiscount(!showDiscount)}
              >
                {discountAmount > 0
                  ? `$${discountAmount?.toFixed(2)}`
                  : "Add Discount"}
              </u>
            </td>
          </tr>
          <tr>
            <td className="text-blue-color" style={{ height: "50px" }}>
              Tax
            </td>
            <td
              className={`d-flex ${
                !showTax ? "justify-content-end" : "justify-content-between"
              }`}
            >
              {showTax && (
                <span style={{ position: "relative", display: "inline-block" }}>
                  <InputText
                    value={formik.values?.Tax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.Tax && Boolean(formik.errors.Tax)}
                    helperText={formik.touched.Tax && formik.errors.Tax}
                    name="Tax"
                    label="Tax"
                    type="text"
                    className="text-blue-color w-100 m-0 mb-3"
                    fieldHeight="56px"
                  />
                  <span
                    className="text-blue-color"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                    }}
                  >
                    %
                  </span>
                </span>
              )}
              <u
                className="text-blue-color"
                style={{
                  fontWeight: 600,
                  cursor: "pointer",
                  marginBottom: "9px",
                }}
                onClick={() => setShowTax(!showTax)}
              >
                {taxAmount > 0 ? `$${taxAmount?.toFixed(2)}` : "Add Tax"}
              </u>
            </td>
          </tr>
          <tr className="last-row border-0">
            <td className="border-0 text-blue-color">
              <b>Total</b>
            </td>
            <td className="text-end border-0 text-blue-color">
              <b>${Total}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Team = ({ setIds = (assignPersonId) => {}, ids, isAddTeam = true }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tokenDecode, setTokenDecode] = useState({});
  const [assignPersonId, setAssignPersonId] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const fetchDatas = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchDatas();
  }, []);
  const fetchData = async () => {
    try {
      const response = await AxiosInstance.get(
        `/contract/assign_team/${
          localStorage.getItem("CompanyId") || tokenDecode?.companyId
        }`
      );
      if (response.status === 200) {
        setTeamData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tokenDecode]);

  const formik = useFormik({
    initialValues: {
      FullName: "",
      Email: "",
      MobileNumber: "",
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("Full Name is required"),
      Email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      MobileNumber: Yup.string().required("Mobile Number is required"),
    }),
    onSubmit: async (values) => {
      try {
        const companyId =
          localStorage.getItem("CompanyId") || tokenDecode?.companyId;

        const response = await AxiosInstance.post(`/contract/assign_team`, {
          AssignPersonId: values.AssignPersonId,
          FullName: values.FullName,
          Email: values.Email,
          PhoneNumber: values.MobileNumber,
          CompanyId: companyId,
        });
        if (response.data.statusCode === 201) {
          toast.success(response.data.message);
          toggleModal();
          fetchData();
        } else if (response.data.statusCode === 302) {
          toast.error(response.data.message);
        }
      } catch (error) {
        if (error.response) {
          console.error("Server responded with an error:", error.response.data);
          toast.error(error.response.data.message || "Something went wrong!");
        } else if (error.request) {
          console.error("No response received:", error.request);
          toast.error("No response from the server, please try again later.");
        } else {
          console.error("Error during request setup:", error.message);
          toast.error("Error occurred while sending request.");
        }
      }
    },
  });

  const handleRemoveTeam = (teamName) => {
    setSelectedTeams(selectedTeams.filter((name) => name !== teamName));
    setCheckedState((prevState) => ({
      ...prevState,
      [teamName]: false,
    }));
  };

  const handleTeamSelect = (event, team) => {
    if (event.target.checked) {
      setSelectedTeams([...selectedTeams, team?.FullName]);
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: true,
      }));
      setIds(() => [...ids, team.AssignPersonId]);
      setAssignPersonId(team.AssignPersonId);
    } else {
      setSelectedTeams(selectedTeams.filter((name) => name !== team?.FullName));
      setCheckedState((prevState) => ({
        ...prevState,
        [team?.FullName]: false,
      }));
      setIds(() => ids.filter((name) => name !== team?.AssignPersonId));
      setAssignPersonId(null);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleModal = () => {
    if (isAddTeam) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleOutsideClick = () => {
    toggleDropdown();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="jobs">
      <div className="team-card" style={{ width: "100%" }}>
        <Card className="" style={{ height: "140px" }}>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "none",
              background: "none",
            }}
            className="team-header"
          >
            <h3 className="text-blue-color" style={{ fontWeight: 600 }}>
              Team
            </h3>
            <div className="assignbtn">
              <Button
                className="bg-blue-color"
                outline
                style={{
                  padding: "0 14px 0 14px",
                  fontSize: "12px",
                  marginTop: 0,
                  height: "32px",
                  color: "#fff",
                }}
                onClick={toggleDropdown}
              >
                + Assign
              </Button>
              {isDropdownOpen && (
                <div
                  className="assigndrop"
                  style={{
                    position: "absolute",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    marginTop: "10px",
                    padding: "10px",
                    zIndex: 1000,
                    width: "250px",
                    right: 0,
                  }}
                >
                  <Card>
                    <CardHeader
                      className="text-white-color bg-blue-color"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Select team
                      <CloseIcon
                        onClick={toggleDropdown}
                        style={{ cursor: "pointer" }}
                      />
                    </CardHeader>
                    <CardBody>
                      <div onClick={handleOutsideClick}>
                        {teamData && teamData.length > 0 ? (
                          teamData.map((person) => (
                            <FormGroup
                              check
                              className="my-3 mb-0"
                              key={person._id}
                            >
                              <Input
                                type="checkbox"
                                checked={
                                  checkedState &&
                                  !!checkedState[person?.FullName]
                                }
                                onChange={(e) => handleTeamSelect(e, person)}
                              />
                              <Label
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(6,49,100,0.7)",
                                  fontWeight: "400",
                                  marginBottom: 0,
                                }}
                              >
                                {person.FullName}
                              </Label>
                            </FormGroup>
                          ))
                        ) : (
                          <p>No team members found.</p>
                        )}
                      </div>
                      {isAddTeam && (
                        <React.Fragment>
                          <hr />
                          <Button
                            className="text-blue-color text-white-color bg-blue-color"
                            style={{
                              // background: "#063164",
                              color: "#fff",
                              border: "none",
                              fontWeight: "400",
                            }}
                            onClick={toggleModal}
                          >
                            + Create User
                          </Button>
                        </React.Fragment>
                      )}
                    </CardBody>
                  </Card>
                  <Modal isOpen={isModalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Add new user</ModalHeader>
                    <ModalBody
                      className="nozindex"
                      style={{ borderBottom: "none" }}
                    >
                      <FormGroup>
                        <Input
                          name="FullName"
                          placeholder="Full Name"
                          type="text"
                          className="text-blue-color w-100 mb-3"
                          value={formik.values.FullName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.FullName &&
                            Boolean(formik.errors.FullName)
                          }
                        />
                        {formik.touched.FullName && formik.errors.FullName ? (
                          <div className="text-danger">
                            {formik.errors.FullName}
                          </div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                        <Input
                          name="Email"
                          placeholder="Email"
                          type="text"
                          className="text-blue-color w-100 mb-3"
                          value={formik.values.Email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.Email && Boolean(formik.errors.Email)
                          }
                        />
                        {formik.touched.Email && formik.errors.Email ? (
                          <div className="text-danger">
                            {formik.errors.Email}
                          </div>
                        ) : null}
                      </FormGroup>
                      <FormGroup>
                          <Input
                            name="MobileNumber"
                            placeholder="Mobile Number"
                            type="text"
                            className="text-blue-color w-100 mb-3"
                            value={formik.values.MobileNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.MobileNumber &&
                              Boolean(formik.errors.MobileNumber)
                            }
                          />
                        {formik.touched.MobileNumber &&
                        formik.errors.MobileNumber ? (
                          <div className="text-danger">
                            {formik.errors.MobileNumber}
                          </div>
                        ) : null}
                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={toggleModal}
                        className="text-blue-color border-blue-color"
                        style={{
                          background: "none",
                          // color: "#063164",
                          // borderColor: "#063164",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        onClick={formik.handleSubmit}
                        className="bg-blue-color text-white-color border-blue-color"
                        // style={{ borderColor: "#063164" }}
                      >
                        Save User
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ marginTop: "-10px", height: "18px" }}>
              {selectedTeams.map((team, index) => (
                <div
                  key={index}
                  className="tag"
                  style={{ marginTop: "0px", marginLeft: "10px" }}
                >
                  <span className="tag-text" style={{ fontSize: "16px" }}>
                    {team}
                  </span>
                  <button
                    className="tag-close"
                    onClick={() => handleRemoveTeam(team)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>

            <FormGroup check className="my-3 mb-0"></FormGroup>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export {
  InternalNotes,
  OneOffContract,
  RecurringContract,
  CalendarJOB,
  ClientMessage,
  AddQuoteDiscount,
  Team,
};
