import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Quotes from "../../../src/assets/Blue-sidebar-icon/Quote.svg";
import Contract from "../../../src/assets/Blue-sidebar-icon/Contract.svg";
import Invoice from "../../../src/assets/Blue-sidebar-icon/Invoice.svg";
import Appoinment from "../../../src/assets/Blue-sidebar-icon/Appoinment-blue.svg";
import "./style.css"
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AxiosInstance from "../AxiosInstance";
import { handleAuth } from "../../components/Login/Auth";
import { Grid, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";


const ClientDashboard = ({ companyId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const [tokenDecode, setTokenDecode] = useState({});
  const CompanyId = tokenDecode.CompanyId;
  const CustomerId = tokenDecode.CustomerId;
  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching token decode data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const customerId = localStorage.getItem("CustomerId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(
          `/customer/customer-dashboard/${CustomerId}/${CompanyId}`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching customer dashboard data:", error);
        navigate("/login");
      }
    };

    if (CustomerId && CompanyId) {
      fetchData();
    }
  }, [CustomerId, CompanyId, navigate, location]);

  const currentHour = new Date().getHours();
  let welcomeMessage =
    currentHour < 12
      ? "Good Morning"
      : currentHour < 18
      ? "Good Afternoon"
      : "Good Evening";

  const handleQuotesNavigation = () => {
    navigate(`/customers/quotes`, {
      state: { navigats: ["/index", "/quotes"] },
    });
  };
  const handlecontractNavigation = () => {
    navigate(`/customers/contract`, {
      state: { navigats: ["/index", "/contract"] },
    });
  };
  const handleinvoiceNavigation = () => {
    navigate(`/customers/invoice`, {
      state: { navigats: ["/index", "/invoice"] },
    });
  };
  const handleappoinmentsNavigation = () => {
    navigate(`/customers/appointment`, {
      state: { navigats: ["/index", "/appointment"] },
    });
  };
  return (
    <Grid>
      <Grid className="px-0 mb-5">
        <Typography
          className="px-1 welcomeMessage_staffMember staffDashboard heading-three"
          style={{
            fontSize: "35px",
            fontWeight: "700",
            fontFamily: "Poppins",
            lineHeight: "28.8px",
            color: "#063164 ",
          }}
        >
          {welcomeMessage}, {tokenDecode?.full_name}
        </Typography>
      </Grid>

      <Grid className="dashboard-customer">
        <Row className="main-customer row">
          <Col className="card client-footer mb-3 col-lg-3 col-md-6 col-sm-12" lg={3} md={6} sm={12}>
            <Grid className="card-header bg-orange-color"></Grid>
            <Grid className="card-body bg-blue-color">
              <Typography className="card-text first-card-client bg-white-color">
                <img src={Quotes} className="border-orange-color " />
              </Typography>
              <Typography className="mx-1 card-title text-white-color mt-5 heading-three">
                {data.quotes || 0}
              </Typography>
            </Grid>
            <Grid
              className="card-footer bg-orange-color text-white-color mb-0"
              onClick={handleQuotesNavigation}
              style={{ cursor: "pointer" }}
            >
              Quotes <ArrowRightIcon style={{ fontSize: "38px" }} />
            </Grid>
          </Col>

          <Col className="card client-footer mb-3 col-lg-3 col-md-6 col-sm-12" lg={3} md={6} sm={12}>
            <Grid className="card-header bg-orange-color"></Grid>
            <Grid className="card-body bg-blue-color">
              <Typography className="card-text first-card-client bg-white-color">
                <img src={Contract} className="border-orange-color " />
              </Typography>
              <Typography className="mx-1 card-title text-white-color mt-5 heading-three">
                {data.contracts || 0}
              </Typography>
            </Grid>
            <Grid
              className="card-footer bg-orange-color text-white-color mb-0"
              onClick={handlecontractNavigation}
              style={{ cursor: "pointer" }}
            >
              Contract <ArrowRightIcon style={{ fontSize: "38px" }} />
            </Grid>
          </Col >

          <Col className="card client-footer mb-3 col-lg-3 col-md-6 col-sm-12" lg={3} md={6} sm={12}>
            <Grid className="card-header bg-orange-color"></Grid>
            <Grid className="card-body bg-blue-color">
              <Typography className="card-text first-card-client bg-white-color">
                <img src={Invoice} className="border-orange-color " />
              </Typography>
              <Typography className="mx-1 card-title text-white-color mt-5 heading-three">
                {data.invoices || 0}
              </Typography>
            </Grid>
            <Grid
              className="card-footer bg-orange-color text-white-color mb-0"
              onClick={handleinvoiceNavigation}
              style={{ cursor: "pointer" }}
            >
              Invoices <ArrowRightIcon style={{ fontSize: "38px" }} />
            </Grid>
          </Col>

          <Col className="card client-footer mb-3 col-lg-3 col-md-6 col-sm-12" lg={3} md={6} sm={12}>
            <Grid className="card-header bg-orange-color"></Grid>
            <Grid className="card-body bg-blue-color">
              <Typography className="card-text first-card-client bg-white-color">
                <img src={Appoinment} className="border-orange-color " />
              </Typography>
              <Typography className="mx-1 heading-three card-title text-white-color mt-5">
                {data.visits || 0}
              </Typography>
            </Grid>
            <Grid
              className="card-footer bg-orange-color text-white-color mb-0"
              onClick={handleappoinmentsNavigation}
              style={{ cursor: "pointer" }}
            >
              Appointment <ArrowRightIcon style={{ fontSize: "38px" }} />
            </Grid>
          </Col>
        </Row>
      </Grid>
    </Grid>
  );
};

export default ClientDashboard;
