import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Input } from "reactstrap";
// import "../../Admin/";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../AxiosInstance";
import invoice from "../../../assets/White-sidebar-icon/Invoice.svg";
import { CardHeader, CardTitle, Table } from "reactstrap";
import { Circles } from "react-loader-spinner";

const WorkerInvoiceTable = () => {
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState();
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const { companyName } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_API;
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const CustomerId = location?.state?.CustomerId;
      if (!CustomerId) return;

      try {
        const res = await AxiosInstance.get(
          `${baseUrl}/contract/get_invoice_data/${CustomerId}`
        );
        const data = res.data.data;
        setCustomerData(data);
      } catch (error) {
        console.error("Error: ", error.message);
      } finally {
        setLoader(false);
      }
    };

    fetchData();
  }, [location?.state?.CustomerId]);

  const handleCheckboxChange = (item) => {
    setSelectedCustomerData(item);
  };

  const handleRowClick = (item) => {
    handleCheckboxChange(item);
  };

  const handleCheckboxClick = (event, item) => {
    event.stopPropagation();
    handleCheckboxChange(item);
  };

  return (
    <div>
      <Card className="my-2" style={{ borderRadius: "15px" }}>
        <CardHeader
          className="invoice-header"
          style={{ background: "transparent" }}
        >
          <span
            style={{
              borderRadius: "50%",
              backgroundColor: "rgba(6, 49, 100, 1)",

              padding: "0px 14px 2px 13px",
            }}
          >
            <img src={invoice} alt="Invoice Icon" />
          </span>{" "}
          &nbsp; New Invoice
        </CardHeader>
        <CardBody>
          <CardTitle tag="h5" style={{ color: "rgba(6, 49, 100, 1)" }}>
            Select the Contracts you want to invoice:
          </CardTitle>
        </CardBody>
        <div className="p-4">
          {loader ? (
            <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
              <Circles
                height="50"
                width="50"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
                color="#063164"
              />
            </div>
          ) : customerData.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center my-5 text-blue-color">
              <p>No Contracts Available</p>
            </div>
          ) : (
            <div
              style={{
                border: "1px solid rgba(6, 49, 100, 0.3)",
                borderRadius: "20px",
              }}
            >
              <Table
                borderless
                className="mb-0"
                style={{ overflowX: "scroll" }}
              >
                <tbody>
                  {customerData.map((item, index) => (
                    <tr
                      className="invoice-table"
                      key={index}
                      onClick={() => handleRowClick(index)}
                      style={{
                        borderBottom:
                          index !== customerData.length - 1
                            ? "1px solid rgba(6, 49, 100, 0.3)"
                            : "none",
                        cursor: "pointer",
                      }}
                    >
                      <th
                        scope="row"
                        style={{
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                          padding: "20px",
                        }}
                      >
                        <Input
                          style={{ marginLeft: "12px" }}
                          type="checkbox"
                          id={`checkbox-${index}`}
                          checked={selectedCustomerData === item || false}
                          onClick={(event) => handleCheckboxClick(event, item)}
                        />
                      </th>
                      <td
                        className="d-flex description-coloum"
                        style={{ padding: "20px" }}
                      >
                        <span>#{item?.contract?.ContractNumber}&nbsp;</span>
                        {item?.contract?.Title}
                      </td>
                      <td
                        style={{
                          padding: "20px",
                          color:
                            item?.contract?.Status === "Unscheduled"
                              ? "#E88C44"
                              : item?.contract?.Status === "Today"
                              ? "#089F57"
                              : item?.contract?.Status === "Upcoming"
                              ? "#089F57"
                              : item?.contract?.Status === "Scheduled"
                              ? "#C8CC00"
                              : "",
                        }}
                      >
                        {item?.contract?.Status}
                      </td>
                      <td style={{ padding: "20px" }}>
                        {item?.location?.address}
                      </td>
                      <td
                        style={{
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                          padding: "20px",
                        }}
                      >
                        ${item?.contract?.Total}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>

        <div
          className="text-end"
          style={{
            marginRight: "10px",
            justifyContent: "flex-end",
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            className="cancelButton mb-3 mt-5"
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(6, 49, 100, 0.8)",
              color: "rgba(6, 49, 100, 1)",
            }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          {/* <Button
            className="bg-button-blue-color createButton mb-3 mt-5 px-4"
            style={{ color: "#fff" }}
            onClick={() => {
              navigate(
                companyName
                  ? `/${companyName}/InvoiceDetails`
                  : "/superadmin/InvoiceDetails",
                {
                  state: {
                    navigats: [...location?.state.navigats, "/InvoiceDetails"],
                  },
                }
              );
            }}
          >
            Next
          </Button> */}
          <Button
            className="bg-button-blue-color createButton mb-3 mt-5 px-4"
            style={{ color: "#fff" }}
            onClick={() => {
              navigate(
                companyName
                  ? `/staff-member/workeraddinvoice`
                  : "/staff-member/workeraddinvoice",
                {
                  state: {
                    navigats: ["/index", "/workeraddinvoice"],
                    id: selectedCustomerData?.contract?.ContractId,
                  },
                }
              );
            }}
            disabled={!selectedCustomerData}
          >
            Next
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default WorkerInvoiceTable;
