// import {
//   Button,
//   FormGroup,
//   Grid,
//   IconButton,
//   InputAdornment,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useState } from "react";
// import login from "../../assets/image/icons/signup.png";
// import AppLogo from "../../assets/image/CMS_LOGO.svg";
// import googlee from "../../assets/image/icons/googlee.svg";
// import "./style.css";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import AxiosInstance from "../../Views/AxiosInstance";
// import Steps from "./Steps";
// import { Link, useNavigate } from "react-router-dom";
// import toast, { Toaster } from "react-hot-toast";
// import { ColorRing } from "react-loader-spinner";
// import InputText from "../InputFields/InputText";
// import sendToast from "../../components/Toast/sendToast";

// const SignUp = () => {
//   const baseUrl = process.env.REACT_APP_BASE_API;
//   const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(false);
//   const [showCPassword, setShowCPassword] = useState(false);
//   const [signSteps, setSignSteps] = useState(false);
//   const formik = useFormik({
//     initialValues: {
//       Password: "",
//       cpassword: "",
//       primaryEmailAddress: "",
//     },
//     validationSchema: Yup.object().shape({
//       primaryEmailAddress: Yup.string()
//         .email("Invalid email")
//         .required("Email is required")
//         .matches(/^[^@]+@[^@]+\.[^@]+$/, "Email must contain '@' and '.'"),
//       Password: Yup.string().required("Password is required"),
//       cpassword: Yup.string()
//         .oneOf([Yup.ref("Password"), null], "Passwords must match")
//         .required("Confirm Password is required"),
//     }),
//     validate: (values) => {
//       const errors = {};
//       if (values.Password !== values.cpassword) {
//         errors.cpassword = "Passwords do not match";
//       }
//       if (!isStrongPassword(values.Password)) {
//         errors.Password =
//           "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character.";
//       }
//       return errors;
//     },
//     onSubmit: (values) => {
//       handleSubmit(values);
//     },
//   });

//   const [loader, setLoader] = useState(false);
//   const handleSubmit = async (values) => {
//     try {
//       setLoader(true);
//       const res = await AxiosInstance.post(`/company/check_user`, {
//         primaryEmailAddress: values.primaryEmailAddress,
//       });
//       if (res.data.statusCode === 200) {
//         setSignSteps(true);
//       } else {
//         sendToast(res.data.message);
//       }
//     } catch (error) {
//       if (error.response) {
//         console.error("Server responded with an error:", error.response.data);
//         toast.error(error.response.data.message || "Something went wrong!");
//       } else if (error.request) {
//         console.error("No response received:", error.request);
//         toast.error("No response from the server, please try again later.");
//       } else {
//         console.error("Error during request setup:", error.message);
//         toast.error("Error occurred while sending request.");
//       }
//     } finally {
//       setLoader(false);
//     }
//   };

//   const isStrongPassword = (password) => {
//     const strongPasswordRegex =
//       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
//     return strongPasswordRegex.test(password);
//   };

//   return (
//     <>
//       <div className="login signup">
//         {!signSteps ? (
//           <div className="main-signup vh-100 d-flex">
//             <div className="d-flex justify-content-center align-items-center col-xs-12 col-md-6">
//               <img src={login} alt="logo" className="signup-image" />
//             </div>
//             <div className="d-flex flex-direction-column pt-3 col-xs-12 col-md-6">
//               <Typography className="app-logo signup-logo">
//                 <Link to="/auth/login">
//                   <img src={AppLogo} alt="logo" className=""></img>
//                 </Link>
//               </Typography>
//               <div style={{}} className="d-flex inout-contener">
//                 <form
//                   style={{ width: "70%" }}
//                   onSubmit={formik.handleSubmit}
//                   className="formmm"
//                 >
//                   <Typography className="text" style={{ fontSize: "25px" }}>Sign Up</Typography>
//                   <Typography className="text2">
//                     Let's proceed with setting you up to access your personal
//                     account.
//                   </Typography>
//                   <FormGroup style={{ width: "100%", marginTop: "8px" }}>
//                     <InputText
//                       value={formik.values?.primaryEmailAddress}
//                       onChange={formik.handleChange}
//                       onBlur={formik.handleBlur}
//                       error={
//                         formik.touched.primaryEmailAddress &&
//                         Boolean(formik.errors.primaryEmailAddress)
//                       }
//                       helperText={
//                         formik.touched.primaryEmailAddress &&
//                         formik.errors.primaryEmailAddress
//                       }
//                       name="primaryEmailAddress"
//                       label="Enter Email"
//                       type="text"
//                       className="text-blue-color w-100"
//                       fieldHeight="56px"
//                       placeholder="abc@example.com"
//                     />
//                   </FormGroup>
//                   <FormGroup
//                     style={{ width: "100%", marginTop: "8px" }}
//                     className="textboxes"
//                   >
//                     <InputText
//                       value={formik.values?.Password}
//                       onChange={formik.handleChange}
//                       onBlur={formik.handleBlur}
//                       error={
//                         formik.touched.Password &&
//                         Boolean(formik.errors.Password)
//                       }
//                       helperText={
//                         formik.touched.Password && formik.errors.Password
//                       }
//                       name="Password"
//                       label="Password"
//                       type={showPassword ? "text" : "password"}
//                       placeholder="abc@123"
//                       className="text-blue-color w-100"
//                       fieldHeight="56px"
//                       endAdornment={
//                         <InputAdornment position="end">
//                           <IconButton
//                             aria-label="toggle password visibility"
//                             onClick={() => setShowPassword(!showPassword)}
//                             edge="end"
//                           >
//                             {showPassword ? (
//                               <VisibilityOffIcon />
//                             ) : (
//                               <VisibilityIcon />
//                             )}
//                           </IconButton>
//                         </InputAdornment>
//                       }
//                     />
//                   </FormGroup>
//                   <FormGroup
//                     style={{ width: "100%", marginTop: "8px" }}
//                     className="reenterpassword"
//                   >
//                     <InputText
//                       value={formik.values?.cpassword}
//                       onChange={formik.handleChange}
//                       onBlur={formik.handleBlur}
//                       error={
//                         formik.touched.cpassword &&
//                         Boolean(formik.errors.cpassword)
//                       }
//                       helperText={
//                         formik.touched.cpassword && formik.errors.cpassword
//                       }
//                       name="cpassword"
//                       label="Enter Re-Password"
//                       placeholder="abc@123"
//                       type={showCPassword ? "text" : "password"}
//                       className="text-blue-color w-100"
//                       fieldHeight="56px"
//                       endAdornment={
//                         <InputAdornment position="end">
//                           <IconButton
//                             aria-label="toggle password visibility"
//                             onClick={() => setShowCPassword(!showCPassword)}
//                             edge="end"
//                           >
//                             {showCPassword ? (
//                               <VisibilityOffIcon />
//                             ) : (
//                               <VisibilityIcon />
//                             )}
//                           </IconButton>
//                         </InputAdornment>
//                       }
//                     />
//                   </FormGroup>
//                   <Typography
//                     style={{
//                       fontSize: "14px",
//                       fontWeight: "500",
//                       lineHeight: "21px",
//                       textAlign: "center",
//                       marginTop: "13px",
//                     }}
//                   >
//                     Do you have an account?{" "}
//                     <span
//                       style={{
//                         color: "rgba(255, 134, 130, 1)",
//                         cursor: "pointer",
//                       }}
//                       onClick={() => navigate("/auth/login")}
//                     >
//                       Login
//                     </span>
//                   </Typography>
//                   <FormGroup style={{ width: "100%", marginTop: "15px" }}>
//                     <Button
//                       style={{
//                         backgroundColor: "rgba(51, 53, 71, 1)",
//                         color: "#fff",
//                         fontSize: "14px",
//                         fontWeight: "600",
//                         lineHeight: "21px",
//                         borderRadius: "4px",
//                         gap: "4px",
//                         height: "48px",
//                       }}
//                       type="submit"
//                       disabled={loader}
//                     >
//                       {loader ? (
//                         <ColorRing
//                           height="30"
//                           width="30"
//                           colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
//                           ariaLabel="circles-loading"
//                           wrapperStyle={{}}
//                           wrapperClass=""
//                           visible={true}
//                         />
//                       ) : (
//                         "Sign Up"
//                       )}
//                     </Button>
//                   </FormGroup>
//                   <Typography
//                     style={{
//                       fontSize: "14px",
//                       fontWeight: "400",
//                       lineHeight: "21px",
//                       textAlign: "center",
//                       marginTop: "13px",
//                       color: "rgba(49, 49, 49, 1)",
//                     }}
//                   >
//                     You can receive marketing emails, SMS, news, and resources
//                     from our contractor management system. You can unsubscribe
//                     at any time. Please view our Privacy Policy{" "}
//                     <span style={{ fontWeight: "600" }}>Privacy Policy</span>{" "}
//                     for more information.
//                   </Typography>
//                 </form>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <>
//             <Steps
//               primaryEmailAddress={formik.values.primaryEmailAddress}
//               Password={formik.values.Password}
//             />
//           </>
//         )}
//       </div>
//       <Toaster />
//     </>
//   );
// };

// export default SignUp;

import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import login from "../../assets/image/icons/signup.jpg";
import AppLogo from "../../assets/image/CMS_LOGO.svg";
import googlee from "../../assets/image/icons/googlee.svg";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../Views/AxiosInstance";
import Steps from "./Steps";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import InputText from "../InputFields/InputText";
import sendToast from "../../components/Toast/sendToast";
import zxcvbn from "zxcvbn";

const SignUp = () => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [signSteps, setSignSteps] = useState(false);

  const validatePassword = Yup.object({
    primaryEmailAddress: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .matches(/^[^@]+@[^@]+\.[^@]+$/, "Email must contain '@' and '.'"),

    Password: Yup.string()
      .required("No password provided")
      .min(12, "Password must be at least 12 characters long")
      .max(16, "Password cannot be longer than 16 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])/,
        "Must contain an uppercase letter, lowercase letter, number, and special character"
      )
      .test("zxcvbn-strength", "Password is too weak or common", (value) => {
        const result = zxcvbn(value);
        return result.score >= 3;
      })
      .test(
        "no-sequential-or-repeating",
        "Avoid sequential or repeating patterns like '1234' or 'aaaa'",
        (value) => {
          return !/(\d)\1\1|\d{3,}|[A-Za-z]{3,}/.test(value);
        }
      ),

    cpassword: Yup.string()
      .oneOf([Yup.ref("Password"), null], "Passwords must match")
      .required("Confirmation password is required"),
  });

  const formik = useFormik({
    initialValues: {
      Password: "",
      cpassword: "",
      primaryEmailAddress: "",
    },
    validationSchema: validatePassword,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (values) => {
    try {
      setLoader(true);
      const res = await AxiosInstance.post(`/company/check_user`, {
        primaryEmailAddress: values.primaryEmailAddress,
      });
      if (res.data.statusCode === 200) {
        setSignSteps(true);
      } else {
        sendToast(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        toast.error(error.response.data.message || "Something went wrong!");
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("No response from the server, please try again later.");
      } else {
        console.error("Error during request setup:", error.message);
        toast.error("Error occurred while sending request.");
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="login signup">
        {!signSteps ? (
          <div className="main-signup vh-100 d-flex">
            <div className="d-flex justify-content-center align-items-center col-xs-12 col-md-6">
              <img src={login} alt="logo" className="signup-image" />
            </div>
            <div className="d-flex flex-direction-column pt-3 col-xs-12 col-md-6">
              <Typography className="app-logo signup-logo">
                <Link to="/auth/login">
                  <img src={AppLogo} alt="logo" className=""></img>
                </Link>
              </Typography>
              <div className="d-flex inout-contener">
                <form
                  style={{ width: "70%" }}
                  onSubmit={formik.handleSubmit}
                  className="formmm"
                >
                  <Typography className="text" style={{ fontSize: "25px" }}>
                    Sign Up
                  </Typography>
                  <Typography className="text2">
                    Let's proceed with setting you up to access your personal
                    account.
                  </Typography>
                  <FormGroup style={{ width: "100%", marginTop: "8px" }}>
                    <InputText
                      value={formik.values?.primaryEmailAddress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.primaryEmailAddress &&
                        Boolean(formik.errors.primaryEmailAddress)
                      }
                      helperText={
                        formik.touched.primaryEmailAddress &&
                        formik.errors.primaryEmailAddress
                      }
                      name="primaryEmailAddress"
                      label="Enter Email"
                      type="text"
                      className="text-blue-color w-100"
                      fieldHeight="56px"
                      placeholder="abc@example.com"
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ width: "100%", marginTop: "8px" }}
                    className="textboxes"
                  >
                    <InputText
                      value={formik.values?.Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.Password &&
                        Boolean(formik.errors.Password)
                      }
                      helperText={
                        formik.touched.Password && formik.errors.Password
                      }
                      name="Password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      placeholder="abc@123"
                      className="text-blue-color w-100"
                      fieldHeight="56px"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormGroup>
                  {/* <FormGroup
                    style={{ width: "100%", marginTop: "8px" }}
                    className="reenterpassword"
                  >
                    <InputText
                      value={formik.values?.cpassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.cpassword &&
                        Boolean(formik.errors.cpassword)
                      }
                      helperText={
                        formik.touched.cpassword && formik.errors.cpassword
                      }
                      name="cpassword"
                      label="Enter Re-Password"
                      placeholder="abc@123"
                      type={showCPassword ? "text" : "password"}
                      className="text-blue-color w-100"
                      fieldHeight="56px"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowCPassword(!showCPassword)}
                            edge="end"
                          >
                            {showCPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormGroup> */}
                  <FormGroup
                    style={{ width: "100%", marginTop: "8px" }}
                    className="reenterpassword"
                  >
                    <InputText
                      value={formik.values?.cpassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.cpassword &&
                        Boolean(formik.errors.cpassword)
                      }
                      helperText={
                        formik.touched.cpassword && formik.errors.cpassword
                      }
                      name="cpassword"
                      label="Enter Re-Password"
                      placeholder="abc@123"
                      type={showCPassword ? "text" : "password"}
                      className="text-blue-color w-100"
                      fieldHeight="56px"
                      onPaste={(e) => e.preventDefault()} 
                      onCopy={(e) => e.preventDefault()} 
                      onCut={(e) => e.preventDefault()} 
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowCPassword(!showCPassword)}
                            edge="end"
                          >
                            {showCPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormGroup>

                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                      marginTop: "13px",
                    }}
                  >
                    Do you have an account?{" "}
                    <span
                      style={{
                        color: "rgba(255, 134, 130, 1)",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/auth/login")}
                    >
                      Login
                    </span>
                  </Typography>
                  <FormGroup style={{ width: "100%", marginTop: "15px" }}>
                    <Button
                      style={{
                        backgroundColor: "rgba(51, 53, 71, 1)",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "21px",
                        width: "100%",
                        padding: "16px 32px",
                      }}
                      type="submit"
                    >
                      {/* {loader ? (
                        <ColorRing
                          visible={true}
                          height="30"
                          width="30"
                          ariaLabel="blocks-loading"
                          wrapperClass="blocks-wrapper"
                        /> */}
                      {loader ? (
                        <ColorRing
                          height="30"
                          width="30"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      ) : (
                        "Sign Up"
                      )}
                    </Button>
                  </FormGroup>

                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      textAlign: "center",
                      marginTop: "13px",
                      color: "rgba(49, 49, 49, 1)",
                    }}
                  >
                    You can receive marketing emails, SMS, news, and resources
                    from our contractor management system. You can unsubscribe
                    at any time. Please view our
                    <Link
                      to="https://cloudjobmanager.com/privacy-policy/"
                      target="_blank"
                    >
                      <span style={{ fontWeight: "600" }}>Privacy Policy</span>{" "}
                    </Link>
                    for more information.
                  </Typography>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <Steps />
        )}
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default SignUp;
