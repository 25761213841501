import React, { useState } from "react";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { FileModal } from "./DisplayFiles";
import "./files.css";
import { Grid } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const allowedTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
  "application/pdf",
  "application/msword", 
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
];

const Files = ({ files, setFiles }) => {
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      const validFiles = droppedFiles.filter((file) =>
        allowedTypes.includes(file.type)
      );

      if (validFiles.length !== droppedFiles.length) {
        alert("Some files are not allowed and were skipped.");
      }

      setFiles([...files, ...validFiles]);
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFiles = Array.from(e.target.files);
      const validFiles = selectedFiles.filter((file) =>
        allowedTypes.includes(file.type)
      );

      if (validFiles.length !== selectedFiles.length) {
        alert("Some files are not allowed and were skipped.");
      }

      setFiles([...files, ...validFiles]);
    }
  };

  return (
    <Grid className="file-upload-container">
      <Grid
        className="file-upload"
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="Attachment"
          name="Attachment"
          className="file-input"
          multiple={true}
          accept=".jpg,.jpeg,.png,.webp,.pdf,.doc,.docx"
          onChange={handleChange}
        />
        <label className="upload-files-label addQUoteFileSelect">
          Drag your files here or{" "}
          <span
            onClick={() => document.getElementById("Attachment").click()}
            className="select-file-link"
          >
            Select a File
          </span>
        </label>
      </Grid>
    </Grid>
  );
};

const File = ({ file, setFile, index }) => {
  const cdnUrl = process.env.REACT_APP_CDN_API;
  const [modalOpen, setModalOpen] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      if (allowedTypes.includes(droppedFile.type)) {
        setFile(index, droppedFile);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (allowedTypes.includes(selectedFile.type)) {
        setFile(index, selectedFile);
      }
    }
  };

  const handleRemove = () => {
    setFile(index, "");
  };

  return (
    <Grid>
      <Grid
        className="file-upload px-3 py-2 fileUpload"
        style={{
          maxHeight: "140px",
          minHeight: "130px",
          height: "100%",
          border: "2px dashed #ccc",
          cursor: !file ? "pointer" : "default",
          // marginTop: "-16px",
        }}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
        onClick={() =>
          !file && document.getElementById(`single-attachment-${index}`).click()
        }
      >
        <input
          type="file"
          id={`single-attachment-${index}`}
          name="single-attachment"
          accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
          style={{ display: "none" }}
          onChange={handleChange}
          multiple={false}
        />
        <span
          className="d-flex flex-column w-100 justify-content-center align-items-center"
          style={{ minHeight: "120px" }}
        >
          {!file ? (
            <CameraAltOutlinedIcon />
          ) : (
            <Row className="row w-100 p-0">
              <Col
                className="col-8 p-0"
                style={{ maxHeight: "120px" }}
                onClick={() => setModalOpen(true)}
                xl={8}
              >
                {file ? (
                  typeof file === "string" ? (
                    file.endsWith(".pdf") ? ( 
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PictureAsPdfIcon
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                          }}
                        />{" "}
                                <span>{file}</span> 
                      </div>
                    ) : (
                      <img
                        src={`${cdnUrl}/upload/${file}`}
                        height={"120px"}
                        alt={file}
                        style={{ width: "100%" }}
                      />
                    )
                  ) : file.type === "application/pdf" ? ( 
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <PictureAsPdfIcon
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                      />{" "}
                      <span>{file?.name}</span> 
                    </div>
                  ) : (
                    <img
                      src={URL?.createObjectURL(file)}
                      height={"120px"}
                      alt={file?.name}
                      style={{ width: "100%" }}
                    />
                  )
                ) : (
                  ""
                )}
              </Col>
              <Col
                className="col-4 d-flex flex-column justify-content-around align-items-end"
                style={{ minHeight: "120px" }}
                xl={4}
              >
                <ModeEditOutlineRoundedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    document
                      .getElementById(`single-attachment-${index}`)
                      .click()
                  }
                />
                <DeleteForeverRoundedIcon
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={handleRemove}
                />
              </Col>
            </Row>
          )}
        </span>
      </Grid>
      <FileModal open={modalOpen} setOpen={setModalOpen} file={file} />
    </Grid>
  );
};

const SingleFileUpload = ({ file, setFile }) => {
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      if (allowedTypes.includes(droppedFile.type)) {
        setFile(droppedFile);
      } else {
        alert("File type not allowed");
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (allowedTypes.includes(selectedFile.type)) {
        setFile(selectedFile);
      } else {
        alert("File type not allowed");
      }
    }
  };

  return (
    <Grid className="file-upload-container productDragFole">
      <Grid
        className="file-upload"
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="Attachment"
          name="Attachment"
          className="file-input"
          accept=".jpg,.jpeg,.png,.webp,.pdf,.doc,.docx"
          onChange={handleChange}
        />
        <label className="upload-files-label addQUoteFileSelect">
          Drag your file here or{" "}
          <span
            onClick={() => document.getElementById("Attachment").click()}
            className="select-file-link"
          >
            Select a File
          </span>
        </label>
      </Grid>
    </Grid>
  );
};

export { Files, File, SingleFileUpload };
