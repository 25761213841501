// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-Icon {
  background-repeat: no-repeat;
  border: none !important;
  margin: 3px !important;
  width: 17px !important;
  height: 17px !important;
}
@media (max-width: 1700px) {
}

/* 1500 */
@media (max-width: 1680px) {
}

/* 1440 */
@media (max-width: 1449px) {
}

/* 1280 */
@media (max-width: 1439px) {
  .margin-rightNone {
    /* margin-right: 0px !important; */
  }
}

/* 992 */
@media (max-width: 1279px) {
  .productAndServiceScroll {
    min-width: 800px !important;
    overflow-x: auto !important;
  }
  .ScrollDiv {
    overflow-x: auto !important;
  }
}

/* 768 */
@media (max-width: 991px) {
}

/* 576 */
@media (max-width: 767px) {
  .totalOfQuoteDes {
    margin-top: 5px !important;
  }
  .productServiceHead {
    font-size: 14px !important;
  }
}

/* 525 */
@media (max-width: 575px) {
}

/* 480 */
@media (max-width: 524px) {
}

/* 425 */
@media (max-width: 479px) {
}

/* 375 */
@media (max-width: 424px) {
}

/* 320 */
@media (max-width: 374px) {
}

/* 280 */
@media (max-width: 319px) {
}
`, "",{"version":3,"sources":["webpack://./src/components/Icon/style.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,uBAAuB;EACvB,sBAAsB;EACtB,sBAAsB;EACtB,uBAAuB;AACzB;AACA;AACA;;AAEA,SAAS;AACT;AACA;;AAEA,SAAS;AACT;AACA;;AAEA,SAAS;AACT;EACE;IACE,kCAAkC;EACpC;AACF;;AAEA,QAAQ;AACR;EACE;IACE,2BAA2B;IAC3B,2BAA2B;EAC7B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B;AACF;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA","sourcesContent":[".edit-Icon {\n  background-repeat: no-repeat;\n  border: none !important;\n  margin: 3px !important;\n  width: 17px !important;\n  height: 17px !important;\n}\n@media (max-width: 1700px) {\n}\n\n/* 1500 */\n@media (max-width: 1680px) {\n}\n\n/* 1440 */\n@media (max-width: 1449px) {\n}\n\n/* 1280 */\n@media (max-width: 1439px) {\n  .margin-rightNone {\n    /* margin-right: 0px !important; */\n  }\n}\n\n/* 992 */\n@media (max-width: 1279px) {\n  .productAndServiceScroll {\n    min-width: 800px !important;\n    overflow-x: auto !important;\n  }\n  .ScrollDiv {\n    overflow-x: auto !important;\n  }\n}\n\n/* 768 */\n@media (max-width: 991px) {\n}\n\n/* 576 */\n@media (max-width: 767px) {\n  .totalOfQuoteDes {\n    margin-top: 5px !important;\n  }\n  .productServiceHead {\n    font-size: 14px !important;\n  }\n}\n\n/* 525 */\n@media (max-width: 575px) {\n}\n\n/* 480 */\n@media (max-width: 524px) {\n}\n\n/* 425 */\n@media (max-width: 479px) {\n}\n\n/* 375 */\n@media (max-width: 424px) {\n}\n\n/* 320 */\n@media (max-width: 374px) {\n}\n\n/* 280 */\n@media (max-width: 319px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
