import React from "react";
import { Circles } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";

import {
  JobberPagination,
  JobberSearch,
  JobberTable,
} from "../../../../components/MuiTable";
import { Grid, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import BlueButton from "../../../../components/Button/BlueButton";
import { LoaderComponent } from "../../../../components/Icon/Index";

const Contract = ({
  loader,
  search,
  setSearch,
  cellData,
  page,
  setPage,
  setRowsPerPage,
  companyName,
  countData,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Grid className="justify-content-center align-items-center mb-3">
        <Grid className="d-flex justify-content-between mb-2 align-items-center customer_addCustomer_div customersAddCustomers">
          <Typography
            className="text-blue-color customer_para heading-three tableNameHead"
            style={{ fontWeight: 700 }}
          >
            Contract
          </Typography>
          <Grid className="searchBarOfTable">
            <BlueButton
              // onClick={() => {
              //   navigate(`/${companyName}/add-contract`, {
              //     state: {
              //       navigats: [...location.state.navigats, "/add-contract"],
              //     },
              //   });
              // }}

              onClick={() => {
                if (companyName) {
                  navigate(`/${companyName}/add-contract`, {
                    state: {
                      navigats: [...location?.state?.navigats, "/add-contract"],
                    },
                  });
                } else {
                  navigate(`/staff-member/add-contract`, {
                    state: {
                      navigats: [...location?.state?.navigats, "/add-contract"],
                    },
                  });
                }
              }}
              label="Add Contract"
            />
          </Grid>
        </Grid>
        <Card
          className="border-blue-color"
          style={{
            borderRadius: "20px",
            border: "2px solid",
            padding: 0,
          }}
        >
          <CardHeader
            className="d-flex justify-content-between align-items-center table-header bg-blue-color customerList_searchbar customersAddCustomers"
            style={{
              borderBottom: "2px solid ",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <Typography className="contrac text-light customerList_head heading-five tableNameHead fw-medium">
              All Contracts
            </Typography>
            <Grid className="contractsearch d-flex customer_searchBar searchBarOfTable">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparant" }}
              />
            </Grid>
          </CardHeader>
          {loader ? (
            <Grid className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <LoaderComponent loader={loader} height="50" width="50" />
            </Grid>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={[
                  "Sr No.",
                  "Customer Name",
                  "Contract number",
                  "Property",
                  "Schedule",
                  "Status",
                  "Total",
                  "Action",
                ]}
                cellData={cellData}
                isCollapse={false}
                page={page}
                isNavigate={true}
                navigatePath={
                  companyName
                    ? `/${companyName}/contractdetails`
                    : `/staff-member/worker-contract-details`
                }
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color border-blue-color"
            style={{
              borderTop: "2px solid",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
      </Grid>
    </>
  );
};

export default Contract;
