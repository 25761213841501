import React, { useEffect, useState, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import AxiosInstance from "../../AxiosInstance";
import { Watch } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import Visit from "../../../assets/Blue-sidebar-icon/Contract.svg";

const localizer = momentLocalizer(moment);

function Schedual() {
  const baseUrl = process.env.REACT_APP_BASE_API;
  const navigate = useNavigate();
  const { companyName } = useParams();

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchWorkerScheduleData = async () => {
    const WorkerId = localStorage.getItem("worker_id");

    try {
      const res = await AxiosInstance.get(
        `${baseUrl}/visits/Worker_schedule/${WorkerId}`
      );
      const scheduleData = res.data.data;
      const mappedEvents = scheduleData.map((item) => ({
        id: item.VisitId,
        WorkerId: item.WorkerId,
        VisitId: item.VisitId,
        ItemName: item.ItemName,
        Note: item.Note,
        start: new Date(item.StartDate),
        end: new Date(item.EndDate),
        StartTime: item.StartTime,
        EndTime: item.EndTime,
        allDay: true,
        customerName: `${item.customerDetails.FirstName} ${item.customerDetails.LastName}`,
      }));

      return mappedEvents;
    } catch (error) {
      console.error("Error:", error.message);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const workerScheduleEvents = await fetchWorkerScheduleData();
      setEvents(workerScheduleEvents);
      setLoading(false);
    };

    fetchData();
  }, []);

  const eventPropGetter = (event) => ({
    style: {
      border: "none",
      color: "black",
      display: "block",
      backgroundColor: "#f0f0f0", // Light background for events
      borderRadius: "4px", // Rounded corners
      padding: "5px", // Padding for better spacing
    },
  });

  const CustomEvent = ({ event }) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleEventClick(event);
      }}
    >
        {event.VisitId && (
        <img
          src={Visit}
          alt="Visit"
          style={{
            width: 16,
            height: 16,
            marginRight: 8,
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
        />
      )}
      <strong style={{ fontWeight: "bold", color: "#333" }}>
        {event.customerName} - {event.ItemName}
      </strong>
    </span>
  );

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setSelectedEvent(null);
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "1500px",
        margin: "0 auto",

        backgroundColor: "#f9f9f9", 
        borderRadius: "8px", 
        boxShadow: "0 4px 10px rgba(0,0,0,0.1)", 
      }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "relative",
            filter: loading ? "blur(2px)" : "none",
            width: "100%",
          }}
        >
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "600px", width: "100%", overflowY: "auto" }}
            selectable
            views={["month"]}
            components={{
              event: CustomEvent,
            }}
            eventPropGetter={eventPropGetter}
            onSelectEvent={handleEventClick}
            popup={true}
          />
        </div>
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Watch
              visible={true}
              height="50"
              width="50"
              radius="48"
              color="#063164"
              ariaLabel="watch-loading"
            />
          </div>
        )}
      </div>

      {selectedEvent && (
        <Dialog
          open={!!selectedEvent}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              borderRadius: "12px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <DialogTitle
            className="bg-orange-color"
            style={{ color: "#fff", borderRadius: "8px 8px 0 0" }}
          >
            Visit Details for {selectedEvent.customerName}
          </DialogTitle>
          <DialogContent dividers>
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              Details
            </Typography>
            <Typography>
              <strong>Item Name:</strong> {selectedEvent.ItemName}
            </Typography>
            <Typography>
              <strong>Note:</strong> {selectedEvent.Note}
            </Typography>
            <Typography>
              <strong>Start Date:</strong>{" "}
              {moment(selectedEvent.start).format("MMMM Do YYYY")}
            </Typography>
            <Typography>
              <strong>Start Time:</strong>{" "}
              {moment(selectedEvent.StartTime, "HH:mm:ss").format("hh:mm A")}
            </Typography>
            <Typography>
              <strong>End Date:</strong>{" "}
              {moment(selectedEvent.end).format("MMMM Do YYYY")}
            </Typography>
            <Typography>
              <strong>End Time:</strong>{" "}
              {moment(selectedEvent.EndTime, "HH:mm:ss").format("hh:mm A")}   
            </Typography>
          </DialogContent>
          <DialogActions>
          <Button
            className="bg-button-blue-color"
              onClick={handleClose}
              style={{
                // backgroundColor: "#f44336",
                color: "#fff",
                borderRadius: "5px",
                padding: "8px 16px",
                "&:hover": {
                  backgroundColor: "#d32f2f",
                },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default Schedual;
