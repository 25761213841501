import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
  NavigatorNav,
} from "../../../../components/MuiTable/index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../../assets/image/icons/edit.svg";
import Delete from "../../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import "./style.css";
import moment from "moment";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListInlineItem,
  Navbar,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import SettingSidebar from "../../../../components/Setting/SettingSidebar.js";
import { Link, useParams } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import List from "@mui/material/MenuItem";
import SwipeableDrawer from "./Drawer.js";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider } from "@mui/material";
import AxiosInstance from "../../../AxiosInstance.js";
import CombinedComponent from "./Drawer.js";
import SettingDropdown from "./SettingComponent.js";
import InputText from "../../../../components/InputFields/InputText.js";
import { SingleFileUpload } from "../../../../components/Files/index.js";
import { DisplayImage } from "../../../../components/Files/DisplayFiles.js";
import InputDropdown from "../../../../components/InputFields/InputDropdown.js";
import { postFile } from "../../../../components/Files/Functions.js";
import AddItems from "./AddItems.js";
import sendSwal from "../../../../components/Swal/sendSwal.js";

const ProductService = () => {
  const [modelOpen, setModelOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [productAndService, setProductAndService] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const CompanyId = localStorage.getItem("CompanyId");
  const [showSquaresSection, setShowSquaresSection] = useState(false);

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`/product/get/${CompanyId}`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
        },
      });
      setProductAndService(res.data.data);
      setCountData(res.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);

  const handleEditClick = (item) => {
    setSelectedProduct(item);
    setSelectedAdminId(item.ProductId);
    setSelectedProductAndService({ Type: item.Type });
    setSelectedUnitsAndHours({
      Type:
        item.Unit && item.CostPerUnit
          ? "Unit"
          : item.Square && item.CostPerSquare
          ? "Sq. Ft."
          : item.Hour && item.CostPerHour
          ? "Hour"
          : "",
    });
    if (item.Unit && item.CostPerUnit) {
      setShowUnitsSection(true);
      setShowSquaresSection(false);
      setShowHoursSection(false);
    }
    if (item.Hour && item.CostPerHour) {
      setShowHoursSection(true);
      setShowUnitsSection(false);
      setShowSquaresSection(false);
    }
    if (item.Square && item.CostPerSquare) {
      setShowSquaresSection(true);
      setShowHoursSection(false);
      setShowUnitsSection(false);
    }
    setModelOpen(true);
  };

  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`/product/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            setTimeout(() => {
              toast.error("", response.data.message, "error");
            }, 500);
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
        }
      } else {
        setTimeout(() => {
          toast.success("Products & Services is safe!", {
            position: "top-center",
            autoClose: 1000,
          });
        }, 500);
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const CollapseData = ({ data }) => {
    return (
      <div className="d-flex gap-4">
        <div className="card col-8">
          <div
            className="card-body w-100"
            style={{ backgroundColor: "#D8E7EE" }}
          >
            <div className="d-flex w-100 flex-row justify-content-between gap-2">
              <p className="text-blue-color">Description: </p>
              <span
                style={{
                  backgroundColor: "white",
                  padding: "0.2rem 0.5rem",
                  borderRadius: "0.25rem",
                  width: "100%",
                  marginBottom: "7px",
                }}
                className="text-blue-color"
              >
                {data.Description}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const cellData = productAndService?.map((item, index) => {
    // Determine which field and value to display with a label
    let displayValue = "-";
    if (item.Hour) {
      displayValue = `Hour : ${item.Hour}`;
    } else if (item.Unit) {
      displayValue = `Unit : ${item.Unit}`;
    } else if (item.Square) {
      displayValue = `Sq. Ft. : ${item.Square}`;
    }

    return {
      key: item.ProductId,
      value: [
        page * rowsPerPage + index + 1,
        item.Type ? item.Type : "-",
        item.Name ? item.Name : "-",
        displayValue,
        item.CostPerHour || item.CostPerUnit || item.CostPerSquare,
        moment(item.updatedAt).format("MM-DD-YYYY"),
        <>
          <img
            src={Edit}
            alt="edit"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item);
            }}
          />
          <img
            className="mx-1"
            alt="delete"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.ProductId);
            }}
          />
        </>,
      ],
      component: item,
    };
  });

  const [showHoursSection, setShowHoursSection] = useState(false);
  const [showUnitsSection, setShowUnitsSection] = useState(false);

  const productsAndService = [{ Type: "Product" }, { Type: "Service" }];
  const UnitsAndHours = [
    { Type: "Unit" },
    { Type: "Hour" },
    { Type: "Sq. Ft." },
  ];

  const [selectedUnitsAndHours, setSelectedUnitsAndHours] = useState(null);
  const [selectedProductAndService, setSelectedProductAndService] =
    useState(null);
  const { companyName } = useParams();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const toggle = () => setIsOpenDropDown(!isOpenDropDown);

  return (
    <>
      <div className="d-flex">
        <div className=" col-2 h-100 hiren">
          <SettingSidebar />
        </div>
        {/* <Divider
          className="divider-productsandservices"
          orientation="vertical"
          flexItem
          style={{
            marginLeft: "-10px",
            marginRight: "1%",
            borderRight: "2px solid black",
            // height: "auto",
            height: "90vh",
            marginTop: "-29px",
          }}
        /> */}
        <div
          className=" product-service-table col-10 "
          style={{
            borderLeft: "0.5px solid rgba(6, 49, 100, 30%)",
            paddingLeft: "20px",
            marginTop: "-30px",
          }}
        >
          <h2 style={{ fontWeight: 700, marginTop: "5%" }}>
            Products & Services
          </h2>
          <p className="text-blue-color">
            By keeping your product and service information updated, you can
            more easily create accurate quotes, jobs, and invoices.
          </p>
          <div className=" justify-content-end align-items-center">
            <div className="d-flex justify-content-end  align-items-center gap-2">
              <div className="setting-dropdown settingsidebardrop mb-2">
                <SettingDropdown
                  isOpenDropDown={isOpenDropDown}
                  toggle={toggle}
                  companyName={companyName}
                />
              </div>
              <div className="d-flex justify-content-end align-items-end mb-2 mr-1 bg-blue-color">
                <Button
                  style={{ color: "#fff" }}
                  onClick={() => {
                    setModelOpen(true);
                    setSelectedProduct(null);
                    setSelectedProductAndService(null);
                  }}
                  className="text-capitalize bg-blue-color"
                >
                  Add Products & Service
                </Button>
              </div>
            </div>

            <Card
              style={{
                borderRadius: "20px",
                border: "2px solid",
                padding: 0,
              }}
              className="border-blue-color"
            >
              <CardHeader
                className="d-flex justify-content-between align-items-center table-header bg-blue-color border-blue-color"
                style={{
                  borderBottom: "2px solid ",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <h5 className="text-light text-size">Product & Service</h5>
                <div className="d-flex">
                  <JobberSearch
                    search={search}
                    setSearch={setSearch}
                    style={{ background: "transparant", color: "white" }}
                  />
                </div>
              </CardHeader>
              {loader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <Circles
                    height="50"
                    width="50"
                    color="#063164"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loader}
                  />
                </div>
              ) : (
                <CardBody style={{ padding: "10px 0px" }}>
                  <JobberTable
                    headerData={[
                      "Sr no",
                      "Type",
                      "Name",
                      "Measurement",
                      "Cost",
                      "Date",
                      "Action",
                    ]}
                    cellData={cellData}
                    CollapseComponent={(data) => CollapseData(data)}
                    isCollapse={true}
                    page={page}
                    isNavigate={false}
                  />
                </CardBody>
              )}
              <CardFooter
                className="bg-orange-color border-blue-color"
                style={{
                  borderTop: "2px solid",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <JobberPagination
                  totalData={countData}
                  currentData={rowsPerPage}
                  dataPerPage={rowsPerPage}
                  pageItems={[10, 25, 50]}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
      <AddItems
        modelOpen={modelOpen}
        setModelOpen={setModelOpen}
        setSelectedProductAndService={setSelectedProductAndService}
        selectedProductAndService={selectedProductAndService}
        productsAndService={productsAndService}
        setShowUnitsSection={setShowUnitsSection}
        setShowHoursSection={setShowHoursSection}
        setSelectedUnitsAndHours={setSelectedUnitsAndHours}
        showHoursSection={showHoursSection}
        showUnitsSection={showUnitsSection}
        selectedProduct={selectedProduct}
        selectedUnitsAndHours={selectedUnitsAndHours}
        UnitsAndHours={UnitsAndHours}
        showSquaresSection={showSquaresSection}
        setShowSquaresSection={setShowSquaresSection}
        selectedAdminId={selectedAdminId}
        CompanyId={CompanyId}
        getData={getData}
      />
      <Toaster />
    </>
  );
};

export default ProductService;
