import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance";
import sendToast from "../../../components/Toast/sendToast";
import CustomerDetailsViews from "./Views/CustomerDetails";
import sendSwal from "../../../components/Swal/sendSwal";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import showToast from "../../../components/Toast/Toster";

function CustomerDetails() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);

  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const { companyName } = useParams();

  const [data, setData] = useState();
  const [open, setOpen] = useState({ isOpen: false, propertyData: null });
  const [loader, setLoader] = useState(true);
  const [modelOpen, setModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenDecode, setTokenDecode] = useState({});
  const [contract, setContract] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [invoice, setInvoice] = useState([]);

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`/customer/${location?.state?.id}`);
      setData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [location?.state?.id]);

  const handlePropertyDelete = async (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const res = await AxiosInstance.delete(`/location/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (res?.data?.statusCode === 200) {
            setTimeout(() => {
              showToast.success(res?.data?.message);
            }, 500);
            getData();
          } else if (res?.data?.statusCode === 202) {
          } else if (res?.data?.statusCode === 202) {
            setTimeout(() => {
              showToast.error(res?.data?.message);
            }, 500);
          } else {
            showToast.error("", res?.data?.message, "error");
          }
        } catch (error) {
          showToast.error(error?.message);
          console.error("Error: ", error?.message);
        }
      } else {
        showToast.success("Property is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const [activeTabId, setActiveTabId] = useState(1);
  const handleClick = (tabId) => {
    setActiveTabId(tabId);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const moreActiontoggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    const fetchQuote = async () => {
      if (data && data?.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/quote/get_quotes_customer/${
              localStorage.getItem("CompanyId") || tokenDecode?.companyId
            }/${data?.CustomerId}`
          );
          if (response?.data?.statusCode === 200) {
            setQuotes(response?.data?.data);
          }
        } catch (err) {
          console.error("Error to fetching quote data: ", err.message);
        }
      }
    };

    fetchQuote();
  }, [data, tokenDecode]);

  useEffect(() => {
    const fetchContract = async () => {
      if (data && data?.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/contract/get_contract_customer/${
              localStorage.getItem("CompanyId") || tokenDecode?.companyId
            }/${data?.CustomerId}`
          );
          setContract(response?.data?.data);
        } catch (err) {
          console.error("Error to fetching contract data: ", err.message);
        }
      }
    };
    fetchContract();
  }, [data, tokenDecode]);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (data && data.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/invoice/get_invoice_customer/${
              localStorage.getItem("CompanyId") || tokenDecode?.companyId
            }/${data?.CustomerId}`
          );
          if (response?.data?.statusCode === 200) {
            setInvoice(response?.data?.data);
          }
        } catch (err) {
          console.error("Error to fetching contract data: ", err.message);
        }
      }
    };
    fetchInvoice();
  }, [data, tokenDecode]);

  const handleQuoteNavigate = (id) => {
    if (companyName) {
      navigate(`/${companyName}/quotes-detail`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/quotes-detail"],
        },
      });
    } else {
      navigate(`/staff-member/worker-quotes-details`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/worker-quotes-detailss"],
        },
      });
    }
  };

  const handleContractNavigate = (id) => {
    if (companyName) {
      navigate(`/${companyName}/contractdetails`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/contractdetails"],
        },
      });
    } else {
      navigate(`/staff-member/worker-contract-details`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/worker-contract-details"],
        },
      });
    }
  };

  const handleInvoiceNavigate = (id) => {
    if (companyName) {
      navigate(`/${companyName}/invoice-details`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/invoice-details"],
        },
      });
    } else {
      navigate(`/staff-member/worker-invoice-detail`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/worker-invoice-detail"],
        },
      });
    }
  };

  const sendMail = async () => {
    try {
      setLoader(true);
      const response = await AxiosInstance.post(
        `/customer/send_mail/${data?.CustomerId}`
      );
      if (response?.data?.statusCode === 200) {
        showToast.success(response?.data?.message);
      } else {
        sendToast(response?.data?.message);
      }
    } catch (error) {
      console.error("Error to send mail");
    } finally {
      setModelOpen(false);
      setLoader(false);
    }
  };

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);
  const menuItems = [
    {
      label: (
        <>
          <RequestQuoteOutlinedIcon />
          Quote
        </>
      ),
      onClick: () => {
        navigate(`/${companyName}/add-quotes`, {
          state: {
            Customer: data,
            CustomerId: data?.CustomerId,
            navigats: [...location?.state?.navigats, "/add-quotes"],
          },
        });
      },
    },
    {
      label: (
        <>
          <WorkOutlineOutlinedIcon
            className="icones-dropdown"
            style={{ fontSize: "18px" }}
          />
          Contract
        </>
      ),
      onClick: () => {
        navigate(`/${companyName}/add-contract`, {
          state: {
            Customer: data,
            CustomerId: data?.CustomerId,
            navigats: [...location?.state?.navigats, "/add-contract"],
          },
        });
      },
    },
    {
      label: (
        <>
          <FileCopyOutlinedIcon
            className="icones-dropdown"
            style={{ fontSize: "18px" }}
          />
          Invoice
        </>
      ),
      onClick: () => {
        navigate(`/${companyName}/invoicetable`, {
          state: {
            CustomerId: data?.CustomerId,
            navigats: [...location?.state?.navigats, "/invoicetable"],
          },
        });
      },
    },
    {
      label: (
        <>
          <MarkEmailReadOutlinedIcon
            className="icones-dropdown"
            style={{ fontSize: "18px" }}
          />
          Resend Invitation
        </>
      ),
      onClick: () => {
        setModelOpen(true);
      },
    },
  ];

  return (
    <>
      <CustomerDetailsViews
        loader={loader}
        navigate={navigate}
        data={data}
        dropdownOpen={dropdownOpen}
        moreActiontoggle={moreActiontoggle}
        companyName={companyName}
        location={location}
        setModelOpen={setModelOpen}
        activeTabId={activeTabId}
        handleClick={handleClick}
        quotes={quotes}
        invoice={invoice}
        contract={contract}
        setOpen={setOpen}
        handlePropertyDelete={handlePropertyDelete}
        handleQuoteNavigate={handleQuoteNavigate}
        moment={moment}
        handleContractNavigate={handleContractNavigate}
        handleInvoiceNavigate={handleInvoiceNavigate}
        open={open}
        modelOpen={modelOpen}
        getData={getData}
        loading={loading}
        sendMail={sendMail}
        toggleDropdown={toggleDropdown}
        menuItems={menuItems}
      />
    </>
  );
}

export default CustomerDetails;
