import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Country } from "country-state-city";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../Client/style.css";
import AxiosInstance from "../../AxiosInstance";
import AddCustomerView from "./Views/AddCustomer";

function AddClient() {
  const location = useLocation();
  const navigate = useNavigate();
  const { companyName } = useParams();

  const [loader, setLoader] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [CompanyId, setCompanyId] = useState(localStorage.getItem("CompanyId"));

  const fetchTokenData = async () => {
    if (!CompanyId) {
      try {
        const token =
          localStorage.getItem("adminToken") ||
          localStorage.getItem("workerToken");

        if (!token) {
          console.error("Token not found in localStorage");
          return;
        }
        const res = await AxiosInstance.post(`/company/token_data`, {
          token,
        });
        if (res.data) {
          setCompanyId(res.data.data.companyId);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchTokenData();
  }, []);

  const formik = useFormik({
    initialValues: {
      CompanyId: "",
      FirstName: "",
      LastName: "",
      City: "",
      State: "",
      Zip: "",
      Country: "",
      PhoneNumber: "",
      EmailAddress: "",
      Address: "",
    },
    validationSchema: Yup.object({
      FirstName: Yup.string().required("Required"),
      LastName: Yup.string().required("Required"),
      // PhoneNumber: Yup.string().required("Required"),
      PhoneNumber: Yup.string()
        .required("Phone number is required")
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        ),
      EmailAddress: Yup.string()
        .email("Invalid email")
        .required("Email is required")
        .matches(/^[^@]+@[^@]+\.[^@]+$/, "Email must contain '@' and '.'"),
      City: Yup.string().required("Required"),
      State: Yup.string().required("Required"),
      Address: Yup.string().required("Required"),
      Zip: Yup.string().required("Required"),
      Country: Yup.string().required("Required"),
    }),
    validateOnChange: false, // Only validate on form submit or blur
    validateOnBlur: false, // Disable validation on blur if it's too slow
    enableReinitialize: true,
    // onSubmit: async (values) => {
    //   if (location.state?.id) {
    //     try {
    //       setLoader(true);
    //       const response = await AxiosInstance.put(
    //         `/customer/${location.state.id}`,
    //         values
    //       );
    //       if (response.data.statusCode === 200) {
    //         setLoader(false);

    //         setTimeout(() => {
    //           toast.success(response.data.message);
    //         }, 500);
    //         navigate(
    //           `/${companyName ? companyName : "staff-member"}/customer`,
    //           {
    //             state: {
    //               navigats: location.state.navigats.filter(
    //                 (item) => item !== "/add-customer"
    //               ),
    //             },
    //           }
    //         );
    //       } else if(response.data.statusCode === 203){
    //         toast.error(response.data.message, "error");
    //       } else {
    //         toast.error("", response.data.message, "error");
    //       }
    //     } catch (error) {
    //       if (error.response) {
    //         console.error(
    //           "Server responded with an error:",
    //           error.response.data
    //         );
    //         toast.error(error.response.data.message || "Something went wrong!");
    //       } else if (error.request) {
    //         console.error("No response received:", error.request);
    //         toast.error("No response from the server, please try again later.");
    //       } else {
    //         console.error("Error during request setup:", error.message);
    //         toast.error("Error occurred while sending request.");
    //       }
    //     } finally {
    //       setSelectedCountry("");
    //       setLoader(false);
    //     }
    //   } else {
    //     try {
    //       setLoader(true);
    //       const response = await AxiosInstance.post(`/customer`, {
    //         ...values,
    //         CompanyId: CompanyId,
    //       });
    //       if (response.data.statusCode === 201) {
    //         setLoader(false);
    //         if (location.state.previewPage) {
    //           toast.success(response.data.message);
    //           navigate(location.state.previewPage, {
    //             state: {
    //               CustomerId: response.data.CustomerId,
    //               navigats: location.state.navigats.filter(
    //                 (item) => item !== "/add-customer"
    //               ),
    //             },
    //           });
    //         } else {
    //           toast.success(response.data.message);
    //           navigate(
    //             `/${
    //               companyName
    //                 ? companyName + "/customer"
    //                 : "staff-member" + "/workercustomer"
    //             }`,
    //             {
    //               state: {
    //                 navigats: location.state.navigats.filter(
    //                   (item) => item !== "/add-customer"
    //                 ),
    //               },
    //             }
    //           );
    //         }
    //       } else {
    //         toast.error(response.data.message, "error");
    //       }
    //     } catch (error) {
    //       if (error.response) {
    //         console.error(
    //           "Server responded with an error:",
    //           error.response.data
    //         );
    //         toast.error(error.response.data.message || "Something went wrong!");
    //       } else if (error.request) {
    //         console.error("No response received:", error.request);
    //         toast.error("No response from the server, please try again later.");
    //       } else {
    //         console.error("Error during request setup:", error.message);
    //         toast.error("Error occurred while sending request.");
    //       }
    //     } finally {
    //       setLoader(false);
    //     }
    //   }
    // },
    onSubmit: async (values) => {
      if (location.state?.id) {
        try {
          setLoader(true);
          const response = await AxiosInstance.put(
            `/customer/${location.state.id}`,
            values
          );
          if (response.data.statusCode === 200) {
            setLoader(false);

            setTimeout(() => {
              toast.success(response.data.message);
            }, 1000); // Delay toast by 1000ms (1 second)

            navigate(
              `/${companyName ? companyName : "staff-member"}/customer`,
              {
                state: {
                  navigats: location.state.navigats.filter(
                    (item) => item !== "/add-customer"
                  ),
                },
              }
            );
          } else if (response.data.statusCode === 203) {
            //
            setTimeout(() => {
              toast.error(response.data.message, "error");
            }, 500);
          } else {
            setTimeout(() => {
              toast.error("", response.data.message, "error");
            }, 500);
          }
        } catch (error) {
          if (error.response) {
            console.error(
              "Server responded with an error:",
              error.response.data
            );
            setTimeout(() => {
            toast.error(error.response.data.message || "Something went wrong!");
          }, 500);
          } else if (error.request) {
            console.error("No response received:", error.request);
            setTimeout(() => {
            toast.error("No response from the server, please try again later.");
          }, 500);
          } else {
            console.error("Error during request setup:", error.message);
            setTimeout(() => {
            toast.error("Error occurred while sending request.");
          }, 500);
          }
        } finally {
          setSelectedCountry("");
          setLoader(false);
        }
      } else {
        try {
          setLoader(true);
          const response = await AxiosInstance.post(`/customer`, {
            ...values,
            CompanyId: CompanyId,
          });
          if (response.data.statusCode === 201) {
            setLoader(false);
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500); // Delay toast by 1000ms (1 second)

            if (location.state.previewPage) {
              navigate(location.state.previewPage, {
                state: {
                  CustomerId: response.data.CustomerId,
                  navigats: location.state.navigats.filter(
                    (item) => item !== "/add-customer"
                  ),
                },
              });
            } else {
              navigate(
                `/${
                  companyName
                    ? companyName + "/customer"
                    : "staff-member" + "/workercustomer"
                }`,
                {
                  state: {
                    navigats: location.state.navigats.filter(
                      (item) => item !== "/add-customer"
                    ),
                  },
                }
              );
            }
          } else {
            setTimeout(() => {
            toast.error(response.data.message, "error");
          }, 500);
          }
        } catch (error) {
          if (error.response) {
            console.error(
              "Server responded with an error:",
              error.response.data
            );
            setTimeout(() => {
            toast.error(error.response.data.message || "Something went wrong!");
          }, 500);
          } else if (error.request) {
            console.error("No response received:", error.request);
            setTimeout(() => {
            toast.error("No response from the server, please try again later.");
          }, 500);
          } else {
            console.error("Error during request setup:", error.message);
            setTimeout(() => {
            toast.error("Error occurred while sending request.");
          }, 500);
          }
        } finally {
          setLoader(false);
        }
      }
    },
  });

  //  Phone number formatting function
  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10); // Limit to 10 digits
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/); // Matches (xxx) xxx-xxxx

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  // Handle phone number change and format it
  const handlePhoneChange = (e) => {
    if (formik.values.PhoneNumber?.length > e.target.value?.length) {
      formik.setFieldValue("PhoneNumber", e.target.value);
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      formik.setFieldValue("PhoneNumber", formattedValue);
    }
  };

  useEffect(() => {
    setCountries(Country.getAllCountries());
    if (formik.values.Country) {
      setSelectedCountry(() => {
        const country = Country.getAllCountries().find(
          (item) => item.name === formik.values.Country
        );
        return country;
      });
    }
  }, [formik]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AxiosInstance.get(`/customer/${location.state.id}`);
        formik.setValues(res.data.data);
        formik.setValues({
          ...res.data.data,
          Address: res.data.data?.location[0]?.Address,
          City: res.data.data?.location[0]?.City,
          State: res.data.data?.location[0]?.State,
          Zip: res.data.data?.location[0]?.Zip,
          Country: res.data.data?.location[0]?.Country,
          // ConfirmPassword: res.data.data?.Password,
        });
      } catch (error) {
        console.error("Error: ", error.message);
      }
    };
    if (location.state?.id) {
      fetchData();
    }
  }, [location.state.id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
  };

  return (
    <>
      <AddCustomerView
        formik={formik}
        handleChange={handleChange}
        loader={loader}
        countries={countries}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        handlePhoneChange={handlePhoneChange}
      />
      <Toaster />
    </>
  );
}

export default AddClient;
