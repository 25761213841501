import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "./style.css"
import { Grid, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap"; // React Bootstrap components


const SettingDropdown = ({
  isOpenDropDown,
  toggle,
  companyName,
}) => {
  const navigate = useNavigate();

  return (
    <Grid className="d-flex justify-content-between ">
      <Grid className="setting-dropdown settingsidebardrop mb-0">
        <Dropdown isOpen={isOpenDropDown} toggle={toggle} className="settingProfileSetting">
          <DropdownToggle className="back-color" caret>
            Setting
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <Typography style={{ fontWeight: 600, marginBottom: "10px" }}>
                BUSINESS <br /> MANAGEMENT
              </Typography>
            </DropdownItem>
            <DropdownItem
              className="dropdown-link-setting"
              onClick={(e) => {
                navigate(
                    companyName
                      ? `/${companyName}/product&service`
                      : "/superadmin/product&service",
                    {
                      state: {
                        navigats: ["/index", "/product&service"],
                      },
                    }
                  );
              }}
              style={{
                textDecoration: "none",
                color: "#4F963B",
                display: "block",
                marginBottom: "5px",
              }}
            >
              Products & Services
            </DropdownItem>
            <DropdownItem>
              <Typography style={{ fontWeight: 600, marginBottom: "10px" }}>
                TEAM <br /> ORGANIZATION
              </Typography>
            </DropdownItem>
            <DropdownItem
              style={{
                textDecoration: "none",
                color: "#4F963B",
                display: "block",
                marginBottom: "5px",
              }}
              className="dropdown-link-setting"
              onClick={() => {
                navigate(
                  companyName
                    ? `/${companyName}/manageteam`
                    : "/superadmin/manageteam",
                  {
                    state: { navigats: ["/index", "/manageteam"] },
                  }
                );
              }}
            >
              Manage Team 
            </DropdownItem>
            <DropdownItem></DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Grid>
    </Grid>
    
  );
};

export default SettingDropdown;
