import React, { useEffect, useState } from "react";
import { handleAuth } from "../../components/Login/Auth";
import "./style.css";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import Customer from "../../assets/image/dashboard/Customer.svg";
import Quote from "../../assets/image/dashboard/Quote.svg";
import contract from "../../assets/image/dashboard/Contract.svg";
import invoice from "../../assets/image/dashboard/Invoice.svg";
import AxiosInstance from "../../Views/AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AdminDashboard = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const { companyName } = useParams();

  const currentHour = new Date().getHours();

  let welcomeMessage;
  if (currentHour < 12) {
    welcomeMessage = "Good Morning";
  } else if (currentHour < 18) {
    welcomeMessage = "Good Afternoon";
  } else {
    welcomeMessage = "Good Evening";
  }

  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = process.env.REACT_APP_BASE_API;
      const token =
        localStorage.getItem("adminToken") ||
        localStorage.getItem("clientToken");

      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      try {
        const res = await AxiosInstance.post(`${baseUrl}/company/token_data`, {
          token,
        });

        setData(res.data.data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchData();
  }, []);

  // Update cardData to use the updated navigate and companyName
  const cardData = [
    {
      title: "Customer",
      logo: Customer,
      desc: "Record new service requests from your Customer.",
      option: "Create a request",
      onClick: () => {
        navigate(`/${companyName}/add-customer`, {
          state: { navigats: ["/index", "/add-customer"] },
        });
      },
    },

    // {
    //   title: (
    //     <span onClick={() => {
    //       navigate(`/${companyName}/customer`, {
    //         state: { navigats: ["/index", "/customer"] },
    //       });
    //     }}>
    //       Customer
    //     </span>
    //   ),
    //   logo: Customer,
    //   desc: (
    //     <span onClick={() => {
    //       navigate(`/${companyName}/customer`, {
    //         state: { navigats: ["/index", "/customer"] },
    //       });
    //     }}>
    //       Record new service requests from your Customer.
    //     </span>
    //   ),
    //   option: (
    //     <span
    //       onClick={() => {
    //         navigate(`/${companyName}/add-customer`, {
    //           state: { navigats: ["/index", "/add-customer"] },
    //         });
    //       }}
    //     >
    //       Create a request
    //     </span>
    //   ),
    // },
    {
      title: "Quotes",
      logo: Quote,
      desc: "Send your Customer professional quotes they can approve online.",
      option: "Create a quote",
      onClick: () => {
        navigate(`/${companyName}/add-quotes`, {
          state: { navigats: ["/index", "/add-quotes"] },
        });
      },
    },
    {
      title: "Contracts",
      logo: contract,
      desc: "Schedule contract with all the details you need to get them done.",
      option: "Create a contract",
      onClick: () => {
        navigate(`/${companyName}/add-contract`, {
          state: { navigats: ["/index", "/add-contract"] },
        });
      },
    },
    // 5150 start
    {
      title: "Invoices",
      logo: invoice,
      desc: "Send your Customer professional invoices they can pay online",
      option: "Create an invoice",
      onClick: () => {
        navigate(`/${companyName}/invoice`, {
          state: { navigats: ["/index", "/invoice"] },
        });
      },
    },
    // 5150 end
  ];

  return (
    <div className="admin">
      <div className="px-0">
        <h4
          className="px-1 text-blue-color"
          style={{
            fontSize: "35px",
            fontWeight: "700",
            fontFamily: "Poppins",
            lineHeight: "28.8px ",
          }}
        >
          {data?.full_name
            ? welcomeMessage + ", " + data?.full_name
            : welcomeMessage}
        </h4>
        <div className="grid-container py-3 pb-5">
          {cardData &&
            cardData.map((item, index) => (
              <div className="grid-item bg-blue-color" key={index}>
                <div className="content ">
                  <div className="title">
                    <div className="logo bg-orange-color">
                      <img
                        src={item.logo}
                        alt={item.title}
                        width={34}
                        height={34}
                      />
                    </div>
                    <h4 className="name">{item.title}</h4>
                  </div>
                  <div className="desc">{item.desc}</div>
                </div>
                <div
                  className="options bg-orange-color text-white-color"
                  onClick={item.onClick}
                >
                  <span>{item.option}</span>
                  <span>
                    <EastRoundedIcon />
                  </span>
                </div>
                <div className="circle">
                  <span className="bg-orange-color"></span>
                </div>
              </div>
            ))}
        </div>
        <div className="appointment border-blue-color">
          <h3 className="appointment-title text-blue-color">
            Today's Appointments
          </h3>
          <div className="appointment-flex">
            <div className="appointment-item">
              <div>
                <span className="appointment-count bg-blue-color ">00</span>
              </div>
              <div className="appointment-info">
                Total
                <br />
                $50,000
              </div>
              <div className="appointment-divider">|</div>
            </div>

            <div className="appointment-item">
              <div>
                <span className="appointment-count bg-blue-color">00</span>
              </div>
              <div className="appointment-info text-blue-color">
                Total
                <br />
                $4,50,000
              </div>
              <div className="appointment-divider text-blue-color">|</div>
            </div>

            <div className="appointment-item">
              <div>
                <span className="appointment-count bg-blue-color">00</span>
              </div>
              <div className="appointment-info">
                Total
                <br />
                $10,50,000
              </div>
              <div className="appointment-divider">|</div>
            </div>

            <div className="appointment-item">
              <div>
                <span className="appointment-count bg-blue-color">00</span>
              </div>
              <div className="appointment-info">
                Total
                <br />
                $100000
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
