import { Dialog, DialogContent, DialogTitle, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Input, CardBody, CardHeader, FormGroup, Label } from "reactstrap";
import {
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import pdficon from "../../../assets/image/icons/pdficon.png";
import { handleAuth } from "../../../components/Login/Auth";
import { postFile } from "../../../components/Files/Functions";
import AxiosInstance from "../../AxiosInstance";
import toast from "react-hot-toast";
import sendToast from "../../../components/Toast/sendToast";
import { ColorRing } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const ContractMail = ({
  modal,
  setModal,
  customerData,
  propertyData,
  contractData,
  handleSubmit,
  Title,
  Total,
  Attachment,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const cdn_url = process.env.REACT_APP_CDN_API;
  const [inputValue, setInputValue] = useState(
    customerData?.EmailAddress || ""
  );

  useEffect(() => {
    setInputValue(customerData?.EmailAddress);
  }, [customerData?.EmailAddress]);

  const todayDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const [files, setFiles] = useState([]);
  const [sendToMail, setSendToMail] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
    } else if (e.type === "dragleave") {
    }
  };

  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const renderFilePreview = (file) => {
    if (file?.type === "application/pdf") {
      return (
        <div
          key={file?.name}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <img
            src={pdficon}
            alt="PDF Icon"
            style={{ width: "30px", height: "30px", marginRight: "10px" }}
          />
          <p>{file?.name}</p>
        </div>
      );
    } else if (file?.type?.startsWith("image/")) {
      return (
        <div
          key={file?.name}
          style={{
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={URL.createObjectURL(file)}
            alt={`file-preview-${file?.name}`}
            style={{
              width: "30px",
              height: "30px",
              objectFit: "cover",
              marginRight: "10px",
            }}
          />
          <p>{file?.name}</p>
        </div>
      );
    } else {
      return (
        <div key={file?.name} style={{ marginBottom: "10px" }}>
          <p>{file?.name}</p>
        </div>
      );
    }
  };

  const [subject, setSubject] = useState(
    `Booking confirmation from ${data?.full_name} - ${todayDate}`
  );

  useEffect(() => {
    setSubject(`Booking confirmation from ${data?.full_name} - ${todayDate}`);
  }, [data]);

  const [content, setContent] = useState(
    `Dear ${customerData?.FirstName} ${customerData?.LastName},\n\n` +
      `Thank you for booking with us.\n\n` +
      `We are pleased to confirm your contract for ${
        contractData?.Title || Title || "the specified services"
      } ` +
      `for which the Total price is ${contractData?.Total || Total}.` +
      `The location of contract is ${propertyData?.Address || ""} ${
        propertyData?.City ? ", " + propertyData?.City : ""
      } ` +
      `${propertyData?.State ? ", " + propertyData?.State : ""} ` +
      `${propertyData?.Country ? ", " + propertyData?.Country : ""} ` +
      `${propertyData?.Zip ? " - " + propertyData?.Zip : ""}.\n\n` +
      `Should you have any questions or need further assistance, please do not hesitate to contact us at ${data?.primaryEmailAddress}.\n\n` +
      `Sincerely,\n${data?.full_name}`
  );

  useEffect(() => {
    const initialContent = `
    <div style="font-family: Arial, sans-serif; color: #333; max-width: 600px; margin: 0 auto; border-radius: 10px; overflow: hidden; border: 1px solid #dedede; box-shadow: none;">
     
      <div style="text-align: center; padding: 10px;    width: 50%;">
         <img src="https://app.cloudjobmanager.com/cdn/upload/20241002123818_site-logo1.png" alt="Company Logo" style="width: 50%; height: auto;">
      </div>

    
      <div style="padding: 20px;">
          <h2 style="font-size: 22px; color: #003366; text-align: center; margin-bottom: 20px;">Your Custom Contract is Ready!</h2>
          <p style="font-size: 16px; color: #555;">Dear <strong style="color: #003366;">${
            customerData?.FirstName
          } ${customerData?.LastName}</strong></strong>,</p>
          <p>Thank you for booking with us.</p>
    <p>We are pleased to confirm your contract for <strong>${
      contractData?.Title || Title
    }</strong> with a total amount of <strong> $${
      contractData?.Total || Total
    }</strong>.</p>
          <div style="background-color: #f9f9f9; border: 1px solid #e0e0e0; border-radius: 8px; padding: 15px; text-align: center; margin: 20px 0;">
              <p style="font-size: 24px; color: #e88c44; margin: 0;">Total Amount: <strong>$${
                contractData?.Total || Total
              }</strong></p>
              <p style="font-size: 16px; color: #718096; margin: 0;">Contract Date: <strong>${moment(
                contractData?.createdAt
              ).format("DD-MM-YYYY")}</strong></p>
          </div>
          <p>The Address of the contract is ${propertyData?.Address || ""} ${
      propertyData?.City ? ", " + propertyData?.City : ""
    }${propertyData?.State ? ", " + propertyData?.State : ""} ${
      propertyData?.Country ? ", " + propertyData?.Country : ""
    } ${propertyData?.Zip ? " - " + propertyData?.Zip : ""}</p>
          <p style="font-size: 16px; color: #555;">For any questions or to proceed with this quote, feel free to reach out at <a href="mailto:${
            data?.primaryEmailAddress
          }" style="color: #003366; text-decoration: none;"><strong>${
      data?.primaryEmailAddress
    }</strong></a>. We're happy to assist you!</p>
          <p style="font-size: 16px; color: #555;">We look forward to working with you!</p>
          <div style="text-align: right; margin-top: 20px;">
            <p style="font-size: 16px; color: #555; margin: 0;">Best regards,<br />
              <strong style="color: #003366;">${data?.full_name}</strong><br />
              <span style="font-size: 14px; color: #718096;">${
                data?.primaryEmailAddress
              }</span>
            </p>
          </div>
      </div>

    
      <div style="background-color: #f4f4f4; color: #777; text-align: center; padding: 15px 0; border-top: 1px solid #dedede;">
          <p style="margin: 0; font-size: 12px;">&copy; ${new Date().getFullYear()} cloudjobrental. All rights reserved.</p>
      </div>
    </div>
    `;

    setContent(initialContent);
  }, [data, customerData, contractData, propertyData,Title,Total]);

  const handleChange = (value) => {
    setContent(value);
  };

  const [postLoader, setPoastLoader] = useState(false);
  const handleSendMail = async () => {
    try {
      setPoastLoader(true);
      const fileUrls = [];
      if (selectedFiles.length > 0) {
        for (const file of selectedFiles) {
          if (typeof file === "string") {
            fileUrls.push(file);
          } else {
            const url = await postFile(file);
            fileUrls.push(url);
          }
        }
      }
      const url = `/contract/send_mail/${data.companyId}`;
      const object = {
        to: inputValue,
        subject,
        content,
        sendToMe: sendToMail ? data?.primaryEmailAddress : false,
        selectedFiles: fileUrls,
      };

      const response = await AxiosInstance.post(url, object);
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        setModal(false);
        if (handleSubmit) {
          handleSubmit();
        }
      } else {
        sendToast(response.data.message);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setPoastLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={modal}
        onClose={() => setModal(!modal)}
        style={{ height: "100%" }}
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            width: "60%",
            height: "70%",
            maxWidth: "none",
            maxHeight: "none",
            marginTop: "10px",
          },
        }}
        className="email-quote"
      >
        <DialogTitle className="d-flex justify-content-between">
          <div className="Title" style={{ margin: "0px", padding: "0px" }}>
            <h4
              className="text-blue-color"
              style={{ fontWeight: "600", fontSize: "34px" }}
            >
              Email booking confirmation to {customerData?.FirstName}{" "}
              {customerData?.LastName}
            </h4>
          </div>
          <span onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            <CloseIcon />
          </span>
        </DialogTitle>
        <DialogContent className="w-100 row mt-3">
          <div className="col-12 col-lg-8 order-1 order-lg-1">
            <div className="justify-content-between d-flex">
              <div
                className="input-email d-flex align-items-center border-blue-color"
                style={{
                  border: "solid 0.5px",
                  borderRadius: "10px",
                  padding: "5px",
                  width: "100%",
                  height: "66px",
                }}
              >
                <div
                  className="text-blue-color"
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  To
                </div>
                <div className="email-input-field" style={{ width: "50%" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      border: "1px solid rgba(6, 49, 100, 0.2)",
                      borderRadius: "10px",
                      marginLeft: "5%",
                      width: "100%",
                    }}
                  >
                    <Input
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "20px",
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: "400",
                        boxShadow: "none",
                      }}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span style={{ marginRight: "3%" }}>
                      <CloseIcon
                        style={{
                          color: "rgba(6, 49, 100, 0.4)",
                          cursor: "pointer",
                          height: "14px",
                          width: "14px",
                        }}
                        onClick={() => setInputValue("")}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className="card"
                style={{
                  marginTop: "20px",
                  height: "325px",
                  borderRadius: "10px",
                  border: "0.5px solid rgba(6, 49, 100, 1)",
                  overflowY: "scroll",
                }}
              >
                <CardHeader
                  className="text-blue-color"
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#fff",
                    padding: "6px",
                    borderRadius: "12px",
                    border: "none",
                  }}
                >
                  <div className="d-flex mt-3 p-0">
                    <div
                      className="text-blue-color"
                      style={{
                        marginLeft: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        marginTop: "0.5%",
                      }}
                    >
                      Subject
                    </div>
                    <div style={{ width: "100%", marginLeft: "5%" }}>
                      <Input
                        id={`Subject`}
                        name={`Subject`}
                        style={{
                          border: "1px solid rgba(6, 49, 100, 0.2)",
                          fontSize: "14px",
                          paddingLeft: "15px",
                          width: "100%",
                          height: "31px",
                          fontWeight: "400",
                        }}
                        className="text-blue-color"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <hr
                  style={{ color: "rgba(6, 49, 100, 1)", fontWeight: "500" }}
                />
                <div>
                  <ReactQuill
                    value={content}
                    onChange={handleChange}
                    modules={{
                      toolbar: [
                        [{ header: "1" }, { header: "2" }, { font: [] }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["bold", "italic", "underline"],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "list",
                      "bullet",
                      "link",
                      "image",
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <FormGroup
                check
                style={{ alignItems: "center", display: "flex" }}
              >
                <Input
                  type="checkbox"
                  id="send_copy"
                  name="send_copy"
                  style={{ width: "20px", height: "20px" }}
                  value={sendToMail}
                  className="text-blue-color"
                  onChange={(e) => setSendToMail(e.target.checked)}
                />
                <Label
                  style={{
                    color: "rgba(6, 49, 100, 1)",
                    fontSize: "12px",
                    fontWeight: "400",
                    marginLeft: "10px",
                    marginTop: "8px",
                  }}
                  check
                >
                  Send me a copy
                </Label>
              </FormGroup>
            </div>
          </div>
          <div className="col-12 col-lg-4 order-2 order-lg-2">
            <h6
              style={{
                color: "rgba(6, 49, 100, 1)",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Attachments
            </h6>
            <div
              className="file-upload"
              style={{
                padding: "5px",
                fontSize: "14px",
                height: "53px",
                paddingTop: "4%",
                alignItems: "center",
                marginTop: "20px",
                border: "2px dashed rgba(6, 49, 100, 0.8)",
              }}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="contract-Attachment"
                name="Attachment"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <label
                className="d-flex justify-content-between"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                Drag your files here or{" "}
                <span
                  style={{
                    color: "rgba(6, 49, 100, 1)",
                    fontSize: "14px",
                    marginTop: "-5px",
                    border: "0.5px solid rgba(6, 49, 100, 0.8)",
                    borderRadius: "8px",
                    padding: "4px 8px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("contract-Attachment").click()
                  }
                >
                  Select a File
                </span>
              </label>
            </div>
            <div className="align-items-center mt-3">
              {files.map((file, index) => (
                <FormGroup
                  check
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Input
                    type="checkbox"
                    id={`send_copy_${index}`}
                    name="send_copy"
                    className="text-blue-color"
                    style={{
                      marginTop: "6px",
                      width: "20px",
                      height: "20px",
                    }}
                    onChange={(e) => {
                      const value = e.target.checked;
                      if (value) {
                        setSelectedFiles([...selectedFiles, file]);
                      } else {
                        setSelectedFiles(() =>
                          selectedFiles.filter((files) => files !== file)
                        );
                      }
                    }}
                  />
                  <Label
                    for={`send_copy_${index}`}
                    className="text-blue-color"
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginLeft: "10px", height: "20px" }}>
                      {renderFilePreview(file, index)}
                    </div>
                  </Label>
                </FormGroup>
              ))}
            </div>
            <hr />
            <div className="align-items-center mt-0">
              <UncontrolledAccordion>
                <AccordionItem style={{ border: "none" }}>
                  <AccordionHeader targetId="1" style={{ padding: "0px" }}>
                    <div
                      style={{ fontSize: "10px", padding: "0px" }}
                      className="d-flex justify-content-between"
                    >
                      <h6
                        style={{
                          color: "rgb(42, 79, 97)",
                          fontSize: "15px",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Contract Attachments
                      </h6>
                      <span
                        style={{
                          width: "24px",
                          height: "24px",
                          border: "1px solid rgba(6, 49, 100, 1)",
                          borderRadius: "50px",
                          background: "rgba(6, 49, 100, 0.2)",
                          textAlign: "center",
                          padding: "1px",
                          fontSize: "18px",
                          marginLeft: "10px",
                          marginRight: "20px",
                        }}
                      >
                        {Attachment?.length || 0}
                      </span>
                    </div>
                  </AccordionHeader>
                  <AccordionBody
                    accordionId="1"
                    style={{ paddingLeft: "0px", marginTop: "-10px" }}
                  >
                    {/* <div>
                      {Attachment ? (
                        Attachment?.map((file, index) => (
                          <FormGroup
                            check
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <Input
                              type="checkbox"
                              id={`send_copy_${index}`}
                              name="send_copy"
                              style={{
                                color: "#063164",
                                marginTop: "6px",
                                width: "20px",
                                height: "20px",
                              }}
                              onChange={(e) => {
                                const value = e.target.checked;
                                if (value) {
                                  setSelectedFiles([...selectedFiles, file]);
                                } else {
                                  setSelectedFiles(() =>
                                    selectedFiles.filter(
                                      (files) => files !== file
                                    )
                                  );
                                }
                              }}
                            />

                            <Label
                              for={`send_copy_${index}`}
                              style={{
                                color: "#063164",
                                fontSize: "12px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{ marginLeft: "10px", height: "20px" }}
                              >
                                {renderFilePreview(file, index)}
                              </div>
                            </Label>
                          </FormGroup>
                        ))
                      ) : (
                        <p
                          style={{
                            fontSize: "12px",
                            lineHeight: "24px",
                            fontStyle: "italic",
                            color: "rgba(6, 49, 100, 0.7)",
                          }}
                        >
                          No attechments were found. <br /> Any Attachments on
                          contract notes will appear here.
                        </p>
                      )}
                    </div> */}
                    <div style={{ marginBottom: "0" }}>
                      {Attachment ? (
                        <div
                          style={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {Attachment.map((file, index) => (
                            <FormGroup
                              check
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Input
                                type="checkbox"
                                id={`send_copy_${index}`}
                                name="send_copy"
                                className="text-blue-color"
                                style={{
                                  marginTop: "6px",
                                  width: "20px",
                                  height: "20px",
                                }}
                                onChange={(e) => {
                                  const value = e.target.checked;
                                  if (value) {
                                    setSelectedFiles([...selectedFiles, file]);
                                  } else {
                                    setSelectedFiles(() =>
                                      selectedFiles.filter(
                                        (files) => files !== file
                                      )
                                    );
                                  }
                                }}
                              />
                              <Label
                                for={`send_copy_${index}`}
                                className="text-blue-color"
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft:"13px"
                                }}
                              >
                                <div
                                  style={{
                                    paddingTop: "10px",
                                    fontSize: "12px",
                                    color: "rgb(42, 79, 97)",
                                  }}
                                >
                                  {typeof file === "string" ? file : file.name}
                                </div>
                              </Label>
                            </FormGroup>
                          ))}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontSize: "12px",
                            lineHeight: "24px",
                            fontStyle: "italic",
                            color: "rgba(6, 49, 100, 0.7)",
                          }}
                        >
                          No attachments were found. <br /> Any attachments on
                          invoice notes will appear here.
                        </p>
                      )}
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>
            <hr />
          </div>
          <div className="order-3 order-lf-3">
            <hr />
            {postLoader ? (
              <div className="d-flex justify-content-end">
                <ColorRing
                  height="30"
                  width="30"
                  colors={["#000", "#000", "#000", "#000", "#000"]}
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <div
                  className="d-flex justify-content-between button-responsive"
                  style={{ marginTop: "0px" }}
                >
                  <div>
                    <Button
                      outline
                      className="footer-buttons"
                      style={{
                        fontSize: "16px",
                        textTransform: "none",
                        border: "solid 1px",
                        width: "100%",
                        borderColor: "rgba(6, 49, 100, 0.8)",
                        color: "rgba(6, 49, 100, 1)",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        setModal(false);
                        setFiles([]);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <div
                  className="d-flex  button-responsive"
                  style={{ marginTop: "0px" }}
                >
                  <div>
                    <Button
                      outline
                      className="footer-buttons"
                      style={{
                        fontSize: "16px",
                        textTransform: "none",
                        color: "white",
                        marginLeft: "15px",
                        backgroundColor: "rgba(6, 49, 100, 1)",
                        fontWeight: "500",
                      }}
                      onClick={handleSendMail}
                    >
                      Send email
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContractMail;
