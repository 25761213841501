import React, { useState } from "react";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { FileModal } from "./DisplayFiles";
import "./files.css";

const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/avif"];

const Files = ({ files, setFiles }) => {
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      setFiles([...files, ...droppedFiles]);
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFiles = Array.from(e.target.files);
      setFiles([...files, ...selectedFiles]);
    }
  };

  return (
    <div className="file-upload-container">
      <div
        className="file-upload"
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="Attachment"
          name="Attachment"
          className="file-input"
          multiple={true}
          onChange={handleChange}
        />
        <label className="upload-files-label">
          Drag your files here or{" "}
          <span
            onClick={() => document.getElementById("Attachment").click()}
            className="select-file-link"
          >
            Select a File
          </span>
        </label>
      </div>
    </div>
  );
};

const File = ({ file, setFile, index }) => {
  const cdnUrl = process.env.REACT_APP_CDN_API;
  const [modalOpen, setModalOpen] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      if (allowedTypes.includes(droppedFile.type)) {
        setFile(index, droppedFile);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (allowedTypes.includes(selectedFile.type)) {
        setFile(index, selectedFile);
      }
    }
  };

  const handleRemove = () => {
    // const confirmation = window.confirm("Are you sure you want to remove this file?");
    // if (confirmation) {
      setFile(index, "");
    // }
  };

  return (
    <div>
      <div
        className="file-upload px-3 py-2 fileUpload"
        style={{
          maxHeight: "140px",
          minHeight: "130px",
          height: "100%",
          border: "2px dashed #ccc",
          cursor: !file ? "pointer" : "default",
          marginTop: "-16px",
        }}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
        onClick={() =>
          !file && document.getElementById(`single-attachment-${index}`).click()
        }
      >
        <input
          type="file"
          id={`single-attachment-${index}`}
          name="single-attachment"
          accept=".jpg,.jpeg,.png,.avif"
          style={{ display: "none" }}
          onChange={handleChange}
          multiple={false}
        />
        <span
          className="d-flex flex-column w-100 justify-content-center align-items-center"
          style={{ minHeight: "120px" }}
        >
          {!file ? (
            <CameraAltOutlinedIcon />
          ) : (
            <div className="row w-100 p-0">
              <div
                className="col-10 p-0"
                style={{ maxHeight: "120px" }}
                onClick={() => setModalOpen(true)}
              >
                {file ? (
                  typeof file === "string" ? (
                    <img
                      src={`${cdnUrl}/upload/${file}`}
                      height={"120px"}
                      alt={file}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <img
                      src={URL?.createObjectURL(file)}
                      height={"120px"}
                      alt={file?.name}
                      style={{ width: "100%" }}
                    />
                  )
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-2 d-flex flex-column justify-content-around align-items-end"
                style={{ minHeight: "120px" }}
              >
                <ModeEditOutlineRoundedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    document
                      .getElementById(`single-attachment-${index}`)
                      .click()
                  }
                />
                <DeleteForeverRoundedIcon
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={handleRemove}
                />
              </div>
            </div>
          )}
        </span>
      </div>
      <FileModal open={modalOpen} setOpen={setModalOpen} file={file} />
    </div>
  );
};

const SingleFileUpload = ({ file, setFile }) => {
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      if (allowedTypes.includes(droppedFile.type)) {
        setFile(droppedFile);
      } else {
        alert("File type not allowed");
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (allowedTypes.includes(selectedFile.type)) {
        setFile(selectedFile);
      } else {
        alert("File type not allowed");
      }
    }
  };

  return (
    <div className="file-upload-container">
      <div
        className="file-upload"
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="Attachment"
          name="Attachment"
          className="file-input"
          accept=".jpg,.jpeg,.png,.avif,pdf"
          onChange={handleChange}
        />
        <label className="upload-files-label">
          Drag your file here or{" "}
          <span
            onClick={() => document.getElementById("Attachment").click()}
            className="select-file-link"
          >
            Select a File
          </span>
        </label>
      </div>
    </div>
  );
};

export { Files, File, SingleFileUpload };
