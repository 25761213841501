import React from "react";
import { useEffect, useState } from "react";
import { useAuthAdmin } from "../../components/Login/Auth";
import axios from "axios";
import AxiosInstance from "../../Views/AxiosInstance";
import Quotes from "../../../src/assets/White-sidebar-icon/Quote.svg";
import Contract from "../../../src/assets/White-sidebar-icon/Contract.svg";
import Invoice from "../../../src/assets/White-sidebar-icon/Invoice.svg";
import Appoinment from "../../../src/assets/White-sidebar-icon/Appoinments.svg";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { handleAuth } from "../../components/Login/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import WorkerGraphs from "../../components/Dashboard/WorkerGraphs";

const CustomerDashboard = () => {
  const [data, setData] = useState({});
  const [loader, setloader] = useState(true);
  const currentHour = new Date().getHours();
  const navigate = useNavigate();
  const location = useLocation();
  let welcomeMessage;
  if (currentHour < 12) {
    welcomeMessage = "Good Morning";
  } else if (currentHour < 18) {
    welcomeMessage = "Good Afternoon";
  } else {
    welcomeMessage = "Good Evening";
  }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const baseUrl = process.env.REACT_APP_BASE_API;
  //     const token =
  //       localStorage.getItem("adminToken") ||
  //       localStorage.getItem("clientToken") ||
  //       localStorage.getItem("workerToken");

  //     if (!token) {
  //       console.error("Token not found in localStorage");
  //       return;
  //     }
  //     try {
  //       const res = await AxiosInstance.post(`${baseUrl}/company/token_data`, {
  //         token,
  //       });
  //       setData(res.data.data);
  //     } catch (error) {
  //       console.error("Error:", error.message);
  //     }
  //   };

  //   fetchData();
  // }, []);
  const [tokenDecode, setTokenDecode] = useState({});
  const companyId = tokenDecode.companyId;
  const WorkerId = tokenDecode.WorkerId;
  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching token decode data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(
          `/worker/worker-dashboard/${WorkerId}/${companyId}`
        );
        if (response.status === 200) {
          setData(response.data.data);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setloader(false);
      }
    };

    fetchData();
  }, [WorkerId, companyId]);
  const handleQuotesNavigation = () => {
    navigate(`/staff-member/WorkerQuotes`, {
      state: { navigats: ["/index", "/WorkerQuotes"] },
    });
  };
  const handlecontractNavigation = () => {
    navigate(`/staff-member/workercontract`, {
      state: { navigats: ["/index", "/workercontract"] },
    });
  };
  const handleinvoiceNavigation = () => {
    navigate(`/staff-member/workerinvoice`, {
      state: { navigats: ["/index", "/workerinvoice"] },
    });
  };
  const handleappoinmentsNavigation = () => {
    navigate(`/staff-member/WorkerAppoinments`, {
      state: { navigats: ["/index", "/WorkerAppoinments"] },
    });
  };
  return (
    <div>
      <div className="px-0">
        <h4
          className="px-1"
          style={{
            fontSize: "35px",
            fontWeight: "700",
            fontFamily: "Poppins",
            lineHeight: "28.8px",
            color: "rgba(42, 79, 97, 1)",
          }}
        >
          {welcomeMessage + ","} {tokenDecode?.full_name}
        </h4>
      </div>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center text-blue-color"
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="d-flex gap-1 mt-5">
          <div className="col-3">
            <div class="card " style={{ borderRadius: "15px" }}>
              <div class="card-body p-0 ">
                <div
                  className="card-body  bg-blue-color"
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                >
                  <div className="text-end d-flex justify-content-end">
                    <p className="card-text text-end first-card-clientt ">
                      <img
                        src={Quotes}
                        className="border-orange-color "
                        style={{ height: "37px" }}
                      />
                    </p>
                  </div>
                  <div>
                    <h1 className="mx-1 card-title text-white-color mt-5">
                      {/* {data.quotes || 0} */}
                      12
                    </h1>
                  </div>
                </div>
                <div
                  onClick={handleQuotesNavigation}
                  className="card-footer worker-footer bg-orange-color text-white-color mb-0"
                  // onClick={handleQuotesNavigation}
                  style={{
                    cursor: "pointer",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  Quotes <ArrowRightIcon style={{ fontSize: "38px" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div class="card " style={{ borderRadius: "15px" }}>
              <div class="card-body p-0 ">
                <div
                  className="card-body  bg-blue-color"
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                >
                  <div className="text-end d-flex justify-content-end">
                    <p className="card-text text-end first-card-clientt ">
                      <img
                        src={Contract}
                        className="border-orange-color "
                        style={{ height: "37px" }}
                      />
                    </p>
                  </div>
                  <div>
                    <h1 className="mx-1 card-title text-white-color mt-5">
                      {/* {data.contracts || 0} */}
                      3
                    </h1>
                  </div>
                </div>
                <div
                  onClick={handlecontractNavigation}
                  className="card-footer worker-footer bg-orange-color text-white-color mb-0"
                  // onClick={handleQuotesNavigation}
                  style={{
                    cursor: "pointer",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  Contract <ArrowRightIcon style={{ fontSize: "38px" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div class="card " style={{ borderRadius: "15px" }}>
              <div class="card-body p-0 ">
                <div
                  className="card-body  bg-blue-color"
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                >
                  <div className="text-end d-flex justify-content-end">
                    <p className="card-text text-end first-card-clientt ">
                      <img
                        src={Invoice}
                        className="border-orange-color "
                        style={{ height: "37px" }}
                      />
                    </p>
                  </div>
                  <div>
                    <h1 className="mx-1 card-title text-white-color mt-5">
                      {/* {data.invoices || 0} */}
                      32
                    </h1>
                  </div>
                </div>
                <div
                  onClick={handleinvoiceNavigation}
                  className="card-footer worker-footer bg-orange-color text-white-color mb-0"
                  // onClick={handleQuotesNavigation}
                  style={{
                    cursor: "pointer",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  Invoices <ArrowRightIcon style={{ fontSize: "38px" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div class="card " style={{ borderRadius: "15px" }}>
              <div class="card-body p-0 ">
                <div
                  className="card-body  bg-blue-color"
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                >
                  <div className="text-end d-flex justify-content-end">
                    <p className="card-text text-end first-card-clientt ">
                      <img
                        src={Appoinment}
                        className="border-orange-color "
                        style={{ height: "37px" }}
                      />
                    </p>
                  </div>
                  <div>
                    <h1 className="mx-1 card-title text-white-color mt-5">
                      {/* {data.visits || 0} */}
                      443
                    </h1>
                  </div>
                </div>
                <div
                  onClick={handleappoinmentsNavigation}
                  className="card-footer worker-footer bg-orange-color text-white-color mb-0"
                  // onClick={handleQuotesNavigation}
                  style={{
                    cursor: "pointer",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  Appointment <ArrowRightIcon style={{ fontSize: "38px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <WorkerGraphs />
    </div>
  );
};

export default CustomerDashboard;
