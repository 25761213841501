// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationCard {
  padding: 4px 0 6px 15px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #063164;
  border-radius: 50%;
  height: 40px;
  width: 43px;
  margin-right: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #e88c44; 
}

.notificationContent {
  flex: 1 1;
}

.quotes-notofication .title {
  font-size: 16px;
  color: #063164;
  font-weight: bold;
}

.description {
  margin-top: 5px;
  color: #333;
}

.quoteNumber {
  font-weight: bold;
  color: #e88c44; 
}
.approvequote {
  font-weight: bold;
  color: rgb(8, 159, 87); 
}
.requestquote {
  font-weight: bold;
  color:rgb(255, 51, 198); 
}
.additionalInfo {
  display: block;
  color: #666;
}

.time {
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}
`, "",{"version":3,"sources":["webpack://./src/components/Notification/QuoteNotification.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,0CAA0C;EAC1C,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,2CAA2C;EAC3C,cAAc;AAChB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb","sourcesContent":[".notificationCard {\n  padding: 4px 0 6px 15px;\n  background-color: #f5f5f5;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  margin: 5px;\n}\n\n.iconContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  border: 1px solid #063164;\n  border-radius: 50%;\n  height: 40px;\n  width: 43px;\n  margin-right: 10px;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n  color: #e88c44; \n}\n\n.notificationContent {\n  flex: 1;\n}\n\n.quotes-notofication .title {\n  font-size: 16px;\n  color: #063164;\n  font-weight: bold;\n}\n\n.description {\n  margin-top: 5px;\n  color: #333;\n}\n\n.quoteNumber {\n  font-weight: bold;\n  color: #e88c44; \n}\n.approvequote {\n  font-weight: bold;\n  color: rgb(8, 159, 87); \n}\n.requestquote {\n  font-weight: bold;\n  color:rgb(255, 51, 198); \n}\n.additionalInfo {\n  display: block;\n  color: #666;\n}\n\n.time {\n  margin-top: 5px;\n  font-size: 12px;\n  color: #999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
