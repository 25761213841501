import React, { useState } from "react";
import { Circles, ColorRing } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SignatureCanvas from "react-signature-canvas";
import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabContent,
  Table,
} from "reactstrap";
import { Toaster } from "react-hot-toast";
import {
  DisplayImage,
  FileModal,
} from "../../../../components/Files/DisplayFiles";
import Edit from "../../../../assets/image/icons/edit.svg";
import Delete from "../../../../assets/image/icons/delete.svg";
import TimeEmpty from "../../../../components/Contract Component/TimeEmpty";
import Expances from "../../../../components/Contract Component/Expances";
import ContractMail from "../ContractMail";
import moment from "moment";
import Visit from "../../../../components/Contract Component/Visit";

const ContractDetails = ({
  loader,
  Previous,
  toggle,
  setMail,
  companyName,
  handleEditClick,
  dropdownOpen,
  moreActiontoggle,
  edit,
  Copy,
  handleCopy,
  options,
  open,
  setOpen,
  file,
  cdnUrl,
  setFile,
  mail,
  Contract,
  contractData,
  downloadPdf,
  handleTimeEmptyModalOpen,
  handleVisitModalOpen,
  modal,
  handleExpanseModalOpen,
  clearSignature,
  setSignatureRef,
  saveSignature,
  isTimeEmptyModalOpen,
  setIsTimeEmptyModalOpen,
  fetchData,
  isExpanseModalOpen,
  setIsExpanseModalOpen,
  isVisitModalOpen,
  setIsVisitModalOpen,
  CompanyId,
  handleTimeEmptyeditModalOpen,
  handleDelete,
  handleExpensesDelete,
  handlevisitDelete,
  CustomerId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [VisitId, setVisitId] = useState("");
  const [LabourId, setLabourId] = useState("");
  const [ExpenseId, setExpenseId] = useState("");
  const totals = contractData?.laborData?.map((item) => {
    const hours = Number(item?.Hours || 0);
    const minutes = Number(item?.Minutes || 0);
    return { hours, minutes };
  });

  const totalHours = totals?.reduce((acc, item) => acc + item.hours, 0);
  const totalMinutes = totals?.reduce((acc, item) => acc + item.minutes, 0);
  const extraHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;
  const finalHours = totalHours + extraHours;
  const finalMinutes = remainingMinutes;

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center text-blue-color "
          style={{ height: "100%", width: "100%", marginTop: "25%" }}
        >
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loader}
          />
        </div>
      ) : (
        <div className="Quote-details">
          <div>
            <div className="d-flex justify-content-between buttonGroup">
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    marginRight: "10px",
                    width: "50px",
                    height: "40px",
                    marginBottom: "0px",
                    padding: "0px 0px",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-capitalize bg-button-blue-color text-white-color "
                >
                  <img
                    src={Previous}
                    alt="Previous"
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
                {/* <NavigatorNav /> */}
              </div>
              <div className="d-flex justify-content-end gap-2 buttonGroupthree">
                <Button
                  className="bg-button-blue-color"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setMail(true);
                  }}
                >
                  <MailOutlineOutlinedIcon style={{ marginRight: "8px" }} />
                  Send Email
                </Button>
                {companyName && (
                  <Button
                    className="outline-button-blue-color tect-blue-color"
                    outline
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick();
                    }}
                  >
                    <img src={edit} />
                    &nbsp; Edit
                  </Button>
                )}
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={moreActiontoggle}
                  style={{ zIndex: 1 }}
                >
                  <DropdownToggle
                    className="text-blue-color outline border-blue-color"
                    style={{
                      background: "none",
                      border: "1px solid ",
                    }}
                  >
                    {" "}
                    <MoreHorizIcon /> More Actions
                  </DropdownToggle>
                  <DropdownMenu
                    className="border-blue-color"
                    style={{
                      borderRadius: "16px",
                      border: "1px solid",
                    }}
                  >
                    <DropdownItem className="text-blue-color" onClick={toggle}>
                      Collect Signature
                    </DropdownItem>
                    <DropdownItem
                      className="text-blue-color"
                      onClick={() => {
                        downloadPdf();
                      }}
                    >
                      Download PDF
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div>
              <Card
                className="p-3 my-4 outline-button-blue-color "
                style={{ border: "1px solid ", borderRadius: "12px" }}
              >
                <div className="justify-content-between d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <img src={Contract} />
                    <p
                      className="mb-0 mx-2 text-blue-color"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        backgroundColor:
                          contractData?.Status === "Unscheduled"
                            ? "#FFC6C6"
                            : contractData?.Status === "Today"
                            ? "#DBECFF"
                            : contractData?.Status === "Upcoming"
                            ? "#AEF6D3"
                            : contractData?.Status === "Scheduled"
                            ? "#FFE9BC"
                            : contractData?.Status === "Converted"
                            ? "rgba(0, 0, 255, 0.2)"
                            : "",
                        alignItems: "center",
                        padding: "7px 20px 7px",
                        borderRadius: "25px",
                      }}
                    >
                      <p
                        className=""
                        style={{
                          backgroundColor:
                            contractData?.Status === "Unscheduled"
                              ? "#FF0000"
                              : contractData?.Status === "Today"
                              ? "#3595FF"
                              : contractData?.Status === "Upcoming"
                              ? "#089F57"
                              : contractData?.Status === "Scheduled"
                              ? "#FFAF0B"
                              : contractData?.Status === "Converted"
                              ? "#063164"
                              : "",
                          borderRadius: "50%",
                          padding: "6px",
                          marginRight: "10px",
                          marginBottom: 0,
                        }}
                      ></p>
                      {contractData?.Status}
                    </p>
                  </div>
                  <p
                    className="text-blue-color"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  ></p>
                </div>
                <div className="d-flex contract-main-first">
                  <div className="col-lg-9 col-md-6 col-sm-6">
                    <h3
                      className="my-4 mb-0 text-blue-color"
                      style={{ fontWeight: 700 }}
                    >
                      {contractData?.customer?.FirstName || ""}{" "}
                      {contractData?.customer?.LastName || ""}{" "}
                      {/* James Anderson */}
                      <img
                        src={Copy}
                        alt="img"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCopy()}
                      />
                    </h3>
                    <p className="text-blue-color" style={{ fontSize: "14px" }}>
                      {contractData?.Title}
                      {/* Office Cleaning */}
                    </p>
                    <div className="col-8">
                      <p
                        className="mb-0 Sub-title text-blue-color"
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        Property Address
                      </p>
                      <p
                        className="mb-0 text-data text-blue-color"
                        style={{ fontSize: "14px", width: "80%" }}
                      >
                        {contractData?.location?.Address || ""}{" "}
                        {contractData?.location?.City || ""}{" "}
                        {contractData?.location?.State || ""}{" "}
                        {contractData?.location?.Country || ""}{" "}
                        {contractData?.location?.Zip || ""}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <p
                      className="mb-0 Sub-title text-blue-color"
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      Contract details
                    </p>
                    <hr />
                    <Table borderless>
                      <tbody className="Contract-table-detail">
                        <tr>
                          <td>Contract Type</td>
                          <td>
                            {contractData?.IsOneoffJob
                              ? "One-off Job"
                              : contractData?.IsRecuringJob
                              ? "Recurring Job"
                              : "Neither"}
                          </td>
                        </tr>
                        <tr>
                          <td>Starts On</td>
                          <td>
                            {moment(
                              contractData?.IsOneoffJob &&
                                contractData?.OneoffJob.StartDate
                                ? contractData?.OneoffJob.StartDate
                                : contractData?.IsRecuringJob &&
                                  contractData?.RecuringJob.StartDate
                                ? contractData?.RecuringJob.StartDate
                                : "No Start Date"
                            ).format("MM-DD-YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td>Lasts For</td>
                          <td>
                            {contractData?.IsOneoffJob &&
                            contractData?.OneoffJob.EndDate
                              ? moment(contractData?.OneoffJob.EndDate).format(
                                  "MM-DD-YYYY"
                                )
                              : contractData?.IsRecuringJob &&
                                contractData?.RecuringJob.Duration &&
                                contractData?.RecuringJob.Frequency
                              ? `${contractData?.RecuringJob.Frequency} ${contractData?.RecuringJob.Duration}`
                              : "No End Date"}
                          </td>
                        </tr>
                        <tr>
                          <td>Schedule</td>
                          <td>
                            {contractData?.IsOneoffJob &&
                            contractData?.OneoffJob.Repeats
                              ? contractData?.OneoffJob.Repeats
                              : contractData?.IsRecuringJob &&
                                contractData?.RecuringJob.Repeats
                              ? contractData?.RecuringJob.Repeats
                              : "No Start Date"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                <hr />

                {contractData?.Items?.length > 0 &&
                  contractData?.Items?.map((item, index) => (
                    <div className="items-contract" key={index}>
                      <div className="d-flex ">
                        <div className="col-4">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              Product/ Service
                            </p>
                          )}
                          <div className="d-flex justify-content-between products mb-4">
                            <div className="mb-3">
                              <p
                                className="mb-0 Sub-title text-blue-color"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                              >
                                {item?.Name}
                                {/* Network Switches */}
                              </p>
                              <p
                                className="mb-0 text-data text-blue-color"
                                style={{ fontSize: "14px" }}
                              >
                                Labor charge for the setup and configuration of
                                network switches for improved connectivity and
                                network management.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "right",
                              }}
                            >
                              Image
                            </p>
                          )}
                          <p
                            className="mb-0 text-data text-blue-color unit"
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                            }}
                          >
                            {item?.Attachment ? (
                              <img
                                style={{
                                  height: "40px",
                                  marginBottom: "30px",
                                  cursor: "pointer",
                                }}
                                src={`${cdnUrl}/upload/${item?.Attachment}`}
                                onClick={() => {
                                  setFile(item?.Attachment);
                                  setOpen(true);
                                }}
                                alt="Thumbnail"
                              />
                            ) : (
                              "-"
                            )}

                            <FileModal
                              open={open}
                              setOpen={setOpen}
                              file={file}
                            />
                            {/* 43 */}
                          </p>
                        </div>
                        {/* 2529 value na hoi to - aaye */}
                        <div className="col-2">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "right",
                              }}
                            >
                              Unit/Hour
                            </p>
                          )}
                          <p
                            className="mb-0 text-data text-blue-color unit"
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                            }}
                          >
                            {item?.Unit
                              ? item?.Unit
                              : item?.Square
                              ? item?.Square
                              : item?.Hour
                              ? item?.Hour
                              : "-"}
                          </p>
                        </div>
                        {/* 2529 value na hoi to - aaye */}
                        <div className="col-2">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                textAlign: "right",
                              }}
                            >
                              Unit Cost/Hour Cost
                            </p>
                          )}
                          <p
                            className="mb-0 text-data text-blue-color per-unit"
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                            }}
                          >
                            $
                            {item?.CostPerHour
                              ? Number(item?.CostPerHour).toFixed(2)
                              : item?.CostPerSquare
                              ? Number(item.CostPerSquare).toFixed(2)
                              : Number(item?.CostPerUnit).toFixed(2)}
                          </p>
                        </div>
                        {/* 2529 value na hoi to - aaye */}
                        <div className="col-2 text-end">
                          {index === 0 && (
                            <p
                              className="mb-3 Sub-heading text-blue-color totalInput"
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              Total
                            </p>
                          )}
                          <p
                            className="mb-0 text-data text-blue-color total-pro"
                            style={{ fontSize: "14px" }}
                          >
                            $
                            {Number(item?.Total) ? (
                              Number(item?.Total).toFixed(2)
                            ) : (
                              <p
                                className="mb-0 text-data text-blue-color total-pro"
                                style={{
                                  fontSize: "14px",
                                  marginRight: "15px",
                                }}
                              >
                                -
                              </p>
                            )}
                          </p>
                        </div>
                        {/* 2529 value na hoi to - aaye */}
                      </div>
                    </div>
                  ))}
                <hr />
                <div className="d-flex products-colum">
                  <div className="col-8 contc">
                    <p
                      className="mb-2 text-blue-color"
                      style={{ fontSize: "14px" }}
                    >
                      Contract/ Disclaimer This contract is valid for the next
                      30 days, after which values may be subject to change.
                    </p>
                  </div>
                  <div className="col-4 contc">
                    <div className="d-flex justify-content-between">
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        Subtotal
                      </p>
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        ${contractData?.Total}
                      </p>
                    </div>

                    <hr className="my-0 mb-2" />
                    <div className="d-flex justify-content-between">
                      <p
                        className="mb-2 text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        <b>Total</b>
                      </p>
                      <p
                        className="text-blue-color"
                        style={{ fontSize: "14px" }}
                      >
                        <b>${contractData?.Total?.toFixed(2)}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
              <Card
                className="p-3 my-4 border-blue-color "
                style={{ border: "1px solid", borderRadius: "12px" }}
              >
                <div className="d-flex justify-content-between">
                  <p
                    className="text-blue-color"
                    style={{ fontWeight: 600, fontSize: "19px" }}
                  >
                    Labour
                  </p>
                  <Button
                    size="sm"
                    outline
                    className="outline-button-blue-color border-blue-color outline text-blue-color"
                    style={{ height: "32px", borderRadius: "8px" }}
                    onClick={handleTimeEmptyModalOpen}
                  >
                    + New Time Entry
                  </Button>
                </div>
                {contractData?.laborData &&
                contractData?.laborData.length > 0 ? (
                  <div className="w-100">
                    <div className="row   py-2 text-left text-blue-color ">
                      <th className="col font-weight-bold">TEAM</th>
                      <th className="col font-weight-bold">NOTES</th>
                      <th className="col font-weight-bold">DATE</th>
                      <th className="col font-weight-bold">HOURS</th>
                      <th className="col text-right text-blue-color font-weight-bold">
                        COST
                      </th>
                      <th className="col text-right text-blue-color font-weight-bold">
                        Action
                      </th>
                    </div>
                    {contractData?.laborData.map((item, index) => (
                      <div className="row  py-2 text-left" key={index}>
                        <div className="col">{item?.WorkerId?.FullName}</div>
                        <div className="col">{item?.Notes || "N/A"}</div>
                        <div className="col">
                          {moment(item?.DatePicker).format("MM-DD-YYYY")}
                          <br />
                          {moment(item?.StartTime, "HH:mm:ss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(item?.EndTime, "HH:mm:ss").format("hh:mm A")}
                        </div>
                        <div className="col">
                          {item?.Hours || "N/A"}:{item?.Minutes || "N/A"}
                        </div>
                        <div className="col text-right">
                          {item?.LabourCost || "N/A"}
                        </div>
                        <div className="col text-right">
                          <>
                            <img
                              src={Edit}
                              alt="edit"
                              onClick={() => {
                                setLabourId(item?.LabourId);
                                handleTimeEmptyeditModalOpen(item?.LabourId);
                              }}
                            />
                            <img
                              className="mx-1"
                              src={Delete}
                              alt="delete"
                              onClick={(event) =>
                                handleDelete(
                                  event,
                                  item.LabourId,
                                  item.ContractId
                                )
                              }
                            />
                          </>
                        </div>
                      </div>
                    ))}
                    <div className="row"></div>
                    <div className="row font-weight-bold text-left border-top">
                      <div className="col"></div>
                      <div className="col"></div>
                      <div className="col"></div>
                      <div className="col">
                        {finalHours} : {finalMinutes}{" "}
                      </div>
                      <div className="col text-right">
                        {contractData?.laborData.reduce(
                          (acc, item) => acc + Number(item?.LabourCost || 0),
                          0
                        )}
                      </div>
                      <div className="col"></div>
                    </div>
                  </div>
                ) : (
                  <i>
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "12px" }}
                    >
                      Time tracked to this job by you or your team will show
                      here
                    </p>
                  </i>
                )}
              </Card>
              <Card
                className="p-3 my-4 border-blue-color"
                style={{ border: "1px solid ", borderRadius: "12px" }}
              >
                <div className="d-flex justify-content-between">
                  <p
                    className="text-blue-color"
                    style={{ fontWeight: 600, fontSize: "19px" }}
                  >
                    Expenses
                  </p>
                  <Button
                    size="sm"
                    outline
                    className="outline-button-blue-color border-blue-color outline text-blue-color"
                    style={{ height: "32px", borderRadius: "8px" }}
                    onClick={handleExpanseModalOpen}
                  >
                    + New Expense
                  </Button>
                </div>
                {contractData?.expenseData &&
                contractData?.expenseData.length > 0 ? (
                  <div className="w-100">
                    <table className="w-100">
                      <tr>
                        <th className="text-blue-color">TEAM</th>
                        <th className="text-blue-color">ITEM</th>
                        <th className="text-blue-color">Description</th>
                        <th className="text-blue-color">DATE</th>
                        <th className="text-blue-color text-right">Amount</th>
                        <th className="text-blue-color text-right">Action</th>
                      </tr>
                      {contractData?.expenseData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-blue-color">
                              {item?.WorkerId?.FullName}
                            </td>
                            <td className="text-blue-color">
                              {item?.ItemName || "N/A"}
                            </td>
                            <td className="text-blue-color">
                              {item?.Description || "N/A"}
                            </td>
                            <td className="text-blue-color">
                              {moment(item?.Date).format("MM-DD-YYYY")}
                            </td>
                            <td className="text-blue-color text-right">
                              {item?.Total || "N/A"}
                            </td>
                            <td className="text-blue-color text-right">
                              <div className="col text-right">
                                <>
                                  <img
                                    src={Edit}
                                    alt="edit"
                                    onClick={() => {
                                      setExpenseId(item.ExpenseId);
                                      handleExpanseModalOpen(item.ExpenseId);
                                    }}
                                  />
                                  <img
                                    className="mx-1"
                                    alt="delete"
                                    src={Delete}
                                    onClick={(event) =>
                                      handleExpensesDelete(
                                        event,
                                        item.ExpenseId,
                                        item.ContractId
                                      )
                                    }
                                  />
                                </>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={5}>
                          <hr className="p-0 my-2 w-100" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}></td>
                        <td className="text-right">
                          {contractData?.expenseData.reduce(
                            (acc, item) => acc + Number(item?.Total || 0),
                            0
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <i>
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "12px" }}
                    >
                      Get an accurate picture of various job costs by recording
                      expenses
                    </p>
                  </i>
                )}
              </Card>
              {/* <Card
                className="p-3 my-4  "
                style={{ border: "1px solid #063164", borderRadius: "12px" }}
              >
                <div className="d-flex justify-content-between">
                  <p
                    className="text-blue-color"
                    style={{ fontWeight: 600, fontSize: "19px" }}
                  >
                    Visits
                  </p>
                  <Button
                    size="sm"
                    outline
                    className="outline-button-blue-color border-blue-color outline text-blue-color"
                    style={{ height: "32px", borderRadius: "8px" }}
                    onClick={handleVisitModalOpen}
                  >
                    + New Visit
                  </Button>
                </div>
                {contractData?.visitsData &&
                contractData?.visitsData.length > 0 ? (
                  <div className="w-100">
                    <div style={{ overflowY: "auto", maxHeight: "300px" }}>
                      <table
                        className="w-100"
                        style={{ borderCollapse: "separate", borderSpacing: 0 }}
                      >
                        <thead
                          style={{
                            position: "sticky",
                            top: 0,
                            background: "#fff",
                            zIndex: 1,
                          }}
                        >
                          <tr>
                            <th className="text-blue-color">START DATE</th>
                            <th className="text-blue-color"></th>
                            <th className="text-blue-color">ITEM</th>
                            <th className="text-blue-color">Description</th>
                            <th className="text-blue-color text-right">
                              Assign
                            </th>
                            <th className="text-blue-color text-right">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {contractData?.visitsData.map((item, index) => (
                            <tr key={index}>
                              <td className="text-blue-color d-flex">
                                {moment(item?.StartDate).format("DD-MM-YYYY")} -{" "}
                                {moment(item?.EndDate).format("DD-MM-YYYY")}
                              </td>
                              <td className="text-blue-color">
                                {item.IsConfirm && (
                                  <p
                                    className="Appoiment text-blue-color"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <DoneIcon style={{ fontSize: "14px" }} />{" "}
                                    Confirmed By Customer
                                  </p>
                                )}
                              </td>
                              <td className="text-blue-color">
                                {item?.ItemName || "N/A"}
                              </td>
                              <td className="text-blue-color">
                                {item?.Note || "N/A"}
                              </td>
                              <td className="text-blue-color text-right">
                                {item?.WorkerId?.FullName ||
                                  "Not Assigned Yet"}
                              </td>
                              <td className="text-blue-color text-right">
                                <>
                                  <img
                                    src={Edit}
                                    alt="edit"
                                    onClick={() => {
                                      setVisitId(item.VisitId);
                                      handleVisitModalOpen(item.VisitId);
                                    }}
                                  />
                                  <img
                                    className="mx-1"
                                    src={Delete}
                                    alt="delete"
                                    onClick={(event) =>
                                      handlevisitDelete(
                                        event,
                                        item.VisitId,
                                        item.ContractId
                                      )
                                    }
                                  />
                                </>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <i>
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "12px" }}
                    >
                      Get an accurate picture of various job costs by recording
                      expenses
                    </p>
                  </i>
                )}
              </Card> */}

              <Card
                className="p-3 my-4 border-blue-color"
                style={{ border: "1px solid ", borderRadius: "12px" }}
              >
                <div className="d-flex justify-content-between">
                  <p
                    className="text-blue-color"
                    style={{ fontWeight: 600, fontSize: "19px" }}
                  >
                    Visits
                  </p>
                  <Button
                    size="sm"
                    outline
                    className="outline-button-blue-color border-blue-color outline text-blue-color"
                    style={{ height: "32px", borderRadius: "8px" }}
                    onClick={handleVisitModalOpen}
                  >
                    + New Visit
                  </Button>
                </div>
                {contractData?.visitsData &&
                contractData?.visitsData.length > 0 ? (
                  <div className="w-100">
                    <div style={{ overflowY: "auto", maxHeight: "300px" }}>
                      {Object.entries(
                        contractData.visitsData.reduce((acc, visit) => {
                          const month = moment(visit.StartDate).format(
                            "MMMM YYYY"
                          );
                          if (!acc[month]) acc[month] = [];
                          acc[month].push(visit);
                          return acc;
                        }, {})
                      ).map(([month, visits]) => (
                        <div key={month}>
                          <h5 className="text-blue-color mt-4">{month}</h5>
                          <Table
                            hover
                            className="w-100"
                            style={{
                              borderCollapse: "separate",
                              borderSpacing: 0,
                            }}
                          >
                            <thead
                              style={{
                                position: "sticky",
                                top: 0,
                                background: "#fff",
                                zIndex: 1,
                              }}
                            >
                              <tr>
                                <th className="text-blue-color">START DATE</th>
                                <th className="text-blue-color"></th>
                                <th className="text-blue-color">Visit Title</th>
                                <th className="text-blue-color">Description</th>
                                <th className="text-blue-color text-right">
                                  Assign
                                </th>
                                <th className="text-blue-color text-right">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {visits.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-blue-color d-flex">
                                    {moment(item?.StartDate).format(
                                      "MM-DD-YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(item?.EndDate).format("MM-DD-YYYY")}
                                  </td>
                                  {/* <td className="text-blue-color">
                                    {item.IsConfirm && (
                                      <p
                                        className="Appoiment text-blue-color"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <DoneIcon
                                          style={{ fontSize: "14px" }}
                                        />{" "}
                                        Confirmed By Customer
                                      </p>
                                    )}
                                  </td> */}
                                  <td
                                    className="text-blue-color p-0"
                                    style={{ paddingTop: "10px" }}
                                  >
                                    {item.IsConfirmByWorker && (
                                      <p
                                        className="Appoiment text-blue-color mb-0"
                                        style={{
                                          fontSize: "10px",
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <DoneIcon
                                          style={{ fontSize: "10px" }}
                                        />{" "}
                                        Confirmed By Worker
                                      </p>
                                    )}
                                    {item.IsConfirm && (
                                      <p
                                        className="Appoiment text-blue-color mb-0"
                                        style={{ fontSize: "10px" }}
                                      >
                                        <DoneIcon
                                          style={{ fontSize: "10px" }}
                                        />{" "}
                                        Confirmed By Customer
                                      </p>
                                    )}
                                  </td>

                                  <td className="text-blue-color">
                                    {item?.ItemName || "N/A"}
                                  </td>
                                  <td className="text-blue-color">
                                    {item?.Note || "N/A"}
                                  </td>
                                  {/* <td className="text-blue-color text-right">
                                    {item?.AssignPersons?.FullName ||
                                      "Not Assigned Yet"}
                                  </td> */}
                                  <td className="text-blue-color text-right">
                                    {item?.AssignPersons &&
                                    item.AssignPersons.length > 0
                                      ? item.AssignPersons.join(" , ")
                                      : "Not Assigned Yet"}
                                  </td>

                                  <td className="text-blue-color text-right">
                                    <>
                                      <img
                                        src={Edit}
                                        alt="edit"
                                        onClick={() => {
                                          setVisitId(item.VisitId);
                                          handleVisitModalOpen(item.VisitId);
                                        }}
                                      />
                                      <img
                                        className="mx-1"
                                        src={Delete}
                                        alt="delete"
                                        onClick={(event) =>
                                          handlevisitDelete(
                                            event,
                                            item.VisitId,
                                            item.ContractId
                                          )
                                        }
                                      />
                                    </>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <i>
                    <p
                      className="mb-0 text-blue-color"
                      style={{ fontSize: "12px" }}
                    >
                      Get an accurate picture of various job costs by recording
                      expenses
                    </p>
                  </i>
                )}
              </Card>

              <Card
                className="p-3 my-4 border-blue-color"
                style={{ border: "1px solid ", borderRadius: "12px" }}
              >
                <p className="text-blue-color" style={{ fontWeight: 600 }}>
                  Internal notes and attachments <HelpOutlineOutlinedIcon />
                </p>
                <div
                  className=""
                  style={{
                    border: "0.5px solid rgba(6, 49, 100, 80%)",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <p>{contractData?.Notes || "No Notes Available"}</p>
                </div>
                <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {contractData?.Attachment && (
                    <DisplayImage
                      files={contractData?.Attachment}
                      IsDeleted={false}
                    />
                  )}
                </div>
              </Card>
              <Card
                className="p-3 my-4 border-blue-color"
                style={{ border: "1px solid ", borderRadius: "12px" }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <p
                      className="bg-blue-color text-white-color "
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                      }}
                    >
                      {contractData?.customer?.FirstName?.charAt(
                        0
                      )?.toUpperCase()}
                      {contractData?.customer?.LastName?.charAt(
                        0
                      )?.toUpperCase()}
                    </p>
                    <div className="mx-2">
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        <b>
                          {contractData?.customer?.FirstName || ""}{" "}
                          {contractData?.customer?.LastName || ""}
                        </b>
                      </p>
                      <p style={{ fontSize: "12px" }} className="">
                        Created :{" "}
                        {new Date(contractData?.updatedAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle} className="text-blue-color">
          Signature Pad
        </ModalHeader>
        <ModalBody>
          <TabContent
            className="text-start my-3 mx-0"
            style={{ padding: "2px" }}
          >
            {/* {/ Draw Signature /} */}
            <Col sm="12">
              <Card
                className="mx-0 border-blue-color"
                style={{
                  width: "100%",
                  border: "1px solid ",
                  borderRadius: "10px",
                }}
              >
                <div className="p-3">
                  <div className="text-end" style={{}}>
                    <Button
                      className="outline-button-color"
                      outline
                      style={{
                        padding: "2px 18px 2px 18px",
                        fontSize: "14px",
                      }}
                      onClick={clearSignature}
                    >
                      Clear
                    </Button>
                  </div>
                  <SignatureCanvas
                    ref={(ref) => setSignatureRef(ref)}
                    canvasProps={{
                      width: 450,
                      height: 130,
                      className: "signature-canvas",
                    }}
                  />
                  <div
                    className="text-center p-2 text-blue-color border-blue-color "
                    style={{
                      borderTop: "2px dashed ",
                      fontSize: "14px",
                    }}
                  >
                    Write signature here...
                  </div>
                </div>
              </Card>
              <p
                className="text-blue-color"
                style={{
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                <i>
                  By signing below, you agree to this quote, the associated
                  costs, and any applicable terms and conditions.
                </i>
              </p>
            </Col>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button
            className="text-blue-color outline border-blue-color"
            onClick={toggle}
            style={{
              border: "1px solid ",
              background: "transparent",
            }}
          >
            Cancel
          </Button>
          <Button
            className="bg-button-blue-color"
            color="primary"
            onClick={saveSignature}
            style={{ border: "none" }}
          >
            {loader ? "Loading..." : "Submit"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Toaster />
      <TimeEmpty
        open={isTimeEmptyModalOpen}
        setOpen={setIsTimeEmptyModalOpen}
        ContractId={contractData?.ContractId}
        fetchData={fetchData}
        CompanyId={CompanyId}
        LabourId={LabourId}
        setLabourId={setLabourId}
      />
      <Expances
        open={isExpanseModalOpen}
        setOpen={setIsExpanseModalOpen}
        ContractId={contractData?.ContractId}
        fetchData={fetchData}
        CompanyId={CompanyId}
        ExpenseId={ExpenseId}
        setExpenseId={setExpenseId}
      />
      <Visit
        open={isVisitModalOpen}
        setOpen={setIsVisitModalOpen}
        fetchData={fetchData}
        contractData={contractData}
        CompanyId={CompanyId}
        ContractId={contractData?.ContractId}
        VisitId={VisitId}
        setVisitId={setVisitId}
        CustomerId={CustomerId}
      />
      <ContractMail
        modal={mail}
        setModal={setMail}
        customerData={contractData?.customer}
        propertyData={contractData?.location}
        Attachment={contractData?.Attachment}
        contractData={contractData}
      />
    </>
  );
};

export default ContractDetails;
