import React, { useEffect, useState } from "react";
import { Card, CardContent, IconButton } from "@mui/material";
import AxiosInstance from "../../AxiosInstance";
import moment from "moment";
import "./style.css";
import CardImage from "../../../assets/image/icons/CreditCard.png";
import Delete from "../../../assets/image/icons/delete.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddCard from "./AddCard";
import { handleAuth } from "../../../components/Login/Auth";
import creditCardType from "credit-card-type";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import sendToast from "../../../components/Toast/sendToast";
import { getTokenizationKey } from "../../../plugins/ApiHandler";
import { addTokenizationScript } from "../../../plugins/helpers";
import AddCardDetailsForm from "../../../components/AdminViews/AddCardDetailsForm";
import swal from "sweetalert";
import sendSwal from "../../../components/Swal/sendSwal";

const Account = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [loading, setLoading] = useState(true);
  const [planDetails, setPlanDetails] = useState([]);
  const [activePlanDetails, setActivePlanDetails] = useState(undefined);
  const [billingDetails, setBillingDetails] = useState([]);
  const [customerVault, setCustomerVault] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");

  const fetchData = async () => {
    try {
      await handleAuth(navigate, location);

      const res = await AxiosInstance.post("/nmi/get-billing-customer-vault", {
        CompanyId: localStorage.getItem("CompanyId"),
        type: "card",
      });
      if (
        res.data.status === 200 &&
        res.data.data &&
        res.data.data.customer &&
        res.data.data.customer.billing
      ) {
        const billingData = [];
        setCustomerVault(res.data.data.customer["@attributes"].id);
        if (Array.isArray(res.data.data.customer.billing)) {
          for (const elem of res.data.data.customer.billing) {
            const image = await getCardImage(elem["@attributes"].id);
            billingData.push({ ...elem, image: image || "" });
          }
        } else {
          const image = await getCardImage(
            res.data.data.customer.billing["@attributes"].id
          );
          billingData.push({
            ...res.data.data.customer.billing,
            image: image || "",
          });
        }
        setBillingDetails(billingData);
      } else {
        setBillingDetails([]);
      }
      const response = await AxiosInstance.get(
        `/planpurchase/company_plans/${localStorage.getItem("CompanyId")}`
      );
      if (response.data.statusCode === 200) {
        setPlanDetails(response.data.data);
        setActivePlanDetails(response.data.activePlan);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getCardImage = async (CardNumber) => {
    try {
      const sanitizedValue = CardNumber.replace(/\D/g, "");
      const cardType = creditCardType(sanitizedValue)[0];

      if (cardType && cardType.type) {
        const sanitizedCardType = cardType.type
          .replace(/[-\s]/g, "")
          .toLowerCase();

        const response = await axios.get(
          `https://logo.clearbit.com/${sanitizedCardType}.com`
        );

        if (response.status === 200) {
          return response.config.url;
        } else {
          return CardImage;
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDelete = async (item) => {
    sendSwal().then(async (willDelete) => {
      if (willDelete) {
        try {
          if (item["@attributes"].id) {
            const object = {
              CompanyId: localStorage.getItem("CompanyId"),
              customer_vault_id: customerVault,
              billing_id: item["@attributes"].id,
            };
            if (billingDetails.length > 1) {
              var url = `/nmi/delete-customer-billing`;
            } else {
              url = `/nmi/delete-customer-vault`;
            }

            const deleteRes = await AxiosInstance.post(url, object);
            if (deleteRes.data.status === 200) {
              toast.success("Card details deleted");
              fetchData();
            }
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    });
  };

  const [modalShow, setModalShow] = useState(false);
  const [scriptGenerating, setScriptGenerating] = useState(false);
  const [scriptError, setScriptError] = useState("");
  const [isPublicKeyAvailable, setIsPublicKeyAvailable] = useState(false); // Add this state

  const getNmiKey = async (Company_Id) => {
    setScriptGenerating(true);
    setScriptError("");

    try {
      const keyResponse = await getTokenizationKey(Company_Id);

      await addTokenizationScript(keyResponse.PublicKey);
      if (keyResponse.PublicKey) {
        setIsPublicKeyAvailable(true);
      } else {
        setIsPublicKeyAvailable(false);
      }
    } catch (error) {
      setScriptError(
        error ||
          "Failed to load the tokenization script. Make sure you have suitable internet connection."
      );
    } finally {
      setScriptGenerating(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const CompanyId = localStorage.getItem("CompanyId");
      await getNmiKey(CompanyId);
    };
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex flex-direction-row justify-content-center align-items-center vh-100 text-blue-color ">
          <Circles
            height="50"
            width="50"
            color="#063164"
            ariaLabel="circles-loading"
            visible={loading}
          />
        </div>
      ) : (
        <div className="pt-5 mx-3 mx-md-5 accounts">
          <h2 className="my-0 text-blue-color">Account & Billing</h2>
          <div className="my-3 d-flex flex-column gap-5">
            <div className="row w-100 p-0 m-0">
              <div className="col-12 col-md-7 p-0 m-0">
                <Card
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #0631644D",
                    borderRadius: "20px",
                    height: "100%",
                    padding: "10px 10px",
                  }}
                >
                  <CardContent>
                    <div className="d-flex justify-content-between">
                      <h4
                        style={{ fontSize: "20px" }}
                        className="text-blue-color"
                      >
                        Account Overview
                      </h4>
                      <button
                        className="bg-blue-color px-1 text-white-color "
                        style={{
                          fontSize: "12px",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          const plan = planDetails.find(
                            (item) => item.status && item.plan !== "Trial"
                          );
                          navigate(`/${companyName}/plan`, {
                            state: {
                              navigats: [...location.state.navigats, "/plan"],
                              plan,
                            },
                          });
                        }}
                      >
                        View Plan
                      </button>
                    </div>
                    <div
                      className="d-flex flex-sm-column flex-row gap-3 mt-4"
                      style={{ overflow: "auto" }}
                    >
                      <div className="d-flex flex-sm-row gap-0 flex-column">
                        <div className="w-100">
                          <span
                            style={{ borderBottom: "1px solid #06316433" }}
                            className="text-blue-color full-sentence"
                          >
                            Account Since
                          </span>
                        </div>
                        <div className="w-100">
                          <span
                            style={{ borderBottom: "1px solid #06316433" }}
                            className="text-blue-color full-sentence"
                          >
                            Account Status
                          </span>
                        </div>
                        <div className="w-100 text-md-right text-left">
                          <span
                            style={{ borderBottom: "1px solid #06316433" }}
                            className="text-blue-color full-sentence"
                          >
                            Current Plan
                          </span>
                        </div>
                      </div>
                      {planDetails && planDetails.length > 0 ? (
                        planDetails.map((item, index) => (
                          <div className="d-flex flex-sm-row gap-0 flex-column">
                            <div className="w-100">
                              <span className="text-blue-color full-sentence">
                                {moment(item.date).format("MM-DD-YYYY")}
                              </span>
                            </div>
                            <div className="w-100">
                              <span className="text-blue-color full-sentence">
                                {item.status ? "Active" : "Inactive"}
                              </span>
                            </div>
                            <div className="w-100 full-sentence text-md-right text-left">
                              <span className="text-blue-color">
                                {item.plan}
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex flex-sm-row gap-0 flex-column">
                          <div className="w-100">
                            <span className="text-blue-color full-sentence">
                              -
                            </span>
                          </div>
                          <div className="w-100">
                            <span className="text-blue-color full-sentence">
                              -
                            </span>
                          </div>
                          <div className="w-100 full-sentence text-md-right text-left">
                            <span className="text-blue-color">-</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="col-12 col-md-4 p-0 m-0 mt-md-0 mt-5">
                <Card
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #0631644D",
                    borderRadius: "20px",
                    height: "100%",
                    padding: "10px 10px",
                  }}
                >
                  <CardContent className="h-100">
                    <h4
                      style={{ fontSize: "20px" }}
                      className="text-blue-color"
                    >
                      Billing Details
                    </h4>
                    <div className="d-flex flex-column justify-content-between gap-3 mt-4 h-auto">
                      {billingDetails && billingDetails.length > 0 ? (
                        billingDetails.map((item, index) => (
                          <div className="d-flex align-items-center justify-content-start w-100">
                            <div className="d-flex align-items-center w-100">
                              <div className="d-flex align-items-center mx-2">
                                <input
                                  type="radio"
                                  checked={selectedCard === item}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedCard(item);
                                    } else {
                                      setSelectedCard("");
                                    }
                                  }}
                                  style={{
                                    color: "#063164",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <div
                                  style={{
                                    width: "44px",
                                    height: "44px",
                                    background: "#F0F0F0",
                                    borderRadius: "50%",
                                    padding: "8px 5px",
                                  }}
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <img
                                    src={item.image || CardImage}
                                    alt=""
                                    width="90%"
                                    height="90%"
                                  />
                                </div>
                                <div className="d-flex flex-column justify-conent-center mx-2">
                                  <span className="text-blue-color">
                                    {item.cc_number}
                                  </span>
                                  <span className="text-blue-color">
                                    {item.first_name} {item.last_name}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex-shrink-1">
                              <IconButton>
                                <img
                                  className="mx-1"
                                  src={Delete}
                                  width={20}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(item);
                                  }}
                                />
                              </IconButton>
                            </div>
                          </div>
                        ))
                      ) : (
                        <>
                          <div className="d-flex align-items-center justify-content-start w-100">
                            <div className="d-flex align-items-center w-100">
                              <div className="d-flex align-items-center w-100">
                                <div
                                  style={{
                                    width: "44px",
                                    height: "44px",
                                    background: "#F0F0F0",
                                    borderRadius: "50%",
                                    padding: "8px 5px",
                                  }}
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <img
                                    src={CardImage}
                                    alt=""
                                    width="90%"
                                    height="90%"
                                  />
                                </div>
                                <div className="d-flex flex-column justify-conent-center mx-2">
                                  <span className="text-blue-color">
                                    No Credit Card
                                  </span>
                                  <span
                                    className="text-blue-color"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Continue using CMS by choosing a plan
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="d-flex align-items-center justify-content-end gap-2 flex-bottom border-blue-color text-white-color ">
                        <button
                          className="p-2 border-blue-color"
                          style={{
                            borderRadius: "4px",
                            fontSize: "12px",
                            border: "1px solid ",
                          }}
                          onClick={() => {
                            const plan = planDetails.find(
                              (item) => item.status && item.plan !== "Trial"
                            );
                            if (selectedCard) {
                              navigate(`/${companyName}/plan`, {
                                state: {
                                  navigats: [
                                    ...location.state.navigats,
                                    "/plan",
                                  ],
                                  card: {
                                    ...selectedCard,
                                    CompanyId:
                                      localStorage.getItem("CompanyId"),
                                    customer_vault_id: customerVault,
                                  },
                                  plan,
                                },
                              });
                            } else {
                              navigate(`/${companyName}/plan`, {
                                state: {
                                  navigats: [
                                    ...location.state.navigats,
                                    "/plan",
                                  ],
                                  plan,
                                },
                              });
                            }
                          }}
                        >
                          <span className="text-blue-color full-sentence border-blue-color">
                            Choose Plan
                          </span>
                        </button>
                        {scriptGenerating ? (
                          <p>NMI card fields loading...</p>
                        ) : scriptError ? (
                          <p style={{ color: "red" }}>{scriptError}</p>
                        ) : (
                          <>
                            {!scriptGenerating && !scriptError ? (
                              <div>
                                <AddCardDetailsForm
                                  onHide={() => setModalShow(false)}
                                  show={modalShow}
                                  scriptGenerating={scriptGenerating}
                                  scriptError={scriptError}
                                  // CustomerId={CustomerId}
                                  fetchData={fetchData}
                                />
                                {isPublicKeyAvailable && (
                                  <button
                                    className="p-2 bg-blue-color text-white-color "
                                    style={{
                                      borderRadius: "4px",
                                      fontSize: "12px",
                                    }}
                                    onClick={() => setModalShow(true)}
                                    // disabled={!scriptGenerating}
                                  >
                                    <span className="full-sentence">
                                      Add Card
                                    </span>
                                  </button>
                                )}
                              </div>
                            ) : null}
                          </>
                        )}{" "}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>

            <div className="row w-100 p-0 m-0">
              <div className="col-12 col-md-6 p-0 m-0">
                <Card
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #0631644D",
                    borderRadius: "20px",
                    height: "100%",
                    padding: "10px 10px",
                  }}
                >
                  <CardContent>
                    <h4
                      style={{ fontSize: "20px" }}
                      className="text-blue-color"
                    >
                      Payment History
                    </h4>
                    {planDetails &&
                    planDetails.length <= 1 &&
                    !activePlanDetails ? (
                      <span
                        style={{ fontSize: "12px" }}
                        className="text-blue-color"
                      >
                        There is no payment history created yet
                      </span>
                    ) : (
                      <div
                        className="d-flex flex-sm-column flex-row gap-3 mt-4"
                        style={{ overflow: "auto" }}
                      >
                        <div className="d-flex flex-sm-row gap-0 flex-column">
                          <div className="w-100">
                            <span
                              style={{ borderBottom: "1px solid #06316433" }}
                              className="text-blue-color full-sentence"
                            >
                              Transaction Id
                            </span>
                          </div>
                          <div className="w-100">
                            <span
                              style={{ borderBottom: "1px solid #06316433" }}
                              className="text-blue-color full-sentence"
                            >
                              Payment Details
                            </span>
                          </div>
                          <div className="w-100">
                            <span
                              style={{ borderBottom: "1px solid #06316433" }}
                              className="text-blue-color full-sentence"
                            >
                              Amount
                            </span>
                          </div>
                          <div className="w-100 text-md-right text-left">
                            <span
                              style={{ borderBottom: "1px solid #06316433" }}
                              className="text-blue-color full-sentence"
                            >
                              Payment Status
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-sm-row gap-0 flex-column">
                          <div className="w-100">
                            <span className="text-blue-color full-sentence">
                              {activePlanDetails?.SubscriptionId}
                            </span>
                          </div>
                          <div className="w-100">
                            <span className="text-blue-color">
                              <span>{activePlanDetails?.PlanName}</span>
                              <br />
                              <span style={{ fontSize: "11px" }}>
                                {activePlanDetails?.PlanDetail}
                              </span>
                            </span>
                          </div>
                          <div className="w-100 full-sentence">
                            <span className="text-blue-color">
                              {activePlanDetails?.PlanPrice}
                            </span>
                          </div>
                          <div className="w-100 full-sentence text-md-right text-left">
                            <span className="text-blue-color">SUCCESS</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
              <div className="col-12 col-md-5 p-0 m-0 mt-md-0 mt-5">
                <Card
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #0631644D",
                    borderRadius: "20px",
                    height: "100%",
                    padding: "10px 10px",
                  }}
                >
                  <CardContent>
                    <h4
                      style={{ fontSize: "20px" }}
                      className="text-blue-color"
                    >
                      Cancel Your Account
                    </h4>
                    {planDetails &&
                    planDetails.length <= 1 &&
                    !activePlanDetails ? (
                      <span
                        style={{ fontSize: "12px" }}
                        className="text-blue-color"
                      >
                        Your trial will automatically expire so there’s no need
                        to cancel
                      </span>
                    ) : (
                      <div
                        className="d-flex flex-sm-column flex-row gap-3 mt-4"
                        style={{ overflow: "auto" }}
                      >
                        <div className="d-flex flex-sm-row gap-0 flex-column">
                          <div className="w-100">
                            <span
                              style={{ borderBottom: "1px solid #06316433" }}
                              className="text-blue-color full-sentence"
                            >
                              Current Plan
                            </span>
                          </div>
                          <div className="w-100">
                            <span
                              style={{ borderBottom: "1px solid #06316433" }}
                              className="text-blue-color full-sentence"
                            >
                              Plan Details
                            </span>
                          </div>
                          <div className="w-100 text-md-right text-left">
                            <span
                              style={{ borderBottom: "1px solid #06316433" }}
                              className="text-blue-color full-sentence"
                            >
                              Plan Duration
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-sm-row gap-0 flex-column">
                          <div className="w-100">
                            <span className="text-blue-color full-sentence">
                              {activePlanDetails?.PlanName}
                            </span>
                          </div>
                          <div className="w-100">
                            <span className="text-blue-color">
                              {activePlanDetails?.PlanDetail}
                            </span>
                          </div>
                          <div className="w-100 full-sentence text-md-right text-left">
                            <span className="text-blue-color">
                              {activePlanDetails?.dueDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {activePlanDetails && (
                      <div>
                        <button
                          className="bg-blue-color p-1 mt-3 text-white-color "
                          style={{
                            fontSize: "14px",
                            borderRadius: "4px",
                            border: "none",
                          }}
                          onClick={async () => {
                            try {
                              const res = await AxiosInstance.delete(
                                `/planpurchase/cancel_plan/${localStorage.getItem(
                                  "CompanyId"
                                )}`
                              );
                              if (res.data.statusCode === 200) {
                                toast.success(res.data.message);
                                localStorage.setItem(
                                  "adminToken",
                                  res.data.token
                                );
                                navigate(`/plan`);
                              } else {
                                sendToast(
                                  "There was an issue account cancelation. Please try again later."
                                );
                              }
                            } catch (error) {
                              sendToast(
                                "There was an issue account cancelation. Please try again later."
                              );
                            }
                          }}
                        >
                          Cancel Account
                        </button>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
          {/* <AddCard
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isBilling={billingDetails.length > 0}
            vaultId={customerVault}
            fetchData={fetchData}
          /> */}
          <Toaster />
        </div>
      )}
    </>
  );
};

export default Account;
