// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Card,
//   CardBody,
//   CardHeader,
//   CardText,
//   Table,
// } from "reactstrap";
// import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
// import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
// import AxiosInstance from "../../AxiosInstance";
// import moment from "moment";
// import { useLocation, useNavigate } from "react-router-dom";
// import { handleAuth } from "../../../components/Login/Auth";
// import "../style.css";
// import { Circles } from "react-loader-spinner";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import Swal from "sweetalert2";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import CallIcon from "@mui/icons-material/Call";

// function ConfirmAppointment() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loader, setLoader] = useState(true);
//   const [quotesData, setQuotesData] = useState(null);
//   const [tokenDecode, setTokenDecode] = useState({});
//   const [isConfirmed, setIsConfirmed] = useState(null);

//   const fetchData = async () => {
//     try {
//       const res = await handleAuth(navigate, location);
//       setTokenDecode(res.data);
//     } catch (error) {
//       console.error("Error fetching token data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [navigate, location]);

//   const companyId = tokenDecode.companyId;
//   const ContractId = location.state.contractId;
//   const WorkerId = localStorage.getItem("worker_id");

//   useEffect(() => {
//     if (companyId && location.state.id && WorkerId) {
//       setLoader(true);
//       AxiosInstance.get(`/visits/workervisits/${location.state.id}/${WorkerId}`)
//         .then((response) => {
//           if (response.data.statusCode === 200 && response.data.data) {
//             setQuotesData(response.data.data);
//             const apiConfirmStatus = response.data.IsConfirmByWorker;
//             setIsConfirmed(
//               apiConfirmStatus !== undefined
//                 ? apiConfirmStatus
//                 : localStorage.getItem("isConfirmed") === "true"
//             );
//           } else {
//             console.error("Unexpected data structure or status code from API.");
//           }
//         })
//         .catch((error) => {
//           console.error("API error:", error);
//         })
//         .finally(() => {
//           setLoader(false);
//         });
//     } else {
//       console.error("Company ID, Visit ID, or Worker ID is not valid.");
//       setLoader(false);
//     }
//   }, [companyId, ContractId, WorkerId, location.state.id]);

//   const handleConfirmAppointment = async () => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "Do you want to confirm this appointment?",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, confirm it!",
//       cancelButtonText: "No, cancel",
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         try {
//           await AxiosInstance.put(
//             `/visits/ConfirmByWorker/${location.state.id}/${quotesData.ContractId}`
//           );
//           setIsConfirmed(true);
//           localStorage.setItem("isConfirmed", "true");
//           Swal.fire(
//             "Confirmed!",
//             "The appointment has been confirmed.",
//             "success"
//           );
//         } catch (error) {
//           console.error("Error confirming appointment:", error);
//           Swal.fire(
//             "Error!",
//             "Something went wrong. Please try again.",
//             "error"
//           );
//         }
//       }
//     });
//   };

//   return (
//     <>
//       {loader ? (
//         <div
//           className="d-flex justify-content-center align-items-center my-5 text-blue-color"
//           style={{ height: "100vh" }}
//         >
//           <Circles
//             height="50"
//             width="50"
//             ariaLabel="circles-loading"
//             color="#063164"
//             visible={true}
//           />
//         </div>
//       ) : (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             width: "100%",
//             alignItems: "center",
//           }}
//         >
//           <div style={{ width: "67%" }}>
//             <Button className="bg-blue-color" onClick={() => navigate(-1)}>
//               <ArrowBackIcon />
//             </Button>{" "}
//           </div>
//           <Card className="invoice-card col-8" style={{ marginTop: "10px" }}>
//             <CardHeader>
//               <div className="text-end">
//                 {isConfirmed !== null && (
//                   <>
//                     {isConfirmed ? (
//                       <Button
//                         className="Appoiment text-blue-color"
//                         style={{
//                           border: "2px solid #063164",
//                           background: "#DBECFF",
//                           borderRadius: "20px",
//                           fontWeight: 600,
//                         }}
//                       >
//                         Confirmed
//                       </Button>
//                     ) : (
//                       <Button
//                         size="sm"
//                         className="bg-blue-color text-white-color"
//                         onClick={handleConfirmAppointment}
//                       >
//                         Confirm Appointment
//                       </Button>
//                     )}
//                   </>
//                 )}
//               </div>
//             </CardHeader>
//             <CardBody>
//               <div className="d-flex">
//                 <div className="col-6">
//                   <h5 style={{ fontWeight: 700 }} className="text-blue-color">
//                     {quotesData?.CustomerData[0]?.FirstName || "N/A"}{" "}
//                     {quotesData?.CustomerData[0]?.LastName || "N/A"} -{" "}
//                     {quotesData?.ItemName || "N/A"}
//                   </h5>
//                   <p className="text-blue-color">
//                     {" "}
//                     {quotesData?.LocationData?.Address || "N/A"}{" "}
//                     {quotesData?.LocationData?.City || ""},{" "}
//                     {quotesData?.LocationData?.State || ""}, <br />{" "}
//                     {quotesData?.LocationData?.Zip || ""}-{" "}
//                     {quotesData?.LocationData?.Country || ""}
//                   </p>
//                 </div>
//                 <div className="col-6">
//                   <h6
//                     className="d-flex align-items-center gap-2 text-blue-color mb-3"
//                     style={{ fontWeight: 600 }}
//                   >
//                     <CalendarMonthIcon className="text-blue-color" />{" "}
//                     {quotesData?.StartDate || "N/A"}
//                   </h6>
//                   <p className="d-flex align-items-center gap-2 text-blue-color">
//                     <CallIcon style={{ fontSize: "20px" }} />{" "}
//                     {quotesData?.CustomerData[0]?.PhoneNumber || ""}
//                   </p>
//                 </div>
//               </div>
//               <hr />
//               <div>
//                 <p style={{ fontWeight: 700 }} className="text-blue-color mb-0">
//                   Line Items
//                 </p>
//                 <Table className="p-0">
//                   <thead>
//                     <tr>
//                       <th style={{ paddingLeft: 0 }}>Product/Service</th>
//                       <th className="text-end">Qty</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {quotesData?.ContractItemData?.length > 0 ? (
//                       quotesData?.ContractItemData.map((item) => (
//                         <tr key={item._id}>
//                           <td style={{ paddingLeft: 0 }}>{item.Name}</td>
//                           <td className="text-end">
//                             {item.Unit
//                               ? `${item.Unit} Units`
//                               : item.Hour
//                               ? `${item.Hour} Hours`
//                               : item.Square
//                               ? `${item.Square} SqFeet`
//                               : "N/A"}{" "}
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="2" className="text-center">
//                           No items available
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </Table>
//               </div>
//             </CardBody>
//           </Card>
//         </div>
//       )}
//     </>
//   );
// }

// export default ConfirmAppointment;

// import React, { useEffect, useState } from "react";
// import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import CallIcon from "@mui/icons-material/Call";
// import AxiosInstance from "../../AxiosInstance";
// import { useLocation, useNavigate } from "react-router-dom";
// import { handleAuth } from "../../../components/Login/Auth";
// import { Circles } from "react-loader-spinner";
// import Swal from "sweetalert2";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// function ConfirmAppointment() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loader, setLoader] = useState(true);
//   const [quotesData, setQuotesData] = useState(null);
//   const [tokenDecode, setTokenDecode] = useState({});

//   const fetchData = async () => {
//     try {
//       const res = await handleAuth(navigate, location);
//       setTokenDecode(res.data);
//     } catch (error) {
//       console.error("Error fetching token data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [navigate, location]);

//   const companyId = tokenDecode.companyId;
//   const WorkerId = localStorage.getItem("worker_id");

//   useEffect(() => {
//     if (companyId && location.state.id && WorkerId) {
//       setLoader(true);
//       AxiosInstance.get(`/visits/workervisits/${location.state.id}/${WorkerId}`)
//         .then((response) => {
//           if (response.data.statusCode === 200 && response.data.data) {
//             const dataWithConfirmation = {
//               ...response.data.data,
//               isConfirmed:
//                 response.data.data.IsConfirmByWorker ??
//                 localStorage.getItem(`isConfirmed_${location.state.id}`) ===
//                   "true",
//             };
//             setQuotesData(dataWithConfirmation);
//           } else {
//             console.error("Unexpected data structure or status code from API.");
//           }
//         })
//         .catch((error) => {
//           console.error("API error:", error);
//         })
//         .finally(() => {
//           setLoader(false);
//         });
//     } else {
//       console.error("Company ID, Visit ID, or Worker ID is not valid.");
//       setLoader(false);
//     }
//   }, [companyId, location.state.id, WorkerId]);

//   const handleConfirmAppointment = async () => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "Do you want to confirm this appointment?",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, confirm it!",
//       cancelButtonText: "No, cancel",
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         try {
//           await AxiosInstance.put(
//             `/visits/ConfirmByWorker/${location.state.id}/${quotesData.ContractId}`
//           );
//           setQuotesData((prevData) => ({
//             ...prevData,
//             isConfirmed: true,
//           }));
//           localStorage.setItem(`isConfirmed_${location.state.id}`, "true");
//           Swal.fire(
//             "Confirmed!",
//             "The appointment has been confirmed.",
//             "success"
//           );
//         } catch (error) {
//           console.error("Error confirming appointment:", error);
//           Swal.fire(
//             "Error!",
//             "Something went wrong. Please try again.",
//             "error"
//           );
//         }
//       }
//     });
//   };

//   return (
//     <>
//       {loader ? (
//         <div
//           className="d-flex justify-content-center align-items-center my-5 text-blue-color"
//           style={{ height: "100vh" }}
//         >
//           <Circles
//             height="50"
//             width="50"
//             ariaLabel="circles-loading"
//             color="#063164"
//             visible={true}
//           />
//         </div>
//       ) : (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             width: "100%",
//             alignItems: "center",
//           }}
//         >
//           <div style={{ width: "67%" }}>
//             <Button className="bg-blue-color" onClick={() => navigate(-1)}>
//               <ArrowBackIcon />
//             </Button>
//           </div>
//           <Card className="invoice-card col-8" style={{ marginTop: "10px" }}>
//             <CardHeader>
//               <div className="text-end">
//                 {quotesData?.isConfirmed !== null && (
//                   <>
//                     {quotesData?.isConfirmed ? (
//                       <Button
//                         className="Appoiment text-blue-color"
//                         style={{
//                           border: "2px solid #063164",
//                           background: "#DBECFF",
//                           borderRadius: "20px",
//                           fontWeight: 600,
//                         }}
//                       >
//                         Confirmed
//                       </Button>
//                     ) : (
//                       <Button
//                         size="sm"
//                         className="bg-blue-color text-white-color"
//                         onClick={handleConfirmAppointment}
//                       >
//                         Confirm Appointment
//                       </Button>
//                     )}
//                   </>
//                 )}
//               </div>
//             </CardHeader>
//             <CardBody>
//               <div className="d-flex">
//                 <div className="col-6">
//                   <h5 style={{ fontWeight: 700 }} className="text-blue-color">
//                     {quotesData?.CustomerData[0]?.FirstName || "N/A"}{" "}
//                     {quotesData?.CustomerData[0]?.LastName || "N/A"} -{" "}
//                     {quotesData?.ItemName || "N/A"}
//                   </h5>
//                   <p className="text-blue-color">
//                     {quotesData?.LocationData?.Address || "N/A"}{" "}
//                     {quotesData?.LocationData?.City || ""},{" "}
//                     {quotesData?.LocationData?.State || ""}, <br />{" "}
//                     {quotesData?.LocationData?.Zip || ""}-{" "}
//                     {quotesData?.LocationData?.Country || ""}
//                   </p>
//                 </div>
//                 <div className="col-6">
//                   <h6
//                     className="d-flex align-items-center gap-2 text-blue-color mb-3"
//                     style={{ fontWeight: 600 }}
//                   >
//                     <CalendarMonthIcon className="text-blue-color" />{" "}
//                     {quotesData?.StartDate || "N/A"}
//                   </h6>
//                   <p className="d-flex align-items-center gap-2 text-blue-color">
//                     <CallIcon style={{ fontSize: "20px" }} />{" "}
//                     {quotesData?.CustomerData[0]?.PhoneNumber || ""}
//                   </p>
//                 </div>
//               </div>
//               <hr />
//               <div>
//                 <p style={{ fontWeight: 700 }} className="text-blue-color mb-0">
//                   Line Items
//                 </p>
//                 <Table className="p-0">
//                   <thead>
//                     <tr>
//                       <th style={{ paddingLeft: 0 }}>Product/Service</th>
//                       <th className="text-end">Qty</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {quotesData?.ContractItemData?.length > 0 ? (
//                       quotesData?.ContractItemData.map((item) => (
//                         <tr key={item._id}>
//                           <td style={{ paddingLeft: 0 }}>{item.Name}</td>
//                           <td className="text-end">
//                             {item.Unit
//                               ? `${item.Unit} Units`
//                               : item.Hour
//                               ? `${item.Hour} Hours`
//                               : item.Square
//                               ? `${item.Square} SqFeet`
//                               : "N/A"}{" "}
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="2" className="text-center">
//                           No items available
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </Table>
//               </div>
//             </CardBody>
//           </Card>
//         </div>
//       )}
//     </>
//   );
// }

// export default ConfirmAppointment;

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Card,
//   CardBody,
//   CardHeader,
//   CardText,
//   Table,
// } from "reactstrap";
// import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
// import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
// import AxiosInstance from "../../AxiosInstance";
// import moment from "moment";
// import { useLocation, useNavigate } from "react-router-dom";
// import { handleAuth } from "../../../components/Login/Auth";
// import "../style.css";
// import { Circles } from "react-loader-spinner";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import Swal from "sweetalert2";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import CallIcon from "@mui/icons-material/Call";

// function ConfirmAppointment() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [loader, setLoader] = useState(true);
//   const [quotesData, setQuotesData] = useState(null);
//   const [tokenDecode, setTokenDecode] = useState({});
//   const [isConfirmed, setIsConfirmed] = useState(false); // Default is false

//   const fetchData = async () => {
//     try {
//       const res = await handleAuth(navigate, location);
//       setTokenDecode(res.data);
//     } catch (error) {
//       console.error("Error fetching token data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [navigate, location]);

//   const companyId = tokenDecode.companyId;
//   const ContractId = location.state.contractId;
//   const WorkerId = localStorage.getItem("worker_id");

//   useEffect(() => {
//     if (companyId && location.state.id && WorkerId) {
//       setLoader(true);
//       AxiosInstance.get(`/visits/workervisits/${location.state.id}/${WorkerId}`)
//         .then((response) => {
//           if (response.data.statusCode === 200 && response.data.data) {
//             setQuotesData(response.data.data);
//             setIsConfirmed(response.data.IsConfirmByWorker || false); // Set initial confirmation state
//           } else {
//             console.error("Unexpected data structure or status code from API.");
//           }
//         })
//         .catch((error) => {
//           console.error("API error:", error);
//         })
//         .finally(() => {
//           setLoader(false);
//         });
//     } else {
//       console.error("Company ID, Visit ID, or Worker ID is not valid.");
//       setLoader(false);
//     }
//   }, [companyId, ContractId, WorkerId, location.state.id]);

//   const handleConfirmAppointment = async () => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "Do you want to confirm this appointment?",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, confirm it!",
//       cancelButtonText: "No, cancel",
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         // Update local state to reflect immediate confirmation
//         setIsConfirmed(true);
//         localStorage.setItem("isConfirmed", "true");

//         // Send confirmation to the database
//         try {
//           const response = await AxiosInstance.put(
//             `/visits/ConfirmByWorker/${location.state.id}/${quotesData.ContractId}`
//           );

//           if (response.data && response.data.statusCode === 200) {
//             Swal.fire(
//               "Confirmed!",
//               "The appointment has been confirmed in the database.",
//               "success"
//             );
//           } else {
//             Swal.fire(
//               "Error!",
//               "Unable to confirm the appointment in the database. Please try again.",
//               "error"
//             );
//           }
//         } catch (error) {
//           console.error("Error confirming appointment in database:", error);
//           Swal.fire(
//             "Error!",
//             "Something went wrong. Please try again.",
//             "error"
//           );
//         }
//       }
//     });
//   };

//   return (
//     <>
//       {loader ? (
//         <div
//           className="d-flex justify-content-center align-items-center my-5 text-blue-color"
//           style={{ height: "100vh" }}
//         >
//           <Circles
//             height="50"
//             width="50"
//             ariaLabel="circles-loading"
//             color="#063164"
//             visible={true}
//           />
//         </div>
//       ) : (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             width: "100%",
//             alignItems: "center",
//           }}
//         >
//           <div style={{ width: "67%" }}>
//             <Button className="bg-blue-color" onClick={() => navigate(-1)}>
//               <ArrowBackIcon />
//             </Button>{" "}
//           </div>
//           <Card className="invoice-card col-8" style={{ marginTop: "10px" }}>
//             <CardHeader>
//               <div className="text-end">
//                 {isConfirmed !== null && (
//                   <>
//                     {isConfirmed ? (
//                       <Button
//                         className="Appoiment text-blue-color"
//                         style={{
//                           border: "2px solid #063164",
//                           background: "#DBECFF",
//                           borderRadius: "20px",
//                           fontWeight: 600,
//                         }}
//                       >
//                         Confirmed
//                       </Button>
//                     ) : (
//                       <Button
//                         size="sm"
//                         className="bg-blue-color text-white-color"
//                         onClick={handleConfirmAppointment}
//                       >
//                         Confirm Appointment
//                       </Button>
//                     )}
//                   </>
//                 )}
//               </div>
//             </CardHeader>
//             <CardBody>
//               <div className="d-flex">
//                 <div className="col-6">
//                   <h5 style={{ fontWeight: 700 }} className="text-blue-color">
//                     {quotesData?.CustomerData[0]?.FirstName || "N/A"}{" "}
//                     {quotesData?.CustomerData[0]?.LastName || "N/A"} -{" "}
//                     {quotesData?.ItemName || "N/A"}
//                   </h5>
//                   <p className="text-blue-color">
//                     {" "}
//                     {quotesData?.LocationData?.Address || "N/A"}{" "}
//                     {quotesData?.LocationData?.City || ""},{" "}
//                     {quotesData?.LocationData?.State || ""}, <br />{" "}
//                     {quotesData?.LocationData?.Zip || ""}-{" "}
//                     {quotesData?.LocationData?.Country || ""}
//                   </p>
//                 </div>
//                 <div className="col-6">
//                   <h6
//                     className="d-flex align-items-center gap-2 text-blue-color mb-3"
//                     style={{ fontWeight: 600 }}
//                   >
//                     <CalendarMonthIcon className="text-blue-color" />{" "}
//                     {/* {quotesData?.StartDate || "N/A"} */}
//                    { moment(quotesData?.StartDate).format("DD/MM/YYYY")}
//                   </h6>
//                   <p className="d-flex align-items-center gap-2 text-blue-color">
//                     <CallIcon style={{ fontSize: "20px" }} />{" "}
//                     {quotesData?.CustomerData[0]?.PhoneNumber || ""}
//                   </p>
//                 </div>
//               </div>
//               <hr />
//               <div>
//                 <p style={{ fontWeight: 700 }} className="text-blue-color mb-0">
//                   Line Items
//                 </p>
//                 <Table className="p-0">
//                   <thead>
//                     <tr>
//                       <th style={{ paddingLeft: 0 }}>Product/Service</th>
//                       <th className="text-end">Qty</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {quotesData?.ContractItemData?.length > 0 ? (
//                       quotesData?.ContractItemData.map((item) => (
//                         <tr key={item._id}>
//                           <td style={{ paddingLeft: 0 }}>{item.Name}</td>
//                           <td className="text-end">
//                             {item.Unit
//                               ? `${item.Unit} Units`
//                               : item.Hour
//                               ? `${item.Hour} Hours`
//                               : item.Square
//                               ? `${item.Square} SqFeet`
//                               : "N/A"}{" "}
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="2" className="text-center">
//                           No items available
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </Table>
//               </div>
//             </CardBody>
//           </Card>
//         </div>
//       )}
//     </>
//   );
// }

// export default ConfirmAppointment;

import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AxiosInstance from "../../AxiosInstance";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import "../style.css";
import { Circles } from "react-loader-spinner";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CallIcon from "@mui/icons-material/Call";

function ConfirmAppointment() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [quotesData, setQuotesData] = useState(null);
  const [tokenDecode, setTokenDecode] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(false);

  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching token data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [navigate, location]);

  const companyId = tokenDecode.companyId;
  const ContractId = location.state.contractId;
  const WorkerId = localStorage.getItem("worker_id");

  const fetchVisitData = async () => {
    if (companyId && location.state.id && WorkerId) {
      setLoader(true);
      try {
        const response = await AxiosInstance.get(
          `/visits/workervisits/${location.state.id}/${WorkerId}`
        );
        if (response.data.statusCode === 200 && response.data.data) {
          setQuotesData(response.data.data);
          setIsConfirmed(response.data.data.IsConfirmByWorker || false);
        } else {
          console.error("Unexpected data structure or status code from API.");
        }
      } catch (error) {
        console.error("API error:", error);
      } finally {
        setLoader(false);
      }
    } else {
      console.error("Company ID, Visit ID, or Worker ID is not valid.");
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchVisitData();
  }, [companyId, ContractId, WorkerId, location.state.id]);

  const handleConfirmAppointment = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to confirm this appointment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsConfirmed(true); // Update UI immediately
        try {
          const objact = {
            WorkerId,companyId
          };
          const response = await AxiosInstance.put(
            `/visits/ConfirmByWorker/${location.state.id}/${quotesData.ContractId}`,
            objact
          );
          if (response.data && response.data.statusCode === 200) {
            Swal.fire(
              "Confirmed!",
              "The appointment has been confirmed in the database.",
              "success"
            );
          } else {
            setIsConfirmed(false); // Revert if API fails
            Swal.fire(
              "Error!",
              "Unable to confirm the appointment in the database. Please try again.",
              "error"
            );
          }
        } catch (error) {
          setIsConfirmed(false); // Revert if API fails
          console.error("Error confirming appointment in database:", error);
          Swal.fire(
            "Error!",
            "Something went wrong. Please try again.",
            "error"
          );
        }
      }
    });
  };

  return (
    <>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center my-5 text-blue-color"
          style={{ height: "100vh" }}
        >
          <Circles
            height="50"
            width="50"
            ariaLabel="circles-loading"
            color="#063164"
            visible={true}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ width: "67%" }}>
            <Button className="bg-blue-color" onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </Button>{" "}
          </div>
          <Card className="invoice-card col-8" style={{ marginTop: "10px" }}>
            <CardHeader>
              <div className="text-end">
                {isConfirmed ? (
                  <Button
                    className="Appoiment text-blue-color"
                    style={{
                      border: "2px solid #063164",
                      background: "#DBECFF",
                      borderRadius: "20px",
                      fontWeight: 600,
                    }}
                  >
                    Confirmed
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="bg-blue-color text-white-color"
                    onClick={handleConfirmAppointment}
                  >
                    Confirm Appointment
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardBody>
              <div className="d-flex">
                <div className="col-6">
                  <h5 style={{ fontWeight: 700 }} className="text-blue-color">
                    {quotesData?.CustomerData[0]?.FirstName || "N/A"}{" "}
                    {quotesData?.CustomerData[0]?.LastName || "N/A"} -{" "}
                    {quotesData?.ItemName || "N/A"}
                  </h5>
                  <p className="text-blue-color">
                    {quotesData?.LocationData?.Address || "N/A"}{" "}
                    {quotesData?.LocationData?.City || ""},{" "}
                    {quotesData?.LocationData?.State || ""}, <br />{" "}
                    {quotesData?.LocationData?.Zip || ""}-{" "}
                    {quotesData?.LocationData?.Country || ""}
                  </p>
                </div>
                <div className="col-6">
                  <h6
                    className="d-flex align-items-center gap-2 text-blue-color mb-3"
                    style={{ fontWeight: 600 }}
                  >
                    <CalendarMonthIcon className="text-blue-color" />{" "}
                    {moment(quotesData?.StartDate).format("MM-DD-YYYY")}
                  </h6>
                  <p className="d-flex align-items-center gap-2 text-blue-color">
                    <CallIcon style={{ fontSize: "20px" }} />{" "}
                    {quotesData?.CustomerData[0]?.PhoneNumber || ""}
                  </p>
                </div>
              </div>
              <hr />
              <div>
                <p style={{ fontWeight: 700 }} className="text-blue-color mb-0">
                  Line Items
                </p>
                <Table className="p-0">
                  <thead>
                    <tr>
                      <th style={{ paddingLeft: 0 }}>Product/Service</th>
                      <th className="text-end">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotesData?.ContractItemData?.length > 0 ? (
                      quotesData?.ContractItemData.map((item) => (
                        <tr key={item._id}>
                          <td style={{ paddingLeft: 0 }}>{item.Name}</td>
                          <td className="text-end">
                            {item.Unit
                              ? `${item.Unit} Units`
                              : item.Hour
                              ? `${item.Hour} Hours`
                              : item.Square
                              ? `${item.Square} SqFeet`
                              : "N/A"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2" className="text-center">
                          No items available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
}

export default ConfirmAppointment;
