import React from "react";
import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import clientdetails from "../../../../assets/White-sidebar-icon/Customer.svg";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import truck from "../../../../assets/image/icons/truck.svg";
import InvoiceBlue from "../../../../assets/Blue-sidebar-icon/Invoice.svg";
import PaymentBlue from "../../../../assets/Orange-icons/payment.svg";
import CustomerProperty from "../Customer-Property";
import {
  DeleteIcone,
  EditIcon,
  LoaderComponent,
  NavigateButton,
} from "../../../../components/Icon/Index";
import "./style.css";
import { Grid } from "@mui/material";
import BlueButton from "../../../../components/Button/BlueButton";
import AllDropdown from "../../../../components/Dropdown/Dropdown";
import { Typography } from "@mui/material";

const CustomerDetails = ({
  loader,
  navigate,
  data,
  dropdownOpen,
  companyName,
  location,
  setModelOpen,
  activeTabId,
  handleClick,
  quotes,
  invoice,
  contract,
  setOpen,
  handlePropertyDelete,
  handleQuoteNavigate,
  moment,
  handleContractNavigate,
  handleInvoiceNavigate,
  open,
  modelOpen,
  getData,
  loading,
  sendMail,
  toggleDropdown,
  menuItems,
}) => {
  return (
    <>
      {loader ? (
        <Grid
          className="d-flex flex-direction-row justify-content-center align-items-center Typography-5 m-5"
          style={{ height: "80vh", marginTop: "25%" }}
        >
          <LoaderComponent loader={loader} height="50" width="50" />
        </Grid>
      ) : (
        <Grid className="justify-content-center align-items-center mb-3 mt-5 client">
          <Button
            style={{
              marginRight: "10px",
              width: "50px",
              height: "40px",
              marginBottom: "10px",
              padding: "0px 0px",
              borderRadius: "4px",
              marginTop: "10px",
            }}
            onClick={() => {
              navigate(-1);
            }}
            className="text-capitalize bg-button-blue-color text-white-color "
          >
            <ArrowBackOutlinedIcon />
          </Button>
          <Grid
            className="d-flex align-items-center justify-content-between username-action"
            style={{ display: "" }}
          >
            <Grid>
              <Grid className="d-flex justify-content-start usrName">
                <Grid
                  className=" text-blue-color costomerUserName "
                  style={{
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "38px",
                  }}
                >
                  <Grid
                    className="bg-blue-color"
                    style={{
                      borderRadius: "50%",
                      marginRight: "10px",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <img src={clientdetails} />
                  </Grid>
                  {data?.FirstName ? data?.FirstName : "-"}&nbsp;
                  {data?.LastName ? data?.LastName : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <AllDropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                menuItems={menuItems}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid
              className="my-2 detail-card flexForDetail"
              style={{ border: "none", gap: "12px" }}
            >
              <Col
                xs={12}
                sm={6}
                md={8}
                lg={8}
                className="first-card add_new_pxroperty_card"
              >
                <Grid
                  className="address propertyAddBoxScroll p-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 80%)",
                  }}
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center"
                    style={{ border: "none" }}
                  >
                    <Typography
                      style={{ fontWeight: 600 }}
                      className="text-blue-color property_tag heading-five"
                    >
                      Properties
                    </Typography>

                    <Grid className="plusNewPropertyToAddPPro">
                      <BlueButton
                        className="bg-button-blue-color company-detail-btn"
                        style={{
                          padding: "3px 10px 3px 10px",
                          fontSize: "14px",
                        }}
                        onClick={() =>
                          setOpen({ isOpen: true, propertyData: null })
                        }
                        label="+ New Property"
                      />
                    </Grid>
                  </CardHeader>
                  <CardBody className="card-body client-details property_addNew boxScrollHEre">
                    <Table borderless className="propertyTableAndItScroll">
                      <TableHead>
                        <TableRow className="dataCollapsHere">
                          <TableCell
                            className="fw-bold"
                            style={{ paddingLeft: "20px", fontSize: "16px" }}
                          >
                            Address
                          </TableCell>
                          <TableCell
                            className="text-center fw-bold"
                            style={{ fontSize: "16px" }}
                          >
                            City
                          </TableCell>
                          <TableCell
                            className="text-center fw-bold"
                            style={{ fontSize: "16px" }}
                          >
                            State
                          </TableCell>
                          <TableCell
                            className="text-center fw-bold"
                            style={{ fontSize: "16px" }}
                          >
                            Country
                          </TableCell>
                          <TableCell
                            className="text-center fw-bold"
                            style={{ fontSize: "16px" }}
                          >
                            Zip
                          </TableCell>
                          {companyName && (
                            <TableCell
                              className="fw-bold"
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              Actions
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.location?.map((property, index) => (
                          <TableRow
                            style={{ cursor: "pointer", width: "318px" }}
                            key={index}
                            // onClick={() =>
                            //   navigate(`/${companyName}/property-details`, {
                            //     state: {
                            //       id: property.LocationId,
                            //       navigats: [
                            //         ...location.state.navigats,
                            //         "/property-details",
                            //       ],
                            //     },
                            //   })
                            // }
                            onClick={() => {
                              if (companyName) {
                                navigate(`/${companyName}/property-details`, {
                                  state: {
                                    id: property.LocationId,
                                    navigats: [
                                      ...location.state.navigats,
                                      "/property-details",
                                    ],
                                  },
                                });
                              } else {
                                navigate(
                                  `/staff-member/worker-property-details`,
                                  {
                                    state: {
                                      id: property.LocationId,
                                      navigats: [
                                        ...location.state.navigats,
                                        "/worker-property-details",
                                      ],
                                    },
                                  }
                                );
                              }
                            }}
                          >
                            <TableCell
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "20px",
                              }}
                            >
                              <MyLocationIcon
                                className="text-blue-color"
                                style={{ marginRight: "15px" }}
                              />
                              <Typography
                                className="text-blue-color"
                                style={{ fontSize: "14px" }}
                              >
                                {property?.Address || "-"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.City || "-"}
                            </TableCell>
                            <TableCell
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.State || "-"}
                            </TableCell>
                            <TableCell
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.Country || "-"}
                            </TableCell>
                            <TableCell
                              className="text-center text-blue-color"
                              style={{ fontSize: "14px" }}
                            >
                              {property.Zip || "-"}
                            </TableCell>

                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <EditIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen({
                                    isOpen: true,
                                    propertyData: property,
                                  });
                                }}
                              />
                              <DeleteIcone
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlePropertyDelete(property.LocationId);
                                }}
                                className="customerEditImgToEdit"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Grid>
                <Grid
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(6, 49, 100, 30%)",
                  }}
                  className="my-4 p-2"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center Typography-2"
                    style={{ border: "none" }}
                  >
                    <Typography
                      className="text-blue-color heading-five"
                      style={{ fontWeight: 600 }}
                    >
                      Overview
                    </Typography>

                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="bg-button-blue-color"
                        caret
                        color="dark"
                        style={{
                          padding: "3px 10px 3px 10px",
                          fontSize: "14px",
                          border: "none",
                        }}
                      >
                        + Add
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          className="dropdown-items text-blue-color "
                          // onClick={() => {
                          //   navigate(`/${companyName}/add-quotes`, {
                          //     state: {
                          //       Customer: data,
                          //       CustomerId: data.CustomerId,
                          //       navigats: [
                          //         ...location.state.navigats,
                          //         "/add-quotes",
                          //       ],
                          //     },
                          //   });
                          // }}
                          onClick={() => {
                            if (companyName) {
                              navigate(`/${companyName}/add-quotes`, {
                                state: {
                                  navigats: [
                                    ...location?.state?.navigats,
                                    "/add-quotes",
                                  ],
                                },
                              });
                            } else {
                              navigate(`/staff-member/add-quotes`, {
                                state: {
                                  navigats: [
                                    ...location?.state?.navigats,
                                    "/add-quotes",
                                  ],
                                },
                              });
                            }
                          }}
                        >
                          <RequestQuoteOutlinedIcon className="icones-dropdown text-blue-color" />
                          Quote
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown-items text-blue-color"
                          // onClick={() => {
                          //   navigate(`/${companyName}/add-contract`, {
                          //     state: {
                          //       Customer: data,
                          //       CustomerId: data.CustomerId,
                          //       navigats: [
                          //         ...location.state.navigats,
                          //         "/add-contract",
                          //       ],
                          //     },
                          //   });
                          // }}
                          onClick={() => {
                            if (companyName) {
                              navigate(`/${companyName}/add-contract`, {
                                state: {
                                  navigats: [
                                    ...location?.state?.navigats,
                                    "/add-contract",
                                  ],
                                },
                              });
                            } else {
                              navigate(`/staff-member/add-contract`, {
                                state: {
                                  navigats: [
                                    ...location?.state?.navigats,
                                    "/add-contract",
                                  ],
                                },
                              });
                            }
                          }}
                        >
                          <WorkOutlineOutlinedIcon className="icones-dropdown text-blue-color" />
                          Contract
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown-items text-blue-color"
                          // onClick={() => {
                          //   navigate(`/${companyName}/invoicetable`, {
                          //     state: {
                          //       Customer: data,
                          //       CustomerId: data.CustomerId,
                          //       navigats: [
                          //         ...location.state.navigats,
                          //         "/invoicetable",
                          //       ],
                          //     },
                          //   });
                          // }}
                          onClick={() => {
                            if (companyName) {
                              navigate(`/${companyName}/AddInvoice`, {
                                state: {
                                  navigats: [
                                    ...location?.state?.navigats,
                                    "/AddInvoice",
                                  ],
                                },
                              });
                            } else {
                              navigate(`/staff-member/workeraddinvoice`, {
                                state: {
                                  navigats: [
                                    ...location?.state?.navigats,
                                    "/workeraddinvoice",
                                  ],
                                },
                              });
                            }
                          }}
                        >
                          <FileCopyOutlinedIcon className="icones-dropdown text-blue-color" />
                          Invoice
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </CardHeader>
                  <CardBody
                    style={{
                      padding: "10px 0px",
                      maxHeight: "400px",
                      overflowY: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <Grid className="text-start customer_navbar">
                      <Nav
                        className="bg-orange-color customerOverviewNav"
                        tabs
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          minWidth: "668px",
                          overflowX: "auto",
                        }}
                      >
                        <NavItem>
                          <NavLink
                            className={activeTabId === 1 ? "active" : ""}
                            onClick={() => handleClick(1)}
                            style={{ cursor: "pointer" }}
                          >
                            All
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 3 ? "active" : ""}
                            onClick={() => handleClick(3)}
                            style={{ cursor: "pointer" }}
                          >
                            Quotes
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 4 ? "active" : ""}
                            onClick={() => handleClick(4)}
                            style={{ cursor: "pointer" }}
                          >
                            Contract
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={activeTabId === 5 ? "active" : ""}
                            onClick={() => handleClick(5)}
                            style={{ cursor: "pointer" }}
                          >
                            Invoices
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTabId}
                        className="text-start"
                      >
                        <TabPane tabId={1}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  quotes?.length +
                                    invoice?.length +
                                    contract?.length >
                                  3
                                    ? "300px"
                                    : "auto",
                                overflowY:
                                  quotes?.length +
                                    invoice?.length +
                                    contract?.length >
                                  3
                                    ? "auto"
                                    : "visible",
                                scrollbarWidth: "thin",
                                minWidth: "700px",
                                overflowX: "auto",
                              }}
                            >
                              {(quotes && quotes?.length > 0) ||
                              (contract && contract?.length > 0) ||
                              (invoice && invoice?.length > 0) ? (
                                <>
                                  {quotes &&
                                    quotes?.length > 0 &&
                                    quotes.map((data, index) => (
                                      <Grid
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleQuoteNavigate(data?.QuoteId)
                                        }
                                      >
                                        <Grid className="container scrollForOverview">
                                          <Row
                                            className="row align-items-start pt-2 p-1 border-blue-color text-blue-color"
                                            style={{
                                              border: "1px solid",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                              color: "#063164",
                                              width: "100%",
                                            }}
                                          >
                                            <Col className="col">
                                              <Typography
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  Quote #{data?.QuoteNumber}
                                                </Typography>
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color:
                                                    data?.status === "Approved"
                                                      ? "rgb(88, 204, 88)"
                                                      : data?.status ===
                                                        "Awaiting Response"
                                                      ? "orange"
                                                      : data?.status ===
                                                        "Request changed"
                                                      ? "#FF33C6"
                                                      : "#063164",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {" "}
                                                  {data?.status}
                                                </Typography>
                                              </Typography>
                                            </Col>
                                            <Col className="col text-center">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                TITLE
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {data?.Title}
                                              </Typography>
                                            </Col>
                                            <Col className="col text-center">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                ADDRESS
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </Typography>
                                            </Col>
                                            <Col className="col text-right">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                CREATED ON
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {moment(data?.createdAt).format(
                                                  "MM-DD-YYYY"
                                                )}
                                              </Typography>
                                            </Col>
                                          </Row>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  {contract &&
                                    contract?.length > 0 &&
                                    contract?.map((data, index) => (
                                      <Grid
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleContractNavigate(
                                            data?.ContractId
                                          )
                                        }
                                      >
                                        <Grid className="container scrollForOverview">
                                          <Row
                                            className="row align-items-start pt-2  p-1 outline-button-blue-color text-blue-color"
                                            style={{
                                              border: "1px solid ",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                              color: "#063164",
                                              width: "100%",
                                            }}
                                          >
                                            <Col className="col">
                                              <Typography
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  Contract #
                                                  {data?.ContractNumber}
                                                </Typography>
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color:
                                                    data?.Status ===
                                                    "Unscheduled"
                                                      ? "#E88C44"
                                                      : data?.Status === "Today"
                                                      ? "#089F57"
                                                      : data?.Status ===
                                                        "Upcoming"
                                                      ? "#089F57"
                                                      : data?.Status ===
                                                        "Scheduled"
                                                      ? "#C8CC00"
                                                      : "",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {data?.Status}
                                                </Typography>
                                              </Typography>
                                            </Col>
                                            <Col className="col text-center">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                TITLE
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {data?.Title}
                                              </Typography>
                                            </Col>
                                            <Col className="col text-center">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                ADDRESS
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </Typography>
                                            </Col>
                                            <Col className="col text-right">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                CREATED ON
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {moment(data?.createdAt).format(
                                                  "ll"
                                                )}
                                              </Typography>
                                            </Col>
                                          </Row>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  {invoice &&
                                    invoice.length > 0 &&
                                    invoice.map((data, index) => (
                                      <Grid
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleInvoiceNavigate(data?.InvoiceId)
                                        }
                                      >
                                        <Grid className="container scrollForOverview">
                                          <Row
                                            className="row align-items-start pt-2 p-1 outline-button-blue-color text-blue-color"
                                            style={{
                                              border: "1px solid ",
                                              fontSize: "12px",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              borderRadius: "8px",
                                              border:
                                                "0.5px solid rgba(6, 49, 100, 0.8)",
                                              color: "#063164",
                                              width: "100%",
                                            }}
                                          >
                                            <Col className="col">
                                              <Typography
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  Invoice #{data?.InvoiceNumber}
                                                </Typography>
                                              </Typography>
                                              <Typography
                                                style={{
                                                  color:
                                                    data?.status === "Approved"
                                                      ? "rgb(88, 204, 88)"
                                                      : data?.status ===
                                                        "Awaiting Response"
                                                      ? "orange"
                                                      : data?.status ===
                                                        "Request changed"
                                                      ? "#FF33C6"
                                                      : "#063164",
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {" "}
                                                  {data?.Status}
                                                </Typography>
                                              </Typography>
                                            </Col>
                                            <Col className="col text-center">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                TITLE
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {data?.Subject}
                                              </Typography>
                                            </Col>
                                            <Col className="col text-center">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                ADDRESS
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {data?.location?.Address || "-"}
                                                , {data?.location?.City || "-"},{" "}
                                                {data?.location?.State || "-"}
                                              </Typography>
                                            </Col>
                                            <Col className="col text-right">
                                              <Typography
                                                className="bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "800",
                                                }}
                                              >
                                                CREATED ON
                                              </Typography>
                                              <Typography
                                                className="text-bolt"
                                                style={{
                                                  marginBottom: "5px",
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {moment(data?.createdAt).format(
                                                  "ll"
                                                )}
                                              </Typography>
                                            </Col>
                                          </Row>
                                        </Grid>
                                      </Grid>
                                    ))}
                                </>
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <Grid
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </Grid>
                                  <Grid className="mx-2">
                                    <Typography
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      All
                                    </Typography>
                                    <Typography
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active Work, Quotes, Contracts and
                                      Invoices this Customer yet
                                    </Typography>
                                  </Grid>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  quotes?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  quotes?.length > 3 ? "auto" : "visible",
                                overflowX: "auto",
                                minWidth: "668px",
                                overflowX: "auto",
                              }}
                            >
                              {quotes?.length > 0 ? (
                                quotes.map((data, index) => (
                                  <Grid
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleQuoteNavigate(data?.QuoteId)
                                    }
                                  >
                                    <Grid className="container">
                                      <Grid
                                        className="row align-items-start pt-2 p-1 text-blue-color border-blue-color customerScrollHorizontal "
                                        style={{
                                          border: "1px solid ",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                        }}
                                      >
                                        <Col className="col">
                                          <Typography
                                            style={{ marginBottom: "5px" }}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                              }}
                                            >
                                              Quote #{data?.QuoteNumber}
                                            </Typography>
                                          </Typography>
                                          <Typography
                                            style={{
                                              color:
                                                data?.status === "Approved"
                                                  ? "rgb(88, 204, 88)"
                                                  : data?.status ===
                                                    "Awaiting Response"
                                                  ? "orange"
                                                  : data?.status ===
                                                    "Request changed"
                                                  ? "#FF33C6"
                                                  : "#063164",
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {" "}
                                              {data?.status}
                                            </Typography>
                                          </Typography>
                                        </Col>
                                        <Col className="col text-center">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            TITLE
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data?.Title}
                                          </Typography>
                                        </Col>
                                        <Col className="col text-center">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            ADDRESS
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </Typography>
                                        </Col>
                                        <Col className="col text-right">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            CREATED ON
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {moment(data.createdAt).format(
                                              "ll"
                                            )}
                                          </Typography>
                                        </Col>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <Grid
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </Grid>
                                  <Grid className="mx-2">
                                    <Typography
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Quotes
                                    </Typography>
                                    <Typography
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active quotes for this Customer yet
                                    </Typography>
                                  </Grid>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  contract?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  contract?.length > 3 ? "auto" : "visible",
                                overflowX: "auto",
                                minWidth: "668px",
                                overflowX: "auto",
                              }}
                            >
                              {contract && contract?.length > 0 ? (
                                contract.map((data, index) => (
                                  <Grid
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleContractNavigate(data?.ContractId)
                                    }
                                  >
                                    <Grid className="container">
                                      <Row
                                        className="row align-items-start pt-2 p-1 outline-button-blue-color text-blue-color customerScrollHorizontal "
                                        style={{
                                          border: "1px solid ",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                        }}
                                      >
                                        <Col className="col">
                                          <Typography
                                            style={{ marginBottom: "5px" }}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                              }}
                                            >
                                              Contract #{data?.ContractNumber}
                                            </Typography>
                                          </Typography>
                                          <Typography
                                            style={{
                                              color:
                                                data?.Status === "Unscheduled"
                                                  ? "#E88C44"
                                                  : data?.Status === "Today"
                                                  ? "#089F57"
                                                  : data?.Status === "Upcoming"
                                                  ? "#089F57"
                                                  : data?.Status === "Scheduled"
                                                  ? "#C8CC00"
                                                  : "",
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {data?.Status}
                                            </Typography>
                                          </Typography>
                                        </Col>
                                        <Col className="col text-center">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            TITLE
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data?.Title}
                                          </Typography>
                                        </Col>
                                        <Col className="col text-center">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            ADDRESS
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </Typography>
                                        </Col>
                                        <Col className="col text-right">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            CREATED ON
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {moment(data?.createdAt).format(
                                              "ll"
                                            )}
                                          </Typography>
                                        </Col>
                                      </Row>
                                    </Grid>
                                  </Grid>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <Grid
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </Grid>
                                  <Grid className="mx-2">
                                    <Typography
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Contract
                                    </Typography>
                                    <Typography
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active Contracts for this Customer yet
                                    </Typography>
                                  </Grid>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId={5}>
                          <Row>
                            <Col
                              sm="12"
                              className="d-flex flex-column"
                              style={{
                                gap: "10px",
                                maxHeight:
                                  invoice?.length > 3 ? "300px" : "auto",
                                overflowY:
                                  invoice?.length > 3 ? "auto" : "visible",
                                overflowX: "auto",
                                minWidth: "668px",
                                overflowX: "auto",
                              }}
                            >
                              {invoice && invoice.length > 0 ? (
                                invoice.map((data, index) => (
                                  <Grid
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleInvoiceNavigate(data?.InvoiceId)
                                    }
                                  >
                                    <Grid className="container">
                                      <Row
                                        className="row align-items-start pt-2  p-1 outline-button-blue-color text-blue-color customerScrollHorizontal "
                                        style={{
                                          border: "1px solid ",
                                          fontSize: "12px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                          borderRadius: "8px",
                                          border:
                                            "0.5px solid rgba(6, 49, 100, 0.8)",
                                          overflowX: "auto",
                                        }}
                                      >
                                        <Col className="col">
                                          <Typography
                                            style={{ marginBottom: "5px" }}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                              }}
                                            >
                                              Invoice #{data?.InvoiceNumber}
                                            </Typography>
                                          </Typography>
                                          <Typography
                                            style={{
                                              color:
                                                data?.status === "Approved"
                                                  ? "rgb(88, 204, 88)"
                                                  : data?.status ===
                                                    "Awaiting Response"
                                                  ? "orange"
                                                  : data?.status ===
                                                    "Request changed"
                                                  ? "#FF33C6"
                                                  : "#063164",
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {" "}
                                              {data?.Status}
                                            </Typography>
                                          </Typography>
                                        </Col>
                                        <Col className="col text-center">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            Subject
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data?.Subject}
                                          </Typography>
                                        </Col>
                                        <Col className="col text-center">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            ADDRESS
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data?.location?.Address || "-"},{" "}
                                            {data?.location?.City || "-"},{" "}
                                            {data?.location?.State || "-"}
                                          </Typography>
                                        </Col>
                                        <Col className="col text-right">
                                          <Typography
                                            className="bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "800",
                                            }}
                                          >
                                            CREATED ON
                                          </Typography>
                                          <Typography
                                            className="text-bolt"
                                            style={{
                                              marginBottom: "5px",
                                              fontSize: "12px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {moment(data?.createdAt).format(
                                              "ll"
                                            )}
                                          </Typography>
                                        </Col>
                                      </Row>
                                    </Grid>
                                  </Grid>
                                ))
                              ) : (
                                <Col sm="12" className="d-flex">
                                  <Grid
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 30%)",
                                      padding: "15px",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                    }}
                                  >
                                    <img src={truck} alt="Truck Icon" />
                                  </Grid>
                                  <Grid className="mx-2">
                                    <Typography
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Invoice
                                    </Typography>
                                    <Typography
                                      className="text-blue-color"
                                      style={{ fontSize: "12px" }}
                                    >
                                      No active Invoices this Customer yet
                                    </Typography>
                                  </Grid>
                                </Col>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Grid>
                  </CardBody>
                </Grid>
              </Col>
              <Col md={4} className="first-card contact_info_histort">
                <Typography className="text-blue-color heading-three">
                  Contact Info.
                </Typography>
                <Grid className="table-responsive mx-0">
                  <Table style={{ width: "100%", tableLayout: "auto" }}>
                    <TableBody>
                      <TableRow className="companydata"></TableRow>
                      <TableRow className="phoneno">
                        <TableCell
                          className="text-blue-color tabledata-contactinfo"
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            minWidth: "150px",
                          }}
                        >
                          Phone Number
                        </TableCell>
                        <TableCell
                          className="text-end text-blue-color"
                          style={{ fontSize: "14px" }}
                        >
                          {data?.PhoneNumber ? (
                            <a
                              className="text-blue-color"
                              style={{
                                textDecoration: "none",
                              }}
                              href={`tel:${data?.PhoneNumber}`}
                            >
                              {data?.PhoneNumber}
                            </a>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow className="email">
                        <TableCell
                          className="text-blue-color tabledata-contactinfo"
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            minWidth: "150px",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          className="text-end text-blue-color"
                          style={{ fontSize: "14px" }}
                        >
                          {data?.EmailAddress ? (
                            <a
                              className="text-blue-color"
                              style={{
                                textDecoration: "none",
                              }}
                              href={`tel:${data?.EmailAddress}`}
                            >
                              {data?.EmailAddress}
                            </a>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

                <Grid
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    border: "0.5px solid rgba(5, 15, 36, 30%)",
                  }}
                  className="my-4"
                >
                  <CardHeader
                    className="d-flex justify-content-between align-items-center p-1 Typography-2"
                    style={{ border: "none" }}
                  >
                    <Typography
                      className="text-blue-color billing_history heading-five"
                      style={{
                        fontWeight: 600,
                        fontSize: "26px",
                      }}
                    >
                      Billing History
                    </Typography>

                    {/* {companyName && (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="bg-button-blue-color"
                          caret
                          color="dark"
                          style={{
                            padding: "3px 10px 3px 10px",
                            fontSize: "14px",
                            border: "none",
                          }}
                        >
                          + Add
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Collect Payments
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Record Deposit
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Invoice
                          </DropdownItem>
                          <DropdownItem className="dropdown-items text-blue-color">
                            Set Initial Balance
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )} */}
                  </CardHeader>

                  <CardBody
                    style={{
                      padding: "10px 0px",
                      maxHeight: "200px",
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                    }}
                    className="d-flex flex-column mx-3"
                  >
                    {data?.invoiceData &&
                    data?.invoiceData?.length > 0 &&
                    data?.invoiceData[0]?.InvoiceNumber ? (
                      data.invoiceData?.map((item, index) => (
                        <Grid className="d-flex flex-column">
                          {item?.payments &&
                            item?.payments?.length > 0 &&
                            item?.payments?.map((elem, i) => (
                              <Grid className="d-flex justify-content-between">
                                <Grid className="d-flex">
                                  <Grid
                                    style={{
                                      backgroundColor: "rgba(6, 49, 100, 10%)",
                                      borderRadius: "50%",
                                      height: "50px",
                                      width: "50px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img src={PaymentBlue} />
                                  </Grid>
                                  <Grid className="mx-2">
                                    <Typography
                                      className="mb-0 my-2 text-blue-color"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {item?.IssueDate}
                                    </Typography>
                                    <Typography
                                      className="text-blue-color"
                                      style={{ fontSize: "14px" }}
                                    >
                                      Payment for invoice #{item?.InvoiceNumber}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid className="d-flex align-items-end">
                                  <Typography
                                    className="text-blue-color"
                                    style={{ fontSize: "14px" }}
                                  >
                                    -${elem?.amount}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                          <Grid className="d-flex justify-content-between">
                            <Grid className="d-flex">
                              <Grid
                                style={{
                                  backgroundColor: "rgba(6, 49, 100, 10%)",
                                  borderRadius: "50%",
                                  height: "50px",
                                  width: "50px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img src={InvoiceBlue} />
                              </Grid>
                              <Grid className="mx-2">
                                <Typography
                                  className="mb-0 my-2 text-blue-color"
                                  style={{ fontSize: "16px", fontWeight: 600 }}
                                >
                                  {item?.IssueDate}
                                </Typography>
                                <Typography
                                  className="text-blue-color"
                                  style={{ fontSize: "14px" }}
                                >
                                  Invoice #{item?.InvoiceNumber}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid className="d-flex align-items-end">
                              <Typography
                                className="text-blue-color"
                                style={{ fontSize: "14px" }}
                              ></Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Typography>No billing history</Typography>
                    )}
                  </CardBody>

                  <CardFooter
                    className="d-flex border-blue-color justify-content-between bg-orange-color text-white-color"
                    style={{
                      borderTop: "1px solid",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      alignItems: "center",
                      padding: "6px 10px 7px",
                    }}
                  >
                    <Typography>Current Balance</Typography>
                    <Typography>${data?.currentBalance}</Typography>
                  </CardFooter>
                </Grid>
              </Col>
            </Grid>
          </Grid>
        </Grid>
      )}
      <CustomerProperty
        open={open}
        setOpen={setOpen}
        data={data}
        getData={getData}
      />

      {/* <Dialog fullWidth open={modelOpen} onClose={() => setModelOpen(false)}>
        <DialogTitle className="bg-blue-color" style={{ color: "#fff" }}>
          {"Please Confirm"}
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            borderTop: "5px solid #e88c44",
          }}
        >
          <Grid container className="mailConfigurationBox" spacing={2}>
            <Typography
              className="text-blue-color"
              style={{
                fontSize: "16px",
                padding: "25px",
                height: "auto",
                lineHeight: "1.5",
              }}
            >
              This action will resend a{" "}
              <strong>Customer Hub login invitation</strong> to:
              <Typography
                component="span"
                className="text-orange-color"
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                {data?.EmailAddress || "the customer's email"}
              </Typography>
              .
              <br />
              If the customer hasn’t received the email, make sure the provided
              address is correct.
            
            </Typography>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
              className="mailSendConfiguration"
            >
              {loader ? (
                <LoaderComponent loader={loader} height="30" width="30" />
              ) : (
                <>
                  <Button
                    className="text-blue-color border-blue-color nogoback"
                    style={{
                      fontSize: "14px",
                      color: "#063164",
                      border: "1px solid #063164",
                      background: "none",
                      borderRadius: "4px",
                      textTransform: "none",
                    }}
                    onClick={() => setModelOpen(false)}
                  >
                    No, Go Back
                  </Button>

                  <Button
                    className="bg-blue-color mailSendYesComonent"
                    style={{
                      padding: "8px 15px",
                      fontSize: "14px",
                      color: "#fff",
                      borderRadius: "4px",
                      textTransform: "none",
                      marginLeft: "15px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                    disabled={loading}
                    onClick={sendMail}
                  >
                    Yes, Send Mail
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog> */}
      <Dialog
        fullWidth
        open={modelOpen}
        onClose={() => setModelOpen(false)}
        PaperProps={{
          style: {
            borderRadius: "12px",
            overflow: "hidden",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <DialogTitle
          className="bg-blue-color"
          style={{
            color: "#fff",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Please Confirm
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: "30px",
            borderTop: "4px solid #e88c44",
            textAlign: "center",
          }}
        >
          <Grid container spacing={3} className="mailConfigurationBox">
            <Grid item xs={12}>
              <Typography
                className="text-blue-color"
                style={{
                  fontSize: "16px",
                  lineHeight: "1.6",
                  color: "#333",
                }}
              >
                Are you sure you want to resend the{" "}
                <strong>Customer Hub login invitation</strong> to:
                <Typography
                  component="span"
                  style={{
                    fontWeight: "bold",
                    marginLeft: "5px",
                  }}
                  className="text-orange-color"
                >
                  {data?.EmailAddress || "the customer's email"}
                </Typography>
                ?
                <br />
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#555",
                    marginTop: "10px",
                    display: "block",
                  }}
                >
                  Note: Ensure the email address is correct before proceeding.
                </Typography>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
              className="mailSendConfiguration"
            >
              {loader ? (
                <LoaderComponent loader={loader} height="30" width="30" />
              ) : (
                <>
                  <Button
                    className="text-blue-color border-blue-color nogoback"
                    style={{
                      fontSize: "14px",
                      color: "#063164",
                      border: "1px solid #063164",
                      background: "#fff",
                      textTransform: "none",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                    onClick={() => setModelOpen(false)}
                  >
                    No, Go Back
                  </Button>

                  <Button
                    className="bg-blue-color mailSendYesComonent"
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      textTransform: "none",
                      marginLeft: "15px",
                      backgroundColor: "#063164",
                      border: "none",
                      boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.15)",
                      transition: "all 0.3s ease",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#042949")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#063164")
                    }
                    disabled={loading}
                    onClick={sendMail}
                  >
                    Yes, Send Mail
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomerDetails;
