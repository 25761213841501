import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Box,
  IconButton,
  Input,
  Typography,
  Divider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Card } from "reactstrap";
import AxiosInstance from "../../../AxiosInstance.jsx";
import InputText from "../../../../components/InputFields/InputText.jsx";
import profileIcon from "../../../../assets/image/icons/profile-icon.jpg";
import sliderindicator from "../../../../assets/image/icons/sliderindicator.svg";
import swal from "sweetalert";
import Address from "../../../../components/Address/index.jsx";
import { Country } from "country-state-city";
import { Circles } from "react-loader-spinner";
import showToast from "../../../../components/Toast/Toster.jsx";
import sendToast from "../../../../components/Toast/sendToast.jsx";
import { LoaderComponent, WhiteLoaderComponent } from "../../../../components/Icon/Index.jsx";

const Superadmin = () => {
  const cdnUrl = process.env.REACT_APP_CDN_API;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [postLoader, setPostLoader] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isEdited, setIsEdited] = useState(false);

  const profileFormik = useFormik({
    initialValues: {
      fullName: "",
      primaryEmailAddress: "",
      PhoneNumber: "",
      Password: "",
      Address: "",
      City: "",
      State: "",
      Zip: "",
      Country: "",
      profilePicture: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name required"),
      primaryEmailAddress: Yup.string()
        .email("Invalid email")
        .required("Email required"),
      PhoneNumber: Yup.string()
        .required("Phone number required")
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        ),
      Address: Yup.string().required("Address required"),
      City: Yup.string().required("City required"),
      State: Yup.string().required("State required"),
      Zip: Yup.string().required("Zip required"),
      Country: Yup.string().required("Country required"),
    }),
    validateOnBlur: true, // Trigger validation on blur
    validateOnChange: true, // Trigger validation on change
    validateOnSubmit: true,
    onSubmit: async (values) => {
      try {
        setPostLoader(true);
        const res = await AxiosInstance.put(`/superadmin/profile`, values);
        if (res?.data?.statusCode === 200) {
          showToast.success(res?.data?.message);
          swal(
            "Profile saved successfully!",
            "Your changes have been saved.",
            "success"
          ).then(() => {
            if (!postLoader) {
              navigate(`/superadmin/index`, {
                state: { navigats: ["/index"] },
              });
            }
          });
          getData();
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        showToast.error("Error updating profile.");
      } finally {
        setPostLoader(false);
      }
    },
  });

  const getData = async () => {
    try {
      const allCountries = Country.getAllCountries();
      setCountries(allCountries);

      const res = await AxiosInstance.get(`/superadmin/profile`);

      if (res?.data?.statusCode === 200) {
        const data = res?.data?.data;
        setInitialData(data);
        setUploadedImageUrl(data?.profilePicture);

        profileFormik.setValues({
          ...data,
          Address: data?.Address || "",
          City: data?.City || "",
          State: data?.State || "",
          Zip: data?.Zip || "",
          Country: data?.Country || "",
        });

        if (data?.Country) {
          const selectedCountry = allCountries.find(
            (item) => item?.name === data?.Country
          );
          setSelectedCountry(selectedCountry);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
      setImageLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, "");
    if (phoneNumber?.length === 0) return "";
    if (phoneNumber?.length <= 3) return `(${phoneNumber}`;
    if (phoneNumber?.length <= 6)
      return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3)}`;
    return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(
      3,
      6
    )}-${phoneNumber?.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    if (profileFormik?.values?.PhoneNumber?.length > input?.length) {
      profileFormik?.setFieldValue("PhoneNumber", input);
    } else {
      const formattedValue = formatPhoneNumber(input);
      profileFormik?.setFieldValue("PhoneNumber", formattedValue);
    }
    setIsEdited(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      profileFormik?.setFieldValue(name, value);
    } else {
      profileFormik?.setFieldValue(name, type === "checkbox" ? checked : value);
    }
    setIsEdited(true);
  };

  // const handleSaveClick = () => {
  //   profileFormik.handleSubmit();

  // };

  const handleFileChange = (file) => {
    setImageLoader(true);
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
        "image/svg+xml",
        "image/tiff",
        "image/x-icon",
      ];
      if (allowedTypes.includes(file.type)) {
        uploadImage(file);
      } else {
        console.error("Unsupported file type. Only JPG and PNG are allowed.");
        return;
      }
    }
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData?.append("files", file);
      const url = `${cdnUrl}/upload`;

      const result = await AxiosInstance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const image = result?.data?.files[0]?.filename;
      if (image) {
        const res = await AxiosInstance.put("/superadmin/profile", {
          profilePicture: image,
        });
        if (res.data.statusCode === 200) {
          showToast.success("Profile image updated successfully.");
          setUploadedImageUrl(image);
          profileFormik.setFieldValue("profilePicture", image);
          await getData();
        } else {
          sendToast(
            "There was an issue updating image. Please try again later."
          );
        }
      } else {
        sendToast("There was an issue updating image. Please try again later.");
      }
    } catch (error) {
      sendToast("There was an issue updating image. Please try again later.");
    } finally {
      setImageLoader(false);
    }
  };

  const hasChanges =
    JSON.stringify(profileFormik?.values) !== JSON.stringify(initialData);
  const isFormValid = profileFormik?.isValid && hasChanges;
  return (
    <>
      <Grid className="manage-team">
        {loader ? (
          <Grid
            className="d-flex justify-content-center align-items-center text-blue-color "
            style={{ height: "100%", width: "100%", marginTop: "25%" }}
          >
            <Circles
              height="50"
              width="50"
              color="#063164"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loader}
            />
          </Grid>
        ) : (
          <Card style={{ padding: "40px", borderRadius: "20px" }}>
            <Grid container spacing={3} className="superAdminProfile">
              <Grid item xs={12} md={6}>
                <InputText
                  value={profileFormik?.values?.fullName}
                  onChange={profileFormik?.handleChange}
                  id="fullName"
                  name="fullName"
                  label="Full Name"
                  placeholder="Enter Full Name"
                  type="text"
                  className="mb-3 my-2 w-100"
                  error={
                    profileFormik?.touched?.fullName &&
                    Boolean(profileFormik?.errors?.fullName)
                  }
                  helperText={
                    profileFormik?.touched?.fullName &&
                    profileFormik?.errors?.fullName
                  }
                />
                <InputText
                  value={profileFormik?.values?.primaryEmailAddress}
                  onChange={profileFormik?.handleChange}
                  id="primaryEmailAddress"
                  name="primaryEmailAddress"
                  label="Email Address"
                  placeholder="Enter Email Address"
                  type="email"
                  className="mb-3 my-2 w-100"
                  error={
                    profileFormik?.touched?.primaryEmailAddress &&
                    Boolean(profileFormik?.errors?.primaryEmailAddress)
                  }
                  helperText={
                    profileFormik?.touched?.primaryEmailAddress &&
                    profileFormik?.errors?.primaryEmailAddress
                  }
                />
                <InputText
                  value={profileFormik?.values?.PhoneNumber}
                  onChange={handlePhoneChange}
                  id="PhoneNumber"
                  name="PhoneNumber"
                  label="Mobile Number"
                  placeholder="Enter Mobile Number here..."
                  type="text"
                  className="mb-3 my-2 textfield_bottom w-100"
                  error={
                    profileFormik?.touched?.PhoneNumber &&
                    Boolean(profileFormik?.errors?.PhoneNumber)
                  }
                  helperText={
                    profileFormik?.touched?.PhoneNumber &&
                    profileFormik?.errors?.PhoneNumber
                  }
                />
                <Address
                  setSelectedCountry={setSelectedCountry}
                  selectedCountry={selectedCountry}
                  countries={countries}
                  handleChange={handleChange}
                  formik={profileFormik}
                />
                <Box position="relative">
                  <InputText
                    value={profileFormik?.values?.Password}
                    onChange={profileFormik?.handleChange}
                    id="Password"
                    name="Password"
                    label="Password"
                    placeholder="Enter password here..."
                    type={showPassword ? "text" : "password"}
                    className="mb-3 my-2 textfield_bottom w-100"
                    error={
                      profileFormik?.touched?.Password &&
                      Boolean(profileFormik?.errors?.Password)
                    }
                    helperText={
                      profileFormik?.touched?.Password &&
                      profileFormik?.errors?.Password
                    }
                  />
                  <IconButton
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#063164",
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>
                <Button
                  onClick={profileFormik.handleSubmit}
                  disabled={!hasChanges}
                  style={{
                    backgroundColor: isFormValid ? "#063164" : "#b3cde0",
                    color: "#fff",
                  }}
                >
                  {postLoader ? (
                    <WhiteLoaderComponent
                      height="20"
                      width="20"
                      padding="20"
                      loader={postLoader}
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Grid>

              <Grid className="col-lg-6 d-flex justify-content-center align-items-center flex-column order-1 order-lg-2 mb-2">
                <Grid
                  className="text-center "
                  style={{ marginTop: "0px", marginBottom: "30px" }}
                >
                  <Typography
                    className="text-blue-color"
                    style={{
                      fontSize: "30px",
                      fontWeight: "600",
                    }}
                  >
                    Personal Information
                  </Typography>
                </Grid>
                <Grid
                  className="text-center"
                  style={{ marginTop: "0px", marginBottom: "30px" }}
                >
                  <Grid
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#fff",
                      width: "80px",
                      height: "80px",
                      borderRadius: "50px",
                      boxShadow: "0 4px 5px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {imageLoader ? (
                      <Grid className="d-flex justify-content-center">
                        <LoaderComponent
                          height="20"
                          width="20"
                          padding="20"
                          loader={imageLoader}
                        />
                      </Grid>
                    ) : (
                      <img
                        src={
                          uploadedImageUrl
                            ? `${cdnUrl}/upload/${uploadedImageUrl}`
                            : profileIcon
                        }
                        alt="Profile"
                        style={{
                          borderRadius: "50%",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid>
                  <label htmlFor="upload-button">
                    <Button
                      component="span"
                      style={{
                        color: "#063164",
                        background: "#FFFF",
                        textTransform: "none",
                        border: "1px solid #063164",
                        marginTop: "20px",
                        fontSize: "12px",
                        cursor: "pointer",
                        padding: "8px 16px",
                        borderRadius: "4px",
                      }}
                    >
                      Upload image here...
                    </Button>
                  </label>
                  <Input
                    id="upload-button"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleFileChange(e.target.files[0]);
                    }}
                    multiple={false}
                  />
                </Grid>

                <Grid style={{ marginTop: "40px" }}>
                  <Typography
                    className="text-blue-color"
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "400",
                      width: "300px",
                    }}
                  >
                    Use this form to update your personal information. Ensure
                    all details are accurate and up-to-date.
                  </Typography>
                </Grid>
                <Grid>
                  <img src={sliderindicator} />
                </Grid>
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
    </>
  );
};

export default Superadmin;
