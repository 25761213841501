import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Quotes from "../../../src/assets/Blue-sidebar-icon/Quote.svg";
import Contract from "../../../src/assets/Blue-sidebar-icon/Contract.svg";
import Invoice from "../../../src/assets/Blue-sidebar-icon/Invoice.svg";
import Appoinment from "../../../src/assets/Blue-sidebar-icon/Appoinment-blue.svg";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AxiosInstance from "../AxiosInstance";
import { handleAuth } from "../../components/Login/Auth";

const ClientDashboard = ({ companyId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({});
  const [tokenDecode, setTokenDecode] = useState({});
  const CompanyId = tokenDecode.CompanyId;
  const CustomerId = tokenDecode.CustomerId;
  const fetchData = async () => {
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching token decode data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const customerId = localStorage.getItem("CustomerId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(
          `/customer/customer-dashboard/${CustomerId}/${CompanyId}`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching customer dashboard data:", error);
        navigate("/login");
      }
    };

    if (CustomerId && CompanyId) {
      fetchData();
    }
  }, [CustomerId, CompanyId, navigate, location]);

  const currentHour = new Date().getHours();
  let welcomeMessage =
    currentHour < 12
      ? "Good Morning"
      : currentHour < 18
      ? "Good Afternoon"
      : "Good Evening";

  const handleQuotesNavigation = () => {
    navigate(`/customers/quotes`, {
      state: { navigats: ["/index", "/quotes"] },
    });
  };
  const handlecontractNavigation = () => {
    navigate(`/customers/contract`, {
      state: { navigats: ["/index", "/contract"] },
    });
  };
  const handleinvoiceNavigation = () => {
    navigate(`/customers/invoice`, {
      state: { navigats: ["/index", "/invoice"] },
    });
  };
  const handleappoinmentsNavigation = () => {
    navigate(`/customers/appointment`, {
      state: { navigats: ["/index", "/appointment"] },
    });
  };
  return (
    <div>
      <div className="px-0 mb-5">
        <h4
          className="px-1"
          style={{
            fontSize: "35px",
            fontWeight: "700",
            fontFamily: "Poppins",
            lineHeight: "28.8px",
            color: "#063164 ",
          }}
        >
          {welcomeMessage}, {tokenDecode?.full_name}
        </h4>
      </div>

      <div className="dashboard-customer">
        <div className="main-customer row">
          <div className="card client-footer mb-3 col-lg-3 col-md-6 col-sm-12">
            <div className="card-header bg-orange-color"></div>
            <div className="card-body bg-blue-color">
              <p className="card-text first-card-client bg-white-color">
                <img src={Quotes} className="border-orange-color " />
              </p>
              <h3 className="mx-1 card-title text-white-color mt-5">
                {data.quotes || 0}
              </h3>
            </div>
            <div
              className="card-footer bg-orange-color text-white-color mb-0"
              onClick={handleQuotesNavigation}
              style={{ cursor: "pointer" }}
            >
              Quotes <ArrowRightIcon style={{ fontSize: "38px" }} />
            </div>
          </div>

          <div className="card client-footer mb-3 col-lg-3 col-md-6 col-sm-12">
            <div className="card-header bg-orange-color"></div>
            <div className="card-body bg-blue-color">
              <p className="card-text first-card-client bg-white-color">
                <img src={Contract} className="border-orange-color " />
              </p>
              <h3 className="mx-1 card-title text-white-color mt-5">
                {data.contracts || 0}
              </h3>
            </div>
            <div
              className="card-footer bg-orange-color text-white-color mb-0"
              onClick={handlecontractNavigation}
              style={{ cursor: "pointer" }}
            >
              Contract <ArrowRightIcon style={{ fontSize: "38px" }} />
            </div>
          </div>

          <div className="card client-footer mb-3 col-lg-3 col-md-6 col-sm-12">
            <div className="card-header bg-orange-color"></div>
            <div className="card-body bg-blue-color">
              <p className="card-text first-card-client bg-white-color">
                <img src={Invoice} className="border-orange-color " />
              </p>
              <h3 className="mx-1 card-title text-white-color mt-5">
                {data.invoices || 0}
              </h3>
            </div>
            <div
              className="card-footer bg-orange-color text-white-color mb-0"
              onClick={handleinvoiceNavigation}
              style={{ cursor: "pointer" }}
            >
              Invoices <ArrowRightIcon style={{ fontSize: "38px" }} />
            </div>
          </div>

          <div className="card client-footer mb-3 col-lg-3 col-md-6 col-sm-12">
            <div className="card-header bg-orange-color"></div>
            <div className="card-body bg-blue-color">
              <p className="card-text first-card-client bg-white-color">
                <img src={Appoinment} className="border-orange-color " />
              </p>
              <h3 className="mx-1 card-title text-white-color mt-5">
                {data.visits || 0}
              </h3>
            </div>
            <div
              className="card-footer bg-orange-color text-white-color mb-0"
              onClick={handleappoinmentsNavigation}
              style={{ cursor: "pointer" }}
            >
              Appointment <ArrowRightIcon style={{ fontSize: "38px" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
