import React, { useState, useEffect } from "react";
import "./style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth.jsx";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.jsx";
import ContractView from "./views/Contract.jsx";
import sendSwal from "../../../components/Swal/sendSwal.jsx";
import { DeleteIcone, EditIcon } from "../../../components/Icon/Index.jsx";
import showToast from "../../../components/Toast/Toster.jsx";

const Contract = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customerData, setCustomerData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const [tokenDecode, setTokenDecode] = useState(null);

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const res = await handleAuth(navigate, location);
        setTokenDecode(res?.data?.companyId);
      } catch (error) {
        console.error("Error fetching token data:", error);
      }
    };

    fetchTokenData();
  }, [navigate, location]);
  
  const getData = async () => {
    setLoader(true);
    try {
      const res = await AxiosInstance.get(
        `/contract/${localStorage.getItem("CompanyId") || tokenDecode}`,
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        }
      );
      setCustomerData(res?.data?.data);
      setCountData(res?.data?.totalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search, tokenDecode]);

  // const handleEditClick = (id) => {
  //   if (id) {
  //     navigate("/" + companyName + "/add-contract", {
  //       state: {
  //         id: id,
  //         navigats: [...location.state.navigats, "/add-contract"],
  //       },
  //     });
  //   }
  // };
  const handleEditClick = (id) => {
    if (companyName) {
      navigate(`/${companyName}/add-contract`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/add-contract"],
        },
      });
    } else {
      navigate(`/staff-member/add-contract`, {
        state: {
          id,
          navigats: [...location?.state?.navigats, "/add-contract"],
        },
      });
    }
  };

  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`/contract/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (response.data.statusCode === 200) {
            setCustomerData((prevData) =>
              prevData.filter((item) => item?.ContractId !== id)
            );
            setTimeout(() => {
              showToast.success(response?.data?.message);
            }, 500);
            getData();
          } else {
            showToast.warning(response?.data?.message);
          }
        } catch (error) {
          console.error("Error:", error);
          showToast.error(error);
        }
      } else {
        showToast.success("Contract is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const cellData =
    customerData &&
    customerData?.length > 0 &&
    customerData?.map((item, index) => {
      const properties = item?.properties || [];
      let propertyDisplay;
      if (properties?.length === 1) {
        const property = properties[0];
        propertyDisplay = `${property?.street_address1 || ""}
       ${property?.street_address2 || ""}
       ${property?.street_address3 || ""} ${property?.city || ""} 
       ${property?.state || ""} 
      ${property?.country || ""} 
      ${property?.zip_code || ""}`;
      } else {
        propertyDisplay = `${properties?.length} ${
          properties.length > 1 ? "Properties" : "Property"
        }`;
      }

      return {
        key: item?.ContractId,
        value: [
          page * rowsPerPage + index + 1,
          `${item?.customer?.FirstName} ${item?.customer?.LastName}`,
          <>
            #{item?.ContractNumber}
            <br />
            {item?.Title}
          </>,
          <div
            dangerouslySetInnerHTML={{
              __html: `${item?.location?.Address || ""} ${
                item?.location?.City || ""
              }<br>${item?.location?.State || ""} ${
                item?.location?.Country || ""
              } ${item?.location?.Zip || ""}`,
            }}
          />,
          item?.RecuringJob?.StartDate
            ? moment(item?.RecuringJob?.StartDate).format("MM-DD-YYYY")
            : item?.OneoffJob?.StartDate
            ? moment(item?.OneoffJob?.StartDate).format("MM-DD-YYYY")
            : "-",

          <b
            style={{
              color:
                item?.Status === "Unscheduled"
                  ? "#E88C44"
                  : item?.Status === "Today"
                  ? "#089F57"
                  : item?.Status === "Upcoming"
                  ? "#089F57"
                  : item?.Status === "Scheduled"
                  ? "#C8CC00"
                  : item?.Status === "Converted"
                  ? "#1f104f"
                  : "",
            }}
          >
            {item?.Status}
          </b>,
          <>{`$${new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item?.Total ?? 0)}`}</>
          ,
          <>
            <EditIcon
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(item?.ContractId);
              }}
            />
            <DeleteIcone
              className="customerEditImgToEdit"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(item?.ContractId);
              }}
            />
          </>,
        ],
      };
    });

  return (
    <>
      <ContractView
        loader={loader}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        companyName={companyName}
        countData={countData}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
};

export default Contract;
