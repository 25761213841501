import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance";
import sendToast from "../../../components/Toast/sendToast";
import CustomerDetailsViews from "./Views/CustomerDetails";
import sendSwal from "../../../components/Swal/sendSwal";

function CustomerDetails() {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const { companyName } = useParams();

  const [data, setData] = useState();
  const [open, setOpen] = useState({ isOpen: false, propertyData: null });
  const [loader, setLoader] = useState(true);
  const [modelOpen, setModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`/customer/${location.state.id}`);
      setData(res.data.data);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [location.state.id]);

  const handlePropertyDelete = async (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const res = await AxiosInstance.delete(`/location/${id}`,{
            data: { DeleteReason: deleteReason },
          });
          if (res.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(res.data.message);
            }, 500);
            getData();
          } else {
            toast.error("", res.data.message, "error");
          }
        } catch (error) {
          toast.error("", error.message, "error");
          console.error("Error: ", error.message);
        }
      }else {
        toast.success("Properties is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const [activeTabId, setActiveTabId] = useState(1);
  const handleClick = (tabId) => {
    setActiveTabId(tabId);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const moreActiontoggle = () => setDropdownOpen((prevState) => !prevState);

  const [quotes, setQuotes] = useState([]);
  useEffect(() => {
    const fetchQuote = async () => {
      if (data && data.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/quote/get_quotes_customer/${localStorage.getItem("CompanyId")}/${
              data.CustomerId
            }`
          );
          if (response.data.statusCode === 200) {
            setQuotes(response.data.data);
          }
        } catch (err) {
          console.error("Error to fetching quote data: ", err.message);
        }
      }
    };

    fetchQuote();
  }, [data]);

  const [contract, setContract] = useState([]);
  useEffect(() => {
    const fetchContract = async () => {
      if (data && data.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/contract/get_contract_customer/${localStorage.getItem(
              "CompanyId"
            )}/${data.CustomerId}`
          );
          setContract(response.data.data);
        } catch (err) {
          console.error("Error to fetching contract data: ", err.message);
        }
      }
    };
    fetchContract();
  }, [data]);

  const [invoice, setInvoice] = useState([]);
  useEffect(() => {
    const fetchInvoice = async () => {
      if (data && data.CustomerId) {
        try {
          const response = await AxiosInstance.get(
            `/invoice/get_invoice_customer/${localStorage.getItem(
              "CompanyId"
            )}/${data.CustomerId}`
          );
          if (response.data.statusCode === 200) {
            setInvoice(response.data.data);
          }
        } catch (err) {
          console.error("Error to fetching contract data: ", err.message);
        }
      }
    };
    fetchInvoice();
  }, [data]);

  const handleQuoteNavigate = (quoteId) => {
    navigate(`/${companyName}/quotes-detail`, {
      state: {
        id: quoteId,
        navigats: ["/index", `/quotes-detail`],
      },
    });
  };

  const handleContractNavigate = (id) => {
    navigate(`/${companyName}/contractdetails`, {
      state: {
        id,
        navigats: ["/index", `/contractdetails`],
      },
    });
  };

  const handleInvoiceNavigate = (id) => {
    navigate(`/${companyName}/invoice-details`, {
      state: {
        id,
        navigats: ["/index", `/invoice-details`],
      },
    });
  };

  const sendMail = async () => {
    try {
      setLoader(true);
      const response = await AxiosInstance.post(
        `/customer/send_mail/${data.CustomerId}`
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
      } else {
        sendToast(response.data.message);
      }
    } catch (error) {
      console.error("Error to send mail");
    } finally {
      setModelOpen(false);
      setLoader(false);
    }
  };

  return (
    <>
      <CustomerDetailsViews
        loader={loader}
        navigate={navigate}
        data={data}
        dropdownOpen={dropdownOpen}
        moreActiontoggle={moreActiontoggle}
        companyName={companyName}
        location={location}
        setModelOpen={setModelOpen}
        activeTabId={activeTabId}
        handleClick={handleClick}
        quotes={quotes}
        invoice={invoice}
        contract={contract}
        setOpen={setOpen}
        handlePropertyDelete={handlePropertyDelete}
        handleQuoteNavigate={handleQuoteNavigate}
        moment={moment}
        handleContractNavigate={handleContractNavigate}
        handleInvoiceNavigate={handleInvoiceNavigate}
        open={open}
        modelOpen={modelOpen}
        getData={getData}
        loading={loading}
        sendMail={sendMail}
      />
      <Toaster />
    </>
  );
}

export default CustomerDetails;
