export const addTokenizationScript = (tokenizationKey) => {
  if (!tokenizationKey) {
    return;
  }

  const existingScript = document.querySelector(
    `script[src="https://hms.transactiongateway.com/token/Collect.js"]`
  );

  if (existingScript) {
    console.log("Collect.js script is already loaded.");
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://hms.transactiongateway.com/token/Collect.js";
    script.dataset.tokenizationKey = tokenizationKey;
    script.dataset.theme = "material";

    script.onload = () => {
      console.log("Collect.js script loaded.");
      resolve();
    };

    script.onerror = () => {
      reject(new Error("Failed to load the tokenization script"));
    };
    document.body.appendChild(script);
  });
};
