// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visit-section {
  margin-bottom: 20px;
}

.visit-card-container {
  display: flex;
  flex-direction: column; 
  gap: 10px; 
}

.invoice-card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.appointment-status-section {
  display: flex;
  flex-direction: column;
}

.appointment-status-header {
  text-align: center;
  color: #063164;
  font-weight: bold;
  font-size: unset;
  background-color: #F8D5B9;
  border: 1px solid #063164;
  border-radius: 5px;
  padding: 2px;
  margin-bottom: 10px;
  width: 30%;
  /* margin-left: auto;
  margin-right: auto; */
}
.map-icon{
  margin-bottom: auto;
  
}`, "",{"version":3,"sources":["webpack://./src/Views/Staff Member/Appoiment/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,0CAA0C;AAC5C;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,UAAU;EACV;uBACqB;AACvB;AACA;EACE,mBAAmB;;AAErB","sourcesContent":[".visit-section {\n  margin-bottom: 20px;\n}\n\n.visit-card-container {\n  display: flex;\n  flex-direction: column; \n  gap: 10px; \n}\n\n.invoice-card {\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n}\n.appointment-status-section {\n  display: flex;\n  flex-direction: column;\n}\n\n.appointment-status-header {\n  text-align: center;\n  color: #063164;\n  font-weight: bold;\n  font-size: unset;\n  background-color: #F8D5B9;\n  border: 1px solid #063164;\n  border-radius: 5px;\n  padding: 2px;\n  margin-bottom: 10px;\n  width: 30%;\n  /* margin-left: auto;\n  margin-right: auto; */\n}\n.map-icon{\n  margin-bottom: auto;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
