import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
} from "../../../components/MuiTable/index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import "./style.css";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  FormGroup,
} from "reactstrap";
import { Circles } from "react-loader-spinner";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import { handleAuth } from "../../../components/Login/Auth.js";
import AxiosInstance from "../../AxiosInstance.js";
import CancelIcon from "@mui/icons-material/Close";
import InputText from "../../../components/InputFields/InputText.js";
import InputDropdown from "../../../components/InputFields/InputDropdown.js";
import { useLocation, useNavigate } from "react-router-dom";
import sendSwal from "../../../components/Swal/sendSwal.js";

const Plan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [modelOpen, setModelOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planId, setPlanId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teamSize, setTeamSize] = useState([]);
  const [countData, setCountData] = useState(0);
  const [loader, setLoader] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`${baseUrl}/plan`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
        },
      });
      setTeamSize(res.data.data);
      setCountData(res.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);

  const [isMostPopular, setIsMostPopular] = useState(
    selectedPlan ? selectedPlan.IsMostpopular : false
  );

  useEffect(() => {
    if (selectedPlan) {
      setIsMostPopular(selectedPlan.IsMostpopular);
    }
  }, [selectedPlan]);

  const handleSubmit = async (values, resetForm) => {
    try {
      setLoader(true);
      values.IsMostpopular = isMostPopular;
      let res;
      if (!planId) {
        res = await AxiosInstance.post(`${baseUrl}/plan`, values);
        if (res.data.statusCode === 200) {
          setModelOpen(false);
          getData();
          setTimeout(() => {
            toast.success(res.data.message);
            setIsMostPopular(false);
            setSelectedMonth(null);
            setSelectedDay(null);
            resetForm();
          }, 500);
        } else if (res.data.statusCode === 202) {
          const userConfirmed = await swal(
            "The most popular plan already exists. Do you want to replace it with the new plan?",
            {
              buttons: ["Cancel", "Proceed"],
            }
          );

          if (userConfirmed) {
            values.confirmReplace = true;
            res = await AxiosInstance.post(`${baseUrl}/plan`, values);
            if (res.data.statusCode === 200) {
              setModelOpen(false);
              getData();
              setTimeout(() => {
                toast.success(res.data.message);
                setIsMostPopular(false);
                setSelectedMonth(null);
                setSelectedDay(null);
                resetForm();
              }, 500);
            }
          }
        } else {
          setTimeout(() => {
          toast.error(res.data.message);
        }, 500);
        }
      } else {
        res = await AxiosInstance.put(`${baseUrl}/plan/${planId}`, values);
        if (res.data.statusCode === 200) {
          setModelOpen(false);
          getData();
          setTimeout(() => {
            toast.success(res.data.message);
            setIsMostPopular(false);
            setSelectedMonth(null);
            setSelectedDay(null);
            resetForm();
          }, 500);
        } else if (res.data.statusCode === 202) {
          setTimeout(() => {
          toast.success(res.data.message);
        }, 500);
        } else {
          setTimeout(() => {
          toast.error(res.data.message);
        }, 500);
        }
      }
    } catch (error) {
      setTimeout(() => {
      toast.error(error.message || "An error occurred");
    }, 500);
    } finally {
      setLoader(false);
    }
  };

  const handleEditClick = (item) => {
    setSelectedPlan(item);
    setSelectedDay({ name: item.DayOfMonth, code: item.DayOfMonth });
    setSelectedMonth({ name: item.MonthFrequency, code: item.MonthFrequency });
    setPlanId(item.PlanId);
    setModelOpen(true);
  };
  // Delete
  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(`${baseUrl}/plan/${id}`);
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            }, 500);
            getData();
          } else if (response.data.statusCode === 201) {
            setTimeout(() => {
              toast.error(response.data.message);
            }, 500);
          } else {
            setTimeout(() => {
            toast.error("", response.data.message, "error");
          }, 500);
          }
        } catch (error) {
          console.error("Error:", error);
          setTimeout(() => {
          toast.error(error);
        }, 500);
        }
      } else {
        setTimeout(() => {
        toast.success("Plan is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }, 500);
      }
    });
  };

  const CollapseData = ({ data }) => {
    return (
      <div className="d-flex gap-4">
        <div className="card col-8">
          <div
            className="card-body w-100"
            style={{ backgroundColor: "#D8E7EE" }}
          >
            <div className="d-flex w-100 flex-row justify-content-between gap-2">
              <p className="text-blue-color">Description: </p>
              <span
                className="text-blue-color"
                style={{
                  backgroundColor: "white",
                  padding: "0.2rem 0.5rem",
                  borderRadius: "0.25rem",
                  width: "100%",
                  marginBottom: "7px",
                }}
              >
                {data?.PlanDetail}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const cellData = teamSize?.map((item, index) => {
  //   return {
  //     key: item.PlanId,
  //     value: [
  //       item.PlanName,
  //       item.PlanPrice,
  //       item.MonthFrequency,
  //       item.createdAt,
  //       <>
  //         <img
  //           src={Edit}
  //           alt="edit"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             handleEditClick(item);
  //           }}
  //         />

  //         <img
  //           className="mx-1"
  //           alt="delete"
  //           src={Delete}
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             handleDelete(item.PlanId);
  //           }}
  //         />
  //       </>,
  //     ],
  //     component: item,
  //   };
  // });

  const cellData = teamSize?.map((item, index) => {
    const createdAt = new Date(item.createdAt);

    const formattedDate = `${(createdAt.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${createdAt
      .getDate()
      .toString()
      .padStart(2, "0")}-${createdAt.getFullYear()}`;

    return {
      key: item.PlanId,
      value: [
        item.PlanName,
        item.PlanPrice,
        item.MonthFrequency,
        formattedDate, // Use formatted date here
        <>
          <img
            src={Edit}
            alt="edit"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item);
            }}
          />
          <img
            className="mx-1"
            alt="delete"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.PlanId);
            }}
          />
        </>,
      ],
      component: item,
    };
  });

  return (
    <>
      <div className="justify-content-center align-items-center mb-3 mt-5">
        <div className="d-flex justify-content-end mb-2">
          <Button
            style={{ color: "#fff" }}
            onClick={() => {
              setSelectedPlan(null);
              setSelectedDay(null);
              setSelectedMonth(null);
              setModelOpen(true);
              setPlanId(null);
            }}
            className="text-capitalize bg-blue-color"
          >
            Add Plan
          </Button>
        </div>
        <Card
          className="border-blue-color"
          style={{ borderRadius: "20px", border: "2px solid " }}
        >
          <CardHeader
            className="d-flex border-blue-color justify-content-between align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid ",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="plan text-light">Plans</h5>
            <div className="plansearch d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparant", color: "#fff" }}
              />
            </div>
          </CardHeader>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <Circles
                height="50"
                width="50"
                color="#063164"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={[
                  "Plan Name",
                  "Plan Price",
                  "Month Frequency",
                  "Created At",
                  "Action",
                ]}
                cellData={cellData}
                CollapseComponent={(data) => CollapseData(data)}
                isCollapse={true}
                page={page}
              />
            </CardBody>
          )}

          <CardFooter
            className="bg-orange-color border-blue-color"
            style={{
              borderTop: "2px solid ",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
      </div>
      <Dialog
        fullWidth
        open={modelOpen}
        // onClose={() => setModelOpen(false)}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setModelOpen(false);
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle className="text-blue-color">
          <div className="d-flex justify-content-between">
            <div>{"Plan Form"}</div>
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => setModelOpen(false)}
            >
              <CancelIcon />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              PlanName: selectedPlan ? selectedPlan.PlanName : "",
              PlanPrice: selectedPlan ? selectedPlan.PlanPrice : "",
              PlanDetail: selectedPlan ? selectedPlan.PlanDetail : "",
              MonthFrequency: selectedMonth ? selectedMonth.code : "",
              DayOfMonth: selectedDay ? selectedDay.code : "",
              IsMostpopular: selectedPlan ? selectedPlan.IsMostpopular : false,
            }}
            validationSchema={Yup.object().shape({
              PlanName: Yup.string().required("Required"),
              PlanPrice: Yup.string().required("Required"),
              PlanDetail: Yup.string().required("Required"),
              MonthFrequency: Yup.string().required("Required"),
              DayOfMonth: Yup.number().required("Required"),
            })}
            
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <div>
                  <div className="mt-3 superadmin-company ">
                    <InputText
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="Add Title here... *"
                      label="Title *"
                      name="PlanName"
                      value={values.PlanName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{
                        style: {
                          height: "35px",
                        },
                      }}
                      className="text-blue-color border-blue-color"
                    />
                    {touched.PlanName && errors.PlanName ? (
                      <div className="text-danger">{errors.PlanName}</div>
                    ) : null}
                  </div>

                  <div className="mt-3 superadmin-company">
                    <InputText
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="Add Plan Details here... *"
                      label="Plan Details *"
                      name="PlanDetail"
                      value={values.PlanDetail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{
                        style: {
                          height: "35px",
                          // marginTop: "7px"
                        },
                      }}
                      className="text-blue-color border-blue-color"
                    />
                    {touched.PlanDetail && errors.PlanDetail ? (
                      <div className="text-danger">{errors.PlanDetail}</div>
                    ) : null}
                  </div>
                  <div className="mt-3 mb-3 superadmin-company">
                    <InputText
                      type="number"
                      size="small"
                      fullWidth
                      placeholder="Enter Cost Per Billing Cycle... *"
                      label="Cost Per Billing Cycle *"
                      name="PlanPrice"
                      value={values.PlanPrice}
                      onBlur={handleBlur}
                      // onChange={handleChange}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (value < 0) {
                          e.target.value = 0;
                        }
                        handleChange(e);
                      }}
                      className="text-blue-color border-blue-color"
                      // onChange={(e) => {
                      //   const value = parseFloat(e.target.value);
                      //   // Allow only numeric values including decimals
                      //   if (/^\d*\.?\d*$/.test(value)) {
                      //     // Use your original handleChange logic
                      //     handleChange({ target: { name: "PlanPrice", value:  parseFloat(e.target.value) } });
                      //   }
                      // }}
                      inputProps={{
                        style: {
                          height: "35px",
                        },
                        min: "0",
                      }}
                    />
                    {touched.PlanPrice && errors.PlanPrice ? (
                      <div className="text-danger">{errors.PlanPrice}</div>
                    ) : null}
                  </div>
                  <div className="plan_billing">
                    <FormControl fullWidth>
                      <InputDropdown
                        onChange={(_, newValue) => {
                          setSelectedMonth(newValue);
                          setFieldValue(
                            "MonthFrequency",
                            newValue ? newValue.code : ""
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.code === value.code
                        }
                        // textFieldProps={getFieldProps("country")}
                        options={[
                          { name: "1", code: "1" },
                          { name: "2", code: "2" },
                          { name: "3 (Quarterly)", code: "3 (Quarterly)" },
                          { name: "4", code: "4" },
                          { name: "5", code: "5" },
                          { name: "6 (Bi-Annually)", code: "6 (Bi-Annually)" },
                          { name: "7", code: "7" },
                          { name: "8", code: "8" },
                          { name: "9", code: "9" },
                          { name: "10", code: "10" },
                          { name: "11", code: "11" },
                          { name: "12 (Annually)", code: "12 (Annually)" },
                        ]}
                        value={selectedMonth || null}
                        inputValue={values.MonthFrequency}
                        onTextFieldChange={handleChange}
                        onBlur={handleBlur}
                        getOptionLabel={(option) => option.name || ""}
                        error={
                          touched.MonthFrequency &&
                          Boolean(errors.MonthFrequency)
                        }
                        helperText={
                          touched.MonthFrequency && errors.MonthFrequency
                        }
                        z={(options, state) => {
                          return options.filter((option) =>
                            option.name
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          );
                        }}
                        name="MonthFrequency"
                        label="Plan Billing Interval"
                        type="text"
                      />
                    </FormControl>
                  </div>
                  <FormControl fullWidth className="charge_box">
                    <InputDropdown
                      onChange={(_, newValue) => {
                        setSelectedDay(newValue);
                        setFieldValue(
                          "DayOfMonth",
                          newValue ? newValue.code : ""
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.code === value?.code
                      }
                      // textFieldProps={getFieldProps("country")}
                      options={[
                        { name: "1", code: "1" },
                        { name: "2", code: "2" },
                        { name: "3", code: "3" },
                        { name: "4", code: "4" },
                        { name: "5", code: "5" },
                        { name: "6", code: "6" },
                        { name: "7", code: "7" },
                        { name: "8", code: "8" },
                        { name: "9", code: "9" },
                        { name: "10", code: "10" },
                        { name: "11", code: "11" },
                        { name: "12", code: "12" },
                        { name: "13", code: "13" },
                        { name: "14", code: "14" },
                        { name: "15", code: "15" },
                        { name: "16", code: "16" },
                        { name: "17", code: "17" },
                        { name: "18", code: "18" },
                        { name: "19", code: "19" },
                        { name: "20", code: "20" },
                        { name: "21", code: "21" },
                        { name: "22", code: "22" },
                        { name: "23", code: "23" },
                        { name: "24", code: "24" },
                        { name: "25", code: "25" },
                        { name: "26", code: "26" },
                        { name: "27", code: "27" },
                        { name: "28", code: "28" },
                      ]}
                      value={selectedDay || null}
                      inputValue={values.DayOfMonth}
                      onTextFieldChange={handleChange}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option.name || ""}
                      error={touched.DayOfMonth && Boolean(errors.DayOfMonth)}
                      helperText={touched.DayOfMonth && errors.DayOfMonth}
                      z={(options, state) => {
                        return options.filter((option) =>
                          option.name
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      name="DayOfMonth"
                      label="Charge on Day of Month"
                      type="text"
                    />
                  </FormControl>

                  <FormGroup switch className="my-3">
                    <Input
                      type="switch"
                      checked={isMostPopular}
                      style={{ cursor: "pointer" }}
                      onChange={(event) => {
                        setIsMostPopular(event.target.checked);
                        setFieldValue("IsMostpopular", event.target.checked);
                      }}
                    />
                    Most Popular Plan
                  </FormGroup>

                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {loader ? (
                      <div className="d-flex justify-content-center">
                        <Circles
                          height="20"
                          width="20"
                          color="#063164"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          visible={loader}
                        />
                      </div>
                    ) : (
                      <Button
                        className="mt-3 text-capitalize bg-blue-color"
                        type="submit"
                        style={{ color: "white" }}
                      >
                        {selectedPlan ? "Update" : "Add"}
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Toaster />
    </>
  );
};

export default Plan;
