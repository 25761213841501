import { Button, FormGroup, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import googlee from "../../assets/image/icons/googlee.svg";
import "./style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../Views/AxiosInstance";
import { Link, useNavigate } from "react-router-dom";
import backimg from "../../assets/image/icons/back.png";
import facebooke from "../../assets/image/icons/facebook.png";
import applee from "../../assets/image/icons/apple.png";
import locke from "../../assets/image/icons/Rectangle 20.png";
import InputText from "../InputFields/InputText";
import logo from "../../assets/image/CMS_LOGO.svg";
import toast, { Toaster } from "react-hot-toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      primaryEmailAddress: "",
    },
    validationSchema: Yup.object().shape({
      primaryEmailAddress: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      handleSendMail(values.primaryEmailAddress);
    },
  });

  const handleSendMail = async (email) => {
    setIsLoading(true);
    try {
      const data = {
        primaryEmailAddress: email,
      };
      const res = await AxiosInstance.post(
        `/resetpassword/resetpasswordmail`,
        data
      );
      console.log(res, "res");
      if (res.status === 200) {
        toast.success("Mail Sent Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate(`/auth/login`);
        }, 2000);
      } else {
        throw new Error("Email sending failed");
      }
    } catch (error) {
      console.log(error, "error");
      console.log(error.response.data.message, "error.response.data.message");
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        toast.error(error.response.data.message || "Something went wrong!");
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("No response from the server, please try again later.");
      } else {
        console.error("Error during request setup:", error.message);
        toast.error("Error occurred while sending request.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login">
      <Grid container spacing={2} className="vh-100">
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex flex-direction-column align-items-center pt-3 h-100"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Link to="/auth/login">
              <img
                src={logo}
                alt="logo"
                style={{
                  marginTop: "20px",
                  width: "15%",
                  position: "absolute",
                  left: 70,
                }}
              />
            </Link>
          </div>
          <div
            style={{
              width: "80%",
              marginTop: "90px",
            }}
            className="d-flex flex-direction-column align-items-center px-3 maon-forget-password"
          >
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column justify-content-between"
              style={{ height: "60%" }}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "left",
                  marginTop: "13px",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img src={backimg} width={20} height={20} alt="back" />
                <span className="mx-2">Back to login </span>
              </Typography>

              <div>
                <Typography
                  className="text"
                  style={{ fontSize: "35px", marginTop: "20px" }}
                >
                  Forgot your password?
                </Typography>
                <Typography
                  className="text2"
                  style={{ color: "#606060", marginTop: "20px" }}
                >
                  Don't worry, happens to all of us. Enter your email below to
                  recover your password
                </Typography>
                <FormGroup style={{ width: "100%", marginTop: "20px" }}>
                  <InputText
                    value={formik.values.primaryEmailAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.email &&
                      Boolean(formik.errors.primaryEmailAddress)
                    }
                    helperText={
                      formik.touched.email && formik.errors.primaryEmailAddress
                    }
                    name="primaryEmailAddress"
                    label="Email"
                    type="email"
                    className="text-blue-color w-100"
                    fieldHeight="56px"
                  />
                </FormGroup>
                <FormGroup style={{ width: "100%", marginTop: "20px" }}>
                  <Button
                    style={{
                      backgroundColor: "rgba(51, 53, 71, 1)",
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      borderRadius: "8px",
                      height: "48px",
                      textTransform: "none",
                    }}
                    type="submit"
                    className="bg-blue-color"
                    disabled={isLoading}
                  >
                    {isLoading ? "Sending..." : "Submit"}
                  </Button>
                </FormGroup>
              </div>
            </form>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center img h-100"
        >
          <img
            src={locke}
            alt="lock"
            style={{ height: "90%", marginTop: "20px" }}
          />
        </Grid>
      </Grid>
      <Toaster />
    </div>
  );
};

export default ForgotPassword;
