import React, { useEffect, useState } from "react";
import { handleAuth } from "../../components/Login/Auth";
import "./style.css";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import Customer from "../../assets/image/dashboard/Customer.svg";
import Quote from "../../assets/image/dashboard/Quote.svg";
import contract from "../../assets/image/dashboard/Contract.svg";
import invoice from "../../assets/image/dashboard/Invoice.svg";
import AxiosInstance from "../../Views/AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";

const AdminDashboard = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const { companyName } = useParams();

  const currentHour = new Date().getHours();

  let welcomeMessage;
  if (currentHour < 12) {
    welcomeMessage = "Good Morning";
  } else if (currentHour < 18) {
    welcomeMessage = "Good Afternoon";
  } else {
    welcomeMessage = "Good Evening";
  }

  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = process.env.REACT_APP_BASE_API;
      const token =
        localStorage.getItem("adminToken") ||
        localStorage.getItem("clientToken");

      if (!token) {
        console.error("Token not found in localStorage");
        return;
      }

      try {
        const res = await AxiosInstance.post(`${baseUrl}/company/token_data`, {
          token,
        });

        setData(res.data.data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchData();
  }, []);

  const cardData = [
    {
      title: "Customer",
      logo: Customer,
      desc: "Record new service requests from your Customer.",
      option: "Create a request",
      onClick: () => {
        navigate(`/${companyName}/add-customer`, {
          state: { navigats: ["/index", "/add-customer"] },
        });
      },
    },

 
    {
      title: "Quotes",
      logo: Quote,
      desc: "Send your Customer professional quotes they can approve online.",
      option: "Create a quote",
      onClick: () => {
        navigate(`/${companyName}/add-quotes`, {
          state: { navigats: ["/index", "/add-quotes"] },
        });
      },
    },
    {
      title: "Contracts",
      logo: contract,
      desc: "Schedule contract with all the details you need to get them done.",
      option: "Create a contract",
      onClick: () => {
        navigate(`/${companyName}/add-contract`, {
          state: { navigats: ["/index", "/add-contract"] },
        });
      },
    },
    {
      title: "Invoices",
      logo: invoice,
      desc: "Send your Customer professional invoices they can pay online",
      option: "Create an invoice",
      onClick: () => {
        navigate(`/${companyName}/invoice`, {
          state: { navigats: ["/index", "/invoice"] },
        });
      },
    },
  ];

  return (
    <Grid className="admin">
      <Grid className="px-0">
        <h4
          className="px-1 text-blue-color welcom_message_admin Good Morning"
          style={{
            fontSize: "35px",
            fontWeight: "700",
            fontFamily: "Poppins",
            lineHeight: "28.8px ",
          }}
        >
          {data?.full_name
            ? welcomeMessage + ", " + data?.full_name
            : welcomeMessage}
        </h4>
        <Grid className="grid-container py-3 pb-5">
          {cardData &&
            cardData.map((item, index) => (
              <Grid className="grid-item bg-blue-color" key={index}>
                <Grid className="content ">
                  <Grid className="title">
                    <Grid className="logo bg-orange-color">
                      <img
                        src={item.logo}
                        alt={item.title}
                        width={34}
                        height={34}
                      />
                    </Grid>
                    <h4 className="name">{item.title}</h4>
                  </Grid>
                  <Grid className="desc">{item.desc}</Grid>
                </Grid>
                <Grid
                  className="options bg-orange-color text-white-color"
                  onClick={item.onClick}
                >
                  <Typography>{item.option}</Typography>
                  <Typography>
                    <EastRoundedIcon />
                  </Typography>
                </Grid>
                <Grid className="circle">
                  <span className="bg-orange-color"></span>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid className="appointment border-blue-color">
          <h3 className="appointment-title text-blue-color">
            Today's Appointments
          </h3>
          <Grid className="appointment-flex">
            <Grid className="appointment-item">
              <Grid>
                <Typography className="appointment-count bg-blue-color ">
                  00
                </Typography>
              </Grid>
              <Grid className="appointment-info">
                Total
                <br />
                $50,000
              </Grid>
              <Grid className="appointment-divider">|</Grid>
            </Grid>

            <Grid className="appointment-item">
              <Grid>
                <Typography className="appointment-count bg-blue-color">
                  00
                </Typography>
              </Grid>
              <Grid className="appointment-info text-blue-color">
                Total
                <br />
                $4,50,000
              </Grid>
              <Grid className="appointment-divider text-blue-color">|</Grid>
            </Grid>

            <Grid className="appointment-item">
              <Grid>
                <Typography className="appointment-count bg-blue-color">
                  00
                </Typography>
              </Grid>
              <Grid className="appointment-info">
                Total
                <br />
                $10,50,000
              </Grid>
              <Grid className="appointment-divider">|</Grid>
            </Grid>

            <Grid className="appointment-item">
              <Grid>
                <Typography className="appointment-count bg-blue-color">
                  00
                </Typography>
              </Grid>
              <Grid className="appointment-info">
                Total
                <br />
                $100000
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
