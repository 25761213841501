import AxiosInstance from "./axios";

// Get tokenization key to generate CollectJS script
export const getTokenizationKey = async (Company_Id) => {
  try {
    const response = await AxiosInstance.get(
      `nmi/nmi_public_key/${Company_Id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// add card details
export const addCardDetails = async (payload) => {
  try {
    const response = await AxiosInstance.post(`nmi/add-customer-card`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
