import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
} from "../../../components/MuiTable/index.js";
import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import CancelIcon from "@mui/icons-material/Close";
import moment from "moment";
import InputText from "../../../components/InputFields/InputText.js";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { Circles } from "react-loader-spinner";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import AxiosInstance from "../../AxiosInstance.js";

const NmiKeys = ({ modelOpen, setModelOpen, item, getAllData }) => {
  const baseUrl = process.env.REACT_APP_BASE_API;
  // const [modelOpen, setModelOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [nmiId, setNmiId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teamSize, setTeamSize] = useState([]);
  const [countData, setCountData] = useState(0);
  const [loader, setLoader] = useState(true);
  const [NmiData, setNmiData] = useState([]);
  const [inputValue2, setInputValue2] = useState("");

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`${baseUrl}/nmikey`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
        },
      });
      setTeamSize(res.data.data);
      setCountData(res.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search,item, getAllData ]);
  const handleSubmit = async (values, resetForm) => {
    try {
      let res;

      // Check if either item.NmiKeyId or nmiId exists
      if (item?.NmiKeyId || nmiId) {
        // Perform PUT request
        res = await AxiosInstance.put(
          `${baseUrl}/nmikey/${nmiId || item?.NmiKeyId}`,
          values
        );
        if (res.data.statusCode === 200) {
          setTimeout(() => {
            toast.success(res.data.message);
            resetForm();
          }, 500);
        } else {
          setTimeout(() => {
          toast.success(res.data.message);
        }, 500);
        }

      } else {
        // Perform POST request
        res = await AxiosInstance.post(`${baseUrl}/nmikey`, values);
        if (res.data.statusCode === 200) {
          setTimeout(() => {
            toast.success(res.data.message);
            resetForm();
          }, 500);
        } else {
          setTimeout(() => {
          toast.success(res.data.message);
        }, 500);
        }
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    } finally {
      getAllData();
      getData();
      setLoader(false);
      setModelOpen(false);
    }
  };

  const handleEditClick = (items) => {
    setSelectedPlan(items);
    setNmiId(items.NmiKeyId);
    setModelOpen(true);
  };

  const handleDelete = (NmiKeyId) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(
            `${baseUrl}/nmikey/${NmiKeyId}`
          );
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            setTimeout(() => {
            toast.error(response.data.message);
          }, 500);
          }
        } catch (error) {
          console.error("Error:", error);
          setTimeout(() => {
          toast.error(error.message);
        }, 500);
        }
      }
    });
  };

  const CollapseData = ({ data }) => {
    return (
      <div className="d-flex gap-4">
        <div className="card col-8">
          <div
            className="card-body w-100"
            style={{ backgroundColor: "#D8E7EE" }}
          >
            <div className="d-flex w-100 flex-row justify-content-between gap-2">
              <p
                style={{
                  margin: 0,
                  flexShrink: 0,
                  padding: "0.8rem 0.8rem",
                  width: "20%",
                  marginBottom: "7px",
                  backgroundColor: "white",
                  borderRadius: "0.25rem",
                }}
              >
                Security Key:
              </p>
              <span
                className="text-blue-color"
                style={{
                  backgroundColor: "white",
                  padding: "0.8rem 0.8rem",
                  borderRadius: "0.25rem",
                  width: "100%",
                  marginBottom: "7px",
                }}
              >
                {data?.SecurityKey}
              </span>
            </div>
          </div>
          <div
            className="card-body w-100"
            style={{ backgroundColor: "#D8E7EE" }}
          >
            <div className="d-flex w-100 flex-row justify-content-between gap-2">
              <p
                style={{
                  margin: 0,
                  flexShrink: 0,
                  padding: "0.8rem 0.8rem",
                  width: "20%",
                  marginBottom: "7px",
                  backgroundColor: "white",
                  borderRadius: "0.25rem",
                }}
              >
                Public Key:
              </p>
              <span
                className="text-blue-color"
                style={{
                  backgroundColor: "white",
                  padding: "0.8rem 0.8rem",
                  borderRadius: "0.25rem",
                  width: "100%",
                  marginBottom: "7px",
                }}
              >
                {data?.PublicKey}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const cellData = teamSize.map((item, index) => ({
    key: item.CompanyId,
    value: [
      page * rowsPerPage + index + 1,
      item.CompanyName,
      moment(item.createdAt).format("DD/MM/YYYY"),
      <>
        <img
          src={Edit}
          alt="Edit"
          onClick={(e) => {
            e.stopPropagation();
            handleEditClick(item);
          }}
        />
        <img
          className="mx-1"
          src={Delete}
          alt="Delete"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(item.NmiKeyId);
          }}
        />
      </>,
    ],
    component: item,
  }));

  const getNmiData = async () => {
    try {
      const response = await AxiosInstance.get(`${baseUrl}/company/dropdown`);
      setNmiData(response.data.data);
    } catch (error) {
      console.error("Error fetching Nmi data:", error);
    }
  };

  useEffect(() => {
    getNmiData();
  }, [item, getAllData]);

  return (
    <>
      {/* <div className="justify-content-center align-items-center mb-3 mt-5">
        <div className="d-flex justify-content-end mb-2">
          <Button
            style={{ color: "#fff" }}
            onClick={() => {
              setSelectedPlan(null);
              setModelOpen(true);
              setNmiId(null);
            }}
            className="text-capitalize bg-blue-color"
          >
            Add Key
          </Button>
        </div>
        <Card style={{ borderRadius: "20px", border: "2px solid #063164" }}>
          <CardHeader
            className="d-flex justify-content-between align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid #063164",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="plan text-light">NMI Keys</h5>
            <div className="plansearch d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparent", color: "#fff" }}
              />
            </div>
          </CardHeader>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <Circles
                height="50"
                width="50"
                color="#063164"
                ariaLabel="circles-loading"
                visible={loader}
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={["Sr No", "Company Name", "Created At", "Action"]}
                cellData={cellData}
                CollapseComponent={(data) => CollapseData(data)}
                isCollapse={true}
                page={page}
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color"
            style={{
              borderTop: "2px solid #063164",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
      </div> */}
      <Dialog
        fullWidth
        open={modelOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setModelOpen(false);
          }
        }}
      >
        <DialogTitle className="text-blue-color">
          <div className="d-flex justify-content-between">
            <div>{"NMI Key Form"}</div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setModelOpen(false)}
            >
              <CancelIcon />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              CompanyId: selectedPlan?.CompanyId || item?.companyId || "",
              CompanyName: selectedPlan?.CompanyName || item?.companyName || "",
              SecurityKey: selectedPlan?.SecurityKey || item?.SecurityKey || "",
              PublicKey: selectedPlan?.PublicKey || item?.PublicKey || "",
            }}
            validationSchema={Yup.object().shape({
              SecurityKey: Yup.string().required("Required"),
              // PublicKey: Yup.string().required("Required"),
              CompanyId: Yup.string().required("Required"),
            })}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setValues,
            }) => (
              <Form>
                <div>
                  <div className="mt-3 mb-4">
                    <FormControl fullWidth>
                      <Autocomplete
                        className="text-blue-color"
                        options={NmiData || []}
                        getOptionLabel={(option) => option.companyName || ""}
                        value={
                          NmiData.find(
                            (ind) => ind.companyId === values.CompanyId
                          ) || null
                        }
                        disabled={item?.companyId}
                        onChange={(_, newValue) => {
                          setFieldValue(
                            "CompanyId",
                            newValue ? newValue.companyId : ""
                          );
                          setFieldValue(
                            "CompanyName",
                            newValue ? newValue.companyName : ""
                          );
                        }}
                        onInputChange={(_, newInputValue) => {
                          setInputValue2(newInputValue);
                        }}
                        renderInput={(params) => (
                          <InputText
                            {...params}
                            label="Select Company *"
                            name="CompanyId"
                            size="small"
                            onBlur={handleBlur}
                            onKeyDown={(event) => {
                              if (
                                event.key === "Enter" &&
                                !NmiData.some(
                                  (ind) =>
                                    ind.companyName.toLowerCase() ===
                                    inputValue2.toLowerCase()
                                )
                              ) {
                              }
                            }}
                          />
                        )}
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            option.companyName
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          );
                        }}
                        noOptionsText="No matching company"
                      />
                    </FormControl>

                    {touched.CompanyId && errors.CompanyId ? (
                      <div className="text-danger field-error">
                        {errors.CompanyId}
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-3 mb-4 superadmin-company">
                    <InputText
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="Add Security Key... *"
                      label="Security Key*"
                      name="SecurityKey"
                      value={values.SecurityKey}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.SecurityKey && errors.SecurityKey ? (
                      <div className="text-danger">{errors.SecurityKey}</div>
                    ) : null}
                  </div>
                  <div className="mt-3 mb-4 superadmin-company">
                    <InputText
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="Add Public Key... *"
                      label="Public Key *"
                      name="PublicKey"
                      value={values.PublicKey}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.PublicKey && errors.PublicKey ? (
                      <div className="text-danger">{errors.PublicKey}</div>
                    ) : null}
                  </div>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {loader ? (
                      <div className="d-flex justify-content-center">
                        <Circles
                          height="10"
                          width="20"
                          color="#063164"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          visible={loader}
                        />
                      </div>
                    ) : (
                      <Button
                        className="mt-3 text-capitalize bg-blue-color"
                        type="submit"
                        style={{ color: "white" }}
                      >
                        {item?.NmiKeyId || nmiId ? "Update" : "Add"}
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Toaster />
    </>
  );
};

export default NmiKeys;
