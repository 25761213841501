import React from "react";
import { postFile } from "../../../../components/Files/Functions";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
} from "@mui/material";
import { Form, Formik } from "formik";
import InputDropdown from "../../../../components/InputFields/InputDropdown";
import InputText from "../../../../components/InputFields/InputText";
import { SingleFileUpload } from "../../../../components/Files";
import { DisplayImage } from "../../../../components/Files/DisplayFiles";
import AxiosInstance from "../../../AxiosInstance";
import toast from "react-hot-toast";
import * as Yup from "yup";

const AddItems = ({
  modelOpen,
  setModelOpen,
  setSelectedProductAndService,
  selectedProductAndService,
  productsAndService,
  setShowUnitsSection,
  setShowHoursSection,
  setSelectedUnitsAndHours,
  showHoursSection,
  showUnitsSection,
  selectedProduct,
  selectedUnitsAndHours,
  UnitsAndHours,
  selectedAdminId,
  CompanyId,
  getData,
  showSquaresSection,
  setShowSquaresSection,
  inputValue,
  handleSelectChange,
  index,
  setNew = false,
}) => {
  return (
    <Dialog fullWidth open={modelOpen} onClose={() => setModelOpen(false)}>
      <DialogTitle>{"Products & Service Form"}</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            Type: selectedProduct ? selectedProduct.Type : "",
            Name: selectedProduct
              ? selectedProduct.Name
              : inputValue
              ? inputValue
              : "",
            Description: selectedProduct ? selectedProduct.Description : "",
            Unit: selectedProduct ? selectedProduct.Unit : "",
            CostPerUnit: selectedProduct ? selectedProduct.CostPerUnit : "",
            Hour: selectedProduct ? selectedProduct.Hour : "",
            CostPerHour: selectedProduct ? selectedProduct.CostPerHour : "",
            Square: selectedProduct ? selectedProduct.Square : "",
            CostPerSquare: selectedProduct ? selectedProduct.CostPerSquare : "",
            Attachment: selectedProduct ? selectedProduct.Attachment : "",
            UnitsAndHoursType: selectedProduct
              ? selectedProduct.Unit && selectedProduct.CostPerUnit
                ? "Unit"
                : selectedProduct.Square && selectedProduct.CostPerSquare
                ? "Sq. Ft."
                : selectedProduct.Hour && selectedProduct.CostPerHour
                ? "Hour"
                : ""
              : "",
          }}
          validationSchema={Yup.object().shape({
            Type: Yup.string().required("Type is required"),
            Name: Yup.string().required("Name is required"),
            Description: Yup.string().required("Description is required"),
            CostPerUnit:
              showUnitsSection &&
              Yup.number().required("Cost per Unit is required"),
            CostPerHour:
              showHoursSection &&
              Yup.number().required("Cost per Hour is required"),
            CostPerSquare:
              showSquaresSection &&
              Yup.number().required("Cost per square foot is required"),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (!values.UnitsAndHoursType) {
              return;
            }

            try {
              if (values.Attachment) {
                const imageForm = new FormData();
                imageForm.append("files", imageForm);
                const image = await postFile();
                values["Attachment"] = image;
              }
              values["companyId"] = CompanyId || " ";
              const object = {
                Type: values.Type,
                Name: values.Name,
                Description: values.Description,
                Attachment: values.Attachment,
                companyId: CompanyId,
                ...(values.UnitsAndHoursType === "Unit"
                  ? {
                      CostPerUnit: values.CostPerUnit,
                      Unit: values.Unit || 1,
                      CostPerSquare: null,
                      Square: null,
                      CostPerHour: null,
                      Hour: null,
                    }
                  : values.UnitsAndHoursType === "Sq. Ft."
                  ? {
                      CostPerSquare: values.CostPerSquare,
                      Square: values.Square || 1,
                      CostPerHour: null,
                      Hour: null,
                      CostPerUnit: null,
                      Unit: null,
                    }
                  : {
                      CostPerHour: values.CostPerHour,
                      Hour: values.Hour || 1,
                      CostPerUnit: null,
                      Unit: null,
                      CostPerSquare: null,
                      Square: null,
                    }),
              };

              let res;
              if (!selectedProduct) {
                res = await AxiosInstance.post(`/product`, object);
                if (res.data.statusCode === 200) {
                  if (setNew) {
                    handleSelectChange(index, res.data.data);
                  }
                }
              } else {
                res = await AxiosInstance.put(
                  `/product/product/${selectedAdminId}`,
                  object
                );
              }

              if (res.data.statusCode === 200) {
                setModelOpen(false);
                getData();
                setSelectedUnitsAndHours("");
                setTimeout(() => {
                toast.success(res.data.message);
              }, 500);
                resetForm(values);
              } else if (res.data.statusCode === 201) {
                getData();
                setSelectedUnitsAndHours("");
                setTimeout(() => {
                toast.error(res.data.message);
              }, 500);
              } else {
                setTimeout(() => {
                toast.error(res.data.message || "An unexpected error occurred");
              }, 500);
              }
            } catch (error) {
              console.error("Submission error:", error);
              toast.error(
                error.response?.data?.message ||
                  error.message ||
                  "An error occurred"
              );
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <Form>
              <div className="form-wrap">
                <FormControl fullWidth>
                  <InputDropdown
                    options={productsAndService}
                    value={selectedProductAndService || null}
                    onChange={(_, newValue) => {
                      setSelectedProductAndService(newValue);
                      handleChange({
                        target: {
                          name: "Type",
                          value: newValue ? newValue.Type : "",
                        },
                      });
                    }}
                    label="Type"
                    inputValue={values?.Type}
                    onTextFieldChange={handleChange}
                    name="Type"
                    onBlur={handleBlur}
                    type="text"
                    getOptionLabel={(option) => option.Type || ""}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.Type.toLowerCase().includes(
                          state.inputValue.toLowerCase()
                        )
                      );
                    }}
                    error={touched?.Type && Boolean(errors?.Type)}
                    helperText={touched?.Type && errors?.Type}
                  />
                </FormControl>
              </div>
              <div>
                <InputText
                  value={values.Name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="Name"
                  label="Name"
                  type="text"
                  className="text-blue-color w-100 m-0 mb-4"
                  error={touched?.Name && Boolean(errors?.Name)}
                  helperText={touched?.Name && errors?.Name}
                />
              </div>
              <div>
                <FormGroup>
                  <InputText
                    id="exampleText"
                    placeholder="Description"
                    name="Description"
                    label="Description"
                    type="textarea"
                    value={values.Description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="text-blue-color w-100 m-0 mb-4"
                    style={{ marginTop: 0 }}
                    error={touched?.Description && Boolean(errors?.Description)}
                    helperText={touched?.Description && errors?.Description}
                  />
                </FormGroup>
              </div>
              <div>
                <FormControl fullWidth required>
                  <InputDropdown
                    inputValue={values?.UnitsAndHoursType}
                    onTextFieldChange={handleChange}
                    options={UnitsAndHours}
                    value={selectedUnitsAndHours || null}
                    onChange={(_, newValue) => {
                      setSelectedUnitsAndHours(newValue);
                      setShowUnitsSection(newValue?.Type === "Unit");
                      setShowHoursSection(newValue?.Type === "Hour");
                      setShowSquaresSection(newValue?.Type === "Sq. Ft.");
                      handleChange({
                        target: {
                          name: "UnitsAndHoursType",
                          value: newValue ? newValue.Type : "",
                        },
                      });
                    }}
                    label="Cost Type"
                    name="UnitsAndHoursType"
                    getOptionLabel={(option) => option.Type || ""}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.Type.toLowerCase().includes(
                          state.inputValue.toLowerCase()
                        )
                      );
                    }}
                    error={
                      touched?.UnitsAndHoursType &&
                      (!selectedUnitsAndHours ||
                        Boolean(errors?.UnitsAndHoursType))
                    }
                    helperText={
                      touched?.UnitsAndHoursType && !selectedUnitsAndHours
                        ? "This field is required"
                        : touched?.UnitsAndHoursType &&
                          errors?.UnitsAndHoursType
                        ? errors.UnitsAndHoursType
                        : ""
                    }
                  />
                </FormControl>
              </div>

              {showUnitsSection && (
              <div className="d-flex w-100 gap-2">
                  <InputText
                    value={values.CostPerUnit}
                    onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numeric values including decimals
                      if (/^\d*\.?\d*$/.test(value)) {
                        // Use your original handleChange logic
                        handleChange({ target: { name: "CostPerUnit", value: Math.max(0, Number(value)) } });
                      }
                    }}
                    name="CostPerUnit"
                    placeholder="$0.00"
                    label="Cost"
                    type="text"
                    className="w-100"
                    error={touched?.CostPerUnit && Boolean(errors?.CostPerUnit)}
                    helperText={touched?.CostPerUnit && errors?.CostPerUnit}
                  />
                </div>
              )}

              {showHoursSection && (
                //2529 Sq. Ft. lable
                <div className="d-flex gap-2 w-100">
                  <InputText
                    value={values.CostPerHour}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numeric values including decimals
                      if (/^\d*\.?\d*$/.test(value)) {
                        // Use your original handleChange logic
                        handleChange({ target: { name: "CostPerHour", value: Math.max(0, Number(value)) } });
                      }
                    }}
                    name="CostPerHour"
                    placeholder="$0.00"
                    label="Cost"
                    type="text"
                    className="w-100"
                    error={touched.CostPerHour && Boolean(errors.CostPerHour)}
                    helperText={touched.CostPerHour && errors.CostPerHour}
                  />
                </div>
              )}
              {showSquaresSection && (
                <div className="d-flex gap-2 w-100">
                  <InputText
                    value={values.CostPerSquare}
                    onBlur={handleBlur}
                    // onChange={handleChange}
                    // onChange={(e) => {
                    //   let value = e.target.value;
                    //   // Ensure value is a number and non-negative
                    //   value = Math.max(0, Number(value));
                    //   handleChange({ target: { name: "CostPerSquare", value } });
                    // }}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only numeric values including decimals
                      if (/^\d*\.?\d*$/.test(value)) {
                        // Use your original handleChange logic
                        handleChange({ target: { name: "CostPerSquare", value: Math.max(0, Number(value)) } });
                      }
                    }}
                    name="CostPerSquare"
                    placeholder="$0.00"
                    label="Cost"
                    type="text"
                    className="w-100"
                    error={
                      touched.CostPerSquare && Boolean(errors.CostPerSquare)
                    }
                    helperText={touched.CostPerSquare && errors.CostPerSquare}
                  />
                </div>
              )}
              <SingleFileUpload
                file={values.Attachment}
                setFile={(value) => setFieldValue("Attachment", value)}
              />
              <DisplayImage
                files={!values.Attachment ? [] : [values.Attachment]}
                setFiles={(value) => setFieldValue("Attachment", value[0])}
                IsDeleted={true}
              />
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  className="mt-3 text-blue-color border-blue-color"
                  onClick={() => setModelOpen(false)}
                  style={{ border: "1px solid" }}
                >
                  Cancel
                </Button>
                <Button
                  className="mt-3 bg-button-blue-color text-white-color"
                  type="submit"
                >
                  {selectedProduct ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddItems;
