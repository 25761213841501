import React, { useState, useEffect } from "react";
import { Menu, MenuItem } from "@mui/material";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
} from "../../../components/MuiTable/index.js";
import { Button } from "reactstrap";
import IconButton from "@mui/material/IconButton";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { handleAuth } from "../../../components/Login/Auth.js";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.js";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import sendSwal from "../../../components/Swal/sendSwal.js";

const WorkerQuotes = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [quotesData, setQuotesData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const [companyId, setCompanyId] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedQuoteId, setSelectedQuoteId] = React.useState(null);

  const handleActionClick = (event, quoteId) => {
    setAnchorEl(event.currentTarget);
    setSelectedQuoteId(quoteId);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const fetchTokenData = async () => {
    if (!companyId) {
      try {
        const baseUrl = process.env.REACT_APP_BASE_API;
        const token =
          localStorage.getItem("adminToken") ||
          localStorage.getItem("workerToken");

        if (!token) {
          console.error("Token not found in localStorage");
          return;
        }
        const res = await AxiosInstance.post(`${baseUrl}/company/token_data`, {
          token,
        });
        if (res.data) {
          setCompanyId(res.data.data.companyId);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchTokenData();
  }, []);

  const [staffData, setStaffData] = useState(undefined);
  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        const response = await AxiosInstance.get(
          `${baseUrl}/worker/get/${localStorage.getItem("worker_id")}`
        );
        setStaffData(response.data.data.permissions);
      } catch (error) {}
    };
    if (location.pathname.includes("/staff-member")) {
      fetchStaffData();
    }
  }, []);

  const getData = async () => {
    if (companyId) {
      try {
        const res = await AxiosInstance.get(`/quote/get_quotes/${companyId}`, {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        });
        setQuotesData(res.data.data);
        setCountData(res.data.totalCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search, companyId]);

  const handleEditClick = (id) => {
    navigate(`/staff-member/add-quotes`, {
      state: {
        id,
        navigats: [...location.state.navigats, "/add-quotes"],
      },
    });
  };

  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`${baseUrl}/quote/${id}`,
            { data: { DeleteReason: deleteReason } 
          });
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            toast.error("", response.data.message, "error");
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
        }
      } else {
        toast.success("Quote is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const cellData = quotesData?.map((item, index) => {
    return staffData?.Quotes?.ViewCreateAndEdit ||
      staffData?.Quotes?.ViewCreateEditAndDelete
      ? {
          key: item.QuoteId,
          value: [
            page * rowsPerPage + index + 1,
            `${item?.customer?.FirstName} ${item?.customer?.LastName}`,
            <>
              #{item?.QuoteNumber}
              <br />
              {item?.Title}
            </>,
            `${item?.location?.Address || ""} ${item?.location?.City || ""} ${
              item?.location?.State || ""
            } ${item?.location?.Country || ""} ${item?.location?.Zip || ""}`,
            moment(item.updatedAt).format("MM-DD-YYYY"),
            <b
              style={{
                color:
                  item.status === "Awaiting Response"
                    ? "orange"
                    : item.status === "Approved"
                    ? "#58cc58"
                    : "",
              }}
            >
              {item.status}
            </b>,
            `${item?.Total}`,
            <>
              {/* <IconButton>
                <MoreHorizIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleActionClick(e, item.QuoteId);
                  }}
                />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {staffData?.Quotes?.ViewCreateAndEdit || staffData?.Quotes?.ViewCreateEditAndDelete ? (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(item.QuoteId);
                      }}
                    >
                      <img src={Edit} />{" "}
                      <p className="mb-0 text-blue-color mx-2">Edit</p>
                    </MenuItem>
                  ) : null}
                  {staffData?.Quotes?.ViewCreateEditAndDelete ? (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item.QuoteId);
                      }}
                    >
                      <img src={Delete} />{" "}
                      <p className="mb-0 text-blue-color mx-2">Delete</p>
                    </MenuItem>
                  ) : null}
                </Menu>
              </IconButton> */}
              {staffData?.Quotes?.ViewCreateAndEdit ||
              staffData?.Quotes?.ViewCreateEditAndDelete ? (
                <img
                  src={Edit}
                  alt="edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick(item.QuoteId);
                  }}
                />
              ) : null}
              {staffData?.Quotes?.ViewCreateEditAndDelete ? (
                <img
                  className="mx-1"
                  alt="delete"
                  src={Delete}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(item.QuoteId);
                  }}
                />
              ) : null}
            </>,
          ],
        }
      : {
          key: item.QuoteId,
          value: [
            page * rowsPerPage + index + 1,
            `${item?.customer?.FirstName} ${item?.customer?.LastName}`,
            <>
              #{item?.QuoteNumber}
              <br />
              {item?.Title}
            </>,
            `${item?.location?.Address || ""} ${item?.location?.City || ""} ${
              item?.location?.State || ""
            } ${item?.location?.Country || ""} ${item?.location?.Zip || ""}`,
            moment(item.updatedAt).format("DD/MM/YYYY"),
            <b
              style={{
                color:
                  item.status === "Awaiting Response"
                    ? "orange"
                    : item.status === "Approved"
                    ? "#58cc58"
                    : "",
              }}
            >
              {item.status}
            </b>,
            `${item?.Total}`,
          ],
        };
  });

  const collapseData = quotesData?.map((item) => ({
    createdAt: item.createdAt || "No details provided",
  }));

  return (
    <>
      <div className="justify-content-center align-items-center mb-3">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h3 className="text-blue-color" style={{ fontWeight: 700 }}>
            Quotes
          </h3>
          {(staffData?.Quotes?.ViewCreateAndEdit ||
            staffData?.Quotes?.ViewCreateEditAndDelete) && (
            <Button
              onClick={() => {
                navigate(`/staff-member/add-quotes`, {
                  state: {
                    navigats: [...location.state.navigats, "/add-quotes"],
                  },
                });
              }}
              className="text-capitalize bg-button-blue-color text-white-color"
            >
              Add Quotes
            </Button>
          )}
        </div>
        <Card className="border-blue-color"
          style={{
            borderRadius: "20px",
            border: "1px solid ",
            padding: 0,
          }}
        >
          <CardHeader
            className="d-flex border-blue-color justify-content-between align-items-center table-header border-blue-color bg-blue-color"
            style={{
              borderBottom: "1px solid ",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="quot text-light">Quotes List</h5>
            <div className=" quotesearch d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparant", color: "white" }}
              />
            </div>
          </CardHeader>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <Circles
                height="50"
                width="50"
                color="#063164"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={
                  staffData?.Quotes?.ViewCreateAndEdit ||
                  staffData?.Quotes?.ViewCreateEditAndDelete
                    ? [
                        "Sr No.",
                        "Customer Name ",
                        "Quote Number",
                        "Property",
                        "Created At",
                        "Status",
                        "Total",
                        "Action",
                      ]
                    : [
                        "Sr No.",
                        "Customer Name ",
                        "Quote Number",
                        "Property",
                        "Created At",
                        "Status",
                        "Total",
                      ]
                }
                cellData={cellData}
                collapseData={collapseData}
                isCollapse={false}
                page={page}
                isNavigate={true}
                navigatePath={`/staff-member/worker-quotes-details`}
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color border-blue-color border-blue-color"
            style={{
              borderTop: "1px solid ",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
      </div>
      <Toaster />
    </>
  );
};

export default WorkerQuotes;
