import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
} from "@mui/material";
import { Button } from "reactstrap";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
} from "../../../components/MuiTable/index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import { useLocation, useParams } from "react-router-dom";
import "./style.css";
import { Card, CardBody, CardFooter, CardHeader, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import moment from "moment";
import AxiosInstance from "../../AxiosInstance.js";
import CancelIcon from "@mui/icons-material/Close";
import { handleAuth } from "../../../components/Login/Auth.js";
import InputText from "../../../components/InputFields/InputText.js";
import { postFile } from "../../../components/Files/Functions";
import sendSwal from "../../../components/Swal/sendSwal.js";

const ProductAndService = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [modelOpen, setModelOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [productAndService, setProductAndService] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const UnitsAndHours = [
    { Type: "Unit" },
    { Type: "Hour" },
    { Type: "Sq. Ft." },
  ];
  const [selectedUnitsAndHours, setSelectedUnitsAndHours] = useState(null);
  const [showHoursSection, setShowHoursSection] = useState(false);
  const [showUnitsSection, setShowUnitsSection] = useState(false);
  const [showSquaresSection, setShowSquaresSection] = useState(false);

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`${baseUrl}/product`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
        },
      });
      setProductAndService(res.data.data);
      setCountData(res.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);

  // const handleSubmit = async (values) => {
  //   try {
  //     values["superAdminId"] = localStorage.getItem("admin_id") || "";
  //     let res;
  //     if (!selectedAdminId) {
  //       res = await AxiosInstance.post(`${baseUrl}/product`, {
  //         ...values,
  //       });
  //     } else {
  //       res = await AxiosInstance.put(
  //         `${baseUrl}/product/${selectedAdminId}`,
  //         values
  //       );
  //     }

  //     if (res.data.statusCode === 200) {
  //       setModelOpen(false);
  //       getData();
  //       setTimeout(() => {
  //         toast.success(res.data.message);
  //       }, 500);
  //     } else if (res.data.statusCode === 201) {
  //       getData();
  //       setTimeout(() => {
  //         toast.error(res.data.message);
  //       }, 500);
  //     } else {
  //       toast.error(res.data.message);
  //     }
  //   } catch (error) {
  //     toast.error(error.message || "An error occurred");
  //   }
  // };

  const handleEditClick = (item) => {
    setSelectedProduct(item);
    setSelectedAdminId(item.ProductId);
    setSelectedProductAndService({ Type: item.Type });
    setSelectedUnitsAndHours({
      Type:
        item.Unit && item.CostPerUnit
          ? "Unit"
          : item.Square && item.CostPerSquare
          ? "Sq. Ft."
          : "Hour",
    });
    if (item.Unit && item.CostPerUnit) {
      setShowUnitsSection(true);
      setShowSquaresSection(false);
      setShowHoursSection(false);
    }
    if (item.Hour && item.CostPerHour) {
      setShowHoursSection(true);
      setShowUnitsSection(false);
      setShowSquaresSection(false);
    }
    if (item.Square && item.CostPerSquare) {
      setShowSquaresSection(true);
      setShowHoursSection(false);
      setShowUnitsSection(false);
    }
    setModelOpen(true);
  };

  // Delete
  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(
            `${baseUrl}/product/${id}`
          );
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            setTimeout(() => {
            toast.error("", response.data.message, "error");
          }, 500);
          }
        } catch (error) {
          console.error("Error:", error);
          setTimeout(() => {
          toast.error(error);
        }, 500);
        }
      } else {
        setTimeout(() => {
        toast.success("Products & Services is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }, 500);
      }
    });
  };

  const CollapseData = ({ data }) => {
    return (
      <div className="d-flex gap-4">
        <div className="card col-8" style={{ borderRadius: "12px" }}>
          <div
            className="card-body w-100 bg-orange-color"
            style={{ borderRadius: "12px" }}
          >
            <div className="d-flex w-100 flex-row justify-content-between gap-2">
              <p className="text-white-color">Description: </p>
              <span
                style={{
                  backgroundColor: "white",
                  padding: "0.2rem 0.5rem",
                  borderRadius: "0.25rem",
                  width: "100%",
                  marginBottom: "7px",
                }}
                className="text-blue-color"
              >
                {data.Description}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const cellData = productAndService?.map((item, index) => {
    // Determine which field and value to display with a label
    let displayValue = "-";
    if (item.Hour) {
      displayValue = `Hour : ${item.Hour}`;
    } else if (item.Unit) {
      displayValue = `Unit : ${item.Unit}`;
    } else if (item.Square) {
      displayValue = `Sq. Ft. : ${item.Square}`;
    }

    return {
      key: item.ProductId,
      value: [
        page * rowsPerPage + index + 1,
        item.Type ? item.Type : "-",
        item.Name ? item.Name : "-",
        displayValue,
        item.CostPerHour || item.CostPerUnit || item.CostPerSquare,
        moment(item.updatedAt).format("MM-DD-YYYY"),
        <>
          <img
            src={Edit}
            alt="edit"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item);
            }}
          />
          <img
            className="mx-1"
            alt="delete"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.ProductId);
            }}
          />
        </>,
      ],
      component: item,
    };
  });

  const productsAndService = [{ Type: "Service" }, { Type: "Product" }];

  const [selectedProductAndService, setSelectedProductAndService] =
    useState(null);

  const { companyName } = useParams();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const toggle = () => setIsOpenDropDown(!isOpenDropDown);

  return (
    <>
      <div className="d-flex">
        <div className="col-12 product-services-content">
          <h2 className="text-blue-color" style={{ fontWeight: 700 }}>
            Products & Services
          </h2>
          <p className="text-blue-color">
            By keeping your product and service information updated, you can
            more easily create accurate quotes, jobs, and invoices.
          </p>
          <div className=" justify-content-between align-items-center">
            <div className="d-flex justify-content-end mb-2 align-items-center">
              <Button
                style={{ color: "#fff" }}
                onClick={() => {
                  setModelOpen(true);
                  setSelectedProduct(null);
                  setSelectedProductAndService(null);
                  setSelectedAdminId(null);
                }}
                className="text-capitalize bg-button-blue-color addproductbutton"
              >
                Add Products & Service
              </Button>
            </div>
            <Card
              className="border-blue-color"
              style={{
                borderRadius: "20px",
                border: "2px solid ",
                padding: 0,
              }}
            >
              <CardHeader
                className="d-flex border-blue-color justify-content-between align-items-center table-header bg-blue-color"
                style={{
                  borderBottom: "2px solid ",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <h5 className="produc text-light">Product & Service</h5>
                <div className="productservicesearch d-flex">
                  <JobberSearch
                    search={search}
                    setSearch={setSearch}
                    style={{ background: "transparant", color: "white" }}
                  />
                </div>
              </CardHeader>
              {loader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <Circles
                    height="50"
                    width="50"
                    color="#063164"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loader}
                  />
                </div>
              ) : (
                <CardBody style={{ padding: "10px 0px" }}>
                  <JobberTable
                    headerData={[
                      "Sr no",
                      "Type",
                      "Name",
                      "Measurement",
                      "Cost",
                      "Date",
                      "Action",
                    ]}
                    cellData={cellData}
                    CollapseComponent={(data) => CollapseData(data)}
                    isCollapse={true}
                    page={page}
                    isNavigate={false}
                  />
                </CardBody>
              )}
              <CardFooter
                className="bg-orange-color border-blue-color"
                style={{
                  borderTop: "2px solid ",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <JobberPagination
                  totalData={countData}
                  currentData={rowsPerPage}
                  dataPerPage={rowsPerPage}
                  pageItems={[10, 25, 50]}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth
        open={modelOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setModelOpen(false);
          }
        }}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            <div>
              {selectedAdminId
                ? "Edit New Product/ Service"
                : "Add New Product/ Service"}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => setModelOpen(false)}
            >
              <CancelIcon />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              Type: selectedProduct ? selectedProduct.Type : "",
              Name: selectedProduct ? selectedProduct.Name : "",
              Description: selectedProduct ? selectedProduct.Description : "",
              Unit: selectedProduct ? selectedProduct.Unit : "",
              CostPerUnit: selectedProduct ? selectedProduct.CostPerUnit : "",
              Hour: selectedProduct ? selectedProduct.Hour : "",
              CostPerHour: selectedProduct ? selectedProduct.CostPerHour : "",
              Square: selectedProduct ? selectedProduct.Square : "",
              CostPerSquare: selectedProduct
                ? selectedProduct.CostPerSquare
                : "",
              Attachment: selectedProduct ? selectedProduct.Attachment : "",
              UnitsAndHoursType: selectedProduct
                ? selectedProduct.Unit && selectedProduct.CostPerUnit
                  ? "Unit"
                  : selectedProduct.Square && selectedProduct.CostPerSquare
                  ? "Sq. Ft."
                  : "Hour"
                : "",
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              Type: Yup.string().required("Required"),
              Name: Yup.string().required("Required"),
              Description: Yup.string().required("Required"),
            })}
            // onSubmit={(values, { resetForm }) => {
            //   handleSubmit(values);
            //   resetForm(values);
            // }}
            onSubmit={async (values, { resetForm }) => {
              try {
                setLoader(true);
                if (values.Attachment) {
                  const imageForm = new FormData();
                  imageForm.append("files", imageForm);
                  const image = await postFile();
                  values["Attachment"] = image;
                }

                const object = {
                  Type: values.Type,
                  Name: values.Name,
                  Description: values.Description,
                  Attachment: values.Attachment,
                  superAdminId: localStorage.getItem("admin_id") || "", // Add superAdminId

                  ...(values.UnitsAndHoursType === "Unit"
                    ? {
                        CostPerUnit: values.CostPerUnit,
                        Unit: values.Unit || 1,
                        CostPerSquare: null,
                        Square: null,
                        CostPerHour: null,
                        Hour: null,
                      }
                    : values.UnitsAndHoursType === "Sq. Ft."
                    ? {
                        CostPerSquare: values.CostPerSquare,
                        Square: values.Square || 1,
                        CostPerHour: null,
                        Hour: null,
                        CostPerUnit: null,
                        Unit: null,
                      }
                    : {
                        CostPerHour: values.CostPerHour,
                        Hour: values.Hour || 1,
                        CostPerUnit: null,
                        Unit: null,
                        CostPerSquare: null,
                        Square: null,
                      }),
                };

                let res;
                if (!selectedProduct) {
                  res = await AxiosInstance.post(`/product`, object);
                } else {
                  res = await AxiosInstance.put(
                    `/product/${selectedAdminId}`,
                    object
                  );
                }

                if (res.data.statusCode === 200) {
                  setModelOpen(false);
                  getData();
                  toast.success(res.data.message);
                  resetForm(values);
                } else if (res.data.statusCode === 201) {
                  getData();
                  toast.error(res.data.message);
                } else {
                  toast.error(
                    res.data.message || "An unexpected error occurred"
                  );
                }
              } catch (error) {
                console.error("Submission error:", error);
                toast.error(
                  error.response?.data?.message ||
                    error.message ||
                    "An error occurred"
                );
              } finally {
                setLoader(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <div>
                  <div className="form-wrap">
                    <div>
                      <div className="mt-4 ">
                        <FormControl fullWidth>
                          <Autocomplete
                            options={productsAndService}
                            getOptionLabel={(option) => option.Type || ""}
                            value={selectedProductAndService || null}
                            onChange={(_, newValue) => {
                              setSelectedProductAndService(newValue);
                              handleChange({
                                target: {
                                  name: "Type",
                                  value: newValue ? newValue.Type : "",
                                },
                              });
                            }}
                            renderInput={(params) => (
                              <InputText
                                className="text-blue-color border-blue-color"
                                {...params}
                                label="Select Type *"
                                name="Type"
                                size="small"
                                onBlur={handleBlur}
                              />
                            )}
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.Type.toLowerCase().includes(
                                  state.inputValue.toLowerCase()
                                )
                              );
                            }}
                            noOptionsText="No matching Type"
                          />
                        </FormControl>
                        {touched.Type && errors.Type ? (
                          <div className="text-danger field-error">
                            {errors.Type}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="my-4 superadmin-company">
                    <InputText
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="Product Name *"
                      label="Product Name *"
                      name="Name"
                      value={values.Name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="text-blue-color border-blue-color"
                    />
                    {touched.Name && errors.Name ? (
                      <div className="text-danger">{errors.Name}</div>
                    ) : null}
                  </div>

                  <div className="mt-4  ">
                    <FormGroup>
                      <Input
                        id="exampleText"
                        placeholder="Description"
                        name="Description"
                        type="textarea"
                        value={values.Description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="text-blue-color border-blue-color"
                      />
                    </FormGroup>
                    {touched.Description && errors.Description ? (
                      <div className="text-danger">{errors.Description}</div>
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-between my-3 row">
                    <Autocomplete
                      className="text-blue-color border-blue-color"
                      options={UnitsAndHours}
                      getOptionLabel={(option) => option.Type || ""}
                      value={selectedUnitsAndHours || null}
                      onChange={(_, newValue) => {
                        setSelectedUnitsAndHours(newValue);
                        setShowUnitsSection(newValue?.Type === "Unit");
                        setShowHoursSection(newValue?.Type === "Hour");
                        setShowSquaresSection(newValue?.Type === "Sq. Ft.");
                        handleChange({
                          target: {
                            name: "UnitsAndHoursType",
                            value: newValue ? newValue.Type : "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <InputText
                          {...params}
                          label="Select Type *"
                          name="UnitsAndHoursType"
                          size="small"
                          onBlur={handleBlur}
                        />
                      )}
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.Type.toLowerCase().includes(
                            state.inputValue.toLowerCase()
                          )
                        );
                      }}
                      noOptionsText="No matching Type"
                    />
                    {showUnitsSection && (
                      <div className="d-flex w-100 gap-2 pt-3">
                        {/* <InputText
                          value={values.Unit}
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numeric values including decimals
                            if (/^\d*\.?\d*$/.test(value)) {
                              // Use your original handleChange logic
                              handleChange({ target: { name: "Unit", value: Math.max(0, Number(value)) } });
                            }
                          }}
                          name="Unit"
                          placeholder="Unit"
                          label="Unit"
                          type="text"
                          className="w-100"
                          error={touched?.Unit && Boolean(errors?.Unit)}
                          helperText={touched?.Unit && errors?.Unit}
                        /> */}
                        <InputText
                          value={values.CostPerUnit}
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numeric values including decimals
                            if (/^\d*\.?\d*$/.test(value)) {
                              // Use your original handleChange logic
                              handleChange({
                                target: {
                                  name: "CostPerUnit",
                                  value: Math.max(0, Number(value)),
                                },
                              });
                            }
                          }}
                          name="CostPerUnit"
                          placeholder="$0.00"
                          label="Cost"
                          type="text"
                          className="w-100"
                          error={
                            touched?.CostPerUnit && Boolean(errors?.CostPerUnit)
                          }
                          helperText={
                            touched?.CostPerUnit && errors?.CostPerUnit
                          }
                        />
                      </div>
                    )}

                    {showHoursSection && (
                      //2529 Sq. Ft. lable
                      <div className="d-flex gap-2 w-100 pt-3">
                        {/* <InputText
                          value={values.Hour}
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numeric values including decimals
                            if (/^\d*\.?\d*$/.test(value)) {
                              // Use your original handleChange logic
                              handleChange({ target: { name: "Hour", value: Math.max(0, Number(value)) } });
                            }
                          }}
                          name="Hour"
                          placeholder="Hour"
                          label="Hour"
                          type="text"
                          className="w-100"
                          error={touched.Hour && Boolean(errors.Hour)}
                          helperText={touched.Hour && errors.Hour}
                        /> */}
                        <InputText
                          value={values.CostPerHour}
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numeric values including decimals
                            if (/^\d*\.?\d*$/.test(value)) {
                              // Use your original handleChange logic
                              handleChange({
                                target: {
                                  name: "CostPerHour",
                                  value: Math.max(0, Number(value)),
                                },
                              });
                            }
                          }}
                          name="CostPerHour"
                          placeholder="$0.00"
                          label="Cost"
                          type="text"
                          className="w-100"
                          error={
                            touched.CostPerHour && Boolean(errors.CostPerHour)
                          }
                          helperText={touched.CostPerHour && errors.CostPerHour}
                        />
                      </div>
                    )}
                    {showSquaresSection && (
                      <div className="d-flex gap-2 w-100 pt-3">
                        {/* <InputText
                    value={values.Square}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="Square"
                    placeholder="Square"
                    label="Square"
                    type="number"
                    className="w-100"
                    error={touched.Square && Boolean(errors.Square)}
                    helperText={touched.Square && errors.Square}
                  /> */}
                        <InputText
                          value={values.CostPerSquare}
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numeric values including decimals
                            if (/^\d*\.?\d*$/.test(value)) {
                              // Use your original handleChange logic
                              handleChange({
                                target: {
                                  name: "CostPerSquare",
                                  value: Math.max(0, Number(value)),
                                },
                              });
                            }
                          }}
                          name="CostPerSquare"
                          placeholder="$0.00"
                          label="Cost"
                          type="text"
                          className="w-100"
                          error={
                            touched.CostPerSquare &&
                            Boolean(errors.CostPerSquare)
                          }
                          helperText={
                            touched.CostPerSquare && errors.CostPerSquare
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex gap-2 justify-content-end">
                    <Button
                      className="mt-3 text-capitalize border-blue-color text-blue-color"
                      onClick={() => setModelOpen(false)}
                      style={{
                        border: "1px solid ",
                        backgroundColor: "white",
                      }}
                    >
                      Cancel
                    </Button>
                    <div>
                      {loader ? (
                        <div className="d-flex justify-content-center mt-4">
                          <Circles
                            height="20"
                            width="20"
                            color="#063164"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            visible={loader}
                          />
                        </div>
                      ) : (
                        <Button
                          className="mt-3 text-capitalize bg-blue-color"
                          type="submit"
                          style={{ color: "white" }}
                        >
                          {selectedProduct ? "Update" : "Create"}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Toaster />
    </>
  );
};

export default ProductAndService;
