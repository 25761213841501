import React from "react";
import Edit from "../../assets/image/icons/edit.svg";
import Delete from "../../assets/image/icons/delete.svg";
import EditWhite from "../../assets/svg/edit-white.svg";
import DeleteWhite from "../../assets/svg/delete-white.svg";
import { Typography } from "@mui/material";
import { Button, Input, Label } from "reactstrap";
import { Grid } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { FileModal } from "../Files/DisplayFiles";
import { Circles } from "react-loader-spinner";
import { padding } from "@mui/system";
import "./style.css";

const EditIcon = ({ onClick }) => {
  return (
    <img
      src={Edit}
      alt="edit"
      style={{ cursor: "pointer", marginRight: "7px" }}
      onClick={onClick}
    />
  );
};
const EditIconWhite = ({ onClick }) => {
  return (
    <img
      src={EditWhite}
      alt="edit"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    />
  );
};
const DeleteIcone = ({ onClick }) => {
  return (
    <img
      src={Delete}
      alt="edit"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    />
  );
};
const DeleteIconeWhite = ({ onClick }) => {
  return (
    <img
      src={DeleteWhite}
      alt="edit"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    />
  );
};
const LoaderComponent = ({ loader, height, width }) => {
  return (
    <Circles
      height={height}
      width={width}
      color="#063164"
      ariaLabel="circles-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={loader}
      padding={padding}
    />
  );
};

const WhiteLoaderComponent = ({ loader, height, width }) => {
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Circles
        height={height}
        width={width}
        color="#fff"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={loader}
        padding={padding}
      />
    </Grid>
  );
};
const CustomCheckbox = ({ id, name, checked, onChange, error, label }) => {
  return (
    <Grid style={{ display: "flex", gap: "8px" }}>
      <Input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        style={{
          border: error ? "2px solid red" : "1px solid #ccc",
          borderRadius: "4px",
          width: "16px",
          height: "16px",
        }}
      />
      <Label
        className="text-blue-color"
        for={id}
        style={{
          cursor: "pointer",
          color: error ? "red" : "inherit",
          fontWeight: error ? "bold" : "normal",
          marginTop: "2px",
        }}
      >
        {label}
      </Label>
    </Grid>
  );
};

const ProductItem = ({
  item,
  index,
  cdnUrl,
  openFileModal,
  setOpen,
  open,
  file,
}) => {
  return (
    <Grid className="items-contract">
      <Grid style={{ width: "100%" }} className="ScrollDiv">
        <Row className="mb-4 productAndServiceScroll">
          <Col lg={4} xl={4} md={4} sm={4} xs={4}>
            {index === 0 && (
              <Typography
                className="mb-3 productServiceHead text-blue-color"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Product/ Service
              </Typography>
            )}
            <Grid className="d-flex justify-content-between products mb-4">
              <Grid className="mb-3">
                <Typography
                  className="mb-0 Sub-title text-blue-color"
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {item?.Name}
                </Typography>
                <Typography
                  className="mb-0 text-data text-blue-color"
                  style={{
                    fontSize: "14px",
                    whiteSpace: "break-spaces",
                  }}
                >
                  {item?.Description ||
                    "Labor charge for the setup and configuration of network switches for improved connectivity and network management."}
                </Typography>
              </Grid>
            </Grid>
          </Col>

          <Col className="col-2" xl={2} lg={2} md={2} sm={2} xs={2}>
            {index === 0 && (
              <Typography
                className="mb-3 productServiceHead  text-blue-color"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "right",
                }}
              >
                Image
              </Typography>
            )}
            <Typography
              className="mb-0 text-data text-blue-color unit"
              style={{
                fontSize: "14px",
                textAlign: "right",
              }}
            >
              {item?.Attachment ? (
                <img
                  style={{
                    height: "40px",
                    marginBottom: "30px",
                    cursor: "pointer",
                  }}
                  src={`${cdnUrl}/upload/${item?.Attachment}`}
                  onClick={() => openFileModal(item?.Attachment)}
                  alt="Thumbnail"
                />
              ) : (
                "-"
              )}
            </Typography>
          </Col>
          <Col className="col-2" xl={2} lg={2} md={2} sm={2} xs={2}>
            {index === 0 && (
              <Typography
                className="mb-3 productServiceHead  text-blue-color"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "right",
                }}
              >
                Unit/Hour
              </Typography>
            )}
            <Typography
              className="mb-0 text-data text-blue-color unit"
              style={{
                fontSize: "14px",
                textAlign: "right",
              }}
            >
              {item?.Unit || item?.Square || item?.Hour || "-"}
            </Typography>
          </Col>
          <Col className="col-2" xl={2} lg={2} md={2} sm={2} xs={2}>
            {index === 0 && (
              <Typography
                className="mb-3  productServiceHead text-blue-color"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "right",
                }}
              >
                Unit Cost/Hour Cost
              </Typography>
            )}
            <Typography
              className="mb-0 text-data text-blue-color per-unit"
              style={{
                fontSize: "14px",
                textAlign: "right",
              }}
            >
              $
              {item?.CostPerHour
                ? `${new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(item?.CostPerHour)}`
                : item?.CostPerSquare
                ? `${new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(item?.CostPerSquare)}`
                : item?.CostPerUnit
                ? `${new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(item?.CostPerUnit)}`
                : "-"}
            </Typography>
          </Col>

          <Col className="col-2 text-end" lg={2} xl={2} md={2} sm={2} xs={2}>
            {index === 0 && (
              <Typography
                className="mb-3 productServiceHead  text-blue-color totalInput totalPrice_contract totalOfQuoteDes"
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Total
              </Typography>
            )}
            <Typography
              className="mb-0 text-data text-blue-color total-pro"
              style={{ fontSize: "14px" }}
            >
              {`$${
                item?.Total
                  ? new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(item?.Total)
                  : "-"
              }`}
            </Typography>
          </Col>
        </Row>
      </Grid>
      <FileModal open={open} setOpen={setOpen} file={file} />
    </Grid>
  );
};
const OrangeButton = ({ label, ...props }) => {
  return (
    <Button
      {...props}
      className="text-capitalize bg-orange-color text-white-color"
    >
      {label}
    </Button>
  );
};

export {
  EditIcon,
  DeleteIcone,
  DeleteIconeWhite,
  EditIconWhite,
  CustomCheckbox,
  ProductItem,
  LoaderComponent,
  WhiteLoaderComponent,
  OrangeButton,
};
