import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardText } from "reactstrap";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import AxiosInstance from "../../AxiosInstance";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { handleAuth } from "../../../components/Login/Auth";
import "./style.css";
import { Circles } from "react-loader-spinner";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LoaderComponent } from "../../../components/Icon/Index";
import { Grid, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import Contract from "../../../assets/Blue-sidebar-icon/Contract.svg";

function Quotes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [quotesData, setQuotesData] = useState([]);
  const [tokenDecode, setTokenDecode] = useState({});

  const fetchData = async () => {
    setLoader(true);
    try {
      const res = await handleAuth(navigate, location);
      setTokenDecode(res.data);
    } catch (error) {
      console.error("Error fetching token decode data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CompanyId = tokenDecode?.CompanyId;
  const CustomerId = tokenDecode?.CustomerId;

  useEffect(() => {
    if (CompanyId && CustomerId) {
      setLoader(true);
      AxiosInstance.get(`/visits/customer_visits/${CompanyId}/${CustomerId}`)

        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setQuotesData(response?.data?.data);
          } else {
            console.error("Error fetching visits data");
          }
        })
        .catch((error) => {
          console.error("API error:", error);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [CompanyId, CustomerId]);

  const categorizeVisits = (visits) => {
    const today = moment().startOf("day");
    const todayVisits = [];
    const upcomingVisits = [];
    const pastVisits = [];

    visits.forEach((visit) => {
      const visitDate = moment(visit?.StartDate);
      if (visitDate?.isSame(today, "day")) {
        todayVisits?.push(visit);
      } else if (visitDate?.isAfter(today)) {
        upcomingVisits?.push(visit);
      } else {
        pastVisits?.push(visit);
      }
    });

    return {
      todayVisits,
      upcomingVisits,
      pastVisits,
    };
  };

  const { todayVisits, upcomingVisits, pastVisits } =
    categorizeVisits(quotesData);

  const renderVisitCards = (visits) => (
    <Grid className="visit-card-container" style={{ flexDirection: "column" }}>
      {visits.map((quote) => (
        <Card
          style={{ cursor: "pointer" }}
          key={quote._id}
          className="invoice-card mb-2"
          onClick={() =>
            navigate(`/customers/appointment-confirm`, {
              state: {
                id: quote?.VisitId,
                contractId: quote?.ContractId,
                navigats: [...location?.state?.navigats, "/appointment-confirm"],
              },
            })
          }
        >
          <CardHeader className="invoice-card-header text-end customerAppointmentTable">
            <CalendarMonthOutlinedIcon className="invoice-icon" /> Appointment:
            &nbsp;
            {moment(quote?.StartDate).format("MM-DD-YYYY")}
          </CardHeader>
          <CardBody>
            <Grid className="text-end mb-2">
              {quote?.IsConfirm && (
                <Button
                  className="Appoiment text-blue-color"
                  style={{
                    border: "none",
                    background: "#FFCDD2",
                    borderRadius: "20px",
                    fontWeight: 400,
                  }}
                >
                  Confirmed
                </Button>
              )}
            </Grid>
            <CardText className="invoice-card-text text-blue-color">
              <img
                src={Contract}
                alt="img"
                style={{
                  height: "20px",
                  marginRight: "7px",
                  marginLeft: "3px",
                }}
              />
              Contract #{quote?.ContractData?.ContractNumber}
            </CardText>
            <CardText className="invoice-card-text text-blue-color">
              <AccessTimeIcon
                className="map-icon text-blue-color"
                style={{ marginRight: "5px" }}
              />
              {quote?.StartTime
                ? moment(quote?.StartTime, "HH:mm")?.format("hh:mm A")
                : "N/A"}
            </CardText>

            <CardText className="invoice-card-text text-start text-blue-color">
              <MyLocationOutlinedIcon
                className="map-icon"
                style={{ marginRight: "5px" }}
              />
              {quote?.Location?.Address} {quote?.Location?.City} <br />
              {quote?.Location?.State}, {quote?.Location?.Country}
            </CardText>
          </CardBody>
        </Card>
      ))}
    </Grid>
  );

  return (
    <>
      {loader ? (
        <Grid
          className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5"
          style={{ height: "80vh", marginTop: "25%" }}
        >
          <LoaderComponent loader={loader} height="50" width="50" />
        </Grid>
      ) : quotesData?.length > 0 ? (
        <Row className="row appoitmentListUpPast">
          <Typography className="invoice-header mb-4 customerAppointmentTable">
            Your Appointment{" "}
          </Typography>
          {todayVisits?.length > 0 && (
            <Grid
              className="col text-center appointment-status-section "
              style={{ flexDirection: "column" }}
            >
              <Typography className="appointment-status-header mb-3 customerAppointmentAppo heading-five">
                Today{" "}
              </Typography>
              {renderVisitCards(todayVisits)}
            </Grid>
          )}

          {upcomingVisits?.length > 0 && (
            <Grid
              className="col text-center appointment-status-section"
              style={{ flexDirection: "column" }}
            >
              <Typography className="appointment-status-header mb-3 customerAppointmentupco heading-five">
                Upcoming
              </Typography>
              {renderVisitCards(upcomingVisits)}
            </Grid>
          )}

          {pastVisits?.length > 0 && (
            <Grid
              className="col text-center appointment-status-section"
              style={{ flexDirection: "column" }}
            >
              <Typography className="appointment-status-header mb-3 heading-five">
                Past
              </Typography>
              {renderVisitCards(pastVisits)}
            </Grid>
          )}
        </Row>
      ) : (
        <Grid className="text-start">
          <Typography>Appointment Not Available.</Typography>
        </Grid>
      )}
    </>
  );
}

export default Quotes;
