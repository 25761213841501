import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from "@mui/material";
import {
  JobberTable,
  JobberSearch,
  JobberPagination,
} from "../../../components/MuiTable/index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import "./style.css";
import { handleAuth } from "../../../components/Login/Auth.js";
import { Card, CardBody, CardFooter, CardHeader, Label } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import AxiosInstance from "../../AxiosInstance.js";
import DoneIcon from "@mui/icons-material/Done";
import swal from "sweetalert";
import sendSwal from "../../../components/Swal/sendSwal.js";

const Permission = () => {
  useEffect(() => {
    handleAuth(navigate, location);
  }, []);
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyData, setCompanyData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const moment = require('moment');

  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`${baseUrl}/permissionsteps`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
        },
      });
      setCompanyData(res.data?.data);
      setCountData(res.data?.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);

  const handleEditClick = (id) => {
    if (id) {
      navigate("/superadmin/add-permission", {
        state: {
          id,
          navigats: [...location.state.navigats, "/add-permission"],
        },
      });
    }
  };

  // Delete
  const handleDelete = (id) => {
    swal("Are you sure you want to delete?", {
      buttons: ["No", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await AxiosInstance.delete(`/permissionsteps/${id}`);
          if (response.data?.statusCode === 200) {
            setTimeout(() => {
              toast.success(response.data?.message);
            }, 500);
            getData();
          } else {
            toast.error("", response.data?.message, "error");
          }
        } catch (error) {
          toast.error(error.message);
        }
      }
    });
  };

  const CollapseData = ({ data }) => {
    return (
      <div className=" gap-4">
        <div
          className="d-flex row mb-2 my-4"
          style={{
            // border:"1px solid red",
            width: "97%",
            borderRadius: "14px",
            // boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
            boxShadow:
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            paddingLeft: "30px",
            paddingTop: "25px",
            paddingBottom: "25px",
          }}
        >
          {data?.Schedule &&
            Object.entries(data?.Schedule).some(([key, value]) => value) && (
              <div className="col-3">
                <p className="text-blue-color">
                  <b>
                    {Object.entries(data?.Schedule).some(
                      ([key, value]) => value
                    )
                      ? "Schedule"
                      : ""}
                  </b>{" "}
                </p>
                <p className="text-blue-color">
                  {data?.Schedule?.ViewTheirOwnSchedule ? (
                    <>
                      View their own schedule{" "}
                      <DoneIcon
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Schedule?.ViewAndCompleteTheirOwnSchedule ? (
                    <>
                      View and complete their own schedule{" "}
                      <DoneIcon
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Schedule?.EditTheirOwnSchedule ? (
                    <>
                      Edit their own schedule{" "}
                      <DoneIcon
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Schedule?.EditEveryonesSchedule ? (
                    <>
                      Edit everyone's schedule{" "}
                      <DoneIcon
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color ">
                  {data?.Schedule?.EditAndDeleteEveryonesSchedule ? (
                    <>
                      Edit and delete everyone's schedule{" "}
                      <DoneIcon
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Schedule?.EditEveryone ? (
                    <>
                      EditEveryone{" "}
                      <DoneIcon
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color ">
                  {data?.Schedule?.Delete ? (
                    <>
                      Delete{" "}
                      <DoneIcon
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            )}
          {data?.ShowPricing &&
            Object.entries(data?.ShowPricing).some(([key, value]) => value) && (
              <div className="col-3">
                <p className="text-blue-color   ">
                  <b>
                    {Object.entries(data?.ShowPricing).some(
                      ([key, value]) => value
                    )
                      ? "ShowPricing"
                      : ""}
                  </b>{" "}
                </p>
                <p className="text-blue-color mb-0">
                  {data?.ShowPricing
                    ?.AllowsEditingOfQuotesInvoicesAndLineItemsOnJobs ? (
                    <>
                      Allows editing of quotes, invoices, and line items on
                      jobs.
                      <DoneIcon
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            )}
          {data?.TimeTracking &&
            Object.entries(data?.TimeTracking).some(
              ([key, value]) => value
            ) && (
              <div className="col-3">
                <p className="text-blue-color">
                  <b>
                    {Object.entries(data?.TimeTracking).some(
                      ([key, value]) => value
                    )
                      ? "TimeTracking"
                      : ""}
                  </b>{" "}
                </p>
                <p className="text-blue-color">
                  {data?.TimeTracking?.ViewAndRecordTheirOwn ? (
                    <>
                      View and record their own
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.TimeTracking?.ViewRecordAndEditTheirOwn ? (
                    <>
                      View, record, and edit their own
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.TimeTracking?.ViewRecordAndEditEveryones ? (
                    <>
                      View, record, and edit everyone's
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            )}
          {data?.CustomersProperties &&
            Object.entries(data?.CustomersProperties).some(
              ([key, value]) => value
            ) && (
              <div className="col-3">
                <p className="text-blue-color ">
                  <b>
                    {Object.entries(data?.CustomersProperties).some(
                      ([key, value]) => value
                    )
                      ? "CustomersProperties"
                      : ""}
                  </b>{" "}
                </p>
                <p className="text-blue-color">
                  {data?.CustomersProperties?.ViewCustomerNameAndAddressOnly ? (
                    <>
                      View Customer name and address only
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.CustomersProperties
                    ?.ViewFullCustomerAndPropertyInfo ? (
                    <>
                      View full Customer and property info
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.CustomersProperties
                    ?.ViewAndEditFullCustomerAndPropertyInfo ? (
                    <>
                      View and edit full Customer and property info
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color mb-0">
                  {data?.CustomersProperties
                    ?.ViewEditAndDeleteFullCustomerAndPropertyInfo ? (
                    <>
                      View, edit, and delete full Customer and property info
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            )}

          {data?.Notes &&
            Object.entries(data?.Notes).some(([key, value]) => value) && (
              <div className="col-3">
                <p className="text-blue-color">
                  <b>
                    {Object.entries(data?.Notes).some(([key, value]) => value)
                      ? "Notes"
                      : ""}
                  </b>{" "}
                </p>
                <p className="text-blue-color">
                  {data?.Notes?.ViewNotesOnJobsAndVisitsOnly ? (
                    <>
                      View notes on jobs and visits only
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Notes?.ViewAllNotes ? (
                    <>
                      View all notes
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Notes?.ViewAndEditAll ? (
                    <>
                      View and edit all
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Notes?.ViewEditAndDeleteAll ? (
                    <>
                      View, edit, and delete all
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            )}
          {data?.Request &&
            Object.entries(data?.Request).some(([key, value]) => value) && (
              <div className="col-3">
                <p className="text-blue-color ">
                  <b>
                    {Object.entries(data?.Request).some(([key, value]) => value)
                      ? "Request"
                      : ""}
                  </b>{" "}
                </p>
                <p className="text-blue-color">
                  {data?.Request?.ViewOnly ? (
                    <>
                      View only
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Request?.ViewCreateAndEdit ? (
                    <>
                      View, create, and edit{" "}
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color">
                  {data?.Request?.ViewCreateEditAndDelete ? (
                    <>
                      View, create, edit, and delete{" "}
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="text-blue-color mb-0">
                  {data?.Request?.RDelete ? (
                    <>
                      Delete{" "}
                      <DoneIcon
                        className="mx-2"
                        style={{ color: "#00EE64", fontSize: "18px" }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            )}
          <div className="d-flex col-12">
            {data?.Expenses &&
              Object.entries(data?.Expenses).some(([key, value]) => value) && (
                <div className="col-3">
                  <p className="text-blue-color">
                    <b>
                      {Object.entries(data?.Expenses).some(
                        ([key, value]) => value
                      )
                        ? "Expenses"
                        : ""}
                    </b>{" "}
                  </p>
                  <p className="text-blue-color">
                    {data?.Expenses?.ViewRecordAndEditTheirOwn ? (
                      <>
                        View, record, and edit their own
                        <DoneIcon
                          className="mx-2"
                          style={{ color: "#00EE64", fontSize: "18px" }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="text-blue-color">
                    {data?.Expenses?.ViewRecordAndEditEveryones ? (
                      <>
                        View, record, and edit everyone's
                        <DoneIcon className="mx-2" />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              )}
            {data?.Quotes &&
              Object.entries(data?.Quotes).some(([key, value]) => value) && (
                <div className="col-3">
                  <p className="text-blue-color ">
                    <b>
                      {Object.entries(data?.Quotes).some(
                        ([key, value]) => value
                      )
                        ? "Quotes"
                        : ""}
                    </b>{" "}
                  </p>
                  <p className="text-blue-color">
                    {data?.Quotes?.ViewOnly ? (
                      <>
                        View only{" "}
                        <DoneIcon
                          className="mx-2"
                          style={{ color: "#00EE64", fontSize: "18px" }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="text-blue-color">
                    {data?.Quotes?.ViewCreateAndEdit ? (
                      <>
                        View, create, and edit{" "}
                        <DoneIcon
                          className="mx-2"
                          style={{ color: "#00EE64", fontSize: "18px" }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="text-blue-color">
                    {data?.Quotes?.ViewCreateEditAndDelete ? (
                      <>
                        View, create, edit, and delete{" "}
                        <DoneIcon
                          className="mx-2"
                          style={{ color: "#00EE64", fontSize: "18px" }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              )}
            {data?.Jobs &&
              Object.entries(data?.Jobs).some(([key, value]) => value) && (
                <div className="col-3">
                  <p className="text-blue-color my-0">
                    <b>
                      {data?.Jobs &&
                      Object.entries(data?.Jobs).some(([key, value]) => value)
                        ? "Jobs"
                        : ""}
                    </b>{" "}
                  </p>
                  <p className="text-blue-color">
                    {data?.Jobs?.ViewOnly ? (
                      <>
                        View only{" "}
                        <DoneIcon
                          className="mx-2"
                          style={{ color: "#00EE64", fontSize: "18px" }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="text-blue-color">
                    {data?.Jobs?.ViewCreateAndEdit ? (
                      <>
                        View, create, and edit{" "}
                        <DoneIcon
                          className="mx-2"
                          style={{ color: "#00EE64", fontSize: "18px" }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="text-blue-color">
                    {data?.Jobs?.ViewCreateEditAndDelete ? (
                      <>
                        View, create, edit, and delete{" "}
                        <DoneIcon
                          className="mx-2"
                          style={{ color: "#00EE64", fontSize: "18px" }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              )}
            {data?.Reports &&
              Object.entries(data?.Reports).some(([key, value]) => value) && (
                <div className="col-3">
                  <p className="text-blue-color my-0">
                    <b>
                      {Object.entries(data?.Reports).some(
                        ([key, value]) => value
                      )
                        ? "Reports"
                        : ""}
                    </b>{" "}
                  </p>
                  <p className="text-blue-color">
                    {data?.Reports
                      ?.UsersWillOnlyBeAbleToSeeReportsAvailableToThemBasedOnTheirOtherPermissions ? (
                      <>
                        Users will only be able to see reports available to them
                        based on their other permissions.
                        <DoneIcon
                          className="mx-2"
                          style={{ color: "#00EE64", fontSize: "18px" }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };

  const cellData = companyData?.map((item, index) => {
    return {
      key: item.PermissionId,
      value: [
        page * rowsPerPage + index + 1,
        <>
          <b>{item.Title}</b> <br /> {item.Description}
        </>,
        // item.createdAt,
        moment(item.createdAt).format("MM-DD-YYYY"),
        <>
          {/* <IconButton> */}
          <img
            src={Edit}
            alt="edit"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item.PermissionId);
            }}
          />
          {/* </IconButton>
          <IconButton> */}
          <img
            className="mx-1"
            alt="img"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.PermissionId);
            }}
          />
          {/* </IconButton> */}
        </>,
      ],
      component: item,
    };
  });

  return (
    <>
      <div className="justify-content-center align-items-center mb-3 industry">
        <div className="d-flex justify-content-end mb-2 align-items-center">
          <Button
            style={{ color: "#fff" }}
            onClick={() => {
              navigate(`/superadmin/add-permission`, {
                state: {
                  navigats: [...location.state.navigats, "/add-permission"],
                },
              });
            }}
            className="text-capitalize bg-button-blue-color addproductbutton"
          >
            Add Permission
          </Button>
        </div>
        <Card className="border-blue-color"
          style={{
            borderRadius: "20px",
            border: "2px solid ",
            padding: 0,
          }}
        >
          <CardHeader
            className="d-flex border-blue-color justify-content-between align-items-center table-header bg-blue-color"
            style={{
              borderBottom: "2px solid",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h5 className="compn text-light" style={{ cursor: "pointer" }}>
              Permission
            </h5>
            <div className="companysearch d-flex">
              <JobberSearch
                search={search}
                setSearch={setSearch}
                style={{ background: "transparent", color: "white" }}
              />
            </div>
          </CardHeader>

          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <Circles
                height="50"
                width="50"
                color="#063164"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
              />
            </div>
          ) : (
            <CardBody style={{ padding: "10px 0px" }}>
              <JobberTable
                headerData={[
                  "Sr no",
                  "Title And Description",
                  "Date",
                  "Action",
                ]}
                cellData={cellData}
                CollapseComponent={(data) => CollapseData(data)}
                isCollapse={true}
                page={page}
                isNavigate={false}
              />
            </CardBody>
          )}
          <CardFooter
            className="bg-orange-color border-blue-color"
            style={{
              borderTop: "2px solid ",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <JobberPagination
              totalData={countData}
              currentData={rowsPerPage}
              dataPerPage={rowsPerPage}
              pageItems={[10, 25, 50]}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </CardFooter>
        </Card>
      </div>
      <Toaster />
    </>
  );
};

export default Permission;
