import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import toast, { Toaster } from "react-hot-toast";
import swal from "sweetalert";
import AxiosInstance from "../../../AxiosInstance.js";
// import { Button, Table } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import sendToast from "../../../../components/Toast/sendToast";
import { JobberPagination } from "../../../../components/MuiTable/index.js";
import {
  Button,
  Table,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Navbar,
} from "reactstrap";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Circles } from "react-loader-spinner";
import SettingSidebar from "../../../../components/Setting/SettingSidebar.js";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
} from "@mui/material";
import "./style.css";
import ActiveUsers from "../Manage Team/Activeuasers.js";
import { Divider } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import SettingDropdown from "../Product&service/SettingComponent.js";
import deleteworker from "../../../../assets/image/icons/workerdelete.svg";
import "./style.css";
import sendSwal from "../../../../components/Swal/sendSwal.js";

function ManageTeamTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const [workerData, setWorkerData] = useState([]);

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const toggle = () => setIsOpenDropDown(!isOpenDropDown);

  const [activeDropdown, setActiveDropdown] = useState(null);
  const toggleDropdown = (email) => {
    setActiveDropdown(activeDropdown === email ? null : email);
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const companyId = localStorage.getItem("CompanyId");
      const res = await AxiosInstance.get(`/worker/${companyId}`, {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
          search: search || "",
        },
      });
      if (res.data.statusCode === 200) {
        setWorkerData(res.data.data);
        setTotalCount(res.data.count);
        setCountData(res.data.count);
        setActiveCount(res.data.active);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [rowsPerPage, page, search]);

  const handleClick = (id) => {
    if (id) {
      navigate("/" + companyName + "/add-user", {
        state: { id, navigats: [...location.state.navigats, "/add-user"] },
      });
    }
  };

  // Delete
  // const handleDelete = (id)=> {
  //    sendSwal().then(async (willDelete) => {
  //      if (willDelete) {
  //        try {
  //          const response = await AxiosInstance.delete(`/worker/${id}`);
  //          console.log(response,'response')
  //          if (response.data.statusCode === 200) {
  //            toast.success(response.data.message);
  //            fetchData();
  //          } else {
  //            toast.error(response.data.message);
  //          }
  //        } catch (error) {
  //          console.error("Error:", error);
  //          toast.error(error);
  //        }
  //      } else {
  //        toast.success("Manage Team is safe!", {
  //          position: "top-center",
  //          autoClose: 1000,
  //        });
  //      }
  //    });
  //    setTimeout(() => {
  //      const deleteButton = document.querySelector(".swal-button--confirm");
  //      if (deleteButton) {
  //        deleteButton.disabled = true;
  //      }
  //    }, 0);
  //  };

  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`/worker/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (response.data.statusCode === 200) {
            toast.success(response.data.message);
            fetchData();
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
        }
      } else {
        toast.success("Manage Team is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({}),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const object = {
          ...values,
          companyId: localStorage.getItem("CompanyId"),
        };

        let response;
        if (location.state.id) {
          response = await AxiosInstance.put(`/worker/${location.state.id}`, {
            IsActive: false,
          });
        } else {
          response = await AxiosInstance.post(`/worker`, object);
        }

        if (response.data.statusCode === 200) {
          setTimeout(() => {
          toast.success(response.data.message);
        }, 500);
          navigate(-1);
        } else if (response.data.statusCode === 202) {
          setTimeout(() => {
          toast.error(response.data.message);
        }, 500);
        } else {
          setTimeout(() => {
          toast.error("", response.data.message, "error");
        }, 500);
        }
      } catch (error) {
        setTimeout(() => {
        toast.error("", error.message, "error");
      }, 500);
        console.error("There was an error submitting the form!", error);
      }
    },
  });

  const sendMail = async (WorkerId) => {
    const willSendMail = await swal(
      "Are you sure you want to send the email?",
      {
        buttons: ["No", "Yes"],
      }
    );

    if (willSendMail) {
      try {
        const response = await AxiosInstance.post(
          `/worker/send_mail/${WorkerId}`
        );
        if (response.data.statusCode === 200) {
          setTimeout(() => {
          toast.success(response.data.message);
        }, 500);
        } else {
          setTimeout(() => {
          toast.error(response.data.message);
        }, 500);
        }
      } catch (error) {
        console.error("Error to send mail", error);
        setTimeout(() => {
        toast.error("An error occurred while sending the email.");
      }, 500);
      }
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div className="col-2 h-100 hiren">
            <SettingSidebar />
          </div>
          {/* <Divider
            className="divider-manageteam"
            orientation="vertical"
            flexItem
            style={{
              marginLeft: "-10px",
              marginRight: "1%",
              borderRight: "2px solid black",
              height: "90vh",
              width: "10px",
            marginTop: "-29px",

            }}
          /> */}
          <div
            className="d-flex col-12 col-lg-10 col-md-12 justify-content-center manageteam-content"
            style={{
              position: "relative",
              zIndex: "9",
              borderLeft: "0.5px solid rgba(6, 49, 100, 30%)",
              paddingLeft: "20px",
              marginTop: "-30px",
            }}
          >
            <div
              style={{
                width: "100%",
                marginLeft: "0%",
                justifyContent: "center",
              }}
            >
              <div>
                <div className="d-block justify-content-between">
                  <Navbar
                    className="navbar-setting"
                    style={{
                      zIndex: "9",
                      borderRadius: "5px",
                    }}
                  ></Navbar>
                  <div className="d-flex justify-content-between ">
                    <p
                      className="text-blue-color page-heading"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        lineHeight: "28px",
                        marginTop: "5%",
                      }}
                    >
                      Manage Team
                    </p>
                    <div className="d-flex justify-content-end mb-4  align-items-center">
                      <Button
                        style={{
                          position: "relative",
                          zIndex: "9999",
                        }}
                        onClick={() => {
                          navigate(`/${companyName}/add-user`, {
                            state: {
                              navigats: [
                                ...location.state.navigats,
                                "/add-user",
                              ],
                            },
                          });
                        }}
                        className="text-capitalize bg-button-blue-color adduserbtn add-user"
                      >
                        + Add Worker
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-blue-color">
                  Include or oversee team members requiring login access to the
                  contract management system both in the office and in the
                  field. Assign them to contract sites or enable them to use
                  more features within the contract management system.
                </p>
              </div>
              <SettingDropdown
                isOpenDropDown={isOpenDropDown}
                toggle={toggle}
                companyName={companyName}
              />
              <div className="justify-content-center align-items-center mb-3">
                <div className="row mt-5 d-flex" style={{}}>
                  <div className="col-lg-9 col-md-12 t-head">
                    <Table
                      className="table-responsive"
                      borderless
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        borderRadius: "15px",
                        overflow: "hidden",
                      }}
                    >
                      <TableHead>
                        <tr className="text-white-color">
                          <th
                            className="bg-orange-color"
                            style={{ borderTopLeftRadius: "15px" }}
                          ></th>
                          <th className="bg-orange-color text-white-color">
                            Name
                          </th>
                          <th className="bg-orange-color text-white-color">
                            Email
                          </th>
                          <th className="bg-orange-color text-white-color">
                            Last Login
                          </th>
                          <th className="bg-orange-color text-white-color">
                            Status
                          </th>
                          <th className="bg-orange-color">Action</th>
                        </tr>
                      </TableHead>
                      {loader ? (
                        <tbody>
                          <tr>
                            <td
                              colSpan="6"
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                <Circles
                                  height="30"
                                  width="30"
                                  ariaLabel="circles-loading"
                                  visible={loader}
                                  color="#063164"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody className="align-items-middle">
                          {workerData?.length > 0 ? (
                            workerData.map((user) => (
                              <tr
                                key={user.EmailAddress}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClick(user.WorkerId);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <td style={{ borderBottomLeftRadius: "15px" }}>
                                  <div className="initials-circle">
                                    {user?.FullName?.split(" ")
                                      .map((part) =>
                                        part.charAt(0).toUpperCase()
                                      )
                                      .join("")}
                                  </div>
                                </td>
                                <td>
                                  {user.FullName}
                                  {user.role && (
                                    <span className="role">
                                      <br />
                                      {user.role}
                                    </span>
                                  )}
                                </td>
                                <td
                                  style={{
                                    wordWrap: "break-word",
                                    overflowWrap: "break-word",
                                    maxWidth: "220px",
                                  }}
                                >
                                  {user.EmailAddress}
                                </td>
                                <td>{moment(user.createdAt).format("ll")}</td>
                                <td
                                  style={{
                                    color: user.IsActive ? "green" : "red",
                                  }}
                                >
                                  <b>{user.IsActive ? "Active" : "Deactive"}</b>
                                </td>
                                <td style={{ borderBottomRightRadius: "15px" }}>
                                  <div
                                    className="action-dots"
                                    style={{ fontWeight: "1000" }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Dropdown
                                      isOpen={
                                        activeDropdown === user.EmailAddress
                                      }
                                      toggle={() =>
                                        toggleDropdown(user.EmailAddress)
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                      style={{
                                        zIndex:
                                          activeDropdown === user.EmailAddress
                                            ? 9999
                                            : 0,
                                      }}
                                    >
                                      <DropdownToggle
                                        className="text-blue-color outline border-blue-color"
                                        style={{
                                          background: "none",
                                          border: "none ",
                                        }}
                                      >
                                        <MoreHorizIcon />
                                      </DropdownToggle>
                                      <DropdownMenu
                                        container="body"
                                        style={{
                                          position: "absolute",
                                          zIndex:
                                            activeDropdown === user.EmailAddress
                                              ? 9998
                                              : 1,
                                          padding: "5px",
                                          minWidth: "150px",
                                        }}
                                      >
                                        <DropdownItem
                                          style={{
                                            fontSize: "14px",
                                            padding: "5px 10px",
                                          }}
                                           onClick={() => {
                                             sendMail(user.WorkerId);
                                           }}
                                           className="text-blue-color"
                                        >
                                          <MarkEmailReadOutlinedIcon
                                            className="icones-dropdown texxt-blue-color"
                                            style={{
                                              fontSize: "16px",
                                              marginRight: "5px",
                                            }}
                                          />
                                          Resend Invitation
                                        </DropdownItem>
                                        <DropdownItem
                                          style={{
                                            fontSize: "14px",
                                            padding: "5px 10px",
                                          }}
                                          onClick={async () => {
                                            const confirmation = await swal(
                                              "Are you sure you want to change the status?",
                                              {
                                                buttons: ["No", "Yes"],
                                              }
                                            );

                                            if (confirmation) {
                                              try {
                                                const newStatus =
                                                  !user.IsActive;
                                                const response =
                                                  await AxiosInstance.put(
                                                    `/worker/${user.WorkerId}`,
                                                    { IsActive: newStatus }
                                                  );

                                                if (
                                                  response.data.statusCode ===
                                                  200
                                                ) {
                                                  const successMessage =
                                                    newStatus
                                                      ? "Worker activated successfully"
                                                      : "Worker deactivated successfully";
                                                  toast.success(successMessage);
                                                  fetchData();
                                                } else {
                                                  setTimeout(() => {
                                                  toast.error(
                                                    response.data.message
                                                  );
                                                }, 500);
                                                }
                                              } catch (error) {
                                                setTimeout(() => {
                                                toast.error(
                                                  "Failed to update the worker's status"
                                                );
                                              }, 500);
                                              }
                                            }
                                          }}
                                        >
                                          <NoAccountsIcon
                                            className="icones-dropdown"
                                            style={{
                                              fontSize: "16px",
                                              color: user.IsActive
                                                ? "red"
                                                : "green",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <span
                                            style={{
                                              color: user.IsActive
                                                ? "red"
                                                : "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {user.IsActive
                                              ? "Deactivate"
                                              : "Activate"}
                                          </span>
                                        </DropdownItem>

                                        <DropdownItem
                                          style={{
                                            fontSize: "14px",
                                            padding: "5px 10px",
                                            alignItems: "center",
                                            marginLeft: "2px",
                                          }}
                                          onClick={() =>
                                            handleDelete(user.WorkerId)
                                          }
                                          className="text-blue-color"
                                        >
                                          <img
                                            src={deleteworker}
                                            style={{ marginRight: "5px" }}
                                          />
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{ textAlign: "center", padding: "20px" }}
                              >
                                Data not found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                      <tfoot>
                        <tr>
                          <td
                            colSpan="6"
                            style={{
                              padding: "0",
                            }}
                          >
                            <CardFooter
                              className="bg-orange-color"
                              style={{
                                // borderTop: "2px solid #063164",
                                borderBottomLeftRadius: "16px",
                                borderBottomRightRadius: "16px",
                              }}
                            >
                              <JobberPagination
                                totalData={countData}
                                currentData={rowsPerPage}
                                dataPerPage={rowsPerPage}
                                pageItems={[10, 25, 50]}
                                page={page}
                                setPage={setPage}
                                setRowsPerPage={setRowsPerPage}
                              />
                            </CardFooter>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>

                  <div className="col-lg-3 col-md-12 d-flex justify-content-between activeusers">
                    <ActiveUsers
                      totalCount={totalCount}
                      activeCount={activeCount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
}

export default ManageTeamTable;
