import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Edit from "../../../assets/image/icons/edit.svg";
import Delete from "../../../assets/image/icons/delete.svg";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../AxiosInstance.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postFile } from "../../../components/Files/Functions";
import InvoiceViews from "./Views/Invoice.js";
import sendSwal from "../../../components/Swal/sendSwal.js";

const Invoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_API;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(true);
  const [isCustomer, setIsCustomer] = useState(false);
  const [source, setModalSource] = useState(false);
  const [countData, setCountData] = useState(0);
  const [isProperty, setIsProperty] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [customersData, setCustomersData] = useState({});
  const [loading, setLoading] = useState(false);

  const [lineItems, setLineItems] = useState([
    {
      Description: "",
      Name: "",
      Type: "",
      Units: "",
      Attachment: "",
      CostPerUnit: "",
      Cost: "",
      Markup: "",
      Total: "",
      isNew: true,
    },
  ]);
  const [staffData, setStaffData] = useState(undefined);

  const [invoiceData, setInvoiceData] = useState([]);
  const getData = async () => {
    setLoader(true);
    try {
      const res = await AxiosInstance.get(
        `/invoice/${localStorage.getItem("CompanyId")}`,
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
            search: search || "",
          },
        }
      );
      // 5150 start
      if (res.data.statusCode === 200) {
        setInvoiceData(res.data.data);
        setCountData(res.data.totalCount);
      }
      // 5150 end
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search]);

  const handleEditClick = (id) => {
    if (id) {
      navigate("/" + companyName + "/addinvoice", {
        state: {
          invoiceId: id,
          navigats: [...location.state.navigats, "/addinvoice"],
        },
      });
    }
  };

  // Delete
  //2529 delete refresh issue
  const handleDelete = (id) => {
    sendSwal().then(async (deleteReason) => {
      if (deleteReason) {
        try {
          const response = await AxiosInstance.delete(`/invoice/${id}`, {
            data: { DeleteReason: deleteReason },
          });
          if (response.data.statusCode === 200) {
            setInvoiceData((prevData) =>
              prevData.filter((item) => item.InvoiceId !== id)
            );
            setTimeout(() => {
              toast.success(response.data.message);
            }, 500);
            getData();
          } else {
            toast.error("", response.data.message, "error");
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
        }
      } else {
        toast.success("Invoice is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };
  //2529 delete refresh issue

  const formik = useFormik({
    initialValues: {
      Title: "",
      InvoiceNumber: 1,
      CustomerId: "",
      CompanyId: localStorage.getItem("CompanyId"),
      LocationId: "",
      CustomerMessage: "",
      ContractDisclaimer:
        "Contract/ Disclaimer\nThis quote is valid for the next 30 days, after which values may be subject to change.",
      Attachment: [],
      Discount: "",
    },
    validationSchema: Yup.object({
      Title: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const strings = [];
        for (let item of values.Attachment) {
          if (typeof item !== "string") {
            const string = await postFile(item);
            strings.push(string);
          } else {
            strings.push(item);
          }
        }

        for (let item of lineItems) {
          if (typeof item?.Attachment !== "string") {
            const string = await postFile(item.Attachment);
            item.Attachment = string;
          }
        }
        const object = {
          ...values,
          details: lineItems,
          Attachment: strings,
        };
        let response;
        if (!location.state?.id) {
          response = await AxiosInstance.post(`${baseUrl}/invoice`, object);
        } else {
          response = await AxiosInstance.put(
            `${baseUrl}/invoice/${location.state?.id}`,
            object
          );
        }
        toast.success(response.message);

        if (response.status === 200) {
          toast.success(response.message);
          navigate(`/${companyName}/invoice`, {
            state: {
              navigats: location.state.navigats.filter(
                (item) => item !== "/AddInvoice"
              ),
            },
          });
        }
      } catch (error) {
        toast.error("", error.message, "error");

        console.error("Error: ", error);
      }
    },
  });
  const cellData = invoiceData?.map((item, index) => {
    return {
      key: item?.InvoiceId,
      value: [
        page * rowsPerPage + index + 1,
        <>
          {item?.customer?.FirstName} {item?.customer?.LastName}
        </>,
        <>
          #{item?.InvoiceNumber}
          <br />
          {item?.Subject}
        </>,
        <>
          <br />
          {item?.location &&
            Object.entries(item?.location)
              .map(([key, value]) => value || "")
              .join(", ")}
        </>,
        // item?.DueDate || "-",
        // item?.DueDate ? (typeof item.DueDate === 'number' ? item.DueDate.toFixed(2) : item.DueDate) : "-",
        item?.DueDate
          ? typeof item.DueDate === "number"
            ? new Date(item.DueDate).toLocaleDateString("en-US") // Converts timestamp to 'mm-dd-yyyy'
            : new Date(item.DueDate).toLocaleDateString("en-US") // Converts date string to 'mm-dd-yyyy'
          : "-",
        `$${item?.Total}` || "-",
        `$${item?.invoiceAccount}` || "",
        <span
          style={{
            color:
              item?.Status === "Paid"
                ? "#089F57"
                : item?.Status === "Unpaid"
                ? "#F82C50"
                : item?.Status === "Canceled"
                ? "#FF0000"
                : "#E88C44",
            fontWeight: 500,
          }}
        >
          {item?.Status}
        </span>,
        <>
          <img
            src={Edit}
            alt="edit"
            onClick={(e) => {
              if (item?.Status !== "Canceled") {
                e.stopPropagation();
                handleEditClick(item?.InvoiceId);
              }
            }}
            style={{
              opacity: item?.Status === "Canceled" ? 0.5 : 1,
              cursor: item?.Status === "Canceled" ? "not-allowed" : "pointer",
            }}
          />
          <img
            className="mx-1"
            alt="img"
            src={Delete}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item?.InvoiceId);
            }}
          />
        </>,
      ],
    };
  });
  return (
    <>
      <InvoiceViews
        loader={loader}
        search={search}
        setSearch={setSearch}
        cellData={cellData}
        setIsCustomer={setIsCustomer}
        setModalSource={setModalSource}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        companyName={companyName}
        countData={countData}
        rowsPerPage={rowsPerPage}
        isCustomer={isCustomer}
        formik={formik}
        lineItems={lineItems}
        propertyData={propertyData}
        setPropertyData={setPropertyData}
        isProperty={isProperty}
        setIsProperty={setIsProperty}
        customersData={customersData}
        setCustomersData={setCustomersData}
        source={source}
        staffData={staffData}
        setStaffData={setStaffData}
      />
      <Toaster />
    </>
  );
};

export default Invoice;
