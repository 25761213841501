// import React, { useState, useEffect } from "react";
// import { formatDistanceToNow } from "date-fns";
// import "./QuoteNotification.css";
// import { handleAuth } from "../../../src/components/Login/Auth";
// import AxiosInstance from "../../Views/AxiosInstance";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import quote from "../../assets/Blue-sidebar-icon/Quote.svg";
// import contract from "../../assets/Blue-sidebar-icon/Contract.svg";
// import Appointment from "../../assets/Blue-sidebar-icon/Appoinment-blue.svg";
// import customerIcon from "../../assets/Blue-sidebar-icon/Customer.svg";
// import InvoiceIcon from "../../assets/Blue-sidebar-icon/Invoice.svg";
// import { differenceInSeconds } from "date-fns";
// import "./QuoteNotification.css";
// import { Grid, Typography } from "@mui/material";
// import { Row, Col } from "react-bootstrap";
// import { LoaderComponent } from "../Icon/Index";

// const formatTimeAgo = (createdAt, currentAt) => {
//   if (!createdAt) return "";

//   const timeDiffInSeconds = differenceInSeconds(currentAt, createdAt);
//   let formattedTime = "";

//   if (timeDiffInSeconds < 60) {
//     formattedTime = "just now";
//   } else if (timeDiffInSeconds < 3600) {
//     formattedTime = `${timeDiffInSeconds} seconds ago`;
//   } else {
//     formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
//   }

//   return formattedTime;
// };

// const formatStyle = (notification) => {
//   const notificationStyle = {
//     backgroundColor: notification?.IsView ? "#f5f5f5" : "#FFE9BC",
//     padding: "10px",
//     borderRadius: "8px",
//     marginBottom: "10px",
//     cursor: "pointer",
//   };
//   return notificationStyle;
// };

// const QuoteNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";

//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard contractNotificationImgCon"
//       onClick={() =>
//         onClick(notification?.QuoteId, notification?.NotificationId, "quote")
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={quote} alt="Quote Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title contractNotificationBar">
//           {notification?.Company?.ownerName} created a quote - $
//           {notification?.Quote?.Total}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="quoteNumber contractNotificationBar"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             Quote #{notification?.Quote?.QuoteNumber} -{" "}
//             {notification?.Quote?.Title}
//           </Typography>
//           <Typography className="additionalInfo contractNotificationBar">
//             {notification?.Customer?.FirstName}{" "}
//             {notification?.Customer?.LastName}
//           </Typography>
//         </Grid>
//         <Typography className="time contractNotificationBar">
//           {formattedTime}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// };

// const ContractNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";

//   const notificationStyle = formatStyle(notification);
//   return (
//     <Grid
//       className="notificationCard contractNotificationImgCon"
//       style={notificationStyle}
//       onClick={() =>
//         onClick(
//           notification?.ContractId,
//           notification?.NotificationId,
//           "contract"
//         )
//       }
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={contract} alt="Contract Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title contractNotificationBar">
//           {notification?.Company?.ownerName} created a contract - $
//           {notification?.Contract?.Total}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="quoteNumber contractNotificationBar"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             Contract #{notification?.Contract?.ContractNumber} -{" "}
//             {notification?.Contract?.Title}
//           </Typography>
//           <Typography className="additionalInfo contractNotificationBar">
//             {notification?.Customer?.FirstName}{" "}
//             {notification?.Customer?.LastName}
//           </Typography>
//         </Grid>
//         <Typography className="time contractNotificationBar">
//           {formattedTime}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// };

// const AppointmentConfirmNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";
//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard"
//       onClick={() =>
//         onClick(
//           notification?.ContractId,
//           notification?.NotificationId,
//           "contract"
//         )
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={Appointment} alt="Contract Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title">
//           Appointment {notification?.Visit?.StartDate} Confirmed By Customer -
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="approvequote"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             {notification?.Customer?.FirstName}{" "}
//             {notification?.Customer?.LastName}
//           </Typography>
//         </Grid>
//         <Typography className="time">{formattedTime}</Typography>
//       </Grid>
//     </Grid>
//   );
// };
// const QuoteConfirmNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";
//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard contractNotificationImgCon"
//       onClick={() =>
//         onClick(notification?.QuoteId, notification?.NotificationId, "quote")
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={quote} alt="Quote Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title contractNotificationBar">
//           Appointment {notification?.Visit?.StartDate} Confirmed by Worker -{" "}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="approvequote contractNotificationBar"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             {notification?.Visit?.WorkerFullName}
//           </Typography>
//         </Grid>
//         <Typography className="time contractNotificationBar">
//           {formattedTime}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// };
// const WorkerCreateNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";
//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard"
//       onClick={() =>
//         onClick(notification?.WorkerId, notification?.NotificationId, "worker")
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={quote} alt="Quote Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title">
//           {notification?.Company?.ownerName} Created A Worker -
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="approvequote"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             {notification?.Worker?.FullName}
//           </Typography>
//         </Grid>
//         <Typography className="time">{formattedTime}</Typography>
//       </Grid>
//     </Grid>
//   );
// };
// const ProductAndServiceCreateNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";
//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard"
//       onClick={() =>
//         onClick(
//           notification?.ProductId,
//           notification?.NotificationId,
//           "productandservice"
//         )
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={quote} alt="Quote Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title">
//           {notification?.Company?.ownerName} Created A{" "}
//           {notification?.ProductAndService?.Type} -
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="approvequote"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             {notification?.ProductAndService?.Name}
//           </Typography>
//         </Grid>
//         <Typography className="time">{formattedTime}</Typography>
//       </Grid>
//     </Grid>
//   );
// };
// const CustomerNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";
//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard contractNotificationImgCon"
//       onClick={() =>
//         onClick(
//           notification?.CustomerId,
//           notification?.NotificationId,
//           "customer"
//         )
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={customerIcon} alt="Customer Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title contractNotificationBar">
//           {notification?.Company?.ownerName} added a new customer:{" "}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="quoteNumber contractNotificationBar"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             {notification?.Customer?.FirstName}{" "}
//             {notification?.Customer?.LastName}
//           </Typography>
//         </Grid>
//         <Typography className="time contractNotificationBar">
//           {formattedTime}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// };
// const InvoiceNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";
//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard contractNotificationImgCon"
//       onClick={() =>
//         onClick(
//           notification?.InvoiceId,
//           notification?.NotificationId,
//           "invoice"
//         )
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={InvoiceIcon} alt="Invoice Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title contractNotificationBar">
//           {notification?.Company?.ownerName} created a invoice - $
//           {notification?.Invoice?.Total}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="quoteNumber contractNotificationBar"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             Invoice #{notification?.Invoice?.InvoiceNumber} -{" "}
//             {notification?.Invoice?.Subject}
//           </Typography>
//           <Typography className="additionalInfo contractNotificationBar">
//             {notification?.Customer?.FirstName}{" "}
//             {notification?.Customer?.LastName}
//           </Typography>
//         </Grid>
//         <Typography className="time contractNotificationBar">
//           {formattedTime}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// };
// const QuoteChangeRequestNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";
//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard"
//       onClick={() =>
//         onClick(notification?.QuoteId, notification?.NotificationId, "Chnge")
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer">
//         <img src={quote} alt="Quote Change Request Icon" />
//       </Grid>
//       <Grid className="notificationContent">
//         <Typography className="title">
//           {notification?.Customer?.FirstName} {notification?.Customer?.LastName}
//           {""} Requested a change to quote - ${notification?.Quote?.Total}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="requestquote"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             Quote #{notification?.Quote?.QuoteNumber} - {""}
//             {notification?.Quote?.Title}
//           </Typography>
//           <Typography className="additionalInfo">
//             Request Message:{" "}
//             {notification?.Request?.RequestMessage || "No message"}{" "}
//           </Typography>
//         </Grid>
//         <Typography className="time">{formattedTime}</Typography>
//       </Grid>
//     </Grid>
//   );
// };
// const QuoteApproveNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt;
//   const formattedTime = createdAt
//     ? formatTimeAgo(createdAt, notification?.currentAt)
//     : "";
//   const notificationStyle = formatStyle(notification);

//   return (
//     <Grid
//       className="notificationCard"
//       onClick={() =>
//         onClick(notification?.QuoteId, notification?.NotificationId, "approve")
//       }
//       style={notificationStyle}
//     >
//       <Grid className="iconContainer">
//         <img src={quote} alt="Quote Approve Icon" />
//       </Grid>
//       <Grid className="notificationContent">
//         <Typography className="title">
//           {notification?.Customer?.FirstName} {notification?.Customer?.LastName}{" "}
//           Approved quote - ${notification?.Quote?.Total}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="approvequote"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             Quote #{notification?.Quote?.QuoteNumber} -{" "}
//             {notification?.Quote?.Title}
//           </Typography>
//         </Grid>
//         <Typography className="time">{formattedTime}</Typography>
//       </Grid>
//     </Grid>
//   );
// };

// const NotificationsPage = ({ handleCloseNotifications,isSwitchOn }) => {
//   const { companyName } = useParams();
//   const location = useLocation();
//   const navigate = useNavigate();

//   const [notifications, setNotifications] = useState([]);
//   const [tokenDecode, setTokenDecode] = useState(null);
//   const [loader, setLoader] = useState(true);

//   const fetchNotifications = async () => {
//     try {
//       setLoader(true);
//       const response = await AxiosInstance.get(`/notifications/${tokenDecode}`);

//       if (response.data.statusCode === 200) {
//         const filteredNotifications = response.data.notifications.filter(
//           (notification) =>
//             notification?.Quote?.QuoteNumber ||
//             notification?.Contract?.ContractNumber ||
//             notification?.Customer?.FirstName ||
//             notification?.Invoice?.InvoiceNumber ||
//             notification?.QuoteChangeRequest ||
//             notification?.QuoteApprove ||
//             notification.WorkerId ||
//             notification.ProductId
//         );
//         setNotifications(filteredNotifications || []);
//       } else {
//         console.warn("No notifications found in response.");
//         setNotifications([]);
//       }
//     } catch (error) {
//       console.error("Error fetching notifications:", error.message);
//       setNotifications([]);
//     } finally {
//       setLoader(false);
//     }
//   };

//   const fetchTokenDecode = async () => {
//     try {
//       setLoader(false);
//       const res = await handleAuth(navigate, location);
//       if (res?.data?.companyId) {
//         setTokenDecode(res.data.companyId);
//       } else {
//         console.error("Company ID not found in response.");
//       }
//     } catch (error) {
//       console.error("Error fetching company data:", error);
//     } finally {
//       setLoader(false);
//     }
//   };

//   useEffect(() => {
//     fetchTokenDecode();
//   }, [navigate, location]);

//   useEffect(() => {
//     if (tokenDecode) {
//       fetchNotifications();
//     }
//   }, [tokenDecode]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoader(false);
//         const res = await handleAuth(navigate, location);
//         if (res?.data?.companyId) {
//           setTokenDecode(res.data.companyId);
//         } else {
//           console.error("Company ID not found in response.");
//         }
//       } catch (error) {
//         console.error("Error fetching company data:", error);
//       }
//     };

//     fetchData();
//   }, [navigate, location]);

//   useEffect(() => {
//     if (tokenDecode) {
//       fetchNotifications();
//     }
//   }, [tokenDecode]);

//   const handleNotificationClick = async (id, notificationId, type) => {
//     try {
//       await AxiosInstance.put(`/notifications/${notificationId}`);
//       handleCloseNotifications();
//       await fetchNotifications();

//       const route =
//         type === "quote"
//           ? "quotes-detail"
//           : type === "contract"
//           ? "contractdetails"
//           : type === "customer"
//           ? "customerdetails"
//           : type === "invoice"
//           ? "invoice-details"
//           : type === "Chnge"
//           ? "quotes-detail"
//           : type === "approve"
//           ? "quotes-detail"
//           : type === "worker"
//           ? "add-user"
//           : type === "productandservice"
//           ? "product&service"
//           : null;

//       if (route) {
//         navigate(`/${companyName}/${route}`, {
//           state: { id: id, navigats: ["/index", `/${route}`] },
//           replace: true,
//         });
//       }
//     } catch (error) {
//       console.error("Error marking notification as read:", error.message);
//     }
//   };

//   return (
//     <>
//       {loader ? (
//         <Grid className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
//           <Grid className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
//             <LoaderComponent loader={loader} height="50" width="50" />
//           </Grid>
//         </Grid>
//       ) : (
//         <Grid className="notificationsContainer quotes-notification mt-4">
//           {notifications.length > 0 ? (
//             notifications.map((notification) => {
//               if (notification.CreatedBy === "WorkerRole") {
//                 return (
//                   <WorkerCreateNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }
//               if (notification?.CreatedBy?.trim().toLowerCase() === "confirm") {
//                 return (
//                   <AppointmentConfirmNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }
//               if (notification.CreatedBy === "customer") {
//                 return (
//                   <QuoteChangeRequestNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }
//               if (notification.CreatedBy === "ProductAndServices") {
//                 return (
//                   <ProductAndServiceCreateNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }
//               if (notification.CreatedBy === "Worker") {
//                 return (
//                   <QuoteConfirmNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }
//               if (notification?.Quote) {
//                 if (notification.CreatedBy === "Customer") {
//                   return (
//                     <QuoteApproveNotification
//                       key={notification._id}
//                       notification={notification}
//                       onClick={handleNotificationClick}
//                     />
//                   );
//                 } else {
//                   return (
//                     <QuoteNotification
//                       key={notification._id}
//                       notification={notification}
//                       onClick={handleNotificationClick}
//                     />
//                   );
//                 }
//               }
//               if (notification?.Contract) {
//                 return (
//                   <ContractNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }
//               // if (notification?.Customer) {
//               //   return (
//               //     <CustomerNotification
//               //       key={notification._id}
//               //       notification={notification}
//               //       onClick={handleNotificationClick}
//               //     />
//               //   );
//               // }
//               {
//                 isSwitchOn && notification?.Customer && (
//                   <CustomerNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }
//               if (notification?.Invoice) {
//                 return (
//                   <InvoiceNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }
//               if (notification?.Worker) {
//                 return (
//                   <WorkerCreateNotification
//                     key={notification._id}
//                     notification={notification}
//                     onClick={handleNotificationClick}
//                   />
//                 );
//               }

//               return null;
//             })
//           ) : (
//             <Grid className="mt-4 text-center" style={{ color: "#063164" }}>
//               No notifications available.
//             </Grid>
//           )}
//         </Grid>
//       )}
//     </>
//   );
// };

// export default NotificationsPage;

import React, { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import "./QuoteNotification.css";
import { handleAuth } from "../Login/Auth";
import AxiosInstance from "../../Views/AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import quote from "../../assets/Blue-sidebar-icon/Quote.svg";
import contract from "../../assets/Blue-sidebar-icon/Contract.svg";
import Appointment from "../../assets/Blue-sidebar-icon/Appoinment-blue.svg";
import customerIcon from "../../assets/Blue-sidebar-icon/Customer.svg";
import InvoiceIcon from "../../assets/Blue-sidebar-icon/Invoice.svg";
import { differenceInSeconds } from "date-fns";
import "./QuoteNotification.css";
import { Grid, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap"; // React Bootstrap components
import { LoaderComponent } from "../Icon/Index";

// const QuoteNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt
//     ? new Date(notification.createdAt)
//     : null;
//   let formattedTime = "";

//   if (createdAt) {
//     const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

//     if (timeDiffInSeconds < 60) {
//       formattedTime = "just now";
//     } else {
//       formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
//     }
//   }

//   return (
//     <Grid
//       className="notificationCard contractNotificationImgCon"
//       onClick={() =>
//         onClick(notification?.QuoteId, notification?.NotificationId, "quote")
//       }
//       style={{ cursor: "pointer" }}
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={quote} alt="Quote Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title contractNotificationBar">
//           {notification?.Company?.ownerName} created a quote - $
//           {notification?.Quote?.Total}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="quoteNumber contractNotificationBar"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             Quote #{notification?.Quote?.QuoteNumber} -{" "}
//             {notification?.Quote?.Title}
//           </Typography>
//           <Typography className="additionalInfo contractNotificationBar">
//             {notification?.Customer?.FirstName}{" "}
//             {notification?.Customer?.LastName}
//           </Typography>
//         </Grid>
//         <Typography className="time contractNotificationBar">
//           {formattedTime}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// };
// const ContractNotification = ({ onClick, notification }) => {
//   const createdAt = notification?.createdAt
//     ? new Date(notification.createdAt)
//     : null;
//   let formattedTime = "";

//   if (createdAt) {
//     const timeDiffInSeconds = differenceInSeconds(new Date(), createdAt);

//     if (timeDiffInSeconds < 60) {
//       formattedTime = "just now";
//     } else {
//       formattedTime = formatDistanceToNow(createdAt, { addSuffix: true });
//     }
//   }

//   return (
//     <Grid
//       className="notificationCard contractNotificationImgCon"
//       style={{ cursor: "pointer" }}
//       onClick={() =>
//         onClick(
//           notification?.ContractId,
//           notification?.NotificationId,
//           "contract"
//         )
//       }
//     >
//       <Grid className="iconContainer" style={{ cursor: "pointer" }}>
//         <img src={contract} alt="Contract Icon" />
//       </Grid>
//       <Grid className="notificationContent" style={{ cursor: "pointer" }}>
//         <Typography className="title contractNotificationBar">
//           {notification?.Company?.ownerName} created a contract - $
//           {notification?.Contract?.Total}
//         </Typography>
//         <Grid className="description">
//           <Typography
//             className="quoteNumber contractNotificationBar"
//             style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
//           >
//             Contract #{notification?.Contract?.ContractNumber} -{" "}
//             {notification?.Contract?.Title}
//           </Typography>
//           <Typography className="additionalInfo contractNotificationBar">
//             {notification?.Customer?.FirstName}{" "}
//             {notification?.Customer?.LastName}
//           </Typography>
//         </Grid>
//         <Typography className="time contractNotificationBar">{formattedTime}</Typography>
//       </Grid>
//     </Grid>
//   );
// };

const formatTimeAgo = (AddedAt, currentAt) => {
  if (!AddedAt) return "";

  const timeDiffInSeconds = differenceInSeconds(currentAt, AddedAt);
  let formattedTime = "";

  if (timeDiffInSeconds < 60) {
    // Explicitly checking if the difference is under a minute.
    formattedTime = `${timeDiffInSeconds} second${
      timeDiffInSeconds !== 1 ? "s" : ""
    } ago`;
  } else if (timeDiffInSeconds < 3600) {
    const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
    formattedTime = `${timeDiffInMinutes} minute${
      timeDiffInMinutes !== 1 ? "s" : ""
    } ago`;
  } else {
    formattedTime = formatDistanceToNow(AddedAt, { addSuffix: true });
  }

  return formattedTime;
};

const formatStyle = (notification) => {
  const notificationStyle = {
    backgroundColor: notification?.IsView ? "#f5f5f5" : "#FFE9BC",
    padding: "10px",
    borderRadius: "8px",
    marginBottom: "10px",
    cursor: "pointer",
  };
  return notificationStyle;
};

const QuoteNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);

  return (
    <Grid
      className="notificationCard contractNotificationImgCon"
      onClick={() =>
        onClick(notification?.QuoteId, notification?.NotificationId, "quote")
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </Grid>
      <Grid className="notificationContent" style={{ cursor: "pointer" }}>
        <Typography className="title contractNotificationBar">
          {notification?.Company?.ownerName} created a quote - $
          {`${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(notification?.Quote?.Total ?? 0)}`}
        </Typography>
        <Grid className="description">
          <Typography
            className="quoteNumber contractNotificationBar"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Quote #{notification?.Quote?.QuoteNumber} -{" "}
            {notification?.Quote?.Title}
          </Typography>
          <Typography className="additionalInfo contractNotificationBar">
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </Typography>
        </Grid>
        <Typography className="time contractNotificationBar">
          {formattedTime}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ContractNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);
  return (
    <Grid
      className="notificationCard contractNotificationImgCon"
      onClick={() =>
        onClick(
          notification?.ContractId,
          notification?.NotificationId,
          "contract"
        )
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={contract} alt="Contract Icon" />
      </Grid>
      <Grid className="notificationContent" style={{ cursor: "pointer" }}>
        <Typography className="title contractNotificationBar">
          {notification?.Company?.ownerName} created a contract - $
          {`${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(notification?.Contract?.Total ?? 0)}`}
        </Typography>
        <Grid className="description">
          <Typography
            className="quoteNumber contractNotificationBar"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Contract #{notification?.Contract?.ContractNumber} -{" "}
            {notification?.Contract?.Title}
          </Typography>
          <Typography className="additionalInfo contractNotificationBar">
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </Typography>
        </Grid>
        <Typography className="time contractNotificationBar">
          {formattedTime}
        </Typography>
      </Grid>
    </Grid>
  );
};

const AppointmentConfirmNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);

  const contractNumber = notification?.Visit?.ContractNumber || "N/A";
  const appointmentDate =
    notification?.Visit?.StartDate || "Date not available";
  const customerName =
    `${notification?.Customer?.FirstName} ${notification?.Customer?.LastName}` ||
    "Customer Name Not Available";

  return (
    <Grid
      className="notificationCard"
      onClick={() =>
        onClick(
          notification?.ContractId,
          notification?.NotificationId,
          "contract"
        )
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={Appointment} alt="Contract Icon" />
      </Grid>
      <Grid className="notificationContent" style={{ cursor: "pointer" }}>
        <Typography className="title">
          Appointment on {appointmentDate} for Contract #{contractNumber} {""}
          Confirmed by customer -
        </Typography>
        <Grid className="description">
          <Typography
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {customerName}
          </Typography>
        </Grid>
        <Typography className="time">{formattedTime}</Typography>
      </Grid>
    </Grid>
  );
};

const QuoteConfirmNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);

  const contractNumber = notification?.Visit?.ContractNumber || "N/A";
  const appointmentDate = notification?.Visit?.StartDate || "N/A";
  const workerName = notification?.Worker?.FullName || "N/A";

  return (
    <Grid
      className="notificationCard contractNotificationImgCon"
      onClick={() =>
        onClick(
          notification?.ContractId,
          notification?.NotificationId,
          "contract"
        )
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </Grid>
      <Grid className="notificationContent" style={{ cursor: "pointer" }}>
        <Typography className="title contractNotificationBar">
          Appointment on {appointmentDate} for Contract #{contractNumber}{" "}
          Confirmed by Worker -
        </Typography>
        <Grid className="description">
          <Typography
            className="approvequote contractNotificationBar"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {workerName}
          </Typography>
        </Grid>
        <Typography className="time contractNotificationBar">
          {formattedTime}
        </Typography>
      </Grid>
    </Grid>
  );
};

const WorkerCreateNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);
  return (
    <Grid
      className="notificationCard"
      onClick={() =>
        onClick(notification?.WorkerId, notification?.NotificationId, "worker")
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </Grid>
      <Grid className="notificationContent" style={{ cursor: "pointer" }}>
        <Typography className="title">
          {notification?.Company?.ownerName} Created A Worker -
        </Typography>
        <Grid className="description">
          <Typography
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {notification?.Worker?.FullName}
          </Typography>
        </Grid>
        <Typography className="time">{formattedTime}</Typography>
      </Grid>
    </Grid>
  );
};
const ProductAndServiceCreateNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);
  return (
    <Grid
      className="notificationCard"
      onClick={() =>
        onClick(
          notification?.ProductId,
          notification?.NotificationId,
          "productandservice"
        )
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={quote} alt="Quote Icon" />
      </Grid>
      <Grid className="notificationContent" style={{ cursor: "pointer" }}>
        <Typography className="title">
          {notification?.Company?.ownerName} Created A{" "}
          {notification?.ProductAndService?.Type} -
        </Typography>
        <Grid className="description">
          <Typography
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {notification?.ProductAndService?.Name}
          </Typography>
        </Grid>
        <Typography className="time">{formattedTime}</Typography>
      </Grid>
    </Grid>
  );
};
const CustomerNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);

  return (
    <Grid
      className="notificationCard contractNotificationImgCon"
      onClick={() =>
        onClick(
          notification?.CustomerId,
          notification?.NotificationId,
          "customer"
        )
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={customerIcon} alt="Customer Icon" />
      </Grid>
      <Grid className="notificationContent" style={{ cursor: "pointer" }}>
        <Typography className="title contractNotificationBar">
          {notification?.Company?.ownerName} added a new customer:{" "}
        </Typography>
        <Grid className="description">
          <Typography
            className="quoteNumber contractNotificationBar"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </Typography>
        </Grid>
        <Typography className="time contractNotificationBar">
          {formattedTime}
        </Typography>
      </Grid>
    </Grid>
  );
};
const InvoiceNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);

  return (
    <Grid
      className="notificationCard contractNotificationImgCon"
      onClick={() =>
        onClick(
          notification?.InvoiceId,
          notification?.NotificationId,
          "invoice"
        )
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer" style={{ cursor: "pointer" }}>
        <img src={InvoiceIcon} alt="Invoice Icon" />
      </Grid>
      <Grid className="notificationContent" style={{ cursor: "pointer" }}>
        <Typography className="title contractNotificationBar">
          {notification?.Company?.ownerName} created a invoice - $
          {`${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(notification?.Invoice?.Total ?? 0)}`}
        </Typography>
        <Grid className="description">
          <Typography
            className="quoteNumber contractNotificationBar"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Invoice #{notification?.Invoice?.InvoiceNumber} -{" "}
            {notification?.Invoice?.Subject}
          </Typography>
          <Typography className="additionalInfo contractNotificationBar">
            {notification?.Customer?.FirstName}{" "}
            {notification?.Customer?.LastName}
          </Typography>
        </Grid>
        <Typography className="time contractNotificationBar">
          {formattedTime}
        </Typography>
      </Grid>
    </Grid>
  );
};
const QuoteChangeRequestNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);

  return (
    <Grid
      className="notificationCard"
      onClick={() =>
        onClick(notification?.QuoteId, notification?.NotificationId, "Chnge")
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer">
        <img src={quote} alt="Quote Change Request Icon" />
      </Grid>
      <Grid className="notificationContent">
        <Typography className="title">
          {notification?.Customer?.FirstName} {notification?.Customer?.LastName}
          {""} Requested a change to quote -
          {`${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(notification?.Quote?.Total ?? 0)}`}
        </Typography>
        <Grid className="description">
          <Typography
            className="requestquote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Quote #{notification?.Quote?.QuoteNumber} - {""}
            {notification?.Quote?.Title}
          </Typography>
          <Typography className="additionalInfo">
            Request Message:{" "}
            {notification?.Request?.RequestMessage || "No message"}{" "}
          </Typography>
        </Grid>
        <Typography className="time">{formattedTime}</Typography>
      </Grid>
    </Grid>
  );
};
const QuoteApproveNotification = ({ onClick, notification }) => {
  const AddedAt = notification?.AddedAt;
  const formattedTime = AddedAt ? formatTimeAgo(AddedAt, new Date()) : "";

  const notificationStyle = formatStyle(notification);

  return (
    <Grid
      className="notificationCard"
      onClick={() =>
        onClick(notification?.QuoteId, notification?.NotificationId, "approve")
      }
      style={notificationStyle}
    >
      <Grid className="iconContainer">
        <img src={quote} alt="Quote Approve Icon" />
      </Grid>
      <Grid className="notificationContent">
        <Typography className="title">
          {notification?.Customer?.FirstName} {notification?.Customer?.LastName}{" "}
          Approved quote -{" "}
          {`${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(notification?.Quote?.Total ?? 0)}`}
        </Typography>
        <Grid className="description">
          <Typography
            className="approvequote"
            style={{ fontWeight: notification?.IsView ? "normal" : "bold" }}
          >
            Quote #{notification?.Quote?.QuoteNumber} -{" "}
            {notification?.Quote?.Title}
          </Typography>
        </Grid>
        <Typography className="time">{formattedTime}</Typography>
      </Grid>
    </Grid>
  );
};

const NotificationsPage = ({ handleCloseNotifications, isSwitchOn }) => {
  const { companyName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [tokenDecode, setTokenDecode] = useState(null);
  const [loader, setLoader] = useState(true);

  const fetchNotifications = async () => {
    try {
      setLoader(true);
      const response = await AxiosInstance.get(`/notifications/${tokenDecode}`);

      if (response.data.statusCode === 200) {
        const filteredNotifications = response?.data?.notifications?.filter(
          (notification) =>
            notification?.Quote?.QuoteNumber ||
            notification?.Contract?.ContractNumber ||
            notification?.Customer?.FirstName ||
            notification?.Invoice?.InvoiceNumber ||
            notification?.QuoteChangeRequest ||
            notification?.QuoteApprove ||
            notification?.WorkerId ||
            notification?.ProductId
        );
        setNotifications(filteredNotifications || []);
      } else {
        console.warn("No notifications found in response.");
        setNotifications([]);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
      setNotifications([]);
    } finally {
      setLoader(false);
    }
  };

  const fetchTokenDecode = async () => {
    try {
      setLoader(false);
      const res = await handleAuth(navigate, location);
      if (res?.data?.companyId) {
        setTokenDecode(res.data.companyId);
      } else {
        console.error("Company ID not found in response.");
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchTokenDecode();
  }, [navigate, location]);

  useEffect(() => {
    if (tokenDecode) {
      fetchNotifications();
    }
  }, [tokenDecode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(false);
        const res = await handleAuth(navigate, location);
        if (res?.data?.companyId) {
          setTokenDecode(res.data.companyId);
        } else {
          console.error("Company ID not found in response.");
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchData();
  }, [navigate, location]);

  useEffect(() => {
    if (tokenDecode) {
      fetchNotifications();
    }
  }, [tokenDecode]);

  const handleNotificationClick = async (id, notificationId, type) => {
    try {
      await AxiosInstance.put(`/notifications/${notificationId}`);
      handleCloseNotifications();
      await fetchNotifications();

      const route =
        type === "quote"
          ? "quotes-detail"
          : type === "contract"
          ? "contractdetails"
          : type === "customer"
          ? "customerdetails"
          : type === "invoice"
          ? "invoice-details"
          : type === "Chnge"
          ? "quotes-detail"
          : type === "approve"
          ? "quotes-detail"
          : type === "worker"
          ? "add-user"
          : type === "productandservice"
          ? "product&service"
          : null;
      if (route) {
        navigate(`/${companyName}/${route}`, {
          state: { id: id, navigats: ["/index", `/${route}`] },
          replace: true,
        });
      }
    } catch (error) {
      console.error("Error marking notification as read:", error.message);
    }
  };

  return (
    <>
      {loader ? (
        <Grid className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
          <Grid className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
            <LoaderComponent loader={loader} height="50" width="50" />
          </Grid>
        </Grid>
      ) : (
        <Grid className="notificationsContainer quotes-notification">
          {notifications.length > 0 ? (
            notifications.map((notification) => {
              if (
                isSwitchOn.worker &&
                notification?.CreatedBy === "WorkerRole"
              ) {
                return (
                  <WorkerCreateNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }
              if (
                isSwitchOn.AppointmentConfirmNotification &&
                notification?.CreatedBy?.trim().toLowerCase() === "confirm"
              ) {
                return (
                  <AppointmentConfirmNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }
              if (
                isSwitchOn.QuoteChangeRequestNotification &&
                notification?.CreatedBy === "customer"
              ) {
                return (
                  <QuoteChangeRequestNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }
              if (
                isSwitchOn?.ProductAndServiceCreateNotification &&
                notification?.CreatedBy === "ProductAndServices"
              ) {
                return (
                  <ProductAndServiceCreateNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }
              if (
                isSwitchOn.QuoteConfirmNotification &&
                notification?.CreatedBy === "Worker"
              ) {
                return (
                  <QuoteConfirmNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }
              if (isSwitchOn?.quotes && notification?.Quote) {
                if (notification.CreatedBy === "Customer") {
                  return (
                    <QuoteApproveNotification
                      key={notification?._id}
                      notification={notification}
                      onClick={handleNotificationClick}
                    />
                  );
                } else {
                  return (
                    <QuoteNotification
                      key={notification?._id}
                      notification={notification}
                      onClick={handleNotificationClick}
                    />
                  );
                }
              }
              // if (notification?.Contract) {
              //   return (
              //     <ContractNotification
              //       key={notification._id}
              //       notification={notification}
              //       onClick={handleNotificationClick}
              //     />
              //   );
              // }
              if (isSwitchOn.contractConfirm && notification?.Contract) {
                return (
                  <ContractNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }

              // {
              //   isSwitchOn && (
              //     <div>
              //       {notifications?.map((notification) => {
              //         if (notification?.Contract) {
              //           return (
              //             <ContractNotification
              //               key={notification._id}
              //               notification={notification}
              //               onClick={handleNotificationClick}
              //             />
              //           );
              //         }
              //         return null;
              //       })}
              //     </div>
              //   );
              // }
              if (isSwitchOn?.customers && notification?.Customer) {
                return (
                  <CustomerNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }
              if (isSwitchOn?.invoices && notification?.Invoice) {
                return (
                  <InvoiceNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }

              // {
              //   isSwitchOn && notification?.Invoice && (
              //     <InvoiceNotification
              //       key={notification._id}
              //       notification={notification}
              //       onClick={handleNotificationClick}
              //     />
              //   );
              // }
              if (isSwitchOn?.worker && notification?.Worker) {
                return (
                  <WorkerCreateNotification
                    key={notification?._id}
                    notification={notification}
                    onClick={handleNotificationClick}
                  />
                );
              }

              return null;
            })
          ) : (
            <Grid className="mt-4 text-center" style={{ color: "#063164" }}>
              No notifications available.
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default NotificationsPage;
