import React, { useState, useEffect } from "react";
import { Button, FormControl, Divider } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import toast, { Toaster } from "react-hot-toast";
import "./style.css";
import {
  Card,
  Dropdown,
  DropdownItem,
  Navbar,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import SettingSidebar from "../../../../components/Setting/SettingSidebar.js";
import profileIcon from "../../../../assets/image/icons/profile-icon.jpg";
import { Country } from "country-state-city";
import sliderindicator from "../../../../assets/image/icons/sliderindicator.svg";
import { Circles, ColorRing } from "react-loader-spinner";
import MenuIcon from "@mui/icons-material/Menu";
import AxiosInstance from "../../../AxiosInstance.js";
import InputText from "../../../../components/InputFields/InputText.js";
import Address from "../../../../components/Address/index.js";
import sendToast from "../../../../components/Toast/sendToast.js";

const CustomerProfile = () => {
  const navigate = useNavigate();
  const { companyName } = useParams();
  const cdnUrl = process.env.REACT_APP_CDN_API;

  const [loader, setLoader] = useState(true);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [postLoader, setPostLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [CompanyId] = useState(localStorage.getItem("CompanyId"));
  const [CustomerId] = useState(localStorage.getItem("CustomerId"));
  const [WorkerId] = useState(localStorage.getItem("worker_id"));

  const [oldData, setOldData] = useState("");
  const profileFormik = useFormik({
    initialValues: {
      CompanyId: "",
      EmailAddress: "",
      PhoneNumber: "",
      Address: "",
      City: "",
      State: "",
      Zip: "",
      Password:"",
      Country: "",
      ProfileImage: "",
      FullName:"",
    },
    validationSchema: Yup.object({
      FullName: Yup.string().required("Required"),
      // PhoneNumber: Yup.string().required("Required"),
      PhoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Phone number must be in the format (xxx) xxx-xxxx"
      ),
      EmailAddress: Yup.string()
        .email("Invalid email")
        .required("Email is required")
        .matches(/^[^@]+@[^@]+\.[^@]+$/, "Email must contain '@' and '.'"),
      Address: Yup.string().required("Address is required"),
      City: Yup.string().required("City is required"),
      State: Yup.string().required("State is required"),
      Zip: Yup.string().required("Zip code is required"),
      Country: Yup.string().required("Country is required"),
    }),
    onSubmit: async (values) => {
      const deepEqual = (a, b) =>
        JSON.stringify(a) === JSON.stringify(b) ||
        (Object.keys(a).length === Object.keys(b).length &&
          Object.keys(a).every((key) => String(a[key]) === String(b[key])));

      const isEqual = deepEqual(oldData, values);
      if (!isEqual) {
        try {
          setPostLoader(true);
          const updatedProfile = {
            ...values,
          };
          const res = await AxiosInstance.put(
            `/worker/profile/${WorkerId}`,
            updatedProfile
          );
          if (res.data.statusCode === 200) {
            toast.success(res.data.message);
            getData();
          }
        } catch (error) {
          console.error("Error updating profile:", error);
        } finally {
          setPostLoader(false);
        }
      } else {
        sendToast("No changes available to update");
      }
    },
  });


  // Phone number formatting function
  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const match = PhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/); // Matches (xxx) xxx-xxxx

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return value;
  };

  // Handle phone number change and format it
  const handlePhoneChange = (e) => {
    if (profileFormik.values.PhoneNumber?.length > e.target.value?.length) {
      profileFormik.setFieldValue("PhoneNumber", e.target.value);
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      profileFormik.setFieldValue("PhoneNumber", formattedValue);
    }
    setIsEdited(true);
  };

  const getData = async () => {
    try {
      const allCountries = Country.getAllCountries();
      setCountries(allCountries);
      const res = await AxiosInstance.get(`/worker/profile/${WorkerId}`);
      if (res.data.statusCode === 200) {
        setOldData(res.data.data);
        setUploadedImageUrl(res.data.data.ProfileImage);
        profileFormik.setValues(res.data.data);

        if (res.data.data.Country) {
          const selectedCountry = allCountries.find(
            (item) => item.name === res.data.data.Country
          );
          setSelectedCountry(selectedCountry);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
      setImageLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, [CompanyId]);

  const [iamgeLoader, setImageLoader] = useState(true);
  const handleFileChange = (file) => {
    setImageLoader(true);
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
        "image/svg+xml",
        "image/tiff",
        "image/x-icon",
      ];
      if (allowedTypes.includes(file.type)) {
        uploadImage(file);
      } else {
        console.error("Unsupported file type. Only JPG and PNG are allowed.");
        return;
      }
    }
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      const url = `${cdnUrl}/upload`;

      const result = await AxiosInstance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const image = result.data.files[0].filename;
      if (image) {
        const res = await AxiosInstance.put(`/worker/profile/${WorkerId}`, {
          ProfileImage: image,
        });
        if (res.data.statusCode === 200) {
          toast.success("Profile image updated successfully.");
          setUploadedImageUrl(image);
          profileFormik.setFieldValue("ProfileImage", image);
          await getData();
        } else {
          sendToast(
            "There was an issue updating image. Please try again later."
          );
        }
      } else {
        sendToast("There was an issue updating image. Please try again later.");
      }
    } catch (error) {
      sendToast("There was an issue updating image. Please try again later.");
    } finally {
      setImageLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "Country") {
      profileFormik.setFieldValue(name, value);
    } else {
      profileFormik.setFieldValue(name, type === "checkbox" ? checked : value);
    }
    setIsEdited(true);
  };

  const toggle = () => setIsOpenDropDown(!isOpenDropDown);

  return (
    <>
      <div className="manage-team">
        {/* <div className="col-2 h-100 hiren">
          <SettingSidebar />
        </div> */}
        <Navbar
          className="navbar-setting"
          style={{
            zIndex: "9",
            borderRadius: "5px",
            display: "block",
          }}
        >
          <Dropdown
            className="dropdown menus"
            isOpen={isOpenDropDown}
            toggle={toggle}
            style={{ width: "100%", color: "#fff" }}
          >
            <DropdownToggle
              className="dropdowntoggle"
              style={{
                background: "#E88C44",
                border: "none",
                color: "#FFFF",
              }}
            >
              <IconButton>
                <MenuIcon style={{ color: "#FFFF" }} />
              </IconButton>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <h6 style={{ fontWeight: 600, marginBottom: "10px" }}>
                  BUSINESS <br /> MANAGEMENT
                </h6>
              </DropdownItem>
              <DropdownItem>
                <div className="d-flex flex-column">
                  <div
                    className="sidebar-link-setting"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/${companyName}/product&service`, {
                        state: { navigats: ["/index", "/product&service"] },
                      });
                    }}
                  >
                    Products & Services
                  </div>
                  <div
                    className="sidebar-link-setting"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/${companyName}/profile`, {
                        state: { navigats: ["/index", "/profile"] },
                      });
                    }}
                  >
                    Manage Team
                  </div>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Navbar>

        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%", width: "100%", marginTop: "25%" }}
          >
            <Circles
              height="50"
              width="50"
              color="#063164"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loader}
            />
          </div>
        ) : (
          <div
            className="col-12 "
            style={{
              // borderLeft: "0.5px solid rgba(6, 49, 100, 30%)",
              paddingLeft: "20px",
              marginTop: "-30px",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ color: "#063164", fontWeight: 700, marginTop: "4%" }}
            >
              <h3 className="text-blue-color mt-3">
                <b>
                  {profileFormik.values?.FullName}
                </b>
              </h3>
            </div> 
            <Card
              style={{
                padding: "40px",
                marginTop: "10px",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                marginBottom:"20px",
              }}
            >
              <div className="row">
                <div className="d-flex col-lg-6 order-2 order-lg-1">
                  <div>
                    <InputText
                      value={profileFormik.values?.FullName}
                      onChange={handleChange}
                      id="FullName"
                      name="FullName"
                      label="Full Name"
                      placeholder="Enter Full Name  here..."
                      type="text"
                      className="mb-3 my-2 textfield_bottom w-100"
                    />
                    <InputText
                      value={profileFormik.values?.EmailAddress}
                      onChange={handleChange}
                      id="EmailAddress"
                      name="EmailAddress"
                      label="Email Address"
                      placeholder="Enter Email Address here..."
                      type="email"
                      className="mb-3 my-2 textfield_bottom w-100"
                    />
                    <InputText
                      value={profileFormik.values?.PhoneNumber}
                      onChange={handlePhoneChange}
                      id="PhoneNumber"
                      name="PhoneNumber"
                      label="Mobile Number"
                      placeholder="Enter Mobile Number here..."
                      type="text"
                      className="mb-3 my-2 textfield_bottom w-100"
                    />

                    <Address
                      setSelectedCountry={setSelectedCountry}
                      selectedCountry={selectedCountry}
                      countries={countries}
                      handleChange={handleChange}
                      formik={profileFormik}
                    />
                    <InputText
                      value={profileFormik.values?.Password}
                      onChange={handleChange}
                      id="Password"
                      name="Password"
                      label="Password "
                      placeholder="Enter password here..."
                      type="text"
                      className="mb-3 my-2 textfield_bottom w-100"
                    />

                    <div className="mb-3 my-2 btn-bottm">
                      <Button
                        className="save-profile-btn"
                        onClick={profileFormik.handleSubmit}
                        style={{
                          fontSize: "16px",
                          textTransform: "none",
                          backgroundColor: "#063164",
                          color: "#fff",
                          width: "20%",
                          opacity: isEdited ? 1 : 0.5,
                        }}
                        disabled={!isEdited}
                      >
                        {postLoader ? (
                          <ColorRing
                            height="30"
                            width="30"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                            ariaLabel="circles-loading"
                            visible={true}
                          />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    flexItem
                    className="d-none d-lg-block"
                    style={{
                      marginLeft: "10%",
                      marginRight: "3%",
                      border: "0.5px solid",
                    }}
                  />
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-items-center flex-column order-1 order-lg-2 mb-2">
                  <div
                    className="text-center "
                    style={{ marginTop: "0px", marginBottom: "30px" }}
                  >
                    <p
                      className="text-blue-color"
                      style={{
                        fontSize: "30px",
                        fontWeight: "600",
                      }}
                    >
                      Personal Information
                    </p>
                  </div>
                  <div
                    className="text-center"
                    style={{ marginTop: "0px", marginBottom: "30px" }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#fff",
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                        boxShadow: "0 4px 5px rgba(0, 0, 0, 0.4)",
                      }}
                    >
                      {iamgeLoader ? (
                        <div className="d-flex justify-content-center">
                          <ColorRing
                            height="30"
                            width="30"
                            colors={["#000", "#000", "#000", "#000", "#000"]}
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <img
                          src={
                            uploadedImageUrl
                              ? `${cdnUrl}/upload/${uploadedImageUrl}`
                              : profileIcon
                          }
                          alt="Profile"
                          style={{
                            borderRadius: "50%",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="upload-button">
                      <Button
                        component="span"
                        style={{
                          color: "#063164",
                          background: "#FFFF",
                          textTransform: "none",
                          border: "1px solid #063164",
                          marginTop: "20px",
                          fontSize: "12px",
                          cursor: "pointer",
                          padding: "8px 16px",
                          borderRadius: "4px",
                        }}
                      >
                        Upload image here...
                      </Button>
                    </label>
                    <Input
                      id="upload-button"
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileChange(e.target.files[0]);
                      }}
                      multiple={false}
                    />
                  </div>

                  <div style={{ marginTop: "40px" }}>
                    <p
                      className="text-blue-color"
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      Use this form to update your personal information. Ensure
                      all details are accurate and up-to-date.
                    </p>
                  </div>
                  <div>
                    <img src={sliderindicator} />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
      <Toaster />
    </>
  );
};

export default CustomerProfile;
